import { getMinPosition } from "../../common/DataUtils";

import {
  PaymentData,
  CourseData,
  CustomerLog,
  CustomLog,
} from "../../types/data_types";

import { busStopId2price } from "../../common/Utils";
import { butStopId2Pin } from "../../common/DataUtils";

import * as common from "../../data/common";

export const moment2min = (mom: any) => {
  return Math.floor(mom.toDate().getTime() / 1000 / 60);
};

export const getActualDrivingTimeMinutes = (carLog: any) => {
  if (carLog && carLog.minTimeEndAt && carLog.minTimeStartAt) {
    const diffMinutes =
      Math.floor(carLog.minTimeEndAt.seconds / 60) -
      Math.floor(carLog.minTimeStartAt.seconds / 60);
    return diffMinutes;
  }
  return null;
};

export const getAllDrivingTimeMinutes = (carLog: any) => {
  if (carLog && carLog.endAt && carLog.startAt) {
    const diffMinutes =
      Math.floor(carLog.endAt.seconds / 60) -
      Math.floor(carLog.startAt.seconds / 60);
    return diffMinutes;
  }
  return null;
};

export const getForwardDrivingTimes = (
  actualDrivingTime: number | null,
  allDrivingTime: number | null,
) => {
  if (actualDrivingTime === null || allDrivingTime === null) {
    return null;
  }
  return allDrivingTime - actualDrivingTime;
};
const formatNum = (num: number) => {
  return num < 10 ? "0" + String(num) : String(num);
};
export const minutes2DisplayTime = (minutes: number | null) => {
  if (minutes === null) {
    return "";
  }
  return [formatNum(Math.floor(minutes / 60)), formatNum(minutes % 60)].join(
    ":",
  );
};
//console.log(seconds2DisplayTime(diffSeconds));
//}

export const log2PaymentData = (log: any) => {
  const paymentData: PaymentData = {
    cash: log.priceData.finalPrice,
    taxi_ticket: 0,
    local_currency: 0,
  };
  return paymentData;
};

export const updatePaymentData2 = (log: any): PaymentData => {
  const paymentData = log.paymentData;
  if (
    paymentData.taxi_ticket + paymentData.local_currency >
    log.priceData.finalPrice
  ) {
    return {
      cash: log.priceData.finalPrice,
      taxi_ticket: 0,
      local_currency: 0,
    };
  }

  const cash =
    log.priceData.finalPrice -
    paymentData.taxi_ticket -
    paymentData.local_currency;
  if (cash >= 0) {
    paymentData.cash = cash;
  }

  return paymentData;
};

export const pickupKey2PositionData = (
  key: number,
  isOn: boolean,
  stopsData: any,
  courceData: CourseData,
  serviceId: number,
) => {
  const pickup = (stopsData || []).find((e: any) => {
    return e.id === key;
  });
  const stopId = (butStopId2Pin(pickup.busStop1Id, courceData.pins) || {}).id;

  const positionData: any = {
    index: key,
    id: "pickupPoint",
    clickPosition: pickup.location,
    position: pickup.location,
    name: pickup.name,
    type: "pickup",
    busStop1Id: pickup.busStop1Id,
    stopId,
  };
  positionData["positionIndex"] = getMinPosition(
    courceData,
    serviceId,
    positionData,
    isOn,
  ).minPosition;
  return positionData;
};

export const numberDataset = {
  data: [
    { name: "0", value: 0 },
    { name: "1", value: 1 },
    { name: "2", value: 2 },
    { name: "3", value: 3 },
  ],
};

export const customerLog2type = (customerLog: CustomerLog): string => {
  if (customerLog.hascommuterpass) {
    return "hascommuterpass";
  } else if (customerLog.pointC) {
    return "pointC";
  } else if (customerLog.returnLicense) {
    return "returnLicense";
  } else if (customerLog.handicapped) {
    return "handicapped";
  } else if (customerLog.isAdult === 0) {
    return "child";
  }
  return "adult";
};

export const log2customData = (customerLog: CustomerLog) => {
  const type = customerLog2type(customerLog);
  const ret: CustomLog = {
    withChild: customerLog.child || 0,
    withBaby: customerLog.baby || 0,
    type,
    onPosition: customerLog.onPosition,
    offPosition: customerLog.offPosition,
    canceled: customerLog.notFoundAt || null,
  };
  // todo base price based on location.
  return ret;
};

export const log2Price = (log: CustomerLog, courseData: CourseData) => {
  if (log.notFoundAt) {
    return {
      price: 0,
      rawPrice: 0,
      basePrice: 0,
      adjustPrice: 0,
    };
  }
  const onBusStopId = log.customLog.onPosition.busStop1Id;
  const offBusStopId = log.customLog.offPosition.busStop1Id;
  const priceData = busStopId2price(
    courseData,
    onBusStopId as number,
    offBusStopId as number,
  );

  const basePrice = priceData.price;
  let price = 0;

  if (log.customLog.type === "hascommuterpass") {
    price = common.commuterPassPrice;
  } else if (
    log.customLog.type === "returnLicense" ||
    log.customLog.type === "handicapped" ||
    log.customLog.type === "child"
  ) {
    price = basePrice * 0.5;
  } else if (log.customLog.type === "adult") {
    price = basePrice;
  }
  if (log.customLog.withChild) {
    price += basePrice * log.customLog.withChild * 0.5;
  }
  const rawPrice = price;
  if (log.priceData.adjustPrice) {
    price += log.priceData.adjustPrice;
  }
  return {
    price,
    rawPrice,
    basePrice,
    adjustPrice: log.priceData.adjustPrice ? log.priceData.adjustPrice : 0,
  };
};
