import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import Header from "./admin/Header";

import ReservationMap from "./admin/ReservationMap";
import User from "./admin/User";
import UserReservation from "./admin/UserReservation";
import UserEdit from "./admin/UserEdit";
import UserNew from "./admin/UserNew";
import News from "./admin/News";
import AdminIndex from "./admin/Index";
import Reservations from "./admin/Reservations";
import Drivers from "./admin/Drivers";
import DriverList from "./admin/DriverList";
import Line from "./admin/Line";

import DailyLog from "./admin/DailyLog";
import DailyLogSummary from "./admin/DailyLogSummary";
import DailyLogRaw from "./admin/DailyLogRaw";
import MonthlyLog from "./admin/MonthlyLog";

import Map from "./admin/Map";

import ErrorBoundary from "./ErrorBoundary";

import CourseData from "./admin/CourseData";

import { allLineData } from "./data/courses";

import { Company, LineData } from "./types/data_types";

import { RootGrid } from "./styles";

import { DefaultDbProps } from "./props";

function Admin(props: DefaultDbProps) {
  const { user, db } = props;

  const [reservedUser, setReservedUser] = useState();
  const [company, setCompany] = useState<Company>();
  const [headerTitle, setHeaderTitle] = useState("");

  useEffect(() => {
    (async () => {
      if (user) {
        const companyId = user.user.companyId;
        const _company = await db.doc(`companies/${companyId}`).get();
        if (_company.exists) {
          const data = _company.data();
          if (data) {
            data.id = _company.id;
            setCompany(data as Company);
          }
        }
      }
    })();
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!company) {
    return (
      <React.Fragment>
        <RootGrid container alignItems="center" direction="row">
          <div>
            No Company Data
            <br />
            <Link to="/">Back</Link>
          </div>
        </RootGrid>
      </React.Fragment>
    );
  }
  const lineData: LineData = allLineData[company.id];

  const params = {
    user,
    db,
    reservedUser,
    setReservedUser,
    company,
    lineData,
    setHeaderTitle,
  };

  return (
    <React.Fragment>
      <Header {...params} headerTitle={headerTitle} />
      <ErrorBoundary>
        <RootGrid container alignItems="center" direction="row">
          <Routes>
            <Route path="" element={<AdminIndex {...props} {...params} />} />
            <Route
              path="reservation/map/:customerId/:reservationId?"
              element={<ReservationMap {...props} {...params} />}
            />
            <Route path="user" element={<User {...props} {...params} />} />
            <Route
              path="drivers"
              element={<Drivers {...props} {...params} />}
            />
            <Route
              path="driverlist"
              element={<DriverList {...props} {...params} />}
            />
            <Route
              path="lines/:lineId?"
              element={<Line {...props} {...params} />}
            />
            <Route
              path="monthlylog/:year?/:month?/:lineId?/:serviceId?"
              element={<MonthlyLog {...props} {...params} />}
            />
            <Route
              path="dailylog/:year?/:month?/:day?"
              element={<DailyLog {...props} {...params} />}
            />
            <Route
              path="dailylogsummary/:year?/:month?"
              element={<DailyLogSummary {...props} {...params} />}
            />
            <Route
              path="dailylograw/:year?/:month?/:day?/:lineId?/:serviceId?"
              element={<DailyLogRaw {...props} {...params} />}
            />
            <Route
              path="user/:customerId/reservation/:reservationId?"
              element={<UserReservation {...props} {...params} />}
            />
            <Route
              path="user/:customerId/edit"
              element={<UserEdit {...props} {...params} />}
            />
            <Route
              path="user/new"
              element={<UserNew {...props} {...params} />}
            />
            <Route path="news" element={<News {...props} {...params} />} />
            <Route
              path="reservations/:year?/:month?/:day?/:_line?/:_service?"
              element={<Reservations {...props} {...params} />}
            />
            <Route path="map" element={<Map {...props} {...params} />} />
            <Route
              path="linedata"
              element={<CourseData {...props} {...params} />}
            />
          </Routes>
        </RootGrid>
      </ErrorBoundary>
    </React.Fragment>
  );
}

export default Admin;
