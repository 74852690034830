import React from "react";
import { Typography } from "@mui/material";
import Header from "./Header";
import { useLocation } from "react-router-dom";

import { CaptionGrip, RootGrid } from "./styles";

import { DefaultProps } from "./props";

const LoginRequire = (props: DefaultProps) => {
  const { user } = props;

  const location = useLocation();
  const path = location.pathname;
  const loginPath = "/Login/target" + path;
  return (
    <React.Fragment>
      <Header user={user} login={loginPath} />
      <RootGrid container alignItems="center" direction="row">
        <CaptionGrip>
          <Typography component="h2" variant="h5" gutterBottom>
            ログインしてください
          </Typography>
        </CaptionGrip>
      </RootGrid>
    </React.Fragment>
  );
};

export default LoginRequire;
