import firebase from "firebase/compat/app";
import React, { useState } from "react";
import { Button, TextField } from "@mui/material";

import { styled } from "@mui/system";

import { BusStopData } from "../types/data_types";

const NoWrapTD = styled("td")(({ theme }) => ({
  whiteSpace: "nowrap",
}));
const GraySpan = styled("span")(({ theme }) => ({
  color: "#aaaaaa",
}));

type Props = {
  db: firebase.firestore.Firestore;
  path: string;
  stop: BusStopData;
};
const Pickup: React.FC<Props> = ({ db, path, stop }) => {
  const [updateData, setUpdateData] = useState(stop);

  const onChange = (keyName: keyof BusStopData, isNum = false) => {
    return (e: any) => {
      const tmp = { ...updateData };
      if (isNum) {
        if (/^[0-9]+$/.test(e.target.value)) {
          tmp[keyName] = Number(e.target.value) as never;
        } else if (e.target.value === "") {
          tmp[keyName] = "" as never;
        } else {
          tmp[keyName] = "" as never;
        }
      } else {
        tmp[keyName] = e.target.value as never;
      }
      setUpdateData(tmp);
    };
  };
  const update = async () => {
    const newStop = { ...stop };
    const updatePath = `${path}/${stop.id}`;
    if (Object.keys(newStop).length > 0) {
      await db.doc(updatePath).set(updateData, { merge: true });
    }
    setUpdateData({} as BusStopData);
  };

  const deletePin = async () => {
    if (window.confirm("本当に削除しますか？")) {
      const updatePath = `${path}/${stop.id}`;
      await db.doc(updatePath).set({ deletedFlag: true }, { merge: true });
    }
  };

  const _isUpdate = (key: keyof BusStopData) => {
    return updateData[key] !== undefined && updateData[key] !== stop[key];
  };
  const isUpdate = _isUpdate("name") || _isUpdate("sortKey");
  const updatedValue = (key: keyof BusStopData) => {
    return updateData[key] as string;
  };
  console.log(updateData);
  return (
    <tr key={stop.id}>
      {stop.type === "busStop" ? (
        <td colSpan={5}>
          <h2>バス停: {stop.name}</h2>
        </td>
      ) : (
        <React.Fragment>
          <NoWrapTD>
            <span
              style={{
                width: "300px",
                ...(isUpdate ? { backgroundColor: "yellow" } : {}),
              }}
            >
              乗降場所
            </span>
            :
            {stop.deletedFlag === true ? (
              <GraySpan>{updatedValue("name")}</GraySpan>
            ) : (
              <TextField
                value={updatedValue("name")}
                style={{
                  width: "300px",
                  ...(isUpdate ? { backgroundColor: "yellow" } : {}),
                }}
                color={isUpdate ? "secondary" : "primary"}
                onChange={onChange("name")}
              />
            )}
          </NoWrapTD>
          <NoWrapTD>
            / 並び:
            {stop.deletedFlag === true ? (
              <GraySpan>{updatedValue("sortKey")}</GraySpan>
            ) : (
              <TextField
                value={updatedValue("sortKey")}
                style={{
                  width: "80px",
                  ...(isUpdate ? { backgroundColor: "yellow" } : {}),
                }}
                color={isUpdate ? "secondary" : "primary"}
                onChange={onChange("sortKey", true)}
                type="number"
                inputProps={{ pattern: "d*" }}
              />
            )}
          </NoWrapTD>
          <NoWrapTD>
            {stop.deletedFlag === true ? (
              <GraySpan>
                {stop.location.lat} / {stop.location.lng}
              </GraySpan>
            ) : (
              <span>
                {stop.location.lat} / {stop.location.lng}
              </span>
            )}
          </NoWrapTD>
          <NoWrapTD>
            {stop.deletedFlag === true ? (
              ""
            ) : (
              <Button
                variant="contained"
                color={isUpdate ? "secondary" : "primary"}
                onClick={update}
                style={{ margin: "3px" }}
              >
                更新
              </Button>
            )}
            {stop.deletedFlag === true ? (
              "削除済み"
            ) : (
              <Button variant="outlined" color="primary" onClick={deletePin}>
                削除
              </Button>
            )}
          </NoWrapTD>
        </React.Fragment>
      )}
    </tr>
  );
};

export default Pickup;
