// week data
import * as Utils from "./Utils";
import {
  LineData,
  CourseData,
  ReservationFreeRidePositionData,
  CourseDataPin,
  Position,
  CourseDataService,
  CustomerData,
  BusStopDataSet,
  BusStopDataSetData,
  UserDataSetData,
  DriverData,
} from "../types/data_types";

import moment from "moment";

export const today = moment().format("YYYY-MM-DD");
export const tommorow = moment().add(1, "days").format("YYYY-MM-DD");
export const yesterday = moment().add(-1, "days").format("YYYY-MM-DD");

export const todayPath = moment().format("YYYY/MM/DD");
export const monthPath = moment().format("YYYY/MM");

export const getWeekDataset = () => {
  const weeks = [];

  for (let i = 0; i < 20; i++) {
    const day = moment().add(i, "days");
    weeks.push(day);
  }

  return {
    data: weeks.map((day, key) => {
      return {
        name: day.format("YYYY-MM-DD"),
        value: key,
      };
    }),
    name: "day",
  };
};

// line data
export const getLineDataset = (lineData: LineData) => {
  return {
    data: Object.values(lineData).map((line: CourseData) => {
      return {
        name: line.name,
        value: line.id,
      };
    }),
    name: "line",
  };
};

export const getLineDatasetWithSum = (lineData: LineData) => {
  const data = Object.values(lineData).map((line: CourseData) => {
    return {
      name: line.name,
      value: line.id,
    };
  });
  data.push({ name: "総括", value: "sum" });
  return {
    data,
    name: "line",
  };
};

export const getServiceDataset = (currentLine: CourseData, short = false) => {
  // for separate way
  return {
    data: (currentLine.services || []).map(
      (service: CourseDataService, key: number) => {
        return {
          name: short ? service.sname : Utils.getServiceLongName(service),
          value: service.id,
        };
      },
    ),
    name: "service",
  };
};

export const getServiceDatasetWithSum = (
  currentLine: CourseData,
  short = false,
) => {
  // for separate way
  // getBusStopFromWay(currentLine);
  if (!currentLine) {
    return { data: [], name: "service" };
  }
  const data: { name: string; value: string | number }[] = (
    currentLine.services || []
  ).map((service: CourseDataService, key: number) => {
    return {
      name: short
        ? service.sname
        : service.name + ":" + (service.inbound ? "上り" : "下り"),
      value: service.id,
    };
  });
  data.push({ name: "総括", value: "sum" });
  return {
    data,
    name: "service",
  };
};

export const butStopId2PinIndex = (
  busStopId: number,
  pins: CourseDataPin[],
): number => {
  const index = pins.findIndex(
    (pin: CourseDataPin) => pin.busStopId === Number(busStopId),
  );
  return index;
};
export const butStopId2Pin = (
  busStopId: number,
  pins: CourseDataPin[],
): CourseDataPin | undefined => {
  const pin = pins.find(
    (pin: CourseDataPin) => pin.busStopId === Number(busStopId),
  );
  return pin;
};
export const stopId2PinIndex = (
  stopId: string,
  pins: CourseDataPin[],
): number => {
  const index = pins.findIndex(
    (pin: CourseDataPin) => pin.id === Number(stopId),
  );
  return index;
};
export const stopId2Pin = (
  stopId: string,
  pins: CourseDataPin[],
): CourseDataPin | undefined => {
  const pin = pins.find((pin: CourseDataPin) => pin.id === Number(stopId));
  return pin;
};
// export const

export const filterOnOffPositionDataset = (
  dataSet: BusStopDataSet,
  lineId: string,
  serviceId: number,
  isOn: boolean,
  date: Date,
) => {
  const line10xId = "OtKXIZnkxxcBXdrmSANU";
  const line40xId = "xgncQ6JLTWJ4dvFSpJLk";

  if (lineId === line10xId) {
    const data = dataSet.data
      .filter((ele: BusStopDataSetData) => {
        // 102~105便では、鍵掛、久百々、大岐、以布利、は乗降できないので、予約時の乗降一覧から非表示にする
        if ([1, 2, 3, 4].includes(serviceId)) {
          return ![4, 5, 6, 7].includes(ele.busStop1Id);
        }
        return true;
      })
      .filter((ele: BusStopDataSetData) => {
        // 新設の横道は、木曜日の102便(1)、104(2)便のみ乗降可能 (2023/07追加)
        if (ele.busStop1Id === 9) {
          if (date.getDay() === 4 && [1, 2].includes(serviceId)) {
            return true;
          }
          return false;
        }
        return true;
      });

    return {
      name: dataSet.name,
      data,
    };
  }

  // 406便の降車場所選択のリストのみに養老や松崎を表示
  // -> 406以外は削除。406も乗車は削除
  if (lineId === line40xId) {
    if ([0, 1, 2, 3, 4].includes(serviceId) || (serviceId === 5 && isOn)) {
      const data = dataSet.data.filter((ele: BusStopDataSetData) => {
        return ![17, 18].includes(ele.busStop1Id);
      });

      return {
        name: dataSet.name,
        data,
      };
    }
  }
  return dataSet;
};

export const getUserDataset = (customers: CustomerData[]) => {
  return {
    data: (customers || [])
      .map((user: CustomerData, key: number) => {
        return {
          name:
            "(" + (Utils.yomiKashira(user.namekana) || "-") + ") " + user.name,
          yomi: Utils.yomiKashira(user.namekana),
          value: key,
        };
      })
      .sort((a: UserDataSetData, b: UserDataSetData) => {
        if (a.yomi === undefined) return 1;
        if (b.yomi === undefined) return -1;
        else if (a.yomi > b.yomi) return 1;
        else return -1;
      }),
    name: "users",
  };
};

export const getDriverDataset = (drivers: DriverData[]) => {
  return {
    data: drivers.map((value: DriverData, key: number) => {
      return {
        name: value.name,
        value: key,
      };
    }),
    name: "drivers",
  };
};

export const getPositionLength = (pos1: Position, pos2: Position) => {
  const len1 = pos1.lat * 1000000 - pos2.lat * 1000000;
  const len2 = pos1.lng * 1000000 - pos2.lng * 1000000;
  return Math.sqrt(len1 * len1 + len2 * len2);
};

export const updateRideData = (
  currentLineData: CourseData,
  currentService: number,
  positionData: ReservationFreeRidePositionData,
  isOn: boolean,
): ReservationFreeRidePositionData => {
  // types are home, nearBusStop, pickUp, click not busStop
  const { pins } = currentLineData;
  const newData = Object.assign({}, positionData);

  const { minPosition, minTermPosition, busStopTerm, position, busOrder } =
    getMinPosition(currentLineData, currentService, positionData, isOn);
  // busStopTerm is pinIndex

  const mostNearStopIndx = getMinDistBusStop(
    currentLineData,
    busStopTerm,
    minTermPosition,
  );

  if (positionData.type === "home") {
    newData.name = pins[mostNearStopIndx].name + "(自宅)";
  } else if (positionData.type === "homeNearBusStop") {
    newData.name = positionData.name;
  } else if (positionData.type === "pickup") {
    newData.name = positionData.name;
  } else {
    // case of  positionData.type === "click"
    newData.name = pins[mostNearStopIndx].name + "付近";
  }

  const busStop1Id = (() => {
    if (positionData.type === "pickup") {
      return positionData.busStop1Id;
    }

    const index = busStopTerm + busOrder;
    if (positionData.type === "homeNearBusStop") {
      if (
        positionData.clickPosition.nearBusStopPoint &&
        PinNameToBusStopId(
          positionData.clickPosition.nearBusStopPoint,
          pins,
        ) !== undefined
      ) {
        // TODO 2022/04
        const pin = PinNameToBusStopId(
          positionData.clickPosition.nearBusStopPoint,
          pins,
        );
        return pin.busStopId;
      }
    }
    return pins[index].busStopId;
  })();
  newData.positionIndex = minPosition;
  newData.busStop1Id = busStop1Id;
  newData.position = position;
  return newData;
};
export function getBusStopPosition(line: CourseData, busStop1Id: number) {
  let index = -1;
  const pins = line.pins.map((pin: CourseDataPin, key: number) => {
    const data = {
      positionIndex: index === -1 ? 0 : index,
      busStop1Id: pin.busStopId,
    };
    if (line.ways[key]) {
      index = index + line.ways[key].length;
    }
    return data;
  });
  return (
    (
      pins.find((a) => {
        return a.busStop1Id === busStop1Id;
      }) || {}
    ).positionIndex || 0
  );
}

function PinNameToBusStopId(name: string, pins: CourseDataPin[]) {
  const res: { [key: string]: CourseDataPin } = {};
  pins.map((value: CourseDataPin, key: number) => {
    res[value.name] = value;
  });
  return res[name];
}

function getMinDistBusStop(
  currentLineData: CourseData,
  pinIndex: number,
  position: number,
) {
  const way = currentLineData.ways[pinIndex];
  let from_dist = 0;
  let to_dist = 0;
  for (let j = 1; j < way.length; j++) {
    const len = getPositionLength(way[j], way[j - 1]);
    if (j > position) {
      to_dist += len;
    } else {
      from_dist += len;
    }
  }
  return from_dist < to_dist ? pinIndex : pinIndex + 1;
}

export const getMinPosition = (
  currentLineData: CourseData,
  currentService: number,
  positionData: ReservationFreeRidePositionData,
  isOn: boolean,
) => {
  let minVlen = 100000000000;
  let minOffset = 0;

  // return values
  let minPosition = 0;
  let minTermPosition = 0;
  let busStopTerm = 0;
  let position: Position = { lat: 0, lng: 0 };

  // @ts-ignore
  const busOrder = isOn ^ currentLineData.services[currentService].inbound;

  const __busStopId = positionData.busStop1Id;

  // if __busStopId is last of ways, busStopId is one before last. v1
  //const busStopId =
  // __busStopId !== undefined && __busStopId === currentLineData.ways.length
  // ? __busStopId - 1
  // : __busStopId;
  // if pinindex is last of ways, busStopId is one before last. v1
  const pinIndex = (() => {
    if (__busStopId === undefined) {
      return __busStopId;
    }
    const index = butStopId2PinIndex(__busStopId, currentLineData.pins);
    return index === currentLineData.pins.length ? index - 1 : index;
  })();

  for (let i = 0; i < currentLineData.ways.length; i++) {
    const way = currentLineData.ways[i];
    if (pinIndex === undefined || pinIndex === i) {
      for (let j = 0; j < way.length; j++) {
        const point = way[j];
        const len = getPositionLength(point, positionData.clickPosition);
        if (
          (minVlen === len && !!!busOrder) || // maybe same position
          minVlen > len
        ) {
          busStopTerm = i;
          minPosition = j + minOffset;
          minTermPosition = j;
          minVlen = len;
          position = point;
        }
      }
    }
    minOffset = minOffset + way.length;
  }
  return { minPosition, minTermPosition, busStopTerm, position, busOrder };
};
