import { TableBody, TableHead, TableRow, Grid } from "@mui/material";

import * as Utils from "../common/Utils";

import { Link } from "react-router-dom";

import { minutes2DisplayTime } from "./utils/DailyLogUtil";

import {
  DailyLogSummary,
  DailyLogTotalData,
  DailyLogServiceData,
} from "../types/data_types";

import {
  DailyLogTable,
  DailyLogCell,
  DailyLogHeadCell,
  DailyLogHeadCellTop,
  DailyLogHeadCellTopNinzuRyokin,
  DailyLogHeadCellTopLeft,
  DailyLogBodyLeftCell,
  DailyLogBodyRightCell,
  DailyLogHeadCellTategakiLight,
  DailyLogHeadCellTategaki,
  DailyLogBodyMiddleCell,
  DailyLogCellNoWrap,
  DailyLogHeadCellNoWrap,
  DailyLogBodyRowOdd,
  DailyLogBodyRowEven,
  DailyLogBodyCell,
  DailyLogBodyCellOdd,
  DailyLogBodyCellEven,
} from "./styles/DailyLogStyle";

type Props = {
  dailyLogData: DailyLogSummary[];
  total: DailyLogTotalData;
  current: any;
};

const DailyLogView: React.FC<Props> = ({ dailyLogData, total, current }) => {
  const splitStr = (str: string) => {
    return str.split("").map((a, key) => {
      return (
        <span key={key}>
          {a}
          <br />
        </span>
      );
    });
  };

  return (
    <Grid item xs={12}>
      <h4>運行実績</h4>
      <DailyLogTable>
        <TableHead>
          <TableRow>
            <DailyLogHeadCellTopLeft rowSpan={5}>便</DailyLogHeadCellTopLeft>
            <DailyLogHeadCellTop rowSpan={5}>区分</DailyLogHeadCellTop>
            <DailyLogHeadCellTop rowSpan={5}>運行エリア</DailyLogHeadCellTop>
            <DailyLogHeadCellTopNinzuRyokin colSpan={17}>
              人数/料金
            </DailyLogHeadCellTopNinzuRyokin>
            <DailyLogHeadCellTop rowSpan={5}>発地区</DailyLogHeadCellTop>
            <DailyLogHeadCellTop rowSpan={5}>着地区</DailyLogHeadCellTop>
            <DailyLogHeadCellTop rowSpan={5}>
              出庫
              <br />
              時刻
            </DailyLogHeadCellTop>
            <DailyLogHeadCellTop rowSpan={5}>
              入庫
              <br />
              時刻
            </DailyLogHeadCellTop>
            <DailyLogHeadCellTop rowSpan={5}>
              実運行
              <br />
              時刻
            </DailyLogHeadCellTop>
            <DailyLogHeadCellTop rowSpan={5}>
              出庫時
              <br />
              メーター
              <br />
              距離数
            </DailyLogHeadCellTop>
            <DailyLogHeadCellTop rowSpan={5}>
              入庫時
              <br />
              メーター
              <br />
              距離数
            </DailyLogHeadCellTop>
            <DailyLogHeadCellTop rowSpan={5}>
              走行
              <br />
              距離
            </DailyLogHeadCellTop>
            <DailyLogHeadCellTop rowSpan={5}>
              出発時
              <br />
              メーター
              <br />
              距離数
            </DailyLogHeadCellTop>
            <DailyLogHeadCellTop rowSpan={5}>
              到着時
              <br />
              メーター
              <br />
              距離数
            </DailyLogHeadCellTop>
            <DailyLogHeadCellTop rowSpan={5}>
              実車
              <br />
              距離
            </DailyLogHeadCellTop>
            <DailyLogHeadCellTop rowSpan={5}>
              運行時間
              <br />
              (実運行時間)
            </DailyLogHeadCellTop>
            <DailyLogHeadCellTop rowSpan={5}>
              待機時間
              <br />
              (回送時間)
            </DailyLogHeadCellTop>
            <DailyLogHeadCellTopLeft rowSpan={5}>便</DailyLogHeadCellTopLeft>
          </TableRow>
          <TableRow sx={{ height: "30px" }}>
            <DailyLogHeadCell colSpan={2}>大人</DailyLogHeadCell>
            <DailyLogHeadCell colSpan={5}>半額</DailyLogHeadCell>
            <DailyLogHeadCell colSpan={5}>無料（一部無料）</DailyLogHeadCell>
            <DailyLogHeadCell colSpan={2}>合計</DailyLogHeadCell>
            <DailyLogHeadCellNoWrap colSpan={3}>
              料金支払内訳
            </DailyLogHeadCellNoWrap>
          </TableRow>
          <TableRow sx={{ height: "20px" }}>
            <DailyLogHeadCell colSpan={1}>人数</DailyLogHeadCell>
            <DailyLogHeadCell colSpan={1}>料金</DailyLogHeadCell>
            <DailyLogHeadCell colSpan={4}>人数</DailyLogHeadCell>
            <DailyLogHeadCell colSpan={1}>料金</DailyLogHeadCell>
            <DailyLogHeadCell colSpan={4}>人数</DailyLogHeadCell>
            <DailyLogHeadCell colSpan={1}>料金</DailyLogHeadCell>
            <DailyLogHeadCell colSpan={1}>人数</DailyLogHeadCell>
            <DailyLogHeadCell colSpan={1}>料金</DailyLogHeadCell>
            <DailyLogHeadCell rowSpan={4}>
              現<br />金<br />
            </DailyLogHeadCell>
            <DailyLogHeadCell rowSpan={4}>
              タ<br />ク<br />シ<br />｜<br />チ<br />ケ<br />ッ<br />ト<br />
            </DailyLogHeadCell>
            <DailyLogHeadCell rowSpan={4}>
              地<br />域<br />通<br />貨<br />メ<br />ジ<br />カ<br />
            </DailyLogHeadCell>
          </TableRow>
          <TableRow>
            <DailyLogHeadCell></DailyLogHeadCell>
            <DailyLogHeadCell></DailyLogHeadCell>
            <DailyLogHeadCellTategakiLight rowSpan={2}>
              {splitStr("小人")}
            </DailyLogHeadCellTategakiLight>
            <DailyLogHeadCellTategakiLight rowSpan={2}>
              {splitStr("障がい者")}
            </DailyLogHeadCellTategakiLight>
            <DailyLogHeadCellTategakiLight rowSpan={2}>
              {splitStr("免許返納者")}
            </DailyLogHeadCellTategakiLight>
            <DailyLogHeadCellTategaki>
              {splitStr("小計")}
            </DailyLogHeadCellTategaki>
            <DailyLogHeadCell></DailyLogHeadCell>
            <DailyLogHeadCellTategakiLight rowSpan={2}>
              {splitStr("未就学児")}
            </DailyLogHeadCellTategakiLight>
            <DailyLogHeadCellTategakiLight rowSpan={2}>
              {splitStr("定期券")}
            </DailyLogHeadCellTategakiLight>
            <DailyLogHeadCellTategakiLight rowSpan={2}>
              {splitStr("ポイントC")}
            </DailyLogHeadCellTategakiLight>
            <DailyLogHeadCellTategaki>
              {splitStr("小計")}
            </DailyLogHeadCellTategaki>
            <DailyLogHeadCell></DailyLogHeadCell>
            <DailyLogHeadCell></DailyLogHeadCell>
            <DailyLogHeadCell></DailyLogHeadCell>
          </TableRow>
          <TableRow>
            <DailyLogHeadCell>&#9312;</DailyLogHeadCell>
            <DailyLogHeadCell>A</DailyLogHeadCell>
            <DailyLogHeadCell>&#9313;</DailyLogHeadCell>
            <DailyLogHeadCell>B</DailyLogHeadCell>
            <DailyLogHeadCell>&#9314;</DailyLogHeadCell>
            <DailyLogHeadCell>C</DailyLogHeadCell>
            <DailyLogHeadCell>&#9312;+&#9313;+&#9314;</DailyLogHeadCell>
            <DailyLogHeadCell>A + B + C</DailyLogHeadCell>
          </TableRow>
        </TableHead>
        <TableBody key="tbody">
          {dailyLogData.map((logData: DailyLogSummary, lineKey: number) => {
            const { subTotal } = logData;
            const tables = logData.serviceLogs.map(
              (serviceLogs: DailyLogServiceData, serviceKey: number) => {
                const {
                  service,
                  serviceId,
                  cyclick,
                  start,
                  end,
                  alert,
                  log,
                  carLog,
                  materLog,
                  meterDistance,
                  actualDrivingTime,
                  forwardDrivingTimes,
                } = serviceLogs;
                const CyclickTableCell = cyclick
                  ? DailyLogBodyCellEven
                  : DailyLogBodyCellOdd;
                const TableTag = cyclick
                  ? DailyLogBodyRowEven
                  : DailyLogBodyRowOdd;
                return (
                  <TableTag key={[serviceKey, lineKey].join("-")}>
                    <DailyLogBodyCell
                      sx={alert ? { backgroundColor: "#ff55ff" } : {}}
                    >
                      <Link
                        to={`/admin/dailylograw/${current.format(
                          "YYYY/MM/DD",
                        )}/${logData.courseData.id}/${serviceLogs.serviceId}`}
                      >
                        {serviceLogs.service.sname}
                      </Link>
                    </DailyLogBodyCell>
                    <DailyLogCell>
                      {service.inbound ? "往路" : "復路"}
                    </DailyLogCell>
                    <DailyLogCell>{service.lname}</DailyLogCell>
                    <DailyLogCell>{log.adult}</DailyLogCell>
                    <DailyLogCell>{log.adultPrice}</DailyLogCell>
                    <CyclickTableCell>{log.child}</CyclickTableCell>
                    <CyclickTableCell>{log.handicapped}</CyclickTableCell>
                    <CyclickTableCell>{log.returnLicense}</CyclickTableCell>
                    <DailyLogCell>{log.halfNumber}</DailyLogCell>
                    <DailyLogCell>{log.halfPrice}</DailyLogCell>
                    <CyclickTableCell>{log.baby}</CyclickTableCell>
                    <CyclickTableCell>{log.hascommuterpass}</CyclickTableCell>
                    <CyclickTableCell>{log.pointC}</CyclickTableCell>
                    <DailyLogCell>{log.freeNumber}</DailyLogCell>
                    <DailyLogCell>
                      {log.freePrice !== undefined ||
                      log.adjustPrice !== undefined
                        ? (log.freePrice || 0) + (log.adjustPrice || 0)
                        : ""}
                    </DailyLogCell>
                    <DailyLogCell>
                      {log.totalNumber || log.totalNubmer}
                    </DailyLogCell>
                    <DailyLogCell>{log.totalPrice}</DailyLogCell>
                    <DailyLogCell>{log.cash}</DailyLogCell>
                    <DailyLogCell>{log.taxi_ticket}</DailyLogCell>
                    <DailyLogCell>{log.local_currency}</DailyLogCell>
                    <DailyLogCellNoWrap>
                      {log.drivedTerm && log.drivedTerm.onPosition
                        ? log.drivedTerm.onPosition.name
                        : start.name}
                    </DailyLogCellNoWrap>
                    <DailyLogCellNoWrap>
                      {log.drivedTerm && log.drivedTerm.offPosition
                        ? log.drivedTerm.offPosition.name
                        : end.name}
                    </DailyLogCellNoWrap>
                    <DailyLogCell>
                      {Utils.timeStamp2Format(carLog.startAt, "HH:mm")}
                    </DailyLogCell>
                    <DailyLogCell>
                      {Utils.timeStamp2Format(carLog.endAt, "HH:mm")}
                    </DailyLogCell>
                    <DailyLogCellNoWrap>
                      {Utils.timeStamp2Format(carLog.minTimeStartAt, "HH:mm")} ~{" "}
                      {Utils.timeStamp2Format(carLog.minTimeEndAt, "HH:mm")}
                    </DailyLogCellNoWrap>
                    <DailyLogCell>
                      {carLog.startDistance || materLog.startDistance}
                    </DailyLogCell>
                    <DailyLogCell>
                      {carLog.endDistance || materLog.endDistance}
                    </DailyLogCell>
                    <DailyLogCell>{meterDistance}</DailyLogCell>
                    <DailyLogCell>{materLog.serviceStartDistance}</DailyLogCell>
                    <DailyLogCell>{materLog.serviceEndDistance}</DailyLogCell>

                    <DailyLogCell>{carLog.distance}</DailyLogCell>
                    <DailyLogCell>
                      {minutes2DisplayTime(actualDrivingTime)}
                    </DailyLogCell>
                    <DailyLogCell>
                      {minutes2DisplayTime(forwardDrivingTimes)}
                    </DailyLogCell>
                    <DailyLogBodyCell
                      sx={alert ? { backgroundColor: "#ff55ff" } : {}}
                    >
                      <Link
                        to={`/admin/dailylograw/${current.format(
                          "YYYY/MM/DD",
                        )}/${logData.courseData.id}/${serviceId}`}
                      >
                        {service.sname}
                      </Link>
                    </DailyLogBodyCell>
                  </TableTag>
                );
              },
            );

            tables.push(
              <TableRow key={["total", lineKey].join("-")}>
                <DailyLogBodyLeftCell>小計</DailyLogBodyLeftCell>
                <DailyLogBodyMiddleCell colSpan={14} />
                <DailyLogBodyMiddleCell>
                  {subTotal.number}
                </DailyLogBodyMiddleCell>
                <DailyLogBodyMiddleCell>
                  {subTotal.price}
                </DailyLogBodyMiddleCell>
                <DailyLogBodyMiddleCell>{subTotal.cach}</DailyLogBodyMiddleCell>
                <DailyLogBodyMiddleCell>
                  {subTotal.taxiTicket}
                </DailyLogBodyMiddleCell>
                <DailyLogBodyMiddleCell>
                  {subTotal.localCurrency}
                </DailyLogBodyMiddleCell>
                <DailyLogBodyMiddleCell>
                  全走行時間 {subTotal.operationTime}分
                </DailyLogBodyMiddleCell>
                <DailyLogBodyMiddleCell>
                  全実車時間 {subTotal.driveTime}分
                </DailyLogBodyMiddleCell>
                <DailyLogBodyMiddleCell colSpan={3}></DailyLogBodyMiddleCell>
                <DailyLogBodyMiddleCell colSpan={3}>
                  全走行距離 {subTotal.distance}
                </DailyLogBodyMiddleCell>
                <DailyLogBodyMiddleCell colSpan={3}>
                  全実車距離 {subTotal.serviceDistance}
                </DailyLogBodyMiddleCell>
                <DailyLogBodyMiddleCell>
                  {minutes2DisplayTime(subTotal.actualDrivingTimes)}
                </DailyLogBodyMiddleCell>
                <DailyLogBodyMiddleCell>
                  {minutes2DisplayTime(subTotal.forwardDrivingTimes)}
                </DailyLogBodyMiddleCell>
                <DailyLogBodyRightCell colSpan={1}></DailyLogBodyRightCell>
              </TableRow>,
            );
            return tables;
          })}
          <TableRow>
            <DailyLogBodyLeftCell>総計</DailyLogBodyLeftCell>
            <DailyLogBodyMiddleCell colSpan={14}></DailyLogBodyMiddleCell>
            <DailyLogBodyMiddleCell>{total.number}</DailyLogBodyMiddleCell>
            <DailyLogBodyMiddleCell>{total.price}</DailyLogBodyMiddleCell>
            <DailyLogBodyMiddleCell>{total.cach}</DailyLogBodyMiddleCell>
            <DailyLogBodyMiddleCell>{total.taxiTicket}</DailyLogBodyMiddleCell>
            <DailyLogBodyMiddleCell>
              {total.localCurrency}
            </DailyLogBodyMiddleCell>
            <DailyLogBodyMiddleCell>
              全走行時間 {total.operationTime}分
            </DailyLogBodyMiddleCell>
            <DailyLogBodyMiddleCell>
              全実車時間 {total.driveTime}分
            </DailyLogBodyMiddleCell>
            <DailyLogBodyMiddleCell colSpan={3}></DailyLogBodyMiddleCell>
            <DailyLogBodyMiddleCell colSpan={3}>
              全走行距離 {total.distance}
            </DailyLogBodyMiddleCell>
            <DailyLogBodyMiddleCell colSpan={3}>
              全実車距離 {total.serviceDistance}
            </DailyLogBodyMiddleCell>
            <DailyLogBodyMiddleCell>
              {minutes2DisplayTime(total.actualDrivingTimes)}
            </DailyLogBodyMiddleCell>
            <DailyLogBodyMiddleCell>
              {minutes2DisplayTime(total.forwardDrivingTimes)}
            </DailyLogBodyMiddleCell>
            <DailyLogBodyRightCell colSpan={1}></DailyLogBodyRightCell>
          </TableRow>
        </TableBody>
      </DailyLogTable>
    </Grid>
  );
};

export default DailyLogView;
