import React, { useState } from "react";
import { Link } from "react-router-dom";

import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Drawer,
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import { Menu, Home, Info } from "@mui/icons-material";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";

import * as DataUtils from "../common/DataUtils";
import { Company, LineData } from "../types/data_types";
import User from "../models/user";

import { env } from "../config";

interface Props {
  user: User;
  login?: string;
  company: Company;
  headerTitle: string;
  lineData: LineData;
}

const AdminHeader: React.FC<Props> = ({
  user,
  login,
  company,
  headerTitle,
  lineData,
}) => {
  const [drawer, setDrawer] = useState(false);

  const lineId = Object.keys(lineData)[0];

  const handleMenu = (event: any) => {
    setDrawer(true);
  };
  const handleClose = () => {
    setDrawer(false);
  };
  const logout = () => {
    console.log("logout");
    firebase.auth().signOut();
  };

  return (
    <div style={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        className={env === "dev" ? "!bg-orange-400" : ""}
      >
        <Toolbar>
          <IconButton
            sx={{ marginLeft: "-12px", marginRight: "20px" }}
            onClick={handleMenu}
            color="inherit"
            aria-label="Menu"
          >
            <Menu />
          </IconButton>
          <Typography variant="h6" color="inherit" sx={{ flexGrow: 1 }}>
            {company.name} / {headerTitle}
          </Typography>
          {user ? (
            <Button color="inherit" onClick={logout}>
              Logout
            </Button>
          ) : (
            <Button color="inherit" to={login || "/login"} component={Link}>
              Login
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <Drawer open={drawer} onClose={handleClose}>
        <List>
          <ListItem button to="/admin/" component={Link}>
            <ListItemIcon>
              <Home />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>
          <Divider />
          <ListItem button to="/admin/user" component={Link}>
            <ListItemIcon>
              <Info />
            </ListItemIcon>
            <ListItemText primary="新規予約　予約確認・変更" />
          </ListItem>
          <ListItem button to="/admin/reservations" component={Link}>
            <ListItemIcon>
              <Info />
            </ListItemIcon>
            <ListItemText primary="日付別　予約一覧／運行計画" />
          </ListItem>
          <ListItem button to="/admin/drivers" component={Link}>
            <ListItemIcon>
              <Info />
            </ListItemIcon>
            <ListItemText primary="月間運行計画" />
          </ListItem>
          <ListItem
            button
            to={`/admin/dailylograw/${DataUtils.todayPath}/${lineId}/0`}
            component={Link}
          >
            <ListItemIcon>
              <Info />
            </ListItemIcon>
            <ListItemText primary="運行後の確認／変更" />
          </ListItem>
          {user.user.admin ? (
            <React.Fragment>
              <ListItem
                button
                to={`/admin/dailylog/${DataUtils.todayPath}`}
                component={Link}
              >
                <ListItemIcon>
                  <Info />
                </ListItemIcon>
                <ListItemText primary="日報" />
              </ListItem>
              <ListItem
                button
                to={`/admin/dailylogsummary/${DataUtils.monthPath}`}
                component={Link}
              >
                <ListItemIcon>
                  <Info />
                </ListItemIcon>
                <ListItemText primary="日報月末まとめ" />
              </ListItem>
              <ListItem
                button
                to={`/admin/monthlylog/${DataUtils.monthPath}/${lineId}/0`}
                component={Link}
              >
                <ListItemIcon>
                  <Info />
                </ListItemIcon>
                <ListItemText primary="月報" />
              </ListItem>
            </React.Fragment>
          ) : (
            ""
          )}
          <ListItem button to="/admin/news" component={Link}>
            <ListItemIcon>
              <Info />
            </ListItemIcon>
            <ListItemText primary="お知らせ" />
          </ListItem>
          <ListItem button to="/admin/map" component={Link}>
            <ListItemIcon>
              <Info />
            </ListItemIcon>
            <ListItemText primary="バスの現在位置" />
          </ListItem>
          <ListItem button to="/admin/lines" component={Link}>
            <ListItemIcon>
              <Info />
            </ListItemIcon>
            <ListItemText primary="乗降場所管理" />
          </ListItem>
        </List>
      </Drawer>
    </div>
  );
};

export default AdminHeader;
