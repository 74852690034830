import React from "react";
import {
  CardActions,
  CardContent,
  Card,
  Typography,
  Button,
} from "@mui/material";
import { ReservationFreeRidePositionData } from "../types/data_types";

// https://cuneyt.aliustaoglu.biz/en/using-google-maps-in-react-without-custom-libraries/
interface Props {
  data: ReservationFreeRidePositionData;
  callbackPin: (action: string, data: ReservationFreeRidePositionData) => void;
}

const InfoWindowRide: React.FC<Props> = ({ data, callbackPin }) => {
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardContent>
        <Typography component="p">{data.name}</Typography>
      </CardContent>
      <CardActions>
        <Button
          size="small"
          color="primary"
          onClick={(e) => {
            callbackPin("on", data);
          }}
        >
          乗車
        </Button>
        <Button
          size="small"
          color="primary"
          onClick={(e) => {
            callbackPin("off", data);
          }}
        >
          下車
        </Button>
      </CardActions>
    </Card>
  );
};

export default InfoWindowRide;
