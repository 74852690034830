import React from "react";
import Header from "./Header";
import { Typography } from "@mui/material";

import { CaptionGrip } from "./styles";

import busIcon from "./bus.png";

import { DefaultProps } from "./props";

function Home(props: DefaultProps) {
  const { user } = props;
  return (
    <React.Fragment>
      <Header user={user} />
      <CaptionGrip container alignItems="center" direction="row">
        <CaptionGrip>
          <Typography
            component="h2"
            variant="h5"
            gutterBottom
            className="!mt-4"
          >
            Bus System.
          </Typography>
          <img src={busIcon} className="m-auto w-96" alt="bus" />
        </CaptionGrip>
      </CaptionGrip>
    </React.Fragment>
  );
}

export default Home;
