import firebase from "firebase/compat/app";
import React, { useEffect, useState } from "react";

import useOnCollection from "../common/useOnCollection";
import { sortStopsData } from "./utils/stopsUtils";

import { Company, LineData, BusStopData } from "../types/data_types";

import Select from "../Select";
import Pickup from "./Pickup";
import AddPickup from "./AddPickup";

import * as DataUtils from "../common/DataUtils";
import * as Utils from "../common/Utils";

import { useNavigate, useParams } from "react-router-dom";

type Props = {
  db: firebase.firestore.Firestore;
  company: Company;
  lineData: LineData;
  setHeaderTitle: any;
};

const Line: React.FC<Props> = ({ db, company, lineData, setHeaderTitle }) => {
  const props = {
    db,
    company,
    lineData,
    setHeaderTitle,
  };

  const { lineId } = useParams();

  const [currentLine, setCurrentLine] = useState(
    lineId || Object.keys(lineData)[0],
  );

  useEffect(() => {
    setHeaderTitle("停車場所設定");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const stopsPath = `companies/${company.id}/lines/${lineId}/stops`;
  const [stopsData] = useOnCollection<BusStopData>(db, stopsPath);

  const sortedData = sortStopsData(stopsData);

  const lineDataset = DataUtils.getLineDataset(lineData);
  const defaultPosition = lineData[currentLine].defaultPosition;
  const navigate = useNavigate();

  useEffect(() => {
    console.log(currentLine);
    if (currentLine !== lineId) {
      navigate(`/admin/lines/${currentLine}`);
    }
  }, [currentLine]);

  return (
    <div>
      <b>路線</b>
      <Select
        dataSet={lineDataset}
        onChange={(e: any) => Utils.onChange2(e, setCurrentLine)}
        value={currentLine}
      />
      <br />
      <hr />
      <div>
        <table>
          <tbody>
            {sortedData.map((stop: BusStopData, key: number) => {
              return (
                <Pickup stop={stop} path={stopsPath} {...props} key={stop.id} />
              );
            })}
          </tbody>
        </table>
      </div>
      <hr />
      <h2>新規追加</h2>
      <AddPickup
        stopsData={sortedData}
        path={stopsPath}
        {...props}
        defaultPosition={defaultPosition}
      />
    </div>
  );
};

export default Line as any;
