import React from "react";
import {
  CardActions,
  CardContent,
  Card,
  Button,
  Typography,
} from "@mui/material";

interface Props {
  data: any;
  callbackPin: any;
}

const InfoWindowHome: React.FC<Props> = ({ data, callbackPin }) => {
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardContent>
        <Typography component="p">{data.name}</Typography>
      </CardContent>
      <CardActions>
        <Button
          size="small"
          color="primary"
          onClick={(e) => {
            callbackPin("home", data);
          }}
        >
          自宅をここにセット
        </Button>
      </CardActions>
    </Card>
  );
};

export default InfoWindowHome;
