import firebase from "firebase/compat/app";
import React, { useEffect, useState } from "react";
import { Grid, Button } from "@mui/material";

import * as Utils from "../common/Utils";

import useOnCollection from "../common/useOnCollection";

import { NewsData } from "../types/data_types";

type Props = {
  db: firebase.firestore.Firestore;
  setHeaderTitle: any;
};

const News: React.FC<Props> = ({ db, setHeaderTitle }) => {
  const [text, setText] = useState("");

  const [newsList, error] = useOnCollection<NewsData>(db, "news");

  useEffect(() => {
    setHeaderTitle("お知らせ管理");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function update() {
    const data = {
      text,
      public: true,
      created: firebase.firestore.FieldValue.serverTimestamp(),
    };
    const customerPath = `news`;
    await db.collection(customerPath).add(data);
    setText("");
  }
  async function deleteText(news: NewsData) {
    const path = `news/${news.id}`;
    await db.doc(path).delete();
  }
  async function changePublic(news: NewsData, flag: boolean) {
    const path = `news/${news.id}`;
    await db.doc(path).update({ public: flag });
  }
  if (error) {
    return <div>error</div>;
  }
  return (
    <React.Fragment>
      <Grid container alignItems="center" direction="row">
        <Grid item xs={2}></Grid>
        <Grid item xs={8}>
          <input
            style={{ width: "100%" }}
            onChange={(e) => {
              Utils.onChange(e, setText);
            }}
            value={text}
          />
          <br />
          <Button
            variant="outlined"
            color="primary"
            onClick={(e) => {
              update();
            }}
          >
            登録
          </Button>
          {(newsList || []).map((news: NewsData, key: number) => {
            return (
              <li key={key}>
                {news.text} / {news.public ? "公開" : "非公開"} /
                {news.public ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={(e) => {
                      changePublic(news, false);
                    }}
                  >
                    非公開に変更
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={(e) => {
                      changePublic(news, true);
                    }}
                  >
                    公開に変更
                  </Button>
                )}{" "}
                /
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={(e) => {
                    deleteText(news);
                  }}
                >
                  削除
                </Button>
              </li>
            );
          })}
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
    </React.Fragment>
  );
};

export default News;
