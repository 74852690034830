import firebase from "firebase/compat/app";
import React, { useState, Dispatch, SetStateAction } from "react";
import { Grid, Button } from "@mui/material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import useOnDocument from "../common/useOnDocument";
import useOnCollection from "../common/useOnCollection";

import { Link, useParams } from "react-router-dom";

import * as Icon from "../common/Icon";
import * as DataUtils from "../common/DataUtils";
import * as Utils from "../common/Utils";

import UserIcon from "./UserIcon";

import { useNavigate, useLocation } from "react-router-dom";

import UserGoogleMap from "./UserGoogleMap";

import {
  Company,
  CustomerData,
  LineData,
  CourseDataService,
  ReservationData,
} from "../types/data_types";

const currentReservationOpt = {
  queryFilter: (query: firebase.firestore.Query) => {
    return query.where("date", ">=", DataUtils.today).orderBy("date", "asc");
  },
};
type Props = {
  db: firebase.firestore.Firestore;
  lineData: LineData;
  setHeaderTitle: Dispatch<SetStateAction<string>>;
  company: Company;
};

const UserReservation: React.FC<Props> = ({
  db,
  lineData,
  setHeaderTitle,
  company,
}) => {
  const props = { db, lineData, setHeaderTitle, company };
  const { customerId, reservationId } = useParams();

  const [customer, error] = useOnDocument<CustomerData>(
    db,
    `companies/${company.id}/customers/${customerId}/`,
  );
  const [reservations, error2] = useOnCollection<ReservationData>(
    db,
    `companies/${company.id}/customers/${customerId}/customerReservations`,
    currentReservationOpt,
  );
  const [currentReservation, setCurrentReservation] =
    useState<ReservationData | null>(null);

  const [open, setOpen] = useState(false);
  const [cancelKey, setCancelKey] = useState<number | null>(null);

  const [openMemo, setOpenMemo] = useState(false);
  const [memo, setMemo] = useState("");

  const [changeOpen, setChangeOpen] = useState<boolean | string>(false);
  const navigate = useNavigate();

  React.useEffect(() => {
    setHeaderTitle("乗客別予約一覧");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const location = useLocation();
  React.useEffect(() => {
    const paths = location.pathname.split("/");
    if (paths.length === 6) {
      const found = reservations.find(
        (ele: ReservationData) => ele.id === paths[5],
      );
      if (found) {
        setCurrentReservation(found);
      }
    }
  }, [location]);

  if (!customer || !reservations) {
    return <div>loading</div>;
  }
  if (reservations.length === 0) {
    return <div>No reservation</div>;
  }
  if (!currentReservation) {
    const matchResevation = (() => {
      if (reservationId) {
        const found = reservations.find(
          (ele: ReservationData) => ele.id === reservationId,
        );
        if (found) {
          return found;
        }
      }
      return reservations[0];
    })();
    setCurrentReservation(matchResevation);
  }
  const params = {
    currentReservation,
  };
  const updateReservation = (reservation: ReservationData) => {
    setCurrentReservation(reservation);
    navigate(
      `/admin/user/${customer.customerId}/reservation/${reservation.id}`,
    );
  };
  if (error || error2) {
    return <div>error</div>;
  }

  const handleCancel = () => {
    if (cancelKey !== null) {
      const reserv = reservations[cancelKey];

      setOpen(false);

      db.runTransaction(async (t) => {
        const customerPath = `companies/${company.id}/customers/${customer.id}/customerReservations/${reserv.id}`;
        const customerRef = db.doc(customerPath);
        await t.delete(customerRef);

        const linePath = `companies/${company.id}/lines/${reserv.lineId}/lineReservations/${reserv.id}`;
        const lineRef = db.doc(linePath);
        await t.delete(lineRef);
      });
    }
  };
  const handleReset = () => {
    setOpen(false);
  };
  const handleOpen = (key: number) => {
    setCancelKey(key);
    setOpen(true);
  };
  const handleChangeReset = () => {
    setChangeOpen(false);
  };

  return (
    <Grid container direction="row">
      <Grid item xs={5}>
        <span>
          <ruby>
            <span style={{ fontWeight: "bold" }}>
              {customer.name || "----"}
            </span>
            <rp>(</rp>
            <rt>{customer.namekana}</rt>
            <rp>)</rp>
          </ruby>
        </span>
        <UserIcon customer={customer} />
        <hr />
        <div>
          <table>
            <tbody>
              {reservations
                .sort((a: ReservationData, b: ReservationData) => {
                  const a_date = Number(a.date.replace(/-/g, ""));
                  const b_date = Number(b.date.replace(/-/g, ""));
                  if (a_date > b_date) {
                    return 1;
                  }
                  if (a_date < b_date) {
                    return -1;
                  }

                  const a_service = lineData[a.lineId].services.find(
                    (ele: CourseDataService) => ele.id === a.serviceId,
                  );
                  const b_service = lineData[b.lineId].services.find(
                    (ele: CourseDataService) => ele.id === b.serviceId,
                  );
                  if (
                    a_service &&
                    b_service &&
                    a_service.start >= b_service.start
                  ) {
                    return 1;
                  }
                  return -1;
                })
                .map((reservation: ReservationData, key: number) => {
                  const line = lineData[reservation.lineId];

                  const service =
                    line.services.find(
                      (ele: CourseDataService) =>
                        ele.id === reservation.serviceId,
                    ) || ({} as CourseDataService);
                  const date = reservation.date.replace(/-/g, "/");
                  return (
                    <tr key={key}>
                      <td>
                        <span
                          onClick={() => {
                            updateReservation(reservation);
                          }}
                        >
                          <Link
                            to={`/admin/reservations/${date}/${reservation.lineId}/${reservation.serviceId}`}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            style={{ fontSize: "1.275rem" }}
                          >
                            {reservation.date}
                          </Link>
                          {Utils.isNull(reservation.memo) ||
                          reservation.memo === "" ? (
                            ""
                          ) : (
                            <Button
                              color="primary"
                              variant="outlined"
                              onClick={(e) => {
                                setMemo(reservation.memo);
                                setOpenMemo(true);
                              }}
                            >
                              メモ
                            </Button>
                          )}
                          <br />
                          {service.name}
                          <br />
                          <b>
                            予約: {reservation.onPosition.name} →{" "}
                            {reservation.offPosition.name}
                          </b>
                          <br />
                          <b>{(reservation.price || {}).actualPrice}円</b>{" "}
                          {Icon.getCustomerIcons(
                            0,
                            reservation.child,
                            reservation.baby,
                            key,
                          )}
                        </span>
                      </td>
                      <td>
                        <Button
                          onClick={(e) => handleOpen(key)}
                          color="primary"
                          autoFocus
                          variant="outlined"
                        >
                          キャンセル
                        </Button>
                      </td>
                      <td>
                        <Button
                          onClick={(e) => {
                            setChangeOpen(reservation.id || false);
                          }}
                          color="primary"
                          autoFocus
                          variant="outlined"
                        >
                          変更
                        </Button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={6}>
        <div style={{ height: "80vh", width: "100%" }}>
          <UserGoogleMap {...props} {...params} />
        </div>
      </Grid>
      <Dialog
        open={open}
        onClose={handleReset}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"キャンセルしますか？"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ textAlign: "center" }}
          >
            <Button
              variant="outlined"
              onClick={handleCancel}
              color="primary"
              autoFocus
            >
              する
            </Button>
            <Button
              variant="outlined"
              onClick={handleReset}
              color="primary"
              autoFocus
            >
              しない
            </Button>
          </DialogContentText>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
      <Dialog
        open={openMemo}
        onClose={() => {
          setOpenMemo(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title2" style={{ minWidth: "300px" }}>
          メモ
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description2"
            style={{ textAlign: "center" }}
          >
            {memo}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={(e) => {
              setOpenMemo(false);
            }}
            color="primary"
            autoFocus
          >
            閉じる
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={!!changeOpen}
        onClose={handleChangeReset}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"予約変更"}</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ textAlign: "center" }}
          >
            乗降場所の変更ができます。
            <br />
            <b>日付</b>や<b>便</b>を変更の場合は一旦キャンセルしてください。
            <br />
            <Button
              variant="outlined"
              onClick={() => {
                navigate(`/admin/reservation/map/${customerId}/${changeOpen}`);
              }}
              color="primary"
              autoFocus
            >
              乗降場所の変更に進む
            </Button>
            <br />
            <Button
              variant="outlined"
              onClick={handleChangeReset}
              color="primary"
              autoFocus
            >
              戻ってこの便をキャンセルする
            </Button>
          </DialogContentText>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </Grid>
  );
};

export default UserReservation;
