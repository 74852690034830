import React from "react";
import { CardContent, Card, Typography } from "@mui/material";
import * as Utils from "../common/Utils";

import { ReservationFreeRidePositionData } from "../types/data_types";

interface Props {
  data: ReservationFreeRidePositionData;
}

const InfoWindowOnOff: React.FC<Props> = ({ data }) => {
  const typeName = Utils.getTypeName(data);
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardContent>
        <Typography component="p" sx={{ textAlign: "center" }}>
          {data.name}/{typeName}
          <br />
          {data?.onOffPositionData?.name || ""}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default InfoWindowOnOff;
