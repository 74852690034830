import { styled } from "@mui/system";
import { Table, TableCell, TableRow } from "@mui/material";

export const MuiTableRoot = styled(Table)(({ theme }) => ({
  border: "solid 1px #888888",
  width: "1050px",
}));

export const MuiTableRowBottom = styled(TableRow)(({ theme }) => ({
  border: "solid 1px #888888",
  backgroundColor: "#eeefef",
}));

export const MuiTableCellRootAndHead = styled(TableCell)(({ theme }) => ({
  "&:last-child": {
    padding: "0 0 0 0",
  },
  textAlign: "center",
  fontWeight: "bold",
  color: "black",
  border: "solid 1px #888888",
  padding: "5px 0 5px 0",
  backgroundColor: "#eeefef",
  fontSize: "0.8rem",
  lineHeight: "1.3",
}));

export const MuiTableCellRootAndHeadA2H = styled(MuiTableCellRootAndHead)(
  ({ theme }) => ({
    borderTop: "none",
    fontSize: "0.6rem",
    fontWeight: "normal",
    letterSpacing: "0.05em",
  }),
);

export const MuiTableCellRootAndHeadBorderBottomNone = styled(
  MuiTableCellRootAndHead,
)(({ theme }) => ({
  borderBottom: "none",
}));

export const MuiTableCellRootAndBody = styled(TableCell)(({ theme }) => ({
  padding: "0 0 0 0",
  "&:last-child": {
    padding: "0 0 0 0",
  },
  textAlign: "center",
  borderBottom: "none",
  borderRight: "solid 1px #888888",
  fontSize: "0.8rem",
}));
