import Grid from "@mui/material/Grid";
import { styled } from "@mui/system";

export const CaptionGrip = styled(Grid)(({ theme }) => ({
  textAlign: "center",
  width: "100%",
}));

export const RootGrid = styled(Grid)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(1),
}));

export const RootGridPaddingTop = styled(RootGrid)(({ theme }) => ({
  paddingTop: theme.spacing(10),
}));
