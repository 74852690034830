import React from "react";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";

import { styled } from "@mui/system";
import { LineData } from "../types/data_types";

import * as DataUtils from "../common/DataUtils";

const GridRoot = styled(Grid)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(1),
  paddingTop: theme.spacing(10),
  "justify-content": "center",
}));

const MenuListUL = styled("ul")(({ theme }) => ({
  fontSize: "1.5rem",
  lineHeight: 2,
}));

interface Props {
  setHeaderTitle: any;
  user: any;
  lineData: LineData;
}

const Index: React.FC<Props> = ({ setHeaderTitle, user, lineData }) => {
  const lineId = Object.keys(lineData)[0];
  React.useEffect(() => {
    setHeaderTitle("Home");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <React.Fragment>
      <GridRoot container alignItems="center" direction="row">
        <Grid>
          <MenuListUL>
            <li>
              <Link to="/admin/user">新規予約　予約確認・変更</Link>
            </li>
            <li>
              <Link to="/admin/reservations">日付別　予約一覧／運行計画</Link>
            </li>
            <li>
              <Link to="/admin/drivers">月間運行計画</Link>
            </li>
            <li>
              <Link
                to={`/admin/dailylograw/${DataUtils.todayPath}/${lineId}/0`}
              >
                運行後の確認／変更
              </Link>
            </li>
            {user.user.admin ? (
              <React.Fragment>
                <li>
                  <Link to={`/admin/dailylog/${DataUtils.todayPath}`}>
                    日報
                  </Link>
                </li>
                <li>
                  <Link to={`/admin/dailylogsummary/${DataUtils.monthPath}`}>
                    日報月末まとめ
                  </Link>
                </li>
                <li>
                  <Link
                    to={`/admin/monthlylog/${DataUtils.monthPath}/${lineId}/0`}
                  >
                    月報
                  </Link>
                </li>
              </React.Fragment>
            ) : (
              ""
            )}
            <li>
              <Link to="/admin/map">バスの現在位置</Link>{" "}
            </li>
            <li>
              <Link to="/admin/lines">乗降場所管理</Link>{" "}
            </li>
          </MenuListUL>
        </Grid>
      </GridRoot>
      <GridRoot container alignItems="center" direction="row">
        <small>最終更新日: {process.env.REACT_APP_LAST_BUILD}</small>
      </GridRoot>
    </React.Fragment>
  );
};

export default Index;
