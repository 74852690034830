import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import firebase from "firebase/compat/app";

import { styled } from "@mui/system";
import { Grid, InputLabel, Select, MenuItem, Button } from "@mui/material";

import EditableField from "../common/EditableField";
import * as inputValidator from "../common/inputValidator";

import customerClass from "../models/customer";

const StyledSelect = styled(Select)(({ theme }) => ({
  margin: theme.spacing(1),
  minWidth: 120,
}));

function UserNew(props: any) {
  const { db, setHeaderTitle, company } = props;

  const [currentValue, setCurrentValue] = useState<any>({});
  const [error, setError] = useState("");
  const [disabled, setDisabled] = useState(false);
  const navigate = useNavigate();

  React.useEffect(() => {
    setHeaderTitle("新規ユーザ登録");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onChange = (key: string) => {
    return (value: any) => {
      const newValue: any = { ...currentValue };
      newValue[key] = value;
      setCurrentValue(newValue);
    };
  };
  const saveData = async () => {
    setDisabled(true);
    if (currentValue.phoneNumber) {
      const customers = await db
        .collection(`companies/${company.id}/customers`)
        .where("phoneNumber", "==", currentValue.phoneNumber)
        .get();
      if (!customers.empty) {
        setError("その携帯電話のお客様は存在します");
        setDisabled(false);
        return;
      }
    }
    if (currentValue.landlineNumber) {
      const customers = await db
        .collection(`companies/${company.id}/customers`)
        .where("landlineNumber", "==", currentValue.landlineNumber)
        .get();
      if (!customers.empty) {
        setError("その固定電話のお客様は存在します");
        setDisabled(false);
        return;
      }
    }

    const newValue = Object.assign(
      {},
      customerClass.defaultValue(),
      currentValue,
    );

    newValue["created"] = firebase.firestore.FieldValue.serverTimestamp();

    const res = await db
      .collection(`companies/${company.id}/customers`)
      .add(newValue);
    const customerId = res.id;
    await db
      .doc(`companies/${company.id}/customers/${customerId}`)
      .update("customerId", customerId);

    navigate(`/admin/user`);
  };
  return (
    <Grid container alignItems="center" direction="row">
      <Grid item xs={2} />
      <Grid item xs={8}>
        <EditableField
          label={"姓名"}
          onChange={onChange("name")}
          value={currentValue.name}
        />
        <EditableField
          label={"せいめい"}
          onChange={onChange("namekana")}
          value={currentValue.namekana}
        />
        <InputLabel>性別</InputLabel>
        <StyledSelect
          label={"性別"}
          value={currentValue.sex || 1}
          onChange={(e) => {
            onChange("sex")(e.target.value);
          }}
        >
          <MenuItem key={1} value={1}>
            男性
          </MenuItem>
          <MenuItem key={2} value={2}>
            女性
          </MenuItem>
        </StyledSelect>
        <EditableField
          label={"固定電話番号"}
          onChange={onChange("landlineNumber")}
          validate={inputValidator.landlineValidator}
          convData={inputValidator.zen2hanNumber}
          helperText={"半角の数字のみ１０桁。カッコとハイフンは不要です"}
          value={currentValue.landlineNumber}
        />
        <EditableField
          label={"携帯電話番号"}
          onChange={onChange("phoneNumber")}
          validate={inputValidator.phoneValidator}
          convData={inputValidator.zen2hanNumber}
          helperText={"半角の数字のみ１１桁。カッコとハイフンは不要です"}
          value={currentValue.phoneNumber}
        />
        電話番号はお客様の識別子になるので正確に入力をお願いします。
        <br />
        <Button
          variant="outlined"
          color="primary"
          onClick={(e) => {
            saveData();
          }}
          disabled={disabled}
        >
          新規作成
        </Button>
        <br />
        {error ? <span style={{ color: "red" }}>{error}</span> : ""}
      </Grid>
    </Grid>
  );
}

export default UserNew;
