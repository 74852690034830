import firebase from "firebase/compat/app";
import React, { useEffect, useState, Dispatch, SetStateAction } from "react";
import Grid from "@mui/material/Grid";
import { useParams } from "react-router-dom";

import * as Utils from "../common/Utils";

import DailyLogSummaryView from "./DailyLogSummaryView";

import useOnCollectionGroup from "../common/useOnCollectionGroup";

import moment from "moment";
import "moment/locale/ja";

import { moment2min } from "./utils/DailyLogUtil";

import { useNavigate, useLocation } from "react-router-dom";

import { Company } from "../types/data_types";

import {
  LineData,
  CourseData,
  CourseDataService,
  DailyLogSummaryTotalData,
  DailyLogSummaryServiceData,
  DailyLogSummaryData,
} from "../types/data_types";

type Props = {
  db: firebase.firestore.Firestore;
  lineData: LineData;
  setHeaderTitle: Dispatch<SetStateAction<string>>;
  company: Company;
};

const DailyLogSummary: React.FC<Props> = ({
  db,
  lineData,
  setHeaderTitle,
  company,
}) => {
  const { year, month } = useParams();

  const now = moment(`${year}-${month}-01`, "YYYY-MM-DD");
  now.endOf("month");

  const [validLogQuery, setValidLogQuery] = useState<any>({});
  const [validLogs] = useOnCollectionGroup(db, "validLog", validLogQuery);
  const [validLogObject, setValidLogObject] = useState<any>({});

  const [current, setCurrent] = useState(now);
  const lineDataArray = Object.values(lineData);
  const navigate = useNavigate();

  useEffect(() => {
    setHeaderTitle("日報月末まとめ");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const query = {
      queryFilter: (query: any) => {
        return query
          .where("month", "==", current.format("YYYY-MM"))
          .where("companyId", "==", company.id);
      },
    };
    setValidLogQuery(query);
  }, [current]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const data = (validLogs || []).reduce((tmp: any, log: any) => {
      if (!tmp[log.lineId]) {
        tmp[log.lineId] = {};
      }
      if (!tmp[log.lineId][log.serviceId]) {
        tmp[log.lineId][log.serviceId] = [];
      }
      tmp[log.lineId][log.serviceId].push(log);
      return tmp;
    }, {});
    setValidLogObject(data);
  }, [validLogs]);

  const decreaseDate = () => {
    const newCurrent = current.clone();
    newCurrent.add(-1, "month");
    navigate(`/admin/dailylogsummary/${newCurrent.format("YYYY/MM")}`);
    setCurrent(newCurrent);
  };
  const increaseDate = () => {
    const newCurrent = current.clone();
    newCurrent.add(1, "month");
    navigate(`/admin/dailylogsummary/${newCurrent.format("YYYY/MM")}`);
    setCurrent(newCurrent);
  };

  const location = useLocation();
  useEffect(() => {
    const [, , , year, month] = location.pathname.split("/");
    const now = moment(`${year}-${month}-01`);
    setCurrent(now);
  }, [location]);

  const totalDefault: DailyLogSummaryTotalData = {
    adult: 0,
    adultPrice: 0,

    child: 0,
    handicapped: 0,
    returnLicense: 0,
    halfNumber: 0,
    halfPrice: 0,

    baby: 0,
    hascommuterpass: 0,
    pointC: 0,
    freeNumber: 0,
    freePrice: 0,

    number: 0,
    price: 0,
    cash: 0,
    taxiTicket: 0,
    localCurrency: 0,
    serviceDistance: 0,
    distance: 0,
    operationTime: 0,
    actualDrivingTime: 0,
    forwardDrivingTimes: 0,

    totalPrice: 0,
    totalNumber: 0,

    adjustPrice: 0,
  };
  const total = { ...totalDefault };
  const dailyLogSummaryData: DailyLogSummaryData[] = lineDataArray.map(
    (courseData: CourseData, lineKey: number) => {
      const subTotal = { ...totalDefault };

      const serviceLogs: DailyLogSummaryServiceData[] = courseData.services.map(
        (service: CourseDataService, serviceKey: number) => {
          const lineTotal = { ...totalDefault };
          const serviceId = service.id;
          const cyclick = serviceKey % 2 === 0;

          const start = courseData.pins[0];
          const end = courseData.pins[courseData.pins.length - 1];

          // for alert
          ((validLogObject[courseData.id] || {})[serviceId] || []).map(
            (currentLog: any) => {
              const carLog = currentLog.carLog || {};
              const serviceDistance = carLog.distance || 0;
              const distance =
                (carLog.endDistance || 0) - (carLog.startDistance || 0);

              const operationTime =
                carLog.endAt && carLog.startAt
                  ? moment2min(carLog.endAt) - moment2min(carLog.startAt)
                  : 0;
              const actualDrivingTime =
                carLog.minTimeEndAt && carLog.minTimeStartAt
                  ? moment2min(carLog.minTimeEndAt) -
                    moment2min(carLog.minTimeStartAt)
                  : 0;
              const forwardDrivingTimes = 0;

              const currentValue: DailyLogSummaryTotalData = {
                adult: currentLog.adult || 0,
                adultPrice: currentLog.adultPrice,

                child: currentLog.child,
                handicapped: currentLog.handicapped,
                returnLicense: currentLog.returnLicense,
                halfNumber: currentLog.halfNumber,
                halfPrice: currentLog.halfPrice,

                baby: currentLog.baby,
                hascommuterpass: currentLog.hascommuterpass,
                pointC: currentLog.pointC,
                freeNumber: currentLog.freeNumber,
                freePrice: currentLog.freePrice,

                number: currentLog.totalNumber || currentLog.totalNubmer || 0,
                price: currentLog.totalPrice || 0,
                cash: currentLog.cash || 0,
                taxiTicket: currentLog.taxi_ticket || 0,
                localCurrency: currentLog.local_currency || 0,

                serviceDistance,
                distance,
                operationTime: operationTime,
                actualDrivingTime: actualDrivingTime || 0,
                forwardDrivingTimes: forwardDrivingTimes || 0,

                totalPrice: 0,
                totalNumber: 0,

                adjustPrice: currentLog.adjustPrice || 0, // TODO bug?? This is missing.
              };
              Utils.objectAddition(lineTotal, currentValue);
              Utils.objectAddition(subTotal, currentValue);
            },
          );
          return {
            lineTotal,
            service,
            serviceId,
            cyclick,
            start,
            end,
            alert: false,
          };
        },
      );
      Utils.objectAddition(total, subTotal);
      return {
        courseData,
        serviceLogs,
        subTotal,
      };
    },
  );

  const params = {
    dailyLogSummaryData,
    total,
    current,
  };

  return (
    <Grid container alignItems="stretch" direction="row">
      <Grid item xs={12}>
        <h2>
          <button
            onClick={(e) => {
              decreaseDate();
            }}
          >
            &#9664;
          </button>
          {current.format("YYYY-MM")}
          <button
            onClick={(e) => {
              increaseDate();
            }}
          >
            &#9654;
          </button>
        </h2>
        <br />
      </Grid>

      <DailyLogSummaryView {...params} />
    </Grid>
  );
};

export default DailyLogSummary;
