import * as Utils from "../../common/Utils";
import holiday from "../../common/holidays";
import { allLineData } from "../../data/courses";

import { LineData } from "../../types/data_types";

export const defaultSum = {
  distancePrice: 0,
  distance: 0,
  otherExpense: 0,
  subTotalExpense: 0,
  totalPayment: 0,
  totalPrice: 0,
  totalNumber: 0,
};

export const summarizeTheDayLog = (
  sumData: any,
  lineId: string,
  dataArray: any,
  currentDay: any,
  currentLineId: string,
  currentServiceId: string,
  companyId: string,
  opRecord: any,
  isCompanySummary: any,
) => {
  // depend on line and service id data
  const distanceUnitPrice = Utils.getDistanceUnutPrice(lineId, currentDay);
  const fixExpense = Utils.getExpense(
    lineId,
    currentLineId === "sum" ? "sum" : currentServiceId,
  );
  const existOperation = !!(opRecord && opRecord[lineId]);
  const existChargedOperation = existOperation && fixExpense > 0;

  const tmpLog = (() => {
    if (dataArray && dataArray.length > 0) {
      const ret = dataArray.reduce((tmp: any, data: any) => {
        const carLog = data.carLog || {};

        const distance = carLog.distance; // B
        const distancePrice = Math.round(
          distanceUnitPrice * (carLog.distance || 0),
        ); // D
        const otherExpense = 0; // TODO
        const subTotalExpense = distancePrice + otherExpense; // F
        const totalNumber = data.totalNumber || data.totalNubmer || 0;
        const totalPrice = data.totalPrice || 0; // G
        const totalPayment = subTotalExpense - totalPrice; // H

        return {
          distance: tmp.distance + distance, // B
          distancePrice: tmp.distancePrice + distancePrice, // D
          otherExpense: tmp.otherExpense + otherExpense, // E
          subTotalExpense: tmp.subTotalExpense + subTotalExpense, // F
          totalNumber: tmp.totalNumber + totalNumber,
          totalPrice: tmp.totalPrice + totalPrice, // G
          totalPayment: tmp.totalPayment + totalPayment, // H
        };
      }, defaultSum);
      ret.subTotalExpense = ret.subTotalExpense + fixExpense;
      ret.totalPayment = ret.totalPayment + fixExpense;
      return ret;
      // end of each service
    }
    if (existChargedOperation || isCompanySummary) {
      return {
        otherExpense: 0,
        subTotalExpense: fixExpense,
        totalPayment: fixExpense,
      };
    }
    return {};
  })();
  const newSumData = { ...sumData };
  Object.keys(defaultSum).forEach((key) => {
    newSumData[key] = newSumData[key] + (tmpLog[key] || 0);
  });

  newSumData["fixExpense"] =
    (newSumData["fixExpense"] || 0) + (fixExpense || 0);
  newSumData["existOperation"] =
    newSumData["existOperation"] || false || existOperation;
  newSumData["existChargedOperation"] =
    newSumData["existChargedOperation"] || false || existChargedOperation;
  return newSumData;
};

export const shouldPaymentDay = (currentDay: any) => {
  if (holiday[currentDay.day.format("YYYY-MM-DD")]) {
    return false;
  }
  if (["01-01", "01-02", "01-03"].includes(currentDay.day.format("MM-DD"))) {
    return false;
  }

  return currentDay.day.day() !== 0;
};
export const summarizeTheDayLog2 = (
  currentDay: any,
  currentLineId: string,
  currentServiceId: string,
  companyId: string,
  opRecord: any,
  isCompanySummary: any,
) => {
  // depend on line and service id data
  const fixExpense = isCompanySummary
    ? Utils.getCompanyExpense(companyId)
    : Utils.getExpense(
        currentLineId,
        currentLineId === "sum" ? "sum" : currentServiceId,
      );
  const existOperation = shouldPaymentDay(currentDay);
  const existChargedOperation = shouldPaymentDay(currentDay) && fixExpense > 0;

  const newSumData = existChargedOperation
    ? {
        distancePrice: 0,
        distance: 0,
        otherExpense: 0,
        subTotalExpense: fixExpense,
        totalPayment: fixExpense,
        totalPrice: 0,
        totalNumber: 0,
        fixExpense: fixExpense,
        existOperation,
        existChargedOperation,
      }
    : { ...defaultSum };
  return newSumData;
};

export const getTheDayData = (
  lineIds: string[],
  dataObj: any,
  currentDay: any,
  currentLineId: string,
  currentServiceId: any,
  companyId: string,
  opRecord: any,
) => {
  // hack
  const isCompanySummary = currentLineId === "sum";

  if (lineIds.length) {
    if (isCompanySummary) {
      return Object.keys(allLineData[companyId]).reduce((sumData, _lineId) => {
        const dataArray = dataObj[_lineId];
        return summarizeTheDayLog(
          sumData,
          _lineId,
          dataArray,
          currentDay,
          currentLineId,
          currentServiceId,
          companyId,
          opRecord,
          isCompanySummary,
        );
      }, defaultSum);
    } else {
      return lineIds.reduce((sumData, _lineId) => {
        const dataArray = dataObj[_lineId];
        return summarizeTheDayLog(
          sumData,
          _lineId,
          dataArray,
          currentDay,
          currentLineId,
          currentServiceId,
          companyId,
          opRecord,
          isCompanySummary,
        );
      }, defaultSum);
    }
  }
  return summarizeTheDayLog2(
    currentDay,
    currentLineId,
    currentServiceId,
    companyId,
    opRecord,
    isCompanySummary,
  );
};

export const getNewDayLog = (
  theDayData: any,
  lineData: LineData,
  dataObj: any,
  currentLineId: string,
  currentDay: any,
  sumMode: any,
) => {
  const distanceUnitPrice =
    currentLineId === "sum"
      ? "---"
      : Utils.getDistanceUnutPrice(currentLineId, currentDay);
  const existCarResult = !!Object.values(dataObj)
    .flat()
    .some((data: any) => !!data.carLog);
  const carName =
    currentLineId === "sum" ? "---" : lineData[currentLineId].carName;
  const drivedTerm =
    !sumMode && (dataObj[currentLineId] || []).length === 1
      ? dataObj[currentLineId][0].drivedTerm
      : null;

  const updateData = (() => {
    if (existCarResult) {
      return {
        existCarResult,
        existOperation: theDayData.existOperation,
        existChargedOperation: theDayData.existChargedOperation,
        carName,
        fixExpense: theDayData.fixExpense, // A sum
        drivedTerm,
        distance: theDayData.distance,
        distanceUnitPrice, // C
        distancePrice: theDayData.distancePrice, // D
        otherExpense: theDayData.otherExpense, // E
        subTotalExpense: theDayData.subTotalExpense, // F
        totalNumber: theDayData.totalNumber,
        totalPrice: theDayData.totalPrice, // G
        totalPayment: theDayData.totalPayment, // H
      };
    } else if (theDayData.existChargedOperation) {
      return {
        existCarResult,
        existOperation: theDayData.existOperation,
        existChargedOperation: theDayData.existChargedOperation,
        carName,
        fixExpense: theDayData.fixExpense, // A sum
        subTotalExpense: theDayData.subTotalExpense, // F
        totalPayment: theDayData.totalPayment, // H
      };
    } else {
      return {
        existCarResult,
        existOperation: theDayData.existOperation,
        existChargedOperation: theDayData.existChargedOperation,
        carName: theDayData.existOperation ? carName : "",
        fixExpense: theDayData.existOperation ? theDayData.fixExpense : "",
      };
    }
  })();

  return Object.assign(updateData, currentDay);
};
