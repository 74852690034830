class Customer {
  customer: any;
  constructor(_customer: any) {
    this.customer = _customer;
  }
  phoneNumber() {
    if (this.customer.phoneNumber) {
      return this.customer.phoneNumber.replace("+81", "0");
    }
  }
  name() {
    return this.customer.name;
  }
  nameKana() {
    return this.customer.namekana;
  }
  userId() {
    return this.customer.userId;
  }
  customerId() {
    return this.customer.customerId;
  }
  updateUsername(name: string) {
    this.customer.name = name;
  }
  updateUsernameKana(name: string) {
    this.customer.namekana = name;
  }
  data() {
    return this.customer;
  }
  save(db: any) {
    const refCustomer = db.doc(`/customers/${this.customer.customerId}`);
    refCustomer.set(this.customer);
  }
  static defaultValue() {
    return {
      postalCode: "",
      addressFull: "",
      landlineNumber: "",
      sex: 1,
      isAdult: 1,
      hasbaby: 0,
      hascommuterpass: 0,
      handicapped: 0,
      returnLicense: 0,
      point: 0,
      homePosition: {},
    };
  }
}

export default Customer;
