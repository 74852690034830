import {
  CustomerData,
  ReservationHomePosition,
  ReservationPositionData,
  ReservationData,
  RideTemporaryPair,
  RideTemporaryData,
} from "../../types/data_types";

export const getHomePosition = (
  customer: CustomerData,
): ReservationHomePosition => {
  if (Object.keys(customer.nearBusStopLocation || {}).length === 2) {
    return Object.assign(
      customer.homePosition,
      customer.nearBusStopLocation || {},
      {
        type: "nearBusStop",
        name: customer.nearBusStop,
        nearBusStopPoint: customer.nearBusStopPoint,
      },
    );
  } else {
    return Object.assign(
      customer.homePosition,
      customer.nearBusStopLocation || {},
      {
        type: "home",
        name: "自宅",
      },
    );
  }
};

export const canReserve = (
  user: CustomerData,
  withBaby: number,
  withChild: number,
  onPosition: ReservationPositionData,
  offPosition: ReservationPositionData,
  lineReservations: ReservationData[],
  capacity: number,
  inbound: boolean,
) => {
  const myRideNumner = 1 + withBaby + withChild;
  const current: RideTemporaryPair = [
    {
      pos: onPosition.positionIndex,
      name: user.name,
      number: myRideNumner,
    },
    {
      pos: offPosition.positionIndex,
      name: user.name,
      number: -myRideNumner,
    },
  ];
  // filter user's data and convert data format then insert data
  const updatedLine: RideTemporaryPair[] = (lineReservations || [])
    .filter((line: ReservationData) => {
      return line.customerId !== user.id;
    })
    .map((line: ReservationData) => {
      const rideNumnber = 1 + (line.baby || 0) + (line.child || 0);
      return [
        {
          pos: line.offPosition.positionIndex,
          name: line.customerName,
          number: -rideNumnber,
        },
        {
          pos: line.onPosition.positionIndex,
          name: line.customerName,
          number: rideNumnber,
        },
      ];
    });
  updatedLine.push(current);
  const newLine = updatedLine.flat();

  // sort data
  newLine.sort((a: RideTemporaryData, b: RideTemporaryData) => {
    if (a.pos === b.pos) {
      return a.number === b.number ? 0 : a.number > b.number ? 1 : -1;
    }
    return a.pos > b.pos ? 1 : -1;
  });
  if (!inbound) {
    newLine.reverse();
  }
  let max_number = 0;
  // ??
  newLine.reduce((ret: number, line: RideTemporaryData) => {
    ret = ret + line.number;
    if (ret > max_number) {
      max_number = ret;
    }
    return ret;
  }, 0);
  return !(max_number > capacity);
};

export const convLineReservations = (lineReservations: ReservationData[]) => {
  return lineReservations
    .sort((a: ReservationData, b: ReservationData) => {
      if (a.serviceId === b.serviceId) {
        return a.onPosition.positionIndex > b.onPosition.positionIndex ? 1 : -1;
      }
      return a.serviceId > b.serviceId ? 1 : -1;
    })
    .reduce(
      (res: { [key: string]: ReservationData[] }, current: ReservationData) => {
        if (res[current.serviceId] === undefined) {
          res[current.serviceId] = [];
        }
        res[current.serviceId].push(current);
        return res;
      },
      {},
    );
};
