import { createTheme } from "@mui/material/styles";
import primary from "@mui/material/colors/grey";
import secondary from "@mui/material/colors/blueGrey";

// import primary from "@mui/material/colors/orange";
// import secondary from "@mui/material/colors/deepOrange";

export default createTheme({
  typography: {
    useNextVariants: true,
    fontSize: 14,
  },
  palette: {
    primary: primary,
    secondary: secondary,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        outlinedPrimary: {
          color: "#3f51b5",
          border: "1px solid rgba(63, 81, 181, 0.5)",
        },
        containedPrimary: {
          "background-color": "#3f51b5",
          color: "white",
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          minWidth: 0,
        },
        padding: 0,
        border: 0,
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          // paddingRight: "20px",
          padding: "4px",
          fontSize: 14,
        },
      },
    },
  },
} as any);
