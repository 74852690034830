import React from "react";
import { Grid } from "@mui/material";

class ErrorBoundary extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    console.log(error, errorInfo);
  }

  render() {
    // @ts-ignore
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Grid
          container
          alignItems="stretch"
          direction="row"
          style={{ textAlign: "center" }}
        >
          <Grid item xs={12}>
            <h1>ごめんなさい。エラーが出ています</h1>
            状況を確認して、なるべく早く問題を解決します！！
          </Grid>
        </Grid>
      );
    }

    // @ts-ignore
    return this.props.children;
  }
}

export default ErrorBoundary;
