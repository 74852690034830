import React from "react";
import Modal from "./Modal";
import Button from "@mui/material/Button";
import { styled } from "@mui/system";

const ModalHeaderDiv = styled("div")(({ theme }) => ({
  marginTop: 0,
  fontSize: "1.25rem",
  fontWeight: 500,
  textAlign: "left",
}));

const ModalBodyDiv = styled("div")(({ theme }) => ({
  textAlign: "left",
  paddingTop: "10px",
}));

const ModalFooterDiv = styled("div")(({ theme }) => ({
  textAlign: "right",
}));

function MessageModal(props: any) {
  const { messageType, toggle } = props;

  const message = ((_type) => {
    if (_type === "customer.save") {
      return <span>保存しました</span>;
    }
  })(messageType);
  return (
    <Modal {...props}>
      <ModalHeaderDiv> Message </ModalHeaderDiv>
      <ModalBodyDiv>{message}</ModalBodyDiv>
      <ModalFooterDiv>
        <Button onClick={toggle}>close</Button>
      </ModalFooterDiv>
    </Modal>
  );
}

export default MessageModal;
