import { CourseData } from "../types/data_types";
import { ways } from "./way4";

export const data: CourseData = {
  id: "QbitiXYi3v6KxJS98Qva",
  companyId: "Qqh1S4vBjZ88Dv2uKPks",
  name: "三崎地区循環",
  carName: "ハイヤー",
  shouldSchedule: true,
  reporter: "西村",
  defaultPosition: {
    lng: 132.884234,
    lat: 32.80619643,
  },
  services: [
    {
      id: 0,
      sname: "301便",
      lname: "三崎地区周回",
      name: "301便 三崎地区周回 8:20→8:40",
      inbound: true,
      capacity: 9,
      start: 820,
    },
    {
      id: 1,
      sname: "302便",
      lname: "三崎地区周回",
      name: "302便 三崎地区周回 10:40→11:00",
      inbound: true,
      capacity: 9,
      start: 1040,
    },
    {
      id: 2,
      sname: "303便",
      lname: "三崎地区周回",
      name: "303便 三崎地区周回 12:25→12:45",
      inbound: true,
      capacity: 9,
      start: 1225,
    },
    {
      id: 3,
      sname: "304便",
      lname: "三崎地区周回",
      name: "304便 三崎地区周回 13:40→14:00",
      inbound: true,
      capacity: 9,
      start: 1340,
    },
    {
      id: 4,
      sname: "305便",
      lname: "三崎地区周回",
      name: "305便 三崎地区周回 15:15→15:35",
      inbound: true,
      capacity: 9,
      start: 1515,
    },
  ],
  ways,
  pins: [
    {
      id: 4010,
      name: "三崎バス停（始発）",
      location: { lng: 132.8761662, lat: 32.7907611 },
      busStopId: 0,
    },
    {
      id: 4020,
      name: "下ノ段",
      location: { lng: 132.874408, lat: 32.794788 },
      busStopId: 1,
    },
    {
      id: 4030,
      name: "平ノ段",
      location: { lng: 132.8670251, lat: 32.796066 },
      busStopId: 2,
    },
    {
      id: 4040,
      name: "斧積",
      location: { lng: 132.8802756, lat: 32.8141867 },
      busStopId: 3,
    },
    {
      id: 4050,
      name: "上野",
      location: { lng: 132.8966485, lat: 32.8172878 },
      busStopId: 4,
    },
    {
      id: 4060,
      name: "益野橋バス停",
      location: { lng: 132.8968625, lat: 32.8011875 },
      busStopId: 5,
    },
    {
      id: 4070,
      name: "浜益野",
      location: { lng: 132.8893605, lat: 32.7951222 },
      busStopId: 6,
    },
    {
      id: 4080,
      name: "三崎バス停（終着）",
      location: { lng: 132.8761662, lat: 32.7907611 },
      busStopId: 7,
    },
  ],
  pinTimes: [
    [
      { time: "8:20" },
      { time: "8:23" },
      { time: "8:26" },
      { time: "8:29" },
      { time: "8:32" },
      { time: "8:35" },
      { time: "8:37" },
      { time: "8:40" },
      { time: "10:40" },
    ],
    [
      { time: "10:40" },
      { time: "10:43" },
      { time: "10:46" },
      { time: "10:49" },
      { time: "10:52" },
      { time: "10:55" },
      { time: "10:57" },
      { time: "11:00" },
      { time: "12:25" },
    ],
    [
      { time: "12:25" },
      { time: "12:28" },
      { time: "12:31" },
      { time: "12:34" },
      { time: "12:37" },
      { time: "12:40" },
      { time: "12:42" },
      { time: "12:45" },
      { time: "12:25" },
    ],
    [
      { time: "13:40" },
      { time: "13:43" },
      { time: "13:46" },
      { time: "13:49" },
      { time: "13:52" },
      { time: "13:55" },
      { time: "13:57" },
      { time: "14:00" },
      { time: "15:15" },
    ],
    [
      { time: "15:15" },
      { time: "15:18" },
      { time: "15:21" },
      { time: "15:24" },
      { time: "15:27" },
      { time: "15:30" },
      { time: "15:32" },
      { time: "15:35" },
      { time: "" },
    ],
  ],
  areas: [
    {
      id: 14010,
      name: "三崎地区",
      pins: [4010, 4020, 4030, 4040, 4050, 4060, 4070, 4080],
    },
  ],
  prices: {
    14010: {
      to_area: {
        14010: { price: 100 },
      },
    },
  },
};
