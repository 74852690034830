import React, { useEffect, useState } from "react";
import { Button, TextField } from "@mui/material";

import Select from "../Select";
import GoogleMapPin from "./GoogleMapPin";

import { convBusStopsData } from "./utils/stopsUtils";

import * as Utils from "../common/Utils";

const defaultValue = {
  name: "",
  sortKey: 10000,
  lat: null,
  lng: null,
};

function AddPickup(props: any) {
  const { db, stopsData, path, defaultPosition } = props;

  const [currentBusStopName, setCurrentBusStopName] = useState("");
  const [currentBusStop, setCurrentBusStop] = useState(0);

  const [updateData, setUpdateData] = useState<any>(defaultValue);
  const [pinPosition, setPinPosition] = useState<any>({});

  const onChange = (keyName: any, isNum = false) => {
    return (e: any) => {
      const tmp: any = { ...updateData };
      if (isNum) {
        if (/^[0-9]+$/.test(e.target.value)) {
          tmp[keyName] = Number(e.target.value);
        } else if (e.target.value === "") {
          tmp[keyName] = "";
        } else {
          tmp[keyName] = "";
        }
      } else {
        tmp[keyName] = e.target.value;
      }
      setUpdateData(tmp);
    };
  };
  const isSetPosition = (() => {
    return (
      updateData["lat"] !== null &&
      updateData["lat"] !== undefined &&
      updateData["lng"] !== null &&
      updateData["lat"] !== undefined
    );
  })();

  const isSetName = (() => {
    return (
      updateData["name"] !== null &&
      updateData["name"] !== undefined &&
      updateData["name"] !== ""
    );
  })();

  const isValidData = (() => {
    return isSetName && updateData["sortKey"] !== "" && isSetPosition;
  })();
  const add = async () => {
    if (isValidData) {
      const id = db.collection("hoge").doc().id;
      const data = {
        type: "pickUp",
        name: [currentBusStopName, updateData["name"]].join("/"),
        location: {
          lat: updateData["lat"],
          lng: updateData["lng"],
        },
        sortKey: updateData["sortKey"],
        id: id,
        busStop1Id: currentBusStop,
      };
      const updatePath = `${path}/${id}`;
      await db.doc(updatePath).set(data);
      setUpdateData(defaultValue);
    }
  };

  const busStops = convBusStopsData(stopsData);

  useEffect(() => {
    const tmp = { ...updateData };
    tmp.lat = pinPosition["lat"];
    tmp.lng = pinPosition["lng"];
    setUpdateData(tmp);
  }, [pinPosition]);

  useEffect(() => {
    const currentStop = (busStops.data || []).find(
      (element: any) => element.value === currentBusStop,
    );
    const stopName = currentStop ? currentStop.name.split("/")[0] : "";
    setCurrentBusStopName(stopName);
  }, [currentBusStop, busStops]);

  const callback = (position: any) => {
    const tmp = {
      lat: Number(position["lat"]),
      lng: Number(position["lng"]),
    };
    setPinPosition(tmp);
  };
  const mapParams = { callback, busStops, defaultPosition };

  if (busStops.data.length === 0) {
    return <div>loading...</div>;
  }

  return (
    <div>
      <table>
        <tbody>
          <tr>
            <td>
              <b>乗降場所</b>
            </td>
            <td>
              <Select
                dataSet={busStops}
                onChange={(e: any) => {
                  // TODO ALERT IF change
                  Utils.onChange2(e, setCurrentBusStop);
                }}
                value={currentBusStop}
              />
            </td>
          </tr>
          <tr>
            <td>乗降場所名</td>
            <td>
              {currentBusStopName}/
              <TextField
                value={updateData["name"]}
                onChange={onChange("name")}
                sx={{ width: "300px" }}
              />
            </td>
          </tr>
          <tr>
            <td>並び</td>
            <td>
              <TextField
                value={updateData["sortKey"]}
                sx={{ width: "80px" }}
                onChange={onChange("sortKey", true)}
                type="number"
                inputProps={{ pattern: "d*" }}
              />
            </td>
          </tr>
          <tr>
            <td>緯度</td>
            <td>{updateData["lat"]}</td>
          </tr>
          <tr>
            <td>経度</td>
            <td>{updateData["lng"]}</td>
          </tr>
          <tr>
            <td />
            <td>
              {isSetPosition ? (
                ""
              ) : (
                <b style={{ color: "red" }}>位置を指定してください</b>
              )}
              {isSetName ? (
                ""
              ) : (
                <b style={{ color: "red" }}>乗降場所を入力してください</b>
              )}
              <Button
                variant="outlined"
                color="primary"
                disabled={!isValidData}
                onClick={add}
              >
                追加
              </Button>
            </td>
          </tr>
        </tbody>
      </table>
      <div style={{ height: "60vh", width: "100%" }}>
        <GoogleMapPin {...mapParams} />
      </div>
    </div>
  );
}
export default AddPickup;
