import React from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import StyledFirebaseAuth from "./StyledFirebaseAuth";
import { Navigate, useParams } from "react-router-dom";
import Header from "./Header";

import { DefaultProps } from "./props";

const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: "popup",
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    {
      provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
      recaptchaParameters: {
        size: "invisible",
      },
      requireDisplayName: true,
      defaultCountry: "JP",
      whitelistedCountries: ["JP"],
    },
  ],
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: (result: any) => {
      const { additionalUserInfo, credential } = result;
      console.log(result);
      if (
        additionalUserInfo &&
        credential &&
        credential.providerId === firebase.auth.TwitterAuthProvider.PROVIDER_ID
      ) {
        // console.log("Twitter user name=", additionalUserInfo.username);
      }
      return false;
    },
  },
};

function Login(props: DefaultProps) {
  const { user } = props;
  const params = useParams();
  const target = params["*"];

  if (!user) {
    return (
      <React.Fragment>
        <Header user={user} />
        <div style={{ padding: "40px" }}>
          <StyledFirebaseAuth
            uiConfig={uiConfig}
            firebaseAuth={firebase.auth()}
          />
        </div>
      </React.Fragment>
    );
  }
  if (target) {
    return <Navigate to={"/" + target} />;
  }
  return <Navigate to={"/"} />;
}

export default Login;
