import React, { useReducer, useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import GoogleMapReact from "google-map-react";
import { mapKey } from "../config";

import InfoWindow from "./InfoWindow";
import InfoWindowRide from "./InfoWindowRide";
import InfoWindowOnOff from "./InfoWindowOnOff";

import * as DataUtils from "../common/DataUtils";
import * as Utils from "../common/Utils";
import { kochiPosition, defaultCenter } from "./utils/position";

import {
  LineData,
  CourseData,
  Position,
  ReservationFreeRidePositionData,
  CourseDataPin,
  ReservationHomePosition,
  ReservationPositionData,
  PositionType,
} from "../types/data_types";

type GLINE = {
  path: google.maps.Polyline;
  pins: google.maps.Marker[];
};

type GLINES = {
  [key: string]: GLINE;
};

function infowindowReducer(state: any, action: any) {
  if (state) {
    state.close();
  }
  return action;
}

type Props = {
  currentLineId: string;
  currentServiceId: number;
  homePosition: ReservationHomePosition;
  setOnPosition: (position: ReservationPositionData) => void;
  setOffPosition: (position: ReservationPositionData) => void;
  onPosition: ReservationFreeRidePositionData;
  offPosition: ReservationFreeRidePositionData;
  lineData: LineData;
};

const GoogleMap: React.FC<Props> = ({
  currentLineId,
  currentServiceId,
  homePosition,
  setOnPosition,
  setOffPosition,
  onPosition,
  offPosition,
  lineData,
}) => {
  //  function GoogleMap(props) {
  const props = {
    currentLineId,
    currentServiceId,
    homePosition,
    setOnPosition,
    setOffPosition,
    onPosition,
    offPosition,
    lineData,
  };
  const [glines, setGLines] = useState<GLINES>({});

  const [clickPosition, setClickPosition] = useState<any>(null);
  const [clickPositionMarker, setClickPositionMarker] = useState<any>(null);
  const [infoWindowInstance, setInfoWindowInstance] = useReducer(
    infowindowReducer,
    null,
  );

  const [center, setCenter] = useState(kochiPosition);
  const [zoom, setZoom] = useState(11);
  // Google js instances
  const [myGoogleMap, setMyGoogleMap] = useState<any>(null);

  const [onPositionPin, setOnPositionPin] = useState<google.maps.Marker | null>(
    null,
  );
  const [offPositionPin, setOffPositionPin] =
    useState<google.maps.Marker | null>(null);

  useEffect(() => {
    const showLine = (key: string) => {
      if (myGoogleMap) {
        const map = myGoogleMap.map;
        glines[key].path.setMap(map);
        glines[key].pins.map((pin: google.maps.Marker) => {
          return pin.setMap(map);
        });
      }
    };

    const moveToLine = (key: string) => {
      setZoom(13);
      const center =
        lineData[key].ways.flat()[
          Math.round(lineData[key].ways.flat().length / 2)
        ];
      setCenter(center);
    };

    const clearAllLines = () => {
      Object.values(glines).map((gline: GLINE) => {
        gline.path.setMap(null);
        return gline.pins.map((pin: google.maps.Marker) => {
          return pin.setMap(null);
        });
      });
    };

    (async () => {
      if (myGoogleMap && Object.values(glines).length > 0) {
        clearAllLines();
        showLine(currentLineId);
        moveToLine(currentLineId);
        const map = myGoogleMap.map;
        map.setZoom(zoom);
      }
    })();
  }, [myGoogleMap, glines, currentLineId, zoom]); // eslint-disable-line react-hooks/exhaustive-deps

  const setClickMarker = (
    position: ReservationHomePosition | Position,
    icon: string,
    id: string,
    name: string,
    type: PositionType,
  ) => {
    const marker = setMarker(
      position,
      "http://maps.google.com/mapfiles/" + icon,
    );
    // open reservation window
    const data: ReservationFreeRidePositionData = {
      id,
      clickPosition: position,
      name,
      type,
    };
    openReservationWindow("ride", data, myGoogleMap.map, myGoogleMap.maps)();
    marker.addListener(
      "click",
      openReservationWindow("ride", data, myGoogleMap.map, myGoogleMap.maps),
    );
    return marker;
  };
  const setMarker = (position: Position, icon: string) => {
    const marker = new myGoogleMap.maps.Marker({
      position,
      map: myGoogleMap.map,
      icon,
    });
    return marker;
  };

  useEffect(() => {
    // update user click position
    if (clickPositionMarker) {
      clickPositionMarker.setMap(null);
    }
    if (myGoogleMap) {
      const marker = setClickMarker(
        clickPosition,
        "ms/icons/blue-dot.png",
        "clickPoint", // id
        "ここ",
        "click", // type
      );
      setClickPositionMarker(marker);
    }
  }, [clickPosition]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (onPositionPin) {
      onPositionPin.setMap(null);
    }
    if (myGoogleMap && onPosition) {
      const position = Utils.getPinPosition(onPosition);
      const marker = setMarker(position, "/images/start.png");
      const data: ReservationFreeRidePositionData = {
        id: "on",
        clickPosition: position,
        name: "乗車",
        type: "" as "",
        onOffPositionData: onPosition,
      };
      marker.addListener(
        "click",
        openReservationWindow("onoff", data, myGoogleMap.map, myGoogleMap.maps),
      );
      setOnPositionPin(marker);
    }
    setClickPosition(null);
  }, [onPosition, myGoogleMap]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (offPositionPin) {
      offPositionPin.setMap(null);
    }
    if (myGoogleMap && offPosition) {
      const position = Utils.getPinPosition(offPosition);
      const marker = setMarker(position, "/images/goal.png");
      const data = {
        id: "off",
        clickPosition: position,
        name: "下車",
        type: "" as "",
        onOffPositionData: offPosition,
      };
      marker.addListener(
        "click",
        openReservationWindow("onoff", data, myGoogleMap.map, myGoogleMap.maps),
      );
      setOffPositionPin(marker);
    }
    setClickPosition(null);
  }, [offPosition, myGoogleMap]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (myGoogleMap && homePosition) {
      // todo
      if (homePosition.type === "nearBusStop") {
        setClickMarker(
          homePosition,
          "ms/icons/pink-dot.png",
          "homePosition", // id
          homePosition.name,
          "homeNearBusStop", // type
        );
      } else {
        // home
        setClickMarker(
          homePosition,
          "ms/icons/pink-dot.png",
          "homePosition", // id
          homePosition.name || "自宅",
          "home", // type
        );
      }
    }
  }, [myGoogleMap, homePosition, currentLineId]); // eslint-disable-line react-hooks/exhaustive-deps

  function callbackPin(action: string, data: ReservationFreeRidePositionData) {
    if (action === "on") {
      const rideData = getRideData(data, true);
      setOnPosition(rideData as ReservationPositionData);
    }
    if (action === "off") {
      const rideData = getRideData(data, false);
      setOffPosition(rideData as ReservationPositionData);
    }
    setInfoWindowInstance(null);
  }
  const getRideData = (
    data: ReservationFreeRidePositionData,
    isOn: boolean,
  ) => {
    if (data.type === "busStop") {
      return data;
    } else {
      // todo home
      const rideData = DataUtils.updateRideData(
        lineData[currentLineId],
        currentServiceId,
        data,
        isOn,
      );
      return rideData;
    }
  };

  function openReservationWindow(
    type: string,
    data: ReservationFreeRidePositionData,
    map: any,
    maps: any,
  ) {
    const { id, clickPosition, name } = data;
    return () => {
      const offset = new maps.Size(0, type === "onoff" ? -45 : -30);
      const infoWindow = new maps.InfoWindow({
        content: '<div id="' + id + '" />',
        position: clickPosition,
        pixelOffset: offset,
      });
      const params = { id, callbackPin, name, data };
      infoWindow.addListener("domready", () => {
        const root = ReactDOM.createRoot(document.getElementById(id)!);
        root.render(
          <InfoWindow>
            {type === "ride" ? <InfoWindowRide {...props} {...params} /> : ""}
            {type === "onoff" ? <InfoWindowOnOff {...props} {...params} /> : ""}
          </InfoWindow>,
        );
      });
      infoWindow.open(map);
      setInfoWindowInstance(infoWindow);
    };
  }

  const mapClickEvent = (e: any) => {
    setInfoWindowInstance(null);
    setClickPosition({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
  };

  const renderMarkers = (map: any, maps: any) => {
    setMyGoogleMap({ maps, map });

    map.addListener("click", mapClickEvent);

    const lines = Object.values(lineData).reduce(
      (tmp: { [key: string]: GLINE }, line: CourseData, key: number) => {
        let index = -1;
        const data = {
          path: new maps.Polyline({
            path: line.ways.flat().map((p: Position) => {
              return { lat: p.lat, lng: p.lng };
            }),
            geodesic: true,
            strokeColor: "#356859",
            strokeOpacity: 1,
            strokeWeight: 3,
          }),
          pins: line.pins.map((pin: CourseDataPin, key2: number) => {
            const marker = new maps.Marker({
              position: pin.location,
              title: pin.name,
              icon: {
                url: "http://maps.google.com/mapfiles/ms/icons/yellow-dot.png",
                labelOrigin: new maps.Point(15, -10),
              },
              //
              label: {
                text: pin.name,
                color: "#00F",
                fontFamily: "Arial",
                fontSize: "20",
                // fontWeight: "bold",
              },
            });
            const data: ReservationFreeRidePositionData = {
              id: "clickPoint_" + key + "_" + key2,
              position: pin.location,
              clickPosition: pin.location,
              positionIndex: index === -1 ? 0 : index,
              name: pin.name,
              type: "busStop",
              busStop1Id: pin.busStopId,
            };
            if (line.ways[key2]) {
              index = index + line.ways[key2].length;
            }
            marker.addListener(
              "click",
              openReservationWindow("ride", data, map, maps),
            );
            return marker;
          }),
        };
        tmp[line.id] = data;
        return tmp;
      },
      {},
    );
    setGLines(lines);
  };

  return (
    <React.Fragment>
      <GoogleMapReact
        bootstrapURLKeys={{ key: mapKey }}
        defaultCenter={defaultCenter}
        yesIWantToUseGoogleMapApiInternals={true}
        center={center}
        defaultZoom={11}
        zoom={zoom}
        onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
      ></GoogleMapReact>
    </React.Fragment>
  );
};

export default GoogleMap;
