import React, { useEffect, useState } from "react";

import { styled } from "@mui/system";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

const MuiTableRoot = styled(Table)(({ theme }) => ({
  border: "solid 1px #888888",
  width: "1050px",
  margin: "5px 0",
}));

const MuiTableCellRootAndHead = styled(TableCell)(({ theme }) => ({
  "&:last-child": {
    padding: "5px 1px",
  },
  textAlign: "center",
  fontWeight: "bold",
  color: "black",
  border: "solid 1px #888888",
  padding: "5px 1px",
  backgroundColor: "#eeefef",
  fontSize: "0.875rem",
  lineHeight: "1.3",
}));

const MuiTableCellRootAndHeadWidth150 = styled(MuiTableCellRootAndHead)(
  ({ theme }) => ({
    width: "150px",
  }),
);

const MuiTableCellRootAndHeadWidth270 = styled(MuiTableCellRootAndHead)(
  ({ theme }) => ({
    width: "270px",
  }),
);
const MuiTableCellRootAndHeadVTOP = styled(MuiTableCellRootAndHead)(
  ({ theme }) => ({
    verticalAlign: "top",
  }),
);
const MuiTableCellRootAndHeadNoBottom = styled(MuiTableCellRootAndHead)(
  ({ theme }) => ({
    borderBottom: "none",
  }),
);

const MuiTableCellBody = styled(TableCell)(({ theme }) => ({
  textAlign: "center",
  borderBottom: "none",
  fontSize: "0.8rem",
  padding: "1px 3px 1px 3px",
  border: "solid 0.5px #BBB",
}));

const MuiTableCellBodyAndFooter = styled(MuiTableCellBody)(({ theme }) => ({
  /*合計の行の設定*/ height: "40px",
  fontWeight: "bold",
  textAlign: "center",
  backgroundColor: "#eeefef",
}));

const MuiTableCellBodyAndTCenter = styled(MuiTableCellBody)(({ theme }) => ({
  /*健常者〜未就学児の人数の文字をセンタリング。ただしここはラジオボタンになる*/
  textAlign: "center",
}));

function ReservationCustomerTable(props: any) {
  const { reservations, customerObj } = props;

  const [totalPrice, setTotalPrice] = useState(0);
  const [finalResult, setFinalResult] = useState<any>({});

  useEffect(() => {
    const _finalResult = reservations.reduce(
      (tmp: any, current: any) => {
        tmp.totalPrice = tmp.totalPrice + current.price.actualPrice;
        if (current.child) {
          tmp.child += current.child;
          tmp.halfNumber += current.child;
        }
        if (current.baby) {
          tmp.baby += current.baby;
          tmp.freeNumber += current.baby;
        }
        if (current.hascommuterpass) {
          tmp.hascommuterpass++;
          tmp.freeNumber++;
        } else if (current.returnLicense) {
          tmp.returnLicense++;
          tmp.halfNumber++;
        } else if (current.handicapped) {
          tmp.handicapped++;
          tmp.halfNumber++;
        } else if (current.isAdult === 0) {
          tmp.child += 1;
          tmp.halfNumber += 1;
        } else {
          tmp.adult++;
        }
        return tmp;
      },
      {
        child: 0,
        baby: 0,
        hascommuterpass: 0,
        returnLicense: 0,
        handicapped: 0,
        adult: 0,
        totalPrice: 0,
        halfNumber: 0,
        freeNumber: 0,
      },
    );
    _finalResult.totalNumber =
      _finalResult.adult + _finalResult.halfNumber + _finalResult.freeNumber;
    setFinalResult(_finalResult);
    setTotalPrice(_finalResult.totalPrice);
  }, [reservations]);

  return (
    <div>
      <MuiTableRoot>
        <TableHead>
          <TableRow>
            <MuiTableCellRootAndHeadWidth150 rowSpan={4}>
              乗客名
            </MuiTableCellRootAndHeadWidth150>
            <MuiTableCellRootAndHead colSpan={7}>種別</MuiTableCellRootAndHead>
            <MuiTableCellRootAndHead rowSpan={4}>料金</MuiTableCellRootAndHead>
            <MuiTableCellRootAndHeadWidth270 rowSpan={4}>
              住所
            </MuiTableCellRootAndHeadWidth270>
            <MuiTableCellRootAndHeadWidth150 rowSpan={4}>
              固定電話番号
            </MuiTableCellRootAndHeadWidth150>
            <MuiTableCellRootAndHeadWidth150 rowSpan={4}>
              携帯電話番号
            </MuiTableCellRootAndHeadWidth150>
          </TableRow>
          <TableRow>
            <MuiTableCellRootAndHead colSpan={5}>大人</MuiTableCellRootAndHead>
            <MuiTableCellRootAndHeadNoBottom rowSpan={2}>
              小<br />人
            </MuiTableCellRootAndHeadNoBottom>
            <MuiTableCellRootAndHeadNoBottom rowSpan={2}>
              未<br />就<br />学<br />児
            </MuiTableCellRootAndHeadNoBottom>
          </TableRow>
          <TableRow>
            <MuiTableCellRootAndHeadVTOP rowSpan={2}>
              健<br />常<br />者
            </MuiTableCellRootAndHeadVTOP>
            <MuiTableCellRootAndHeadVTOP>
              障<br />が<br />い<br />者
            </MuiTableCellRootAndHeadVTOP>
            <MuiTableCellRootAndHeadVTOP>
              免<br />許<br />返<br />納<br />者
            </MuiTableCellRootAndHeadVTOP>
            <MuiTableCellRootAndHeadVTOP>
              定<br />期<br />券
            </MuiTableCellRootAndHeadVTOP>
            <MuiTableCellRootAndHeadVTOP>
              ポ<br />イ<br />ン<br />ト<br />C
            </MuiTableCellRootAndHeadVTOP>
          </TableRow>
          <TableRow>
            <MuiTableCellRootAndHead colSpan={2}>半額</MuiTableCellRootAndHead>
            <MuiTableCellRootAndHead colSpan={2}>無料</MuiTableCellRootAndHead>
            <MuiTableCellRootAndHead>半額</MuiTableCellRootAndHead>
            <MuiTableCellRootAndHead>無料</MuiTableCellRootAndHead>
          </TableRow>
        </TableHead>
        <TableBody>
          {(reservations || []).map((reservation: any, rkey: number) => {
            const extraChild = reservation.isAdult === 0 ? 1 : 0;
            const customer: any = customerObj[reservation.customerId] || {};
            return (
              <TableRow key={reservation.id}>
                <MuiTableCellBody>{reservation.customerName}</MuiTableCellBody>
                <MuiTableCellBodyAndTCenter>
                  {reservation.handicapped !== 1 &&
                  reservation.returnLicense !== 1 &&
                  reservation.hascommuterpass !== 1 &&
                  reservation.isAdult !== 0
                    ? "1"
                    : ""}
                </MuiTableCellBodyAndTCenter>
                <MuiTableCellBodyAndTCenter>
                  {reservation.handicapped === 1 ? "1" : ""}
                </MuiTableCellBodyAndTCenter>
                <MuiTableCellBodyAndTCenter>
                  {reservation.returnLicense === 1 ? "1" : ""}
                </MuiTableCellBodyAndTCenter>
                <MuiTableCellBodyAndTCenter>
                  {reservation.hascommuterpass === 1 ? "1" : ""}
                </MuiTableCellBodyAndTCenter>
                <MuiTableCellBodyAndTCenter></MuiTableCellBodyAndTCenter>
                <MuiTableCellBodyAndTCenter>
                  {reservation.child + extraChild > 0
                    ? reservation.child + extraChild
                    : ""}
                </MuiTableCellBodyAndTCenter>
                <MuiTableCellBodyAndTCenter>
                  {reservation.baby > 0 ? reservation.baby : ""}
                </MuiTableCellBodyAndTCenter>
                <MuiTableCellBodyAndTCenter>
                  {(reservation.price || {}).actualPrice}
                </MuiTableCellBodyAndTCenter>
                <MuiTableCellBody>{customer.addressFull}</MuiTableCellBody>
                <MuiTableCellBody>{customer.landlineNumber}</MuiTableCellBody>
                <MuiTableCellBody>{customer.phoneNumber}</MuiTableCellBody>
              </TableRow>
            );
          })}
          <TableRow>
            <MuiTableCellBodyAndFooter>合計</MuiTableCellBodyAndFooter>
            <MuiTableCellBodyAndFooter>
              {finalResult.adult || 0}
            </MuiTableCellBodyAndFooter>
            <MuiTableCellBodyAndFooter>
              {finalResult.handicapped || 0}
            </MuiTableCellBodyAndFooter>
            <MuiTableCellBodyAndFooter>
              {finalResult.returnLicense || 0}
            </MuiTableCellBodyAndFooter>
            <MuiTableCellBodyAndFooter>
              {finalResult.hascommuterpass || 0}
            </MuiTableCellBodyAndFooter>
            <MuiTableCellBodyAndFooter>0</MuiTableCellBodyAndFooter>
            <MuiTableCellBodyAndFooter>
              {finalResult.child || 0}
            </MuiTableCellBodyAndFooter>
            <MuiTableCellBodyAndFooter>
              {finalResult.baby || 0}
            </MuiTableCellBodyAndFooter>
            <MuiTableCellBodyAndFooter>{totalPrice}</MuiTableCellBodyAndFooter>
            <MuiTableCellBodyAndFooter></MuiTableCellBodyAndFooter>
            <MuiTableCellBodyAndFooter></MuiTableCellBodyAndFooter>
            <MuiTableCellBodyAndFooter></MuiTableCellBodyAndFooter>
          </TableRow>
        </TableBody>
      </MuiTableRoot>
    </div>
  );
}

export default ReservationCustomerTable;
