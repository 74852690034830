import firebase from "firebase/compat/app";
import React from "react";

import { CourseData, CourseDataPin } from "../types/data_types";
import { Grid } from "@mui/material";

type Props = {
  db: firebase.firestore.Firestore;
  setHeaderTitle: any;
  lineData: { [key: string]: CourseData };
};
const serviceSname = (line: CourseData) => {
  return (
    <React.Fragment>
      <td />
      {line.services.map((service) => {
        return <td>{service.sname}</td>;
      })}
    </React.Fragment>
  );
};
const serviceLname = (line: CourseData) => {
  return (
    <React.Fragment>
      <td />
      {line.services.map((service) => {
        return <td>{service.lname}</td>;
      })}
    </React.Fragment>
  );
};
const serviceName = (line: CourseData) => {
  return (
    <React.Fragment>
      <td />
      {line.services.map((service) => {
        return <td>{service.name}</td>;
      })}
    </React.Fragment>
  );
};
const lineView = (line: CourseData) => {
  const pinObj = line.pins.reduce(
    (tmp: { [key: string]: CourseDataPin }, pin) => {
      tmp[pin.id] = pin;
      return tmp;
    },
    {},
  );

  return (
    <React.Fragment>
      <Grid container alignItems="center" direction="row">
        <Grid item xs={12}>
          <h2>
            {line.name}/{line.carName}
          </h2>
        </Grid>
        <Grid item xs={12}>
          <table>
            <thead>
              <tr>{serviceSname(line)}</tr>
            </thead>
            <tbody>
              <tr>{serviceLname(line)}</tr>
              <tr>{serviceName(line)}</tr>
              {line.pins.map((pin, pindex) => {
                return (
                  <tr>
                    <td>{pin.name}</td>
                    {line.services.map((service, sindex) => {
                      const pinTime = line.pinTimes[sindex][pindex];
                      return <td>{pinTime.time}</td>;
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Grid>
        <Grid item xs={12}>
          <h2>エリア</h2>
          {line.areas.map((area) => {
            return (
              <React.Fragment>
                <div>
                  <b>{area.name}</b>
                </div>
                <div>
                  {area.pins
                    .map((pinkey) => {
                      return pinObj[pinkey].name;
                    })
                    .join("/")}
                </div>
              </React.Fragment>
            );
          })}
        </Grid>
        <Grid item xs={12}>
          <h2>料金</h2>
        </Grid>
        <table>
          <thead>
            <tr>
              <td />
              {line.areas.map((area) => {
                return <td>{area.name}</td>;
              })}
            </tr>
          </thead>
          <tbody>
            {line.areas.map((area1) => {
              return (
                <tr>
                  <td>{area1.name}</td>
                  {line.areas.map((area2) => {
                    if (area1.id >= area2.id) {
                      return (
                        <td>{line.prices[area2.id].to_area[area1.id].price}</td>
                      );
                    }
                    return (
                      <td>{line.prices[area1.id].to_area[area2.id].price}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Grid>
    </React.Fragment>
  );
};

const CourseDataView: React.FC<Props> = ({ db, setHeaderTitle, lineData }) => {
  return (
    <React.Fragment>
      {Object.keys(lineData).map((key) => {
        const line = lineData[key];
        return lineView(line);
      })}
    </React.Fragment>
  );
};

export default CourseDataView;
