import React from "react";
import { styled } from "@mui/system";

const ModalDiv = styled("div")(({ theme }) => ({
  position: "fixed",
  display: "flex",
  "align-items": "center",
  "justify-content": "center",
  top: 0,
  left: 0,
  width: "100vw",
  height: "100vh",
  background: "rgba(100,100,100,0.4)",
  "z-index": 100000,
}));

const ModalWindowDiv = styled("div")(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%;",
  transform: "translateY(-50%) translateX(-50%)",
  "-webkit-transform": "translateY(-50%) translateX(-50%)",
  "z-index": 100001,
  animation: "fadein .3s 1, zoomin .3s 1",
  width: "60%",
  margin: "auto",
  "text-align": "center",
  background: "#fff",
}));

function Modal(props: any) {
  const { modalOpen, toggle } = props;

  return (
    <ModalDiv
      style={{ display: modalOpen ? "block" : "none" }}
      onClick={toggle}
    >
      <ModalWindowDiv
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {props.children}
      </ModalWindowDiv>
    </ModalDiv>
  );
}

export default Modal;
