import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { onChange } from "../common/Utils";

import { Link } from "react-router-dom";

import { Grid, Button } from "@mui/material";
import { styled } from "@mui/system";

import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import * as Utils from "../common/Utils";
import useOnCollection from "../common/useOnCollection";

import { CustomerData } from "../types/data_types";

const GridRoot = styled(Grid)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(1),
}));
const GridCaption = styled(Grid)(({ theme }) => ({
  width: "100%",
}));
const TabIndex = styled(Tab)(({ theme }) => ({
  minWidth: "8%",
}));

function UserAdmin(props: any) {
  const { db, reservedUser, setHeaderTitle, company } = props;

  const [customers, error] = useOnCollection(
    db,
    `companies/${company.id}/customers`,
  );
  const [searchText, setSearchText] = useState("");
  const [tabValue, setTabValue] = useState(0);

  React.useEffect(() => {
    setHeaderTitle("新規予約 予約確認・変更");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (error) {
    return <div>error</div>;
  }

  const filteredNameMap = Utils.kanaChars.reduce(
    (tmp: { [key: string]: CustomerData[] }, char: string) => {
      tmp[char] = [];
      return tmp;
    },
    {},
  );
  filteredNameMap["-"] = [];

  const filteredCustomers = (
    (searchText === ""
      ? customers
      : (customers || []).filter((customer: CustomerData) => {
          return (
            (customer.phoneNumber &&
              customer.phoneNumber.indexOf(searchText) > -1) ||
            (customer.landlineNumber &&
              customer.landlineNumber.indexOf(searchText) > -1) ||
            (customer.namekana && customer.namekana.indexOf(searchText) > -1) ||
            (customer.name && customer.name.indexOf(searchText) > -1)
          );
        })) || []
  ).sort((a: CustomerData, b: CustomerData) => {
    return a.namekana > b.namekana ? 1 : -1;
  });
  filteredCustomers.forEach((customer: CustomerData) => {
    const c = Utils.yomiKashira(customer.namekana);
    if (filteredNameMap[c]) {
      filteredNameMap[c].push(customer);
    } else {
      filteredNameMap["-"].push(customer);
    }
  });
  const index = [
    "全",
    "あ",
    "か",
    "さ",
    "た",
    "な",
    "は",
    "ま",
    "や",
    "ら",
    "わ",
    "他",
  ];

  const handleChange = (e: any, newValue: any) => {
    setTabValue(newValue);
  };

  if (reservedUser) {
    return <Navigate to={"/admin/map"} />;
  }

  const filterNameKeys = (() => {
    const char = index[tabValue];
    const keys = Object.keys(filteredNameMap);
    if (tabValue === 0) {
      return keys;
    }
    if (tabValue < 8) {
      return keys.slice((tabValue - 1) * 5, (tabValue - 1) * 5 + 5);
    }
    if (char === "や") {
      return keys.slice(35, 38);
    }
    if (char === "ら") {
      return keys.slice(38, 43);
    }
    if (char === "わ") {
      return keys.slice(43, 46);
    }
    if (char === "他") {
      return keys.slice(46, 47);
    }
    return keys;
  })();

  return (
    <React.Fragment>
      <GridRoot container alignItems="center" direction="row">
        <GridCaption item xs={12}>
          検索/電話番号か名前の一部を入れてください
          <br />
          <input
            value={searchText}
            onChange={(e) => onChange(e, setSearchText)}
          />
          <Link color="primary" to="/admin/user/new">
            新規ユーザ登録
          </Link>
          <hr />
          <AppBar position="relative">
            <Tabs
              value={tabValue}
              onChange={handleChange}
              indicatorColor="secondary"
              textColor="inherit"
            >
              {index.map((v, k) => {
                return <TabIndex label={v} key={k} />;
              })}
            </Tabs>
          </AppBar>
          <table>
            <thead>
              <tr>
                <td />
                <td>名前</td>
                <td>なまえ</td>
                <td>固定電話</td>
                <td>携帯電話</td>
              </tr>
            </thead>
            <tbody>
              {filterNameKeys.map((key) => {
                if (filteredNameMap[key].length === 0) {
                  return (
                    <tr key={key}>
                      <td>{key}</td>
                    </tr>
                  );
                } else {
                  return filteredNameMap[key].map(
                    (customer: CustomerData, key2: number) => {
                      return (
                        <tr key={key2}>
                          <td>{key2 === 0 ? key : ""}</td>
                          <td>{customer.name}</td>
                          <td>{customer.namekana}</td>
                          <td>{customer.landlineNumber}</td>
                          <td>{customer.phoneNumber}</td>
                          <td>
                            <Button
                              variant="outlined"
                              color="primary"
                              to={`/admin/reservation/map/${customer.id}`}
                              component={Link}
                            >
                              予約へ
                            </Button>
                          </td>
                          <td>
                            <Button
                              variant="outlined"
                              color="primary"
                              to={`/admin/user/${customer.id}/reservation`}
                              component={Link}
                            >
                              予約確認
                            </Button>
                          </td>
                          <td>
                            <Button
                              variant="outlined"
                              color="primary"
                              to={`/admin/user/${customer.id}/edit`}
                              component={Link}
                            >
                              ユーザ情報編集
                            </Button>
                          </td>
                        </tr>
                      );
                    },
                  );
                }
              })}
            </tbody>
          </table>
        </GridCaption>
      </GridRoot>
    </React.Fragment>
  );
}

export default UserAdmin;
