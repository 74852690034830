import firebase from "firebase/compat/app";
import React from "react";

import useOnCollection from "../common/useOnCollection";

import { CarData, DriverData } from "../types/data_types";
import { useFilteredDriverData, useFilteredCarData } from "./driverUtil";

type Props = {
  db: firebase.firestore.Firestore;
  company: any;
  lineData: any;
};

const Opt = {
  queryFilter: (query: any) => {
    return query.orderBy("order", "asc");
  },
};

const DriverList: React.FC<Props> = ({ db, company, lineData }) => {
  const driverPath = `companies/${company.id}/drivers`;
  const [driverData] = useOnCollection<DriverData>(db, driverPath, Opt);
  const { filteredDriverData } = useFilteredDriverData(driverData);

  const carPath = `companies/${company.id}/cars`;
  const [carData] = useOnCollection<CarData>(db, carPath, Opt);
  const { filteredCarData } = useFilteredCarData(carData);

  return (
    <React.Fragment>
      <div>
        {filteredDriverData.map((driver, k) => {
          return (
            <div key={k}>
              <span>
                {driver.name}/{driver.order}/{driver.carId || "--"}/
                {driver.lineId || "--"}
              </span>
            </div>
          );
        })}
      </div>
      <div>
        {filteredCarData.map((car, k) => {
          return <div>{car.name}</div>;
        })}
      </div>
    </React.Fragment>
  );
};
export default DriverList;
