import React from "react";
import { TableBody, TableHead, TableRow, Grid } from "@mui/material";

import { Link } from "react-router-dom";

import {
  DailyLogSummaryTotalData,
  DailyLogSummaryServiceData,
  DailyLogSummaryData,
} from "../types/data_types";

import {
  DailyLogTable,
  DailyLogHeadCell,
  DailyLogHeadCellTop,
  DailyLogHeadCellTopNinzuRyokin,
  DailyLogHeadCellTopLeft,
  DailyLogBodyLeftCell,
  DailyLogBodyRightCell,
  DailyLogHeadCellTategakiLight,
  DailyLogHeadCellTategaki,
  DailyLogBodyMiddleCell,
  DailyLogHeadCellNoWrap,
  DailyLogBodyRowOdd,
  DailyLogBodyRowEven,
  DailyLogBodyCell,
  DailyLogBodyCellOdd,
  DailyLogBodyCellEven,
} from "./styles/DailyLogStyle";

type Props = {
  dailyLogSummaryData: DailyLogSummaryData[];
  total: DailyLogSummaryTotalData;
  current: any;
};

const DailyLogSummaryView: React.FC<Props> = ({
  dailyLogSummaryData,
  total,
  current,
}) => {
  const splitStr = (str: string) => {
    return str.split("").map((a, key) => {
      return (
        <span key={key}>
          {a}
          <br />
        </span>
      );
    });
  };

  return (
    <Grid item xs={12}>
      <h4>運行実績</h4>
      <DailyLogTable>
        <TableHead>
          <TableRow>
            <DailyLogHeadCellTopLeft rowSpan={5}>便</DailyLogHeadCellTopLeft>
            <DailyLogHeadCellTop rowSpan={5}>区分</DailyLogHeadCellTop>
            <DailyLogHeadCellTop rowSpan={5}>運行エリア</DailyLogHeadCellTop>
            <DailyLogHeadCellTopNinzuRyokin colSpan={17}>
              人数/料金
            </DailyLogHeadCellTopNinzuRyokin>
            <DailyLogHeadCellTop rowSpan={5}>全走行時間</DailyLogHeadCellTop>
            <DailyLogHeadCellTop rowSpan={5}>実運行時間</DailyLogHeadCellTop>
            <DailyLogHeadCellTop rowSpan={5}>回送時間</DailyLogHeadCellTop>
            <DailyLogHeadCellTop rowSpan={5}>全走行距離</DailyLogHeadCellTop>
            <DailyLogHeadCellTop rowSpan={5}>実運行距離</DailyLogHeadCellTop>
            <DailyLogHeadCellTop rowSpan={5}>回送距離</DailyLogHeadCellTop>
            <DailyLogHeadCellTopLeft rowSpan={5}>便</DailyLogHeadCellTopLeft>
          </TableRow>
          <TableRow sx={{ height: "30px" }}>
            <DailyLogHeadCell colSpan={2}>大人</DailyLogHeadCell>
            <DailyLogHeadCell colSpan={5}>半額</DailyLogHeadCell>
            <DailyLogHeadCell colSpan={5}>無料（一部無料）</DailyLogHeadCell>
            <DailyLogHeadCell colSpan={2}>合計</DailyLogHeadCell>
            <DailyLogHeadCellNoWrap colSpan={3}>
              料金支払内訳
            </DailyLogHeadCellNoWrap>
          </TableRow>
          <TableRow sx={{ height: "20px" }}>
            <DailyLogHeadCell colSpan={1}>人数</DailyLogHeadCell>
            <DailyLogHeadCell colSpan={1}>料金</DailyLogHeadCell>
            <DailyLogHeadCell colSpan={4}>人数</DailyLogHeadCell>
            <DailyLogHeadCell colSpan={1}>料金</DailyLogHeadCell>
            <DailyLogHeadCell colSpan={4}>人数</DailyLogHeadCell>
            <DailyLogHeadCell colSpan={1}>料金</DailyLogHeadCell>
            <DailyLogHeadCell colSpan={1}>人数</DailyLogHeadCell>
            <DailyLogHeadCell colSpan={1}>料金</DailyLogHeadCell>
            <DailyLogHeadCell rowSpan={4}>
              現<br />金<br />
            </DailyLogHeadCell>
            <DailyLogHeadCell rowSpan={4}>
              タ<br />ク<br />シ<br />｜<br />チ<br />ケ<br />ッ<br />ト<br />
            </DailyLogHeadCell>
            <DailyLogHeadCell rowSpan={4}>
              地<br />域<br />通<br />貨<br />メ<br />ジ<br />カ<br />
            </DailyLogHeadCell>
          </TableRow>
          <TableRow>
            <DailyLogHeadCell></DailyLogHeadCell>
            <DailyLogHeadCell></DailyLogHeadCell>
            <DailyLogHeadCellTategakiLight rowSpan={2}>
              {splitStr("小人")}
            </DailyLogHeadCellTategakiLight>
            <DailyLogHeadCellTategakiLight rowSpan={2}>
              {splitStr("障がい者")}
            </DailyLogHeadCellTategakiLight>
            <DailyLogHeadCellTategakiLight rowSpan={2}>
              {splitStr("免許返納者")}
            </DailyLogHeadCellTategakiLight>
            <DailyLogHeadCellTategaki>
              {splitStr("小計")}
            </DailyLogHeadCellTategaki>
            <DailyLogHeadCell></DailyLogHeadCell>
            <DailyLogHeadCellTategakiLight rowSpan={2}>
              {splitStr("未就学児")}
            </DailyLogHeadCellTategakiLight>
            <DailyLogHeadCellTategakiLight rowSpan={2}>
              {splitStr("定期券")}
            </DailyLogHeadCellTategakiLight>
            <DailyLogHeadCellTategakiLight rowSpan={2}>
              {splitStr("ポイントC")}
            </DailyLogHeadCellTategakiLight>
            <DailyLogHeadCellTategaki>
              {splitStr("小計")}
            </DailyLogHeadCellTategaki>
            <DailyLogHeadCell></DailyLogHeadCell>
            <DailyLogHeadCell></DailyLogHeadCell>
            <DailyLogHeadCell></DailyLogHeadCell>
          </TableRow>
          <TableRow>
            <DailyLogHeadCell>&#9312;</DailyLogHeadCell>
            <DailyLogHeadCell>A</DailyLogHeadCell>
            <DailyLogHeadCell>&#9313;</DailyLogHeadCell>
            <DailyLogHeadCell>B</DailyLogHeadCell>
            <DailyLogHeadCell>&#9314;</DailyLogHeadCell>
            <DailyLogHeadCell>C</DailyLogHeadCell>
            <DailyLogHeadCell>&#9312;+&#9313;+&#9314;</DailyLogHeadCell>
            <DailyLogHeadCell>A + B + C</DailyLogHeadCell>
          </TableRow>
        </TableHead>
        <TableBody key="tbody">
          {dailyLogSummaryData.map(
            (logSummary: DailyLogSummaryData, lineKey: number) => {
              const { subTotal } = logSummary;
              const tables = logSummary.serviceLogs.map(
                (
                  serviceLogs: DailyLogSummaryServiceData,
                  serviceKey: number,
                ) => {
                  const { lineTotal, service, serviceId, cyclick, alert } =
                    serviceLogs;

                  const CyclickTableCell = cyclick
                    ? DailyLogBodyCellEven
                    : DailyLogBodyCellOdd;
                  const TableTag = cyclick
                    ? DailyLogBodyRowEven
                    : DailyLogBodyRowOdd;
                  return (
                    <TableTag key={[serviceKey, lineKey].join("-")}>
                      <DailyLogBodyCell
                        sx={alert ? { backgroundColor: "#ff55ff" } : {}}
                      >
                        <Link
                          to={`/admin/dailylograw/${current.format(
                            "YYYY/MM/DD",
                          )}/${logSummary.courseData.id}/${
                            serviceLogs.serviceId
                          }`}
                        >
                          {serviceLogs.service.sname}
                        </Link>
                      </DailyLogBodyCell>
                      <DailyLogBodyCell>
                        {service.inbound ? "往路" : "復路"}
                      </DailyLogBodyCell>
                      <DailyLogBodyCell>{service.lname}</DailyLogBodyCell>
                      <DailyLogBodyCell>{lineTotal.adult}</DailyLogBodyCell>
                      <DailyLogBodyCell>
                        {lineTotal.adultPrice}
                      </DailyLogBodyCell>
                      <CyclickTableCell>{lineTotal.child}</CyclickTableCell>
                      <CyclickTableCell>
                        {lineTotal.handicapped}
                      </CyclickTableCell>
                      <CyclickTableCell>
                        {lineTotal.returnLicense}
                      </CyclickTableCell>
                      <DailyLogBodyCell>
                        {lineTotal.halfNumber}
                      </DailyLogBodyCell>
                      <DailyLogBodyCell>{lineTotal.halfPrice}</DailyLogBodyCell>
                      <CyclickTableCell>{lineTotal.baby}</CyclickTableCell>
                      <CyclickTableCell>
                        {lineTotal.hascommuterpass}
                      </CyclickTableCell>
                      <CyclickTableCell>{lineTotal.pointC}</CyclickTableCell>
                      <DailyLogBodyCell>
                        {lineTotal.freeNumber}
                      </DailyLogBodyCell>
                      <DailyLogBodyCell>
                        {lineTotal.freePrice !== undefined ||
                        lineTotal.adjustPrice !== undefined
                          ? (lineTotal.freePrice || 0) +
                            (lineTotal.adjustPrice || 0)
                          : ""}
                      </DailyLogBodyCell>
                      <DailyLogBodyCell>{lineTotal.number}</DailyLogBodyCell>
                      <DailyLogBodyCell>{lineTotal.price}</DailyLogBodyCell>
                      <DailyLogBodyCell>{lineTotal.cash}</DailyLogBodyCell>
                      <DailyLogBodyCell>
                        {lineTotal.taxiTicket}
                      </DailyLogBodyCell>
                      <DailyLogBodyCell>
                        {lineTotal.localCurrency}
                      </DailyLogBodyCell>

                      <DailyLogBodyCell>
                        {lineTotal.operationTime}
                      </DailyLogBodyCell>
                      <DailyLogBodyCell>
                        {lineTotal.actualDrivingTime}
                      </DailyLogBodyCell>
                      <DailyLogBodyCell>
                        {lineTotal.operationTime - lineTotal.actualDrivingTime}
                      </DailyLogBodyCell>
                      <DailyLogBodyCell>{lineTotal.distance}</DailyLogBodyCell>

                      <DailyLogBodyCell>
                        {lineTotal.serviceDistance}
                      </DailyLogBodyCell>
                      <DailyLogBodyCell>
                        {lineTotal.distance - lineTotal.serviceDistance}
                      </DailyLogBodyCell>
                      <DailyLogBodyCell
                        sx={alert ? { backgroundColor: "#ff55ff" } : {}}
                      >
                        <Link
                          to={`/admin/dailylograw/${current.format(
                            "YYYY/MM/DD",
                          )}/${logSummary.courseData.id}/${serviceId}`}
                        >
                          {service.sname}
                        </Link>
                      </DailyLogBodyCell>
                    </TableTag>
                  );
                },
              );

              tables.push(
                <TableRow key={["total", lineKey].join("-")}>
                  <DailyLogBodyLeftCell>小計</DailyLogBodyLeftCell>
                  <DailyLogBodyMiddleCell colSpan={14}></DailyLogBodyMiddleCell>
                  <DailyLogBodyMiddleCell>
                    {subTotal.number}
                  </DailyLogBodyMiddleCell>
                  <DailyLogBodyMiddleCell>
                    {subTotal.price}
                  </DailyLogBodyMiddleCell>
                  <DailyLogBodyMiddleCell>
                    {subTotal.cash}
                  </DailyLogBodyMiddleCell>
                  <DailyLogBodyMiddleCell>
                    {subTotal.taxiTicket}
                  </DailyLogBodyMiddleCell>
                  <DailyLogBodyMiddleCell>
                    {subTotal.localCurrency}
                  </DailyLogBodyMiddleCell>
                  <DailyLogBodyMiddleCell>
                    {subTotal.operationTime}分
                  </DailyLogBodyMiddleCell>
                  <DailyLogBodyMiddleCell>
                    {subTotal.actualDrivingTime}分
                  </DailyLogBodyMiddleCell>
                  <DailyLogBodyMiddleCell>
                    {subTotal.operationTime - subTotal.actualDrivingTime}分
                  </DailyLogBodyMiddleCell>
                  <DailyLogBodyMiddleCell>
                    {subTotal.distance}
                  </DailyLogBodyMiddleCell>
                  <DailyLogBodyMiddleCell>
                    {subTotal.serviceDistance}
                  </DailyLogBodyMiddleCell>
                  <DailyLogBodyMiddleCell>
                    {subTotal.distance - subTotal.serviceDistance}
                  </DailyLogBodyMiddleCell>
                  <DailyLogBodyRightCell></DailyLogBodyRightCell>
                </TableRow>,
              );
              return tables;
            },
          )}
          <TableRow>
            <DailyLogBodyLeftCell>総計</DailyLogBodyLeftCell>
            <DailyLogBodyMiddleCell colSpan={14}></DailyLogBodyMiddleCell>
            <DailyLogBodyMiddleCell>{total.number}</DailyLogBodyMiddleCell>
            <DailyLogBodyMiddleCell>{total.price}</DailyLogBodyMiddleCell>
            <DailyLogBodyMiddleCell>{total.cash}</DailyLogBodyMiddleCell>
            <DailyLogBodyMiddleCell>{total.taxiTicket}</DailyLogBodyMiddleCell>
            <DailyLogBodyMiddleCell>
              {total.localCurrency}
            </DailyLogBodyMiddleCell>
            <DailyLogBodyMiddleCell>
              {total.operationTime}分
            </DailyLogBodyMiddleCell>
            <DailyLogBodyMiddleCell>
              {total.actualDrivingTime}分
            </DailyLogBodyMiddleCell>
            <DailyLogBodyMiddleCell>
              {total.operationTime - total.actualDrivingTime}分
            </DailyLogBodyMiddleCell>
            <DailyLogBodyMiddleCell>{total.distance}</DailyLogBodyMiddleCell>
            <DailyLogBodyMiddleCell>
              {total.serviceDistance}
            </DailyLogBodyMiddleCell>
            <DailyLogBodyMiddleCell>
              {total.distance - total.serviceDistance}
            </DailyLogBodyMiddleCell>
            <DailyLogBodyRightCell></DailyLogBodyRightCell>
          </TableRow>
        </TableBody>
      </DailyLogTable>
    </Grid>
  );
};

export default DailyLogSummaryView;
