import React from "react";

class UserNew extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = { dispatchData: props.dispatchData };
  }
  setInitData(data: any) {
    this.setState({ dispatchData: data });
  }
  handleChangeDriver(key: string, lineId: string, id: string) {
    // @ts-ignore
    const { db, current, company } = this.props;
    //db.collection
    const mykey = current.startOf("month").format("YYYY-MM");

    // @ts-ignore
    const newData = Object.assign({}, this.state.dispatchData);
    newData[key][lineId].driver = id;
    this.setState({ dispatchData: newData });
    db.doc(`companies/${company.id}/driverScedule/${mykey}`).set(newData);
  }

  handleChangeCar(key: string, lineId: string, id: string) {
    // @ts-ignore
    const { db, current, company } = this.props;
    //db.collection
    const mykey = current.startOf("month").format("YYYY-MM");

    // @ts-ignore
    const newData = Object.assign({}, this.state.dispatchData);
    newData[key][lineId].car = id;
    this.setState({ dispatchData: newData });
    db.doc(`companies/${company.id}/driverScedule/${mykey}`).set(newData);
  }

  render() {
    return null;
  }
}

// Drivers.tsx
export default UserNew as any;
