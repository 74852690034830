import { CourseData } from "../types/data_types";
import { ways } from "./way7";

export const data: CourseData = {
  id: "OtKXIZnkxxcBXdrmSANU",
  companyId: "UEqSulzUjeysV9nfXvQj",
  name: "立石〜市街地（清水）",
  carName: "ジャンボ",
  shouldSchedule: true,
  defaultPosition: {
    lng: 132.9566243398454,
    lat: 32.832845225849454,
  },
  reporter: "田村",
  services: [
    {
      id: 0,
      sname: "101便",
      lname: "立石 → 市街地",
      name: "101便 立石 6:50 → 市街地 7:50",
      inbound: true,
      capacity: 9,
      start: 650,
    },
    {
      id: 1,
      sname: "102便",
      lname: "立石 → 市街地",
      name: "102便 立石 9:30 → 市街地 10:50",
      inbound: true,
      capacity: 9,
      start: 930,
    },
    {
      id: 3,
      sname: "103便",
      lname: "立石 → 市街地",
      name: "103便 立石 14:45 → 市街地15:45",
      inbound: true,
      capacity: 9,
      start: 1445,
    },
    {
      id: 2,
      sname: "104便",
      lname: "市街地 → 立石",
      name: "104便 市街地 13:45 → 立石15:00",
      inbound: false,
      capacity: 9,
      start: 1345,
    },
    {
      id: 4,
      sname: "105便",
      lname: "市街地 → 立石",
      name: "105便 市街地 15:45 → 立石 16:45",
      inbound: false,
      capacity: 9,
      start: 1545,
    },
  ],
  ways,
  pins: [
    {
      id: 7010,
      name: "立石",
      location: { lng: 133.000502, lat: 32.89536 },
      busStopId: 0,
    },
    {
      id: 7020,
      name: "布",
      location: { lng: 132.985603, lat: 32.867997 },
      busStopId: 1,
    },
    {
      id: 7030,
      name: "下ノ加江市民センター",
      location: { lng: 132.954293, lat: 32.866753 },
      busStopId: 2,
    },
    {
      id: 7040,
      name: "下浦バス停",
      location: { lng: 132.952134, lat: 32.867522 },
      busStopId: 3,
    },
    {
      id: 7050,
      name: "鍵掛",
      location: { lng: 132.9499804, lat: 32.8594857 },
      busStopId: 4,
    },
    {
      id: 7060,
      name: "久百々",
      location: { lng: 132.9523845, lat: 32.8441209 },
      busStopId: 5,
    },
    {
      id: 7065,
      name: "横道",
      location: { lng: 132.919624, lat: 32.818166 },
      busStopId: 9,
    },
    {
      id: 7070,
      name: "大岐",
      location: { lng: 132.945449, lat: 32.821474 },
      busStopId: 6,
    },
    {
      id: 7080,
      name: "以布利",
      location: { lng: 132.9638166, lat: 32.7992213 },
      busStopId: 7,
    },
    {
      id: 7090,
      name: "市街地（清水）",
      location: { lng: 132.953638, lat: 32.7800888 },
      busStopId: 8,
    },
  ],
  // key is service id
  pinTimes: [
    // 101
    [
      { time: "6:50" },
      { time: "7:05" },
      { time: "7:15" },
      { time: "7:20" },
      { time: "7:25" },
      { time: "7:30" },
      { time: "7:35" },
      { time: "7:40" },
      { time: "7:50" },
      {}, //横道
    ],
    // 102
    [
      { time: "9:30" },
      { time: "9:45" },
      { time: "9:55" },
      { time: "10:00" },
      {},
      {},
      {},
      {},
      { time: "10:50" },
      { time: "10:25" }, //横道
    ],
    // 104
    [
      { time: "15:00" },
      { time: "14:45" },
      { time: "14:35" },
      { time: "14:30" },
      {},
      {},
      {},
      {},
      { time: "13:45" },
      { time: "14:00" }, //横道
    ],
    // 103
    [
      { time: "14:45" },
      { time: "15:00" },
      { time: "15:10" },
      { time: "15:15" },
      {},
      {},
      {},
      {},
      { time: "15:45" },
      {}, //横道
    ],
    // 105
    [
      { time: "16:45" },
      { time: "16:30" },
      { time: "16:20" },
      { time: "16:15" },
      {},
      {},
      {},
      {},
      { time: "15:45" },
      { time: "16:15" }, //横道
    ],
  ],
  /*
  pickups: [
    {
      "name": "布/布バス停",
      "location": {
        "lat": 32.867795,
        "lng": 132.984107
      }
    },
    {
      "name": "布/布大橋トイレ前",
      "location": {
        "lat": 32.86795,
        "lng": 132.98566
      }
    },
    {
      "name": "布/布診療所前",
      "location": {
        "lat": 32.868887,
        "lng": 132.984064
      }
    },
    {
      "name": "布/布小学校",
      "location": {
        "lat": 32.869363,
        "lng": 132.983133
      }
    },
    {
      "name": "布/布中学校北側",
      "location": {
        "lat": 32.873657,
        "lng": 132.982931
      }
    },
    {
      "name": "布/布屯所前",
      "location": {
        "lat": 32.869377,
        "lng": 132.983135
      }
    },
    {
      "name": "布/東谷",
      "location": {
        "lat": 32.868911,
        "lng": 132.986554
      }
    },
    {
      "name": "布/嶋ストアー前",
      "location": {
        "lat": 32.86834,
        "lng": 132.98431
      }
    },
    {
      "name": "布/谷口建設横",
      "location": {
        "lat": 32.86912,
        "lng": 132.98603
      }
    },
    {
      "name": "布/長笹自宅前",
      "location": {
        "lat": 32.8668,
        "lng": 132.971377
      }
    },
    {
      "name": "布/宮地宅前",
      "location": {
        "lat": 32.871477,
        "lng": 132.984231
      }
    },
    {
      "name": "布/よこやまさま自宅付近",
      "location": {
        "lat": 32.869531,
        "lng": 132.983538
      }
    },
    {
      "name": "布/いまむらさま自宅前",
      "location": {
        "lat": 32.872112,
        "lng": 132.98399
      }
    },
    {
      "name": "下浦バス停/安岡歯科",
      "location": {
        "lat": 32.86578,
        "lng": 132.95527
      }
    },
    {
      "name": "下浦バス停/弘小路バス停",
      "location": {
        "lat": 32.86474,
        "lng": 132.95582
      }
    },
    {
      "name": "下浦バス停/浅井水産工場前",
      "location": {
        "lat": 32.86343,
        "lng": 132.95915
      }
    },
    {
      "name": "下浦バス停/下浦バス停",
      "location": {
        "lat": 32.86734,
        "lng": 132.95231
      }
    },
    {
      "name": "下浦バス停/下ノ加江ローソン",
      "location": {
        "lat": 32.86772,
        "lng": 132.95203
      }
    },
    {
      "name": "久百々/久百々電話BOX",
      "location": {
        "lat": 32.843186,
        "lng": 132.953116
      }
    },
    {
      "name": "大岐/和田分岐バス停",
      "location": {
        "lat": 32.81602,
        "lng": 132.9469
      }
    },
    {
      "name": "大岐/和田ゴミステーション",
      "location": {
        "lat": 32.814655,
        "lng": 132.943858
      }
    },
    {
      "name": "大岐/和田・白皇神社前",
      "location": {
        "lat": 32.813602,
        "lng": 132.941688
      }
    },
    {
      "name": "大岐/大岐・弘田石工所",
      "location": {
        "lat": 32.816644,
        "lng": 132.946816
      }
    },
    {
      "name": "大岐/下港山バス停",
      "location": {
        "lat": 32.810195,
        "lng": 132.954245
      }
    },
    {
      "name": "大岐/岡林商店",
      "location": {
        "lat": 32.81593,
        "lng": 132.947
      }
    },
    {
      "name": "以布利/しおさい前バス停",
      "location": {
        "lat": 32.80304,
        "lng": 132.95915
      }
    },
    {
      "name": "市街地/市役所",
      "location": {
        "lat": 32.78145,
        "lng": 132.95473
      }
    },
    {
      "name": "市街地/社会福祉センター",
      "location": {
        "lat": 32.77875,
        "lng": 132.9517
      }
    },
    {
      "name": "市街地/清水警察署",
      "location": {
        "lat": 32.78048,
        "lng": 132.95196
      }
    },
    {
      "name": "市街地/清水高校",
      "location": {
        "lat": 32.785253,
        "lng": 132.945967
      }
    },
    {
      "name": "市街地/四国電力",
      "location": {
        "lat": 32.77955,
        "lng": 132.9587
      }
    },
    {
      "name": "市街地/四国銀行",
      "location": {
        "lat": 32.78315,
        "lng": 132.9583
      }
    },
    {
      "name": "市街地/楠井歯科",
      "location": {
        "lat": 32.77733,
        "lng": 132.95438
      }
    },
    {
      "name": "市街地/足摺病院",
      "location": {
        "lat": 32.77924,
        "lng": 132.9613
      }
    },
    {
      "name": "市街地/清水薬局",
      "location": {
        "lat": 32.78087,
        "lng": 132.95152
      }
    },
    {
      "name": "市街地/渭南病院",
      "location": {
        "lat": 32.78151,
        "lng": 132.95118
      }
    },
    {
      "name": "市街地/植垣歯科",
      "location": {
        "lat": 32.78231,
        "lng": 132.954591
      }
    },
    {
      "name": "市街地/松谷病院",
      "location": {
        "lat": 32.78306,
        "lng": 132.95413
      }
    },
    {
      "name": "市街地/吉田接骨院",
      "location": {
        "lat": 32.78125,
        "lng": 132.954
      }
    },
    {
      "name": "市街地/共和ガラス",
      "location": {
        "lat": 32.78159,
        "lng": 132.94855
      }
    },
    {
      "name": "市街地/喫茶さんご",
      "location": {
        "lat": 32.77881,
        "lng": 132.9514
      }
    },
    {
      "name": "市街地/加久見ふれあい市",
      "location": {
        "lat": 32.78724,
        "lng": 132.94489
      }
    },
    {
      "name": "市街地/加久見バス停",
      "location": {
        "lat": 32.78729,
        "lng": 132.94495
      }
    },
    {
      "name": "市街地/加久見セイムス",
      "location": {
        "lat": 32.78693,
        "lng": 132.94568
      }
    },
    {
      "name": "市街地/パル",
      "location": {
        "lat": 32.78012,
        "lng": 132.95376
      }
    },
    {
      "name": "市街地/サニーマート",
      "location": {
        "lat": 32.77978,
        "lng": 132.96073
      }
    },
    {
      "name": "市街地/コメリ",
      "location": {
        "lat": 32.785139,
        "lng": 132.947389
      }
    },
    {
      "name": "市街地/コスモス",
      "location": {
        "lat": 32.78462,
        "lng": 132.94724
      }
    },
    {
      "name": "市街地/アトム電気",
      "location": {
        "lat": 32.7811,
        "lng": 132.94887
      }
    }
  ],
  */
  // onOffPosition: onoffData,
  areas: [
    {
      id: 17010,
      name: "立石",
      pins: [7010],
    },
    {
      id: 17020,
      name: "布",
      pins: [7020],
    },
    {
      id: 17030,
      name: "下浦",
      pins: [7030, 7040],
    },
    {
      id: 17040,
      name: "鍵掛",
      pins: [7050],
    },
    {
      id: 17050,
      name: "久百々",
      pins: [7060],
    },
    {
      id: 17060,
      name: "大岐/横道",
      pins: [7065, 7070],
    },
    {
      id: 17070,
      name: "以布利",
      pins: [7080],
    },
    {
      id: 17080,
      name: "市街地",
      pins: [7090],
    },
  ],
  prices: {
    17010: {
      to_area: {
        17010: { price: 100 },
        17020: { price: 100 },
        17030: { price: 100 },
        17040: { price: 400 },
        17050: { price: 400 },
        17060: { price: 600 },
        17070: { price: 700 },
        17080: { price: 800 },
      },
    },
    17020: {
      to_area: {
        17020: { price: 100 },
        17030: { price: 100 },
        17040: { price: 300 },
        17050: { price: 300 },
        17060: { price: 500 },
        17070: { price: 600 },
        17080: { price: 800 },
      },
    },
    17030: {
      to_area: {
        17030: { price: 100 },
        17040: { price: 100 },
        17050: { price: 200 },
        17060: { price: 400 },
        17070: { price: 500 },
        17080: { price: 600 },
      },
    },
    17040: {
      to_area: {
        17040: { price: 100 },
        17050: { price: 200 },
        17060: { price: 300 },
        17070: { price: 400 },
        17080: { price: 500 },
      },
    },
    17050: {
      to_area: {
        17050: { price: 100 },
        17060: { price: 200 },
        17070: { price: 300 },
        17080: { price: 400 },
      },
    },
    17060: {
      to_area: {
        17060: { price: 100 },
        17070: { price: 200 },
        17080: { price: 300 },
      },
    },
    17070: {
      to_area: {
        17070: { price: 100 },
        17080: { price: 200 },
      },
    },
    17080: {
      to_area: {
        17080: { price: 100 },
      },
    },
  },
};
