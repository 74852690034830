import { BusStopData } from "../../types/data_types";

export const sortStopsData = (stopsData: BusStopData[]): BusStopData[] => {
  // v1: sort by busStop1Id.
  // v2: sort by id of bus stop.

  const bus2idMap = stopsData.reduce(
    (tmp: { [key: string]: number }, a: BusStopData) => {
      if (a.type === "busStop") {
        tmp[a.busStop1Id] = Number(a.id);
      }
      return tmp;
    },
    {},
  );

  const sortedData = stopsData
    .map((a: BusStopData) => {
      a.sortIdKey = bus2idMap[a.busStop1Id];
      return a;
    })
    .sort((a: BusStopData, b: BusStopData) => {
      if (a.sortIdKey !== undefined && b.sortIdKey !== undefined) {
        if (a.sortIdKey > b.sortIdKey) {
          return 1;
        }
        if (a.sortIdKey < b.sortIdKey) {
          return -1;
        }
      }
      if (a.sortKey > b.sortKey) {
        return 1;
      }
      if (a.sortKey < b.sortKey) {
        return -1;
      }
      return 0;
    });
  return sortedData;
};

// DailyLogRaw
export const convStopsData = (stopsData: BusStopData[]) => {
  return {
    name: "onOffPosition",
    data: stopsData
      .filter((stop: BusStopData) => {
        return stop.deletedFlag !== true;
      })
      .map((stop: BusStopData) => {
        return {
          name: stop.name,
          value: stop.id,
          busStop1Id: stop.busStop1Id,
        };
      }),
  };
};

// AddPickup
export const convBusStopsData = (stopsData: BusStopData[]) => {
  return {
    name: "onOffPosition",
    data: stopsData
      .filter((stop: BusStopData) => {
        return stop.type === "busStop";
      })
      .map((stop: BusStopData) => {
        return {
          name: stop.name,
          // value: stop.id,
          value: stop.busStop1Id,
          location: stop.location,
        };
      }),
  };
};
