class User {
  user: any;
  constructor(_user: any) {
    this.user = _user;
    console.log(_user);
  }
  phoneNumber() {
    if (this.user.phoneNumber) {
      return this.user.phoneNumber.replace("+81", "0");
    }
  }
  name() {
    return this.user.name;
  }
  nameKana() {
    return this.user.namekana;
  }
  uid() {
    return this.user.uid;
  }
  customerId() {
    return this.user.customerId;
  }
  updateUsername(name: string) {
    this.user.name = name;
  }
  updateUsernameKana(name: string) {
    this.user.namekana = name;
  }
  updateCustomerId(customerId: string) {
    this.user.customerId = customerId;
  }
  save(db: any) {
    const refUser = db.doc(`/users/${this.user.uid}`);
    return refUser.set(this.user);
  }
}

export default User;
