import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Grid,
  Button,
  TextField,
  MenuItem,
  Select as UISelect,
  Typography,
} from "@mui/material";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import { styled } from "@mui/system";

import Select from "../Select";

import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

import "./cal.css";

import GoogleMap from "./GoogleMap";
import ReservationMapTable from "./ReservationMapTable";

import moment from "moment";

import * as DataUtils from "../common/DataUtils";
import * as Utils from "../common/Utils";

import useOnCollection from "../common/useOnCollection";
import useOnDocument from "../common/useOnDocument";

import UserIcon from "./UserIcon";

import { sortStopsData, convStopsData } from "./utils/stopsUtils";

import {
  getHomePosition,
  canReserve,
  convLineReservations,
} from "./utils/ReservationUtils";

import * as common from "../data/common";
import { RootGrid } from "../styles";

import {
  CourseDataService,
  BusStopData,
  ReservationData,
  ReservationPrice,
  CourseDataArea,
  ReservationPositionData,
  CourseDataPinTimes,
} from "../types/data_types";

const WidthGrid = styled(Grid)(({ theme }) => ({
  width: "100%",
}));

const SelectLabelB = styled("b")(({ theme }) => ({
  marginLeft: "2px",
  marginBottom: "1px",
  marginTop: "8px",
  display: "block",
}));

function ReservationMap(props: any) {
  const { db, lineData, company, setHeaderTitle } = props;
  const { customerId, reservationId } = useParams();

  const [lineOpt, updateLineOpt] = useState({
    queryFilter: (query: any) => {
      return query.where("date", "==", DataUtils.today);
    },
  });

  const [reservedUser, error] = useOnDocument(
    db,
    `companies/${company.id}/customers/${customerId}`,
  );
  const [linesReservationPath, setLinesReservationPath] = useState(
    `companies/${company.id}/lines/1/lineReservations`,
  );
  const [lineReservations, error2] = useOnCollection<ReservationData>(
    db,
    linesReservationPath,
    lineOpt,
  );

  // for select form
  const [currentLineId, setCurrentLineId] = useState(
    (Object.values(lineData)[0] as any).id,
  );
  const [currentServiceId, setCurrentServiceId] = useState(0);

  const [reserveServiceId, setReserveServiceId] = useState<number | null>(null);

  const [currentOnPosition, setCurrentOnPosition] = useState(""); // for select form
  const [currentOffPosition, setCurrentOffPosition] = useState(""); // for select form

  const [withBaby, setWithBaby] = useState(0); // for select form
  const [withChild, setWithChild] = useState(0); // for select form

  const [calenderDate, setCalenderDate] = useState(new Date());

  const stopsPath = `companies/${company.id}/lines/${currentLineId}/stops`;
  const [stopsData] = useOnCollection<BusStopData>(db, stopsPath);

  // ride Data
  const [onPosition, setOnPosition] = useState<ReservationPositionData | null>(
    null,
  ); // for select form
  const [offPosition, setOffPosition] =
    useState<ReservationPositionData | null>(null); // for select form

  const [fromArea, setFromArea] = useState<CourseDataArea | null>(null);
  const [toArea, setToArea] = useState<CourseDataArea | null>(null);
  const [price, setPrice] = useState<ReservationPrice>({ price: 0 });

  const [memo, setMemo] = useState("");

  const [available, setAvailable] = useState(false);

  const [open, setOpen] = useState(false);

  const [openOnPin, setOpenOnPin] = useState(false);
  const [openOffPin, setOpenOffPin] = useState(false);

  const [onPinTime, setOnPinTime] = useState("");
  const [offPinTime, setOffPinTime] = useState("");

  const reservationDate = moment(calenderDate).format("YYYY-MM-DD");

  const [pinTimes, setPinTimes] = useState<CourseDataPinTimes>([]);

  useEffect(() => {
    setHeaderTitle("個人予約");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    (async () => {
      if (reservationId) {
        const doc = await db
          .doc(
            `companies/${company.id}/customers/${customerId}/customerReservations/${reservationId}`,
          )
          .get();
        if (doc.exists) {
          const data = doc.data();
          setCurrentLineId(data.lineId);
          setCurrentServiceId(data.serviceId);

          setOnPosition(data.onPosition);
          setOffPosition(data.offPosition);

          setWithBaby(data.baby);
          setWithChild(data.child);

          setCalenderDate(moment(data.date).toDate());

          setMemo(data.memo);
        }
      }
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setPinTimes(lineData[currentLineId].pinTimes[currentServiceId]);
  }, [currentLineId, currentServiceId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (onPosition) {
      const onBusIndex = DataUtils.butStopId2PinIndex(
        onPosition.busStop1Id || 0,
        lineData[currentLineId].pins,
      );
      const onBus = lineData[currentLineId].pins[onBusIndex];
      const onArea = Utils.pin2area(lineData[currentLineId], onBus?.id || 0);
      setFromArea(onArea);

      setOnPinTime((pinTimes[onPosition.busStop1Id || 0] || {}).time || "");
    }
  }, [onPosition, currentServiceId, currentLineId, pinTimes]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (offPosition) {
      const offBusIndex = DataUtils.butStopId2PinIndex(
        offPosition.busStop1Id || 0,
        lineData[currentLineId].pins,
      );
      const offBus = lineData[currentLineId].pins[offBusIndex];
      const offArea = Utils.pin2area(lineData[currentLineId], offBus?.id || 0);
      setToArea(offArea);

      setOffPinTime((pinTimes[offPosition.busStop1Id || 0] || {}).time || "");
    }
  }, [offPosition, currentServiceId, currentLineId, pinTimes]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (fromArea && toArea) {
      const priceData = Object.assign(
        {},
        Utils.area2price(lineData[currentLineId], fromArea.id, toArea.id),
      );
      priceData.actualPrice = getActualPrice(priceData.price);
      setPrice(priceData);
    }
  }, [fromArea, toArea, reservedUser, withChild]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let isOK = false;
    if (reservedUser && onPosition && offPosition) {
      // validation
      const service = lineData[currentLineId].services.find(
        (ele: CourseDataService) => {
          return ele.id === currentServiceId;
        },
      );
      const capacity = service.capacity;
      const inbound = service.inbound;
      if (
        (inbound && onPosition.positionIndex < offPosition.positionIndex) ||
        (!inbound && onPosition.positionIndex > offPosition.positionIndex)
      ) {
        // just capacity check
        isOK = canReserve(
          reservedUser,
          withBaby,
          withChild,
          onPosition,
          offPosition,
          lineReservationObj[currentServiceId],
          capacity,
          inbound,
        );
      }
      setAvailable(isOK);
    }
  }, [
    onPosition,
    offPosition,
    reservationDate,
    currentLineId,
    currentServiceId,
    reservedUser,
  ]); // eslint-disable-line react-hooks/exhaustive-deps

  // for select
  const lineDataset = DataUtils.getLineDataset(lineData);
  const serviceDataset = DataUtils.getServiceDataset(lineData[currentLineId]);

  const orgOnOffPositionDataSet = convStopsData(sortStopsData(stopsData));
  const onPositionDataSet = DataUtils.filterOnOffPositionDataset(
    orgOnOffPositionDataSet,
    currentLineId,
    currentServiceId,
    true,
    calenderDate,
  );
  const offPositionDataSet = DataUtils.filterOnOffPositionDataset(
    orgOnOffPositionDataSet,
    currentLineId,
    currentServiceId,
    false,
    calenderDate,
  );
  //  for select functions
  useEffect(() => {
    const resetService = () => {
      setCurrentServiceId(0);
    };
    const resetPin = () => {
      setOffPosition(null);
      setOnPosition(null);
    };
    resetService();
    resetPin();
    setFromArea(null);
    setToArea(null);
    setPrice({ price: 0 });
    setLinesReservationPath(
      `companies/${company.id}/lines/${currentLineId}/lineReservations`,
    );
  }, [currentLineId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const updateLineOptState = (date: any) => {
      const from = moment(date);

      updateLineOpt({
        queryFilter: (query) => {
          return query.where("date", "==", from.format("YYYY-MM-DD"));
        },
      });
    };
    updateLineOptState(reservationDate);
  }, [reservationDate]);

  //  end for select functions

  function copyReservasion(line: ReservationData) {
    setOnPosition(line.onPosition);
    setOffPosition(line.offPosition);
  }

  const confirmReserve = async () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleReserve = () => {
    reserve();
    setOpen(false);
  };

  const handleOnPinReserve = () => {
    setOnPin();
    setOpenOnPin(false);
  };

  const handleOffPinReserve = () => {
    setOffPin();
    setOpenOffPin(false);
  };

  const handleOpenOnOffPin = (
    position: any,
    setCurrentOfOffPosition: any,
    setOpenPin: any,
  ) => {
    if (position) {
      if (position.type === "home") {
        setCurrentOfOffPosition("");
      } else {
        setCurrentOfOffPosition(position.index);
      }
    }
    setOpenPin(true);
  };

  const handleOpenOnPin = () => {
    handleOpenOnOffPin(onPosition, setCurrentOnPosition, setOpenOnPin);
  };
  const handleOpenOffPin = () => {
    handleOpenOnOffPin(offPosition, setCurrentOffPosition, setOpenOffPin);
  };

  const getAdultPrice = (price: number) => {
    if (reservedUser.hascommuterpass) {
      return common.commuterPassPrice;
    } else if (
      reservedUser.returnLicense ||
      reservedUser.handicapped ||
      reservedUser.isAdult === 0
    ) {
      return price * 0.5;
    } else {
      return price;
    }
  };

  const getChildPrice = (price: number) => {
    return price * 0.5 * (withChild || 0);
  };

  const getActualPrice = (price: number) => {
    return getAdultPrice(price) + getChildPrice(price);
  };

  const reserve = async () => {
    const key1 = `${reservationDate}-${currentServiceId}`;
    const key2 = `${reservationDate}-${currentLineId}`;

    if (
      fromArea === null ||
      toArea === null ||
      onPosition === null ||
      offPosition === null
    ) {
      return;
    }

    const data: ReservationData = {
      customerId: reservedUser.id,
      customerName: reservedUser.name || "ななし",
      date: reservationDate,
      lineId: currentLineId,
      serviceId: Number(currentServiceId),
      companyId: company.id,
      onPosition,
      offPosition,
      baby: withBaby || 0,
      child: withChild || 0,

      price,
      fromArea,
      toArea,
      key1,
      key2,
      memo: memo || "",
      hascommuterpass: reservedUser.hascommuterpass,
      handicapped: reservedUser.handicapped,
      returnLicense: reservedUser.returnLicense,

      isAdult: reservedUser.isAdult,
    };
    const uniq_key = [
      reservationDate,
      String(currentLineId),
      String(currentServiceId),
      reservedUser.id,
    ].join("-");
    const hashed_key = Utils.sha256base64String(uniq_key);

    db.runTransaction(async (t: any) => {
      const customerPath = `companies/${company.id}/customers/${reservedUser.id}/customerReservations/${hashed_key}`;
      const customerRef = db.doc(customerPath);
      await t.set(customerRef, data);

      const linePath = `companies/${company.id}/lines/${currentLineId}/lineReservations/${hashed_key}`;
      const lineRef = db.doc(linePath);
      await t.set(lineRef, data);
    });
    setReserveServiceId(Number(currentServiceId));
  };
  function reverse() {
    const tmpOn = onPosition;
    const tmpOff = offPosition;
    setOffPosition(tmpOn);
    setOnPosition(tmpOff);
  }

  const lineReservationObj = convLineReservations(lineReservations);

  const existReservation = (lineReservationObj[currentServiceId] || []).some(
    (a: ReservationData) => {
      return (
        reserveServiceId !== currentServiceId &&
        a.customerId === reservedUser.id
      );
    },
  );
  useEffect(() => {
    setReserveServiceId(null);
  }, [currentServiceId, currentLineId]);

  if (error || error2) {
    return <div>error</div>;
  }

  if (!reservedUser) {
    return <div>loading</div>;
  }

  const homePosition = getHomePosition(reservedUser);

  const mapParams = {
    currentLineId,
    currentServiceId,
    homePosition,
    setOnPosition,
    setOffPosition,
    onPosition,
    offPosition,
    reservation: lineReservationObj[currentServiceId],
  };
  const setOnPin = () => {
    // currentOnPosition is id of stops (in firebase) and id in pins data (vue)
    setOnOffPin(currentOnPosition, setOnPosition);
  };
  const setOffPin = () => {
    setOnOffPin(currentOffPosition, setOffPosition);
  };
  const setOnOffPin = (stopId: string, setFunc: any) => {
    const stop = (stopsData || []).find((busStop: BusStopData) => {
      return busStop.id === stopId;
    });
    if (stop.type === "busStop") {
      const pinIndex = DataUtils.stopId2PinIndex(
        stopId,
        lineData[currentLineId].pins,
      );
      setPin(stopId, stop.busStop1Id, pinIndex, setFunc);
    }
    if (stop.type === "pickUp") {
      setPickup(stopId, stop, setFunc);
    }
  };
  // stop id is id of stops (in firebase). busStopId is id for backforward compatibility.
  const setPin = (
    stopId: string,
    busStop1Id: number,
    indexOfPins: number,
    setFunc: any,
  ) => {
    const line = lineData[currentLineId];
    const pin = line.pins[indexOfPins];

    let way_index = -1;
    for (let i = 0; i < indexOfPins; i++) {
      way_index = line.ways[i].length + way_index;
    }

    const data: ReservationPositionData & { index: string } = {
      id: "clickPoint_" + currentLineId + "_" + busStop1Id,
      position: pin.location, // ??
      clickPosition: pin.location,
      positionIndex: way_index === -1 ? 0 : way_index,
      name: pin.name,
      type: "busStop",
      stopId: stopId,
      index: stopId, // actually, this was not index but id of stops. Index is just for temporary data for this component.
      busStop1Id: Number(busStop1Id),
    };
    setFunc(data);
  };

  // for onOffPosition
  const setPickup = (stopId: string, onPickup: BusStopData, setFunc: any) => {
    const positionIndex = DataUtils.getBusStopPosition(
      lineData[currentLineId],
      onPickup.busStop1Id,
    );

    const data = {
      id: "pickupPoint",
      index: stopId, // actually, this was not index but id of stops. Index is just for temporary data for this component.
      clickPosition: onPickup.location,
      name: onPickup.name,
      type: "pickup",
      busStop1Id: onPickup.busStop1Id,
      stopId: stopId,
      positionIndex,
      position: onPickup.location, // or busStopData.position
    };
    setFunc(data);
  };
  const service = lineData[currentLineId].services.find(
    (ele: CourseDataService) => ele.id === currentServiceId,
  );
  return (
    <React.Fragment>
      <RootGrid container direction="row">
        <WidthGrid item xs={4}>
          <Grid container>
            <Grid item xs={8}>
              <Typography variant="h5">
                {reservedUser.name}({reservedUser.namekana})
              </Typography>
              <UserIcon customer={reservedUser} />
              <table>
                <tbody>
                  <tr>
                    <td>
                      <b>同行未就学児</b>
                    </td>
                    <td>
                      <UISelect
                        label={"未就学児"}
                        value={withBaby}
                        onChange={(e) => {
                          Utils.onChange2(e, setWithBaby);
                        }}
                      >
                        <MenuItem key={0} value={0}>
                          0人
                        </MenuItem>
                        <MenuItem key={1} value={1}>
                          1人
                        </MenuItem>
                        <MenuItem key={2} value={2}>
                          2人
                        </MenuItem>
                        <MenuItem key={3} value={3}>
                          3人
                        </MenuItem>
                      </UISelect>
                      <br />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>同行小人</b>
                    </td>
                    <td>
                      <UISelect
                        label={"小人"}
                        value={withChild}
                        onChange={(e) => {
                          Utils.onChange2(e, setWithChild);
                        }}
                      >
                        <MenuItem key={0} value={0}>
                          0人
                        </MenuItem>
                        <MenuItem key={1} value={1}>
                          1人
                        </MenuItem>
                        <MenuItem key={2} value={2}>
                          2人
                        </MenuItem>
                        <MenuItem key={3} value={3}>
                          3人
                        </MenuItem>
                      </UISelect>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Grid>
            <Grid item xs={4}>
              <Button
                variant="outlined"
                color="primary"
                to="/admin/user"
                component={Link}
              >
                ユーザ
                <br />
                変更
              </Button>
            </Grid>
          </Grid>
          <Calendar
            value={calenderDate}
            // @ts-ignore
            style={{ fontSize: "100px" }}
            formatDay={(locale, date) => {
              return String(date.getDate());
            }}
            onChange={(date: any) => {
              // TODO ALERT IF change.
              setCalenderDate(date);
            }}
          />
          {existReservation ? (
            <div style={{ backgroundColor: "yellow" }}>
              <b>
                <span>
                  この便にすでに予約が入っています。
                  <br />
                  変更する場合は、乗車/下車などの設定後、予約ボタンを押してください。
                  <br />
                </span>
              </b>
            </div>
          ) : (
            ""
          )}
          <SelectLabelB>路線</SelectLabelB>
          <Select
            dataSet={lineDataset}
            onChange={(e: any) => Utils.onChange2(e, setCurrentLineId)}
            value={currentLineId}
          />
          <br />
          <SelectLabelB>便</SelectLabelB>
          <Select
            dataSet={serviceDataset}
            onChange={(e: any) => {
              // TODO ALERT IF change
              Utils.onChange2(e, setCurrentServiceId);
            }}
            value={currentServiceId}
          />
          <br />
          <SelectLabelB>乗車:</SelectLabelB>
          <Button
            style={{ minWidth: "120px" }}
            variant="outlined"
            color="primary"
            onClick={() => {
              handleOpenOnPin();
            }}
          >
            {onPosition ? onPosition.name : <span>&nbsp;</span>}
          </Button>
          <br />
          <SelectLabelB>下車:</SelectLabelB>
          <Button
            style={{ minWidth: "120px" }}
            variant="outlined"
            color="primary"
            onClick={() => {
              handleOpenOffPin();
            }}
          >
            {offPosition ? offPosition.name : <span>&nbsp;</span>}
          </Button>
          <br />
          <hr />
          <Button variant="outlined" color="primary" onClick={reverse}>
            乗車/下車 反転
          </Button>
          <hr />
          <SelectLabelB>メモ</SelectLabelB>
          <TextField
            multiline={true}
            onChange={(e: any) => Utils.onChange(e, setMemo)}
            value={memo}
          />
          <hr />
          {price && fromArea && toArea ? (
            <span style={{ fontSize: "1.5rem" }}>
              {fromArea.name} 〜 {toArea.name} : {price.actualPrice}円<br />
            </span>
          ) : (
            ""
          )}
          <Button
            variant="outlined"
            color="primary"
            onClick={confirmReserve}
            disabled={!available}
          >
            予約
          </Button>{" "}
          <div style={{ float: "right" }}>
            <UserIcon customer={reservedUser} />
          </div>
          <hr />
        </WidthGrid>
        <WidthGrid item xs={8}>
          <div style={{ height: "80vh", width: "100%", margin: "10px" }}>
            <GoogleMap {...props} {...mapParams} />
          </div>
        </WidthGrid>
        <hr />
        <WidthGrid item xs={12}>
          {Object.keys(lineReservationObj).map((serviceId) => {
            // TODO sort???
            const service = lineData[currentLineId].services.find(
              (ele: CourseDataService) => ele.id === Number(serviceId),
            );
            if (service !== undefined) {
              const serviceName = Utils.getServiceLongName(service);
              const params = {
                reservations: lineReservationObj[serviceId],
                currentLineId,
                lineData,
                copyReservasion,
                serviceId: Number(serviceId),
              };
              return (
                <React.Fragment key={serviceId}>
                  <b>{serviceName}</b>
                  <ReservationMapTable {...props} {...params} />
                </React.Fragment>
              );
            } else {
              <React.Fragment />;
            }
          })}
        </WidthGrid>
      </RootGrid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="xl"
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
          {"予約しますか？"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <span
              style={{
                display: "block",
                marginRight: "50px",
                marginLeft: "50px",
              }}
            >
              <b>{(reservedUser || {}).name}</b>さま 未就{withBaby} 小人
              {withChild}
              <br />
              {moment(calenderDate).format("Mo月D日 (ddd)")} {service.sname}
              <br />
              {(onPosition || {}).name} {onPinTime} ~ {(offPosition || {}).name}{" "}
              {offPinTime}
              <br />
              {price.actualPrice}円{" "}
              <UserIcon customer={reservedUser} simple={true} />
              <br />
              <br />
              <b>{memo}</b>
              <br />
              <br />
              <span style={{ display: "block", textAlign: "center" }}>
                <Button
                  onClick={handleReserve}
                  color="primary"
                  variant="outlined"
                  style={{ marginRight: "20px" }}
                >
                  予約
                </Button>
                <Button
                  onClick={handleClose}
                  color="primary"
                  variant="outlined"
                  autoFocus
                >
                  キャンセル
                </Button>
              </span>
            </span>
          </DialogContentText>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
      <Dialog
        open={openOnPin}
        onClose={(e: any) => setOpenOnPin(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title2" style={{ minWidth: "400px" }}>
          乗車
        </DialogTitle>
        <DialogContent id="setBus" style={{ textAlign: "center" }}>
          <SelectLabelB>
            <Select
              dataSet={onPositionDataSet}
              onChange={(e: any) => {
                Utils.onChange2(e, setCurrentOnPosition);
              }}
              value={currentOnPosition}
              empty={true}
              blankName="--よくある乗降場所--"
            />
          </SelectLabelB>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleOnPinReserve}
            color="primary"
            variant="outlined"
          >
            設定
          </Button>
          <Button
            onClick={() => setOpenOnPin(false)}
            color="primary"
            variant="outlined"
          >
            戻る
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openOffPin}
        onClose={() => setOpenOffPin(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title2" style={{ minWidth: "400px" }}>
          下車
        </DialogTitle>
        <DialogContent id="setBus" style={{ textAlign: "center" }}>
          <SelectLabelB>
            <Select
              dataSet={offPositionDataSet}
              onChange={(e: any) => {
                Utils.onChange2(e, setCurrentOffPosition);
              }}
              value={currentOffPosition}
              empty={true}
              blankName="--よくある乗降場所--"
            />
          </SelectLabelB>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleOffPinReserve}
            color="primary"
            variant="outlined"
          >
            設定
          </Button>
          <Button
            onClick={(e: any) => setOpenOffPin(false)}
            color="primary"
            variant="outlined"
          >
            戻る
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default ReservationMap;
