import { useEffect, useState } from "react";
import { DriverData, CarData } from "../types/data_types";

export const useFilteredDriverData = (driverData: DriverData[]) => {
  const [filteredDriverData, setFilteredDriverData] = useState<DriverData[]>(
    [],
  );

  useEffect(() => {
    const tmp = (driverData || []).filter((data: DriverData) => {
      return !(data || ({} as any)).left;
    });
    setFilteredDriverData(tmp);
  }, [driverData]);
  return {
    filteredDriverData,
  };
};

export const useFilteredCarData = (carData: CarData[]) => {
  const [filteredCarData, setFilteredCarData] = useState<CarData[]>([]);
  useEffect(() => {
    const tmp = (carData || []).filter((data: any) => {
      return !(data || ({} as any)).left;
    });
    setFilteredCarData(tmp);
  }, [carData]);
  return {
    filteredCarData,
  };
};
