import React from "react";
import { styled } from "@mui/system";
import customerClass from "../models/customer";

const IconSpan = styled("span")(({ theme }) => ({
  backgroundColor: "#355584",
  color: "#FFF",
  padding: "5px",
  borderRadius: "20%",
  marginLeft: "3px",
}));
const IconBoxDiv = styled("div")(({ theme }) => ({
  margin: "10px",
}));

function UserIcon(props: any) {
  const { customer, simple } = props;

  const customerData = Object.assign(
    {},
    customerClass.defaultValue(),
    customer,
  );
  if (simple) {
    return (
      <React.Fragment>
        {customerData.hascommuterpass === 1 ? <IconSpan>定</IconSpan> : ""}
        {customerData.handicapped === 1 ? <IconSpan>障</IconSpan> : ""}
        {customerData.isAdult === 0 ? <IconSpan>子</IconSpan> : ""}
        {customerData.hasbaby === 1 ? <IconSpan>未</IconSpan> : ""}
        {customerData.returnLicense === 1 ? <IconSpan>免</IconSpan> : ""}
      </React.Fragment>
    );
  }
  return (
    <div>
      <IconBoxDiv>
        ({customerData.sex === 1 ? "男" : "女"})
        {customerData.hascommuterpass === 1 ? <IconSpan>定</IconSpan> : ""}
        {customerData.handicapped === 1 ? <IconSpan>障</IconSpan> : ""}
        {customerData.isAdult === 0 ? <IconSpan>子</IconSpan> : ""}
        {customerData.hasbaby === 1 ? <IconSpan>未</IconSpan> : ""}
        {customerData.returnLicense === 1 ? <IconSpan>免</IconSpan> : ""}
      </IconBoxDiv>
    </div>
  );
}

export default UserIcon;
