import React from "react";
import { generateElementKey } from "./Utils";
import emoji from "./emoji";

export const getCustomerIcons = (
  adult: number,
  child: number,
  baby: number,
  key: number,
) => {
  const ret = [];
  for (let i = 0; i < adult; i++) {
    ret.push(
      <span
        style={{ fontSize: "1.5rem" }}
        role="img"
        aria-label=""
        key={generateElementKey([key, "ad", String(i)])}
      >
        {emoji.adult}
      </span>,
    );
  }
  for (let i = 0; i < child; i++) {
    ret.push(
      <span
        style={{ fontSize: "1.5rem" }}
        role="img"
        aria-label=""
        key={generateElementKey([key, "ch", String(i)])}
        className="icon"
      >
        {emoji.child}
      </span>,
    );
  }
  for (let i = 0; i < baby; i++) {
    ret.push(
      <span
        style={{ fontSize: "1.5rem" }}
        role="img"
        aria-label=""
        key={generateElementKey([key, "ba", String(i)])}
      >
        {emoji.baby}
      </span>,
    );
  }
  return <React.Fragment>{ret}</React.Fragment>;
};
