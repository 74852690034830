export const firebaseConfig = {
  apiKey: "AIzaSyABy0GMAOtRXQA2yo3IcU16AtgAoLNKCks",
  authDomain: "kochi-bus.firebaseapp.com",
  databaseURL: "https://kochi-bus.firebaseio.com",
  projectId: "kochi-bus",
  storageBucket: "kochi-bus.appspot.com",
  messagingSenderId: "851903834794",
  appId: "1:851903834794:web:34556be54570e2394cfed3",
};

export const sentry = {
  dsn: "https://73643039d9194e439ecf7301843b7962@o466770.ingest.sentry.io/5481371",
};

export const mapKey = "AIzaSyABy0GMAOtRXQA2yo3IcU16AtgAoLNKCks";

export const env: string = "prod";
