import { useState, useEffect } from "react";
import firebase from "firebase/compat/app";

// This function asynchronously fetches a Firestore document specified by the path and returns it.
// It returns null the document does not exist.
// It returns undefined if the path is null or undefined.
function useOnDocument<T = any>(
  db: firebase.firestore.Firestore,
  path: string,
) {
  const [document, setDocument] = useState<T | null>(null);
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    let detacher = undefined;
    if (path) {
      try {
        const ref = db.doc(path);
        detacher = ref.onSnapshot(
          (
            snapshot: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>,
          ) => {
            if (snapshot.exists) {
              const data = snapshot.data() || {};
              data.id = snapshot.id;
              setDocument(data as T);
            } else {
              setDocument(null);
            }
          },
        );
      } catch (e) {
        console.log(e);
        setError(e);
      }
    } else {
      setDocument(null);
    }
    return detacher;
  }, [db, path]);

  return [document, error];
}

export default useOnDocument;
