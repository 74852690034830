import firebase from "firebase/compat/app";

import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import { Grid, Radio, TextField, Button, IconButton } from "@mui/material";
import { TableBody, TableHead, TableRow } from "@mui/material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Restore } from "@mui/icons-material";

import * as crypto from "crypto";

import moment from "moment";
import "moment/locale/ja";

import * as DataUtils from "../common/DataUtils";
import * as Utils from "../common/Utils";
import * as AdminUtils from "./utils/Utils";
import {
  log2PaymentData,
  updatePaymentData2,
  pickupKey2PositionData,
  numberDataset,
  log2customData,
  log2Price,
} from "./utils/DailyLogUtil";

import useOnCollection from "../common/useOnCollection";
import useOnCollectionGroup from "../common/useOnCollectionGroup";

import Select from "../Select";

import { sortStopsData, convStopsData } from "./utils/stopsUtils";

import * as common from "../data/common";

import {
  LineData,
  CarLog,
  CustomerData,
  CustomerLog,
  ReservationPositionData,
  DriverLocationLog,
  FinalResult,
  PaymentData,
  PriceData,
  ValidLog,
  DrivedTerm,
  Company,
} from "../types/data_types";

import {
  MuiTableRoot,
  MuiTableCellRootAndHead,
  MuiTableCellRootAndHeadVTOP,
  MuiTableCellRootAndHeadWidth150,
  MuiTableCellRootAndHeadWidth270,
  MuiTableCellRootAndHeadNoBottom,
  MuiTableCellBody,
  MuiTableCellBodyAndFooter,
  MuiTableCellBodyAndTCenter,
  MuiTableCellBodyAndTRight,
  MuiTableCellBodyAndNoWrap,
  MuiTableCellBodyAndNoWrapTRight,
  MuiTableCellBodyAndNoWrapTRightAdjustPrice,
  WarnRed,
  WarnGreen,
  BMiddle,
} from "./styles/DailyLogRawStyle";

const query = {
  queryFilter: (query: any) => {
    return query.orderBy("created", "asc");
  },
};

type Props = {
  db: firebase.firestore.Firestore;
  company: Company;
  lineData: LineData;
  setHeaderTitle: any;
};

const DailyLogRaw: React.FC<Props> = ({
  db,
  company,
  lineData,
  setHeaderTitle,
}) => {
  const navigate = useNavigate();

  const { year, month, day, lineId, serviceId } = useParams();

  const now = moment(`${year}-${month}-${day}`);

  const [current, setCurrent] = useState(now);
  const [currentLineId, setCurrentLineId] = useState(
    lineId || Object.keys(lineData)[0],
  );
  const [currentServiceId, setCurrentServiceId] = useState<number>(
    Number(serviceId) || 0,
  );
  // log
  const [locationPath, setLocationPath] = useState(
    `companies/${
      company.id
    }/lines/${currentLineId}/services/${currentServiceId}/drivelog/${current.format(
      "YYYY-MM-DD",
    )}/loation`,
  );
  const [locations] = useOnCollection<DriverLocationLog>(
    db,
    locationPath,
    query,
  );

  // customer log
  const [customerLogPath, setCustomerLogPath] = useState(
    `companies/${
      company.id
    }/lines/${currentLineId}/services/${currentServiceId}/customerlog/${current.format(
      "YYYY-MM-DD",
    )}/lineResults`,
  );

  const [customerLogs] = useOnCollection<CustomerLog>(db, customerLogPath);
  const [customerLogObject, setCustomerLogObject] = useState<{
    [key: string]: CustomerLog;
  }>({});
  const [drivedTerm, setDrivedTerm] = useState<DrivedTerm>({} as DrivedTerm);

  // car Log
  const [lineQuery, setLineQuery] = useState<any>({});
  const [carLogs] = useOnCollectionGroup<CarLog[]>(
    db,
    "mileageMeter",
    lineQuery,
  );
  const [carLog, setCarLog] = useState<CarLog>({} as CarLog);
  const [carLogObject, setCarLogObject] = useState<{
    [key: string]: { [key: number]: CarLog };
  }>({});
  const [currentCarStatus, setCurrentCarStatus] = useState("");
  const [isEditableData, setIsEdittableData] = useState(false);

  // for alert message
  const [validLogs] = useOnCollectionGroup(db, "validLog", lineQuery);
  const [validLogObject, setValidLogObject] = useState<{
    [key: string]: { [key: number]: ValidLog };
  }>({});

  const [distance, setDistance] = useState(0);
  const [gpsDistance, setGpsDistance] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [finalResult, setFinalResult] = useState<FinalResult>(
    {} as FinalResult,
  );

  //
  const driverPath = `companies/${company.id}/drivers`;
  const [driverData] = useOnCollection(db, driverPath);

  const stopsPath = `companies/${company.id}/lines/${currentLineId}/stops`;
  const [stopsData] = useOnCollection(db, stopsPath);

  // customers
  const [customers] = useOnCollection(db, `companies/${company.id}/customers`);
  const [extraUser, setExtraUser] = useState(0);
  const [extraOnPosition, setExtraOnPosition] = useState(0);
  const [extraOffPosition, setExtraOffPosition] = useState(0);

  const [open, setOpen] = useState(false);
  const [deleteLog, setDeleteLog] = useState<CustomerLog | null>(null);
  const [updateLog, setUpdateLog] = useState<any>(null);

  const filteredCustomer = (customers || []).filter((cus: CustomerData) => {
    // TODO test
    return !(customerLogs || []).some((log: CustomerLog) => {
      return log["customerId"] && log["customerId"] === cus.id;
    });
  });

  const orgOnOffPositionDataSet = convStopsData(sortStopsData(stopsData));
  const onPositionDataSet = DataUtils.filterOnOffPositionDataset(
    orgOnOffPositionDataSet,
    currentLineId,
    Number(currentServiceId),
    true,
    current.toDate(),
  );
  const offPositionDataSet = DataUtils.filterOnOffPositionDataset(
    orgOnOffPositionDataSet,
    currentLineId,
    Number(currentServiceId),
    false,
    current.toDate(),
  );

  const driversDataSet = DataUtils.getDriverDataset(driverData || []);
  const userDataSet = DataUtils.getUserDataset(filteredCustomer || []);

  const selectedLine = lineData[currentLineId];
  const selectedService =
    selectedLine.services.find(
      (ele: any) => ele.id === Number(currentServiceId),
    ) || ({} as any);

  const lineDataset = DataUtils.getLineDataset(lineData);
  const serviceDataset = DataUtils.getServiceDataset(
    lineData[currentLineId],
    true,
  );

  const existValidLog = !!(validLogObject[currentLineId] || {})[
    currentServiceId
  ];

  const handleCancel = () => {
    setOpen(false);
  };
  const handleResetDeleteLog = () => {
    setDeleteLog(null);
  };
  const handleResetUpdateLog = () => {
    setUpdateLog(null);
  };

  const decreaseDate = () => {
    const newCurrent = current.clone();
    newCurrent.add(-1, "day");
    navigate(
      `/admin/dailylograw/${newCurrent.format(
        "YYYY/MM/DD",
      )}/${currentLineId}/${currentServiceId}`,
    );
    setCurrent(newCurrent);
  };
  const increaseDate = () => {
    const newCurrent = current.clone();
    newCurrent.add(1, "day");
    navigate(
      `/admin/dailylograw/${newCurrent.format(
        "YYYY/MM/DD",
      )}/${currentLineId}/${currentServiceId}`,
    );
    setCurrent(newCurrent);
  };
  const location = useLocation();
  useEffect(() => {
    const url = location.pathname;
    const newPath = `/admin/dailylograw/${current.format(
      "YYYY/MM/DD",
    )}/${currentLineId}/${currentServiceId}`;
    if (newPath !== url) {
      navigate(newPath);
    }
  }, [currentLineId, currentServiceId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const [, , , year, month, day, lineId, serviceId] =
      location.pathname.split("/");
    const now = moment(`${year}-${month}-${day}`);
    setCurrent(now);
    setCurrentLineId(lineId);
    setCurrentServiceId(Number(serviceId));
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setHeaderTitle("運行後の確認／変更");

    const query = {
      queryFilter: (query: any) => {
        return query
          .where("date", "==", current.format("YYYY-MM-DD"))
          .where("companyId", "==", company.id);
      },
    };
    setLineQuery(query);
  }, [current]); // eslint-disable-line react-hooks/exhaustive-deps

  // car Log
  useEffect(() => {
    const _tmp = Utils.convData(carLogs, "");
    setCarLogObject(_tmp);
  }, [carLogs]);

  useEffect(() => {
    if (!Utils.isNull(carLogObject) && !Utils.isNull(currentLineId)) {
      const _carlog =
        (carLogObject[currentLineId] || {})[currentServiceId] || {};
      setCarLog(_carlog);
      if (_carlog.serviceEndDistance > 0 && _carlog.serviceStartDistance > 0) {
        setDistance(_carlog.serviceEndDistance - _carlog.serviceStartDistance);
      } else {
        setDistance(0);
      }
    }
  }, [carLogObject, currentLineId, currentServiceId]);

  useEffect(() => {
    setCurrentCarStatus(AdminUtils.getCurrentCarStatus(carLog));
    setIsEdittableData(AdminUtils.isEditableData(carLog));
  }, [carLog]);

  useEffect(() => {
    const data = Utils.convData(validLogs, "");
    setValidLogObject(data);
  }, [validLogs]);

  useEffect(() => {
    // change selected cource case
    resetMinTime();
  }, [carLog, customerLogs, currentLineId, currentServiceId, isEditableData]); // eslint-disable-line react-hooks/exhaustive-deps

  function resetMinTime(force = false) {
    if (
      customerLogs &&
      customerLogs.length > 0 &&
      String(customerLogs[0].serviceId) === String(currentServiceId) &&
      customerLogs[0].lineId === currentLineId
    ) {
      if ((!carLog.minTime || force) && isEditableData) {
        const newCarLog = Object.assign({}, carLog);
        newCarLog.minTime = AdminUtils.updateMinTime(customerLogs);
        setCarLog(newCarLog);
      }
    }
  }

  useEffect(() => {
    const _tmp = Utils.array2obj(customerLogs, "id");

    const _drivedTerm = (customerLogs || []).reduce(
      (
        tmp: {
          onPosition: ReservationPositionData;
          offPosition: ReservationPositionData;
        },
        log: CustomerLog,
      ) => {
        const onPosition = (log.customLog || {}).onPosition || log.onPosition;
        const offPosition =
          (log.customLog || {}).offPosition || log.offPosition;

        // positionIndex
        const OnPositionIndex =
          onPosition.positionIndex === 0
            ? DataUtils.getBusStopPosition(
                lineData[currentLineId],
                Number(onPosition.busStop1Id),
              )
            : onPosition.positionIndex;
        const OffPositionIndex =
          offPosition.positionIndex === 0
            ? DataUtils.getBusStopPosition(
                lineData[currentLineId],
                Number(offPosition.busStop1Id),
              )
            : offPosition.positionIndex;

        if (onPosition) {
          if (!tmp.onPosition && onPosition) {
            tmp.onPosition = onPosition;
          }
          if (selectedService["inbound"]) {
            if (tmp.onPosition.positionIndex > OnPositionIndex) {
              tmp.onPosition = onPosition;
            }
          } else {
            if (tmp.onPosition.positionIndex < OnPositionIndex) {
              tmp.onPosition = onPosition;
            }
          }
        }
        if (offPosition) {
          if (!tmp.offPosition && offPosition) {
            tmp.offPosition = offPosition;
          }
          if (selectedService["inbound"]) {
            if (tmp.offPosition.positionIndex < OffPositionIndex) {
              tmp.offPosition = offPosition;
            }
          } else {
            if (tmp.offPosition.positionIndex > OffPositionIndex) {
              tmp.offPosition = offPosition;
            }
          }
        }
        return tmp;
      },
      {},
    );
    setCustomerLogObject(_tmp);
    setDrivedTerm(_drivedTerm);
  }, [customerLogs]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const aclog = [];
    const _gpsDistance = [locations || []].reduce(
      (tmp: number, loc: DriverLocationLog, index: number) => {
        if (
          carLog &&
          carLog.minTime &&
          carLog.minTime.start &&
          carLog.minTime.end &&
          carLog.minTime.end &&
          loc &&
          loc.created
        ) {
          if (
            carLog.minTime.start.toDate().getTime() <
              loc.created.toDate().getTime() &&
            loc.created.toDate().getTime() <
              carLog.minTime.end.toDate().getTime()
          ) {
            aclog.push(loc);
            if (index !== 0) {
              const prev = (locations || [])[index - 1];
              const dist = AdminUtils.getDistance(
                prev.lat,
                prev.lng,
                loc.lat,
                loc.lng,
              );
              return tmp + dist;
            }
          }
        }
        return tmp;
      },
      0,
    );
    setGpsDistance(Math.round(_gpsDistance * 10) / 10.0);
  }, [locations, carLog.minTime]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setLocationPath(
      `companies/${
        company.id
      }/lines/${currentLineId}/services/${currentServiceId}/drivelog/${current.format(
        "YYYY-MM-DD",
      )}/loation`,
    );
    setCustomerLogPath(
      `companies/${
        company.id
      }/lines/${currentLineId}/services/${currentServiceId}/customerlog/${current.format(
        "YYYY-MM-DD",
      )}/lineResults`,
    );
  }, [currentLineId, currentServiceId, current]); // eslint-disable-line react-hooks/exhaustive-deps

  // just here
  useEffect(() => {
    // TODO: Change this algorithem for custom data
    (customerLogs || []).map((current: CustomerLog) => {
      if (current["customLog"] === undefined) {
        const customLog = log2customData(current);
        current["customLog"] = customLog;
      }
      return current;
    });
    const _finalResult = (customerLogs || []).reduce(
      (tmp: FinalResult, current: CustomerLog) => {
        if (current.notFoundAt) {
          return tmp;
        }
        const newPrice = log2Price(current, lineData[currentLineId]);
        const basePrice = newPrice.basePrice;

        current.priceData["basePrice"] = newPrice.basePrice;
        current.priceData["rawPrice"] = newPrice.rawPrice;
        current.priceData["finalPrice"] = newPrice.price;

        if (
          !current.paymentData ||
          Object.keys(current.paymentData).length === 0
        ) {
          current.paymentData = log2PaymentData(current);
        } else {
          current.paymentData = updatePaymentData2(current);
        }
        if (current.customLog.withChild) {
          tmp.extraChild += current.customLog.withChild;
          tmp.halfPrice += basePrice * current.customLog.withChild * 0.5;
          tmp.halfNumber += current.customLog.withChild;
        }
        if (current.customLog.withBaby) {
          tmp.baby += current.customLog.withBaby;
          tmp.freeNumber += current.customLog.withBaby;
        }
        if (current.customLog.type === "hascommuterpass") {
          tmp.hascommuterpass++;
          tmp.freeNumber++;
          tmp.freePrice += common.commuterPassPrice;
        } else if (current.customLog.type === "pointC") {
          tmp.pointC++;
          tmp.freeNumber++;
          // tmp.freePrice += common.commuterPassPrice;
        } else if (current.customLog.type === "returnLicense") {
          tmp.returnLicense++;
          tmp.halfPrice += basePrice * 0.5;
          tmp.halfNumber++;
        } else if (current.customLog.type === "handicapped") {
          tmp.handicapped++;
          tmp.halfPrice += basePrice * 0.5;
          tmp.halfNumber++;
        } else if (current.customLog.type === "child") {
          tmp.child += 1;
          tmp.halfPrice += basePrice * 0.5;
          tmp.halfNumber += 1;
        } else if (current.customLog.type === "adult") {
          tmp.adultPrice += basePrice;
          tmp.adult++;
        }
        tmp.totalPrice += newPrice.price || 0;
        tmp.adjustPrice += newPrice.adjustPrice || 0;

        tmp.cash += current.paymentData.cash || 0;
        tmp.taxi_ticket += current.paymentData.taxi_ticket || 0;
        tmp.local_currency += current.paymentData.local_currency || 0;

        return tmp;
      },
      {
        extraChild: 0,
        child: 0,
        baby: 0,
        hascommuterpass: 0,
        pointC: 0,
        returnLicense: 0,
        handicapped: 0,
        adult: 0,
        adultPrice: 0,
        halfPrice: 0,
        freePrice: 0,
        adjustPrice: 0,
        halfNumber: 0,
        freeNumber: 0,
        totalNumber: 0,
        totalPrice: 0,
        cash: 0,
        taxi_ticket: 0,
        local_currency: 0,
      },
    );
    _finalResult.totalNumber =
      _finalResult.adult + _finalResult.halfNumber + _finalResult.freeNumber;
    setFinalResult(_finalResult);
    setTotalPrice(_finalResult.totalPrice);
  }, [customerLogs]); // eslint-disable-line react-hooks/exhaustive-deps

  const submit = async () => {
    // todo save all customer log for price
    await Promise.all(
      (customerLogs || []).map(async (current: CustomerLog) => {
        await saveCustomerLog(current);
      }),
    );
    await updateCarLog({ month: current.format("YYYY-MM") });

    const path = `companies/${
      company.id
    }/lines/${currentLineId}/services/${currentServiceId}/validLog/${current.format(
      "YYYY-MM-DD",
    )}`;
    const data: ValidLog = {
      date: current.format("YYYY-MM-DD"),
      month: current.format("YYYY-MM"),
      lineId: currentLineId,
      serviceId: Number(currentServiceId),
      companyId: company.id,
      carLog: {
        startAt: carLog.startAt,
        endAt: carLog.endAt,
        minTimeStartAt: carLog.minTime.start,
        minTimeEndAt: carLog.minTime.end,
        startDistance: carLog.startDistance,
        endDistance: carLog.endDistance,
        gpsDistance: gpsDistance,
        distance: distance,
      } as CarLog, // todo
      adult: finalResult.adult,
      child: finalResult.child + finalResult.extraChild,
      handicapped: finalResult.handicapped,
      returnLicense: finalResult.returnLicense,
      baby: finalResult.baby,
      hascommuterpass: finalResult.hascommuterpass,
      pointC: finalResult.pointC,

      adultPrice: finalResult.adultPrice,
      halfPrice: finalResult.halfPrice,
      freePrice: finalResult.freePrice,
      adjustPrice: finalResult.adjustPrice,
      totalPrice: totalPrice,

      halfNumber: finalResult.halfNumber,
      freeNumber: finalResult.freeNumber,
      totalNumber: finalResult.totalNumber,

      cash: finalResult.cash,
      taxi_ticket: finalResult.taxi_ticket,
      local_currency: finalResult.local_currency,

      drivedTerm,
    };
    // TODO: if this is not valid data, we failed.
    //  Think validation. (if not valid, user can not submit this data)
    await db.doc(path).set(data);
    navigate(`/admin/dailylog/${current.format("YYYY/MM/DD")}`);
  };

  const addExtraUser = async () => {
    const user = filteredCustomer[extraUser];
    const onPosition = pickupKey2PositionData(
      extraOnPosition,
      true,
      stopsData,
      lineData[currentLineId],
      currentServiceId,
    );
    const offPosition = pickupKey2PositionData(
      extraOffPosition,
      false,
      stopsData,
      lineData[currentLineId],
      currentServiceId,
    );
    // validation
    const inbound = selectedService.inbound;
    // This is simplify because this is not user point index
    const ok =
      (inbound && onPosition.stopId < offPosition.stopId) ||
      (!inbound && onPosition.stopId > offPosition.stopId);
    // positionIndex
    if (!ok) {
      return;
    }
    const onBusStopData: any = {
      ...onPosition,
      id: "pickupPoint",
      index: extraOnPosition,
      position: onPosition.clickPosition,
      type: "pickup",
    };
    onBusStopData["positionIndex"] = DataUtils.getMinPosition(
      lineData[currentLineId],
      currentServiceId,
      onBusStopData,
      true,
    ).minPosition;

    const offBusStopData: any = {
      ...offPosition,
      index: extraOffPosition,
      position: offPosition.clickPosition,
      type: "pickup",
    };
    offBusStopData["positionIndex"] = DataUtils.getMinPosition(
      lineData[currentLineId],
      currentServiceId,
      offBusStopData,
      false,
    ).minPosition;

    // return
    const getOnAtPosition = {
      latitude: onBusStopData.position.lat,
      longitude: onBusStopData.position.lng,
    };
    const getOffAtPosition = {
      latitude: offBusStopData.position.lat,
      longitude: offBusStopData.position.lng,
    };

    const data: any = {
      baby: 0,
      child: 0,
      companyId: company.id,
      customerId: user.id,
      date: current.format("YYYY-MM-DD"),
      // TODO:
      getOffAtPosition,
      getOnAtPosition,
      handicapped: user.handicapped,
      hascommuterpass: user.hascommuterpass,
      isAdult: user.isAdult,
      lineId: currentLineId,
      name: user.name,
      offPosition: offBusStopData,
      onPosition: onBusStopData,
      priceData: {},
      returnLicense: user.returnLicense,
      serviceId: currentServiceId,
      setByWeb: true,
    };
    const customLog = log2customData(data);
    customLog["onKey"] = extraOnPosition;
    customLog["offKey"] = extraOffPosition;
    data["customLog"] = customLog;

    const newPrice = log2Price(data, lineData[currentLineId]);
    data["priceData"] = {
      ...newPrice,
      actualPrice: newPrice["rawPrice"],
      driverPrice: newPrice["rawPrice"],
      finalPrice: newPrice["rawPrice"],
    };
    data["price"] = newPrice["rawPrice"];

    const keyBase =
      ["date", "companyId", "lineId", "serviceId"]
        .map((key) => data[key])
        .join("_") +
      "_" +
      user.id;
    const id = crypto.createHash("sha1").update(keyBase).digest("hex");
    const path = `companies/${
      company.id
    }/lines/${currentLineId}/services/${currentServiceId}/customerlog/${current.format(
      "YYYY-MM-DD",
    )}/lineResults/${id}`;
    await db.doc(path).set(data, { merge: true });

    setExtraUser(0);
    setExtraOffPosition(0);
    setExtraOnPosition(0);
    handleCancel();
  };

  const updatePriceData = async (id: string, price: number) => {
    const newPriceData = Object.assign({}, customerLogObject[id].priceData);
    if ((newPriceData["adjustPrice"] || 0) + price >= 0) {
      newPriceData["adjustPrice"] = (newPriceData["adjustPrice"] || 0) + price;

      const path = `companies/${
        company.id
      }/lines/${currentLineId}/services/${currentServiceId}/customerlog/${current.format(
        "YYYY-MM-DD",
      )}/lineResults/${id}`;
      await db.doc(path).set(
        {
          priceData: newPriceData,
          paymentData: null,
        },
        { merge: true },
      );
    }
  };
  const resetAdjustPrice = (id: string) => {
    const newPriceData = Object.assign({}, customerLogObject[id].priceData);
    newPriceData["adjustPrice"] = 0;
    return newPriceData;
  };
  const updatePaymentData = async (
    id: string,
    type: keyof PaymentData,
    price: number,
  ) => {
    const log = customerLogObject[id];
    const paymentData: PaymentData = { ...log.paymentData };
    if ((paymentData[type] || 0) + price >= 0) {
      paymentData[type] = (paymentData[type] || 0) + price;
      const cash =
        log.priceData.finalPrice -
        (paymentData.taxi_ticket || 0) -
        (paymentData.local_currency || 0);
      if (cash >= 0) {
        paymentData.cash = cash;
        const path = `companies/${
          company.id
        }/lines/${currentLineId}/services/${currentServiceId}/customerlog/${current.format(
          "YYYY-MM-DD",
        )}/lineResults/${id}`;
        await db.doc(path).set(
          {
            paymentData,
          },
          { merge: true },
        );
      }
    }
  };
  const updateCustomerLog = async (id: string, data: any) => {
    const newLog = Object.assign({}, customerLogObject[id].customLog, data);
    if (data.onKey !== undefined) {
      newLog.onPosition =
        data.onKey === "default"
          ? customerLogObject[id].onPosition
          : pickupKey2PositionData(
              data.onKey,
              true,
              stopsData,
              lineData[currentLineId],
              currentServiceId,
            );
    }
    if (data.offKey !== undefined) {
      newLog.offPosition =
        data.offKey === "default"
          ? customerLogObject[id].offPosition
          : pickupKey2PositionData(
              data.offKey,
              false,
              stopsData,
              lineData[currentLineId],
              currentServiceId,
            );
    }

    // TODO: check position
    const path = `companies/${
      company.id
    }/lines/${currentLineId}/services/${currentServiceId}/customerlog/${current.format(
      "YYYY-MM-DD",
    )}/lineResults/${id}`;
    await db.doc(path).set(
      {
        priceData: resetAdjustPrice(id),
        customLog: newLog,
      },
      { merge: true },
    );
  };

  const updateCarLog = async (data: any) => {
    const newLog = Object.assign({}, carLog, data);
    await db.doc(carLog.key).set(newLog);
  };
  // finally
  const saveCustomerLog = async (customerLog: CustomerLog) => {
    const id = customerLog.id;
    const newPrice = log2Price(customerLog, lineData[currentLineId]);

    const newPriceData = Object.assign({}, customerLog.priceData);
    newPriceData["basePrice"] = newPrice.basePrice;
    newPriceData["finalPrice"] = newPrice.price;

    const path = `companies/${
      company.id
    }/lines/${currentLineId}/services/${currentServiceId}/customerlog/${current.format(
      "YYYY-MM-DD",
    )}/lineResults/${id}`;
    await db.doc(path).set(
      {
        priceData: newPriceData,
      },
      { merge: true },
    );
  };

  return (
    <Grid container alignItems="stretch" direction="row">
      <Grid item xs={12}>
        <h2>
          <button
            onClick={(e: any) => {
              decreaseDate();
            }}
          >
            &#9664;
          </button>
          {current.format("YYYY-MM-DD")}
          <button
            onClick={(e: any) => {
              increaseDate();
            }}
          >
            &#9654;
          </button>
        </h2>
        <br />
      </Grid>
      <Grid item xs={12}>
        <div>
          <Select
            dataSet={lineDataset}
            onChange={(e: any) => Utils.onChange2(e, setCurrentLineId)}
            value={currentLineId}
          />
          {currentCarStatus}
          <MuiTableRoot>
            <TableHead>
              <TableRow>
                <MuiTableCellRootAndHead>運行日</MuiTableCellRootAndHead>
                <MuiTableCellRootAndHead>便</MuiTableCellRootAndHead>
                <MuiTableCellRootAndHead>運転者</MuiTableCellRootAndHead>
                <MuiTableCellRootAndHead>出庫時間</MuiTableCellRootAndHead>
                <MuiTableCellRootAndHead>入庫時間</MuiTableCellRootAndHead>
                <MuiTableCellRootAndHead>
                  実運行時刻
                  <IconButton
                    onClick={() => {
                      resetMinTime(true);
                    }}
                  >
                    <Restore />
                  </IconButton>
                </MuiTableCellRootAndHead>
                <MuiTableCellRootAndHead>
                  出庫時
                  <br />
                  メーター
                </MuiTableCellRootAndHead>
                <MuiTableCellRootAndHead>
                  入庫時
                  <br />
                  メーター
                </MuiTableCellRootAndHead>
                <MuiTableCellRootAndHead>
                  出発時
                  <br />
                  メーター
                </MuiTableCellRootAndHead>
                <MuiTableCellRootAndHead>
                  到着時
                  <br />
                  メーター
                </MuiTableCellRootAndHead>
                <MuiTableCellRootAndHead>実車距離</MuiTableCellRootAndHead>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(carLog).length === 0 ? (
                <TableRow>
                  <MuiTableCellBody>
                    {carLog.startAt
                      ? moment(carLog.startAt.toDate()).format("YYYY/MM/DD")
                      : ""}
                  </MuiTableCellBody>
                  <MuiTableCellBody>
                    <Select
                      dataSet={serviceDataset}
                      onChange={(e: any) =>
                        Utils.onChange2(e, setCurrentServiceId)
                      }
                      value={currentServiceId}
                    />
                  </MuiTableCellBody>
                  <MuiTableCellBody>
                    {carLog.driver ? carLog.driver.name : ""}
                  </MuiTableCellBody>
                  <MuiTableCellBody>
                    {carLog.startAt
                      ? moment(carLog.startAt.toDate()).format("HH:mm")
                      : ""}
                  </MuiTableCellBody>
                  <MuiTableCellBody>
                    {carLog.endAt
                      ? moment(carLog.endAt.toDate()).format("HH:mm")
                      : ""}
                  </MuiTableCellBody>
                  <MuiTableCellBodyAndNoWrap>
                    {carLog && carLog.minTime
                      ? moment(carLog.minTime.start.toDate()).format("HH:mm")
                      : ""}
                    ~{" "}
                    {carLog && carLog.minTime
                      ? moment(carLog.minTime.end.toDate()).format("HH:mm")
                      : ""}
                  </MuiTableCellBodyAndNoWrap>
                  <MuiTableCellBody>{carLog.startDistance}</MuiTableCellBody>
                  <MuiTableCellBody>{carLog.endDistance}</MuiTableCellBody>
                  <MuiTableCellBody>
                    {carLog.serviceStartDistance}
                  </MuiTableCellBody>
                  <MuiTableCellBody>
                    {carLog.serviceEndDistance}
                  </MuiTableCellBody>
                  <MuiTableCellBody>{distance}</MuiTableCellBody>
                </TableRow>
              ) : (
                <TableRow>
                  <MuiTableCellBody>
                    {carLog.startAt
                      ? moment(carLog.startAt.toDate()).format("YYYY/MM/DD")
                      : ""}
                  </MuiTableCellBody>
                  <MuiTableCellBody>
                    <Select
                      dataSet={serviceDataset}
                      onChange={(e: any) =>
                        Utils.onChange2(e, setCurrentServiceId)
                      }
                      value={currentServiceId}
                    />
                  </MuiTableCellBody>
                  <MuiTableCellBody>
                    <Select
                      dataSet={driversDataSet}
                      onChange={(e: any) => {
                        const driver = Object.assign(
                          {},
                          (driverData || ({} as any))[e.target.value],
                        );
                        updateCarLog({ driver });
                      }}
                      value={
                        carLog.driver && driverData
                          ? driverData.findIndex((d: any) => {
                              return d.id === carLog.driver.id;
                            })
                          : ""
                      }
                      empty={false}
                      disabled={!isEditableData}
                    />
                  </MuiTableCellBody>
                  <MuiTableCellBody>
                    <TextField
                      type="time"
                      disabled={!isEditableData}
                      value={
                        carLog.startAt
                          ? moment(carLog.startAt.toDate()).format("HH:mm")
                          : ""
                      }
                      onChange={(e: any) => {
                        const formatedTime =
                          (carLog.startAt
                            ? moment(carLog.startAt.toDate())
                            : current
                          ).format("YYYY-MM-DD ") + e.target.value;
                        const momentObj = moment(formatedTime);
                        const newTime = firebase.firestore.Timestamp.fromDate(
                          momentObj.toDate(),
                        );
                        updateCarLog({ startAt: newTime });
                      }}
                    />
                  </MuiTableCellBody>
                  <MuiTableCellBody>
                    <TextField
                      type="time"
                      disabled={!isEditableData}
                      value={
                        carLog.endAt
                          ? moment(carLog.endAt.toDate()).format("HH:mm")
                          : ""
                      }
                      onChange={(e: any) => {
                        const formatedTime =
                          (carLog.endAt
                            ? moment(carLog.endAt.toDate())
                            : current
                          ).format("YYYY-MM-DD ") + e.target.value;
                        const momentObj = moment(formatedTime);
                        const newTime = firebase.firestore.Timestamp.fromDate(
                          momentObj.toDate(),
                        );
                        updateCarLog({ endAt: newTime });
                      }}
                    />
                  </MuiTableCellBody>
                  <MuiTableCellBodyAndNoWrap>
                    <TextField
                      type="time"
                      disabled={!isEditableData}
                      value={
                        carLog && carLog.minTime && carLog.minTime.start
                          ? moment(carLog.minTime.start.toDate()).format(
                              "HH:mm",
                            )
                          : ""
                      }
                      onChange={(e: any) => {
                        const formatedTime =
                          moment(carLog.minTime.start.toDate()).format(
                            "YYYY-MM-DD ",
                          ) + e.target.value;
                        const momentObj = moment(formatedTime);
                        const newTime = firebase.firestore.Timestamp.fromDate(
                          momentObj.toDate(),
                        );
                        const newMinTime = Object.assign({}, carLog.minTime);
                        newMinTime.start = newTime;
                        updateCarLog({ minTime: newMinTime });
                      }}
                    />
                    ~
                    <TextField
                      type="time"
                      disabled={!isEditableData}
                      value={
                        carLog && carLog.minTime
                          ? moment(carLog.minTime.end.toDate()).format("HH:mm")
                          : ""
                      }
                      onChange={(e: any) => {
                        const formatedTime =
                          moment(carLog.minTime.end.toDate()).format(
                            "YYYY-MM-DD ",
                          ) + e.target.value;
                        const momentObj = moment(formatedTime);
                        const newTime = firebase.firestore.Timestamp.fromDate(
                          momentObj.toDate(),
                        );
                        const newMinTime = Object.assign({}, carLog.minTime);
                        newMinTime.end = newTime;
                        updateCarLog({ minTime: newMinTime });
                      }}
                    />
                  </MuiTableCellBodyAndNoWrap>
                  <MuiTableCellBody>
                    <TextField
                      value={carLog.startDistance || 0}
                      type="number"
                      disabled={!isEditableData}
                      inputProps={{ inputMode: "numeric", pattern: "[0-9]" }}
                      onChange={(e: any) => {
                        updateCarLog({ startDistance: Number(e.target.value) });
                      }}
                    />
                  </MuiTableCellBody>
                  <MuiTableCellBody>
                    <TextField
                      value={carLog.endDistance || 0}
                      type="number"
                      disabled={!isEditableData}
                      inputProps={{ inputMode: "numeric", pattern: "[0-9]" }}
                      onChange={(e: any) => {
                        updateCarLog({ endDistance: Number(e.target.value) });
                      }}
                    />
                  </MuiTableCellBody>
                  <MuiTableCellBody>
                    <TextField
                      value={carLog.serviceStartDistance || 0}
                      type="number"
                      disabled={!isEditableData}
                      inputProps={{ inputMode: "numeric", pattern: "[0-9]" }}
                      onChange={(e: any) => {
                        updateCarLog({
                          serviceStartDistance: Number(e.target.value),
                        });
                      }}
                    />
                  </MuiTableCellBody>
                  <MuiTableCellBody>
                    <TextField
                      value={carLog.serviceEndDistance || 0}
                      type="number"
                      disabled={!isEditableData}
                      inputProps={{ inputMode: "numeric", pattern: "[0-9]" }}
                      onChange={(e: any) => {
                        updateCarLog({
                          serviceEndDistance: Number(e.target.value),
                        });
                      }}
                    />
                  </MuiTableCellBody>
                  <MuiTableCellBody>{distance}</MuiTableCellBody>
                </TableRow>
              )}
            </TableBody>
          </MuiTableRoot>
        </div>
      </Grid>

      <Grid item xs={12}>
        運行実績
        <MuiTableRoot>
          <TableHead>
            <TableRow>
              <MuiTableCellRootAndHeadWidth150 rowSpan={4}>
                乗客名
              </MuiTableCellRootAndHeadWidth150>
              <MuiTableCellRootAndHead colSpan={8}>
                種別
              </MuiTableCellRootAndHead>
              <MuiTableCellRootAndHead rowSpan={4}>
                基<br />本<br />料<br />金
              </MuiTableCellRootAndHead>
              <MuiTableCellRootAndHead rowSpan={4}>
                定<br />期<br />券<br /> <br />料<br />金<br />調<br />整
              </MuiTableCellRootAndHead>
              <MuiTableCellRootAndHead rowSpan={4}>
                料<br />金
              </MuiTableCellRootAndHead>
              <MuiTableCellRootAndHead colSpan={3}>
                料金支払方法
              </MuiTableCellRootAndHead>
              <MuiTableCellRootAndHead rowSpan={4}>
                乗<br />務<br />員<br />入<br />力<br />料<br />金
              </MuiTableCellRootAndHead>
              <MuiTableCellRootAndHead rowSpan={4}>
                発<br />
                ポイント
              </MuiTableCellRootAndHead>
              <MuiTableCellRootAndHeadWidth270 rowSpan={4}>
                着<br />
                ポイント
              </MuiTableCellRootAndHeadWidth270>
              <MuiTableCellRootAndHead rowSpan={4}>
                備考
                <br />
                ドタキャン
                <br />
                予約無乗車
              </MuiTableCellRootAndHead>
              <MuiTableCellRootAndHead rowSpan={4}>
                削除
              </MuiTableCellRootAndHead>
            </TableRow>
            <TableRow>
              <MuiTableCellRootAndHead colSpan={5}>
                大人
              </MuiTableCellRootAndHead>
              <MuiTableCellRootAndHeadNoBottom rowSpan={2}>
                小<br />人<br />︵<br />本<br />人<br />︶<br />
              </MuiTableCellRootAndHeadNoBottom>
              <MuiTableCellRootAndHeadNoBottom rowSpan={2}>
                小<br />人<br />︵<br />同<br />乗<br />︶
              </MuiTableCellRootAndHeadNoBottom>
              <MuiTableCellRootAndHeadNoBottom rowSpan={2}>
                未<br />就<br />学<br />児
              </MuiTableCellRootAndHeadNoBottom>
              <MuiTableCellRootAndHead rowSpan={3}>
                現<br />金<br />
              </MuiTableCellRootAndHead>
              <MuiTableCellRootAndHead rowSpan={3}>
                タ<br />ク<br />シ<br />｜<br />チ<br />ケ<br />ッ<br />ト<br />
              </MuiTableCellRootAndHead>
              <MuiTableCellRootAndHead rowSpan={3}>
                地<br />域<br />通<br />貨<br />メ<br />ジ<br />カ<br />
              </MuiTableCellRootAndHead>
            </TableRow>
            <TableRow>
              <MuiTableCellRootAndHeadVTOP rowSpan={2}>
                健<br />常<br />者
              </MuiTableCellRootAndHeadVTOP>
              <MuiTableCellRootAndHeadVTOP>
                障<br />が<br />い<br />者
              </MuiTableCellRootAndHeadVTOP>
              <MuiTableCellRootAndHeadVTOP>
                免<br />許<br />返<br />納<br />者
              </MuiTableCellRootAndHeadVTOP>
              <MuiTableCellRootAndHeadVTOP>
                定<br />期<br />券
              </MuiTableCellRootAndHeadVTOP>
              <MuiTableCellRootAndHeadVTOP>
                ポ<br />イ<br />ン<br />ト<br />C
              </MuiTableCellRootAndHeadVTOP>
            </TableRow>
            <TableRow>
              <MuiTableCellRootAndHead colSpan={2}>
                半額
              </MuiTableCellRootAndHead>
              <MuiTableCellRootAndHead colSpan={2}>
                無料
              </MuiTableCellRootAndHead>
              <MuiTableCellRootAndHead colSpan={2}>
                半額
              </MuiTableCellRootAndHead>
              <MuiTableCellRootAndHead>無料</MuiTableCellRootAndHead>
            </TableRow>
          </TableHead>

          <TableBody>
            {(customerLogs || [])
              .sort((a: CustomerLog, b: CustomerLog) => {
                const value = selectedService["inbound"] ? 1 : -1;
                if (
                  a["onPosition"]["positionIndex"] !==
                  b["onPosition"]["positionIndex"]
                ) {
                  return a["onPosition"]["positionIndex"] >
                    b["onPosition"]["positionIndex"]
                    ? value
                    : -1 * value;
                }
                return a["offPosition"]["positionIndex"] >
                  b["offPosition"]["positionIndex"]
                  ? value
                  : -1 * value;
              })
              .map((log: CustomerLog) => {
                const customLog = log.customLog || {};
                if (!log.priceData) {
                  log.priceData = {} as PriceData;
                }
                const driverPrice =
                  log.priceData.actualPrice === log.priceData.driverPrice
                    ? ""
                    : log.priceData.driverPrice;

                return (
                  <TableRow key={log.id}>
                    <MuiTableCellBody
                      sx={{ "background-color": log.setByWeb ? "#ddd" : "" }}
                    >
                      {log.name}
                    </MuiTableCellBody>
                    {[
                      "adult",
                      "handicapped",
                      "returnLicense",
                      "hascommuterpass",
                      "pointC",
                      "child",
                    ].map((type, key) => {
                      return (
                        <MuiTableCellBodyAndTCenter key={key}>
                          <Radio
                            disabled={!isEditableData}
                            checked={customLog.type === type}
                            onChange={() => {
                              updateCustomerLog(log.id, { type });
                            }}
                            value={log.id}
                            color="default"
                            inputProps={{ "aria-label": "E" }}
                            size="small"
                          />
                        </MuiTableCellBodyAndTCenter>
                      );
                    })}
                    <MuiTableCellBodyAndTCenter>
                      <Select
                        dataSet={numberDataset}
                        disabled={!isEditableData}
                        onChange={(e: any) => {
                          updateCustomerLog(log.id, {
                            withChild: e.target.value,
                          });
                        }}
                        value={customLog.withChild}
                      />
                      <br />
                    </MuiTableCellBodyAndTCenter>
                    <MuiTableCellBodyAndTCenter>
                      <Select
                        dataSet={numberDataset}
                        disabled={!isEditableData}
                        onChange={(e: any) => {
                          updateCustomerLog(log.id, {
                            withBaby: e.target.value,
                          });
                        }}
                        value={customLog.withBaby}
                      />
                      <br />
                    </MuiTableCellBodyAndTCenter>
                    <MuiTableCellBodyAndTRight>
                      {log.priceData.basePrice}
                    </MuiTableCellBodyAndTRight>
                    <MuiTableCellBodyAndNoWrapTRightAdjustPrice>
                      <input
                        type="button"
                        disabled={!isEditableData}
                        value="-"
                        onClick={() => {
                          updatePriceData(log.id, -50);
                        }}
                      />
                      {log.priceData.adjustPrice || 0}
                      <input
                        type="button"
                        disabled={!isEditableData}
                        value="+"
                        onClick={() => {
                          updatePriceData(log.id, 50);
                        }}
                      />
                    </MuiTableCellBodyAndNoWrapTRightAdjustPrice>
                    <MuiTableCellBodyAndTRight>
                      {log.priceData.finalPrice}
                    </MuiTableCellBodyAndTRight>

                    <MuiTableCellBodyAndTRight>
                      {(log.paymentData || {}).cash}
                    </MuiTableCellBodyAndTRight>
                    <MuiTableCellBodyAndNoWrapTRight>
                      <input
                        type="button"
                        disabled={!isEditableData}
                        value="-"
                        onClick={() => {
                          updatePaymentData(log.id, "taxi_ticket", -50);
                        }}
                      />
                      {(log.paymentData || {}).taxi_ticket || 0}
                      <input
                        type="button"
                        disabled={!isEditableData}
                        value="+"
                        onClick={() => {
                          updatePaymentData(log.id, "taxi_ticket", 50);
                        }}
                      />
                    </MuiTableCellBodyAndNoWrapTRight>

                    <MuiTableCellBodyAndNoWrapTRight>
                      <input
                        type="button"
                        disabled={!isEditableData}
                        value="-"
                        onClick={() => {
                          updatePaymentData(log.id, "local_currency", -50);
                        }}
                      />
                      {(log.paymentData || {}).local_currency || 0}
                      <input
                        type="button"
                        disabled={!isEditableData}
                        value="+"
                        onClick={() => {
                          updatePaymentData(log.id, "local_currency", 50);
                        }}
                      />
                    </MuiTableCellBodyAndNoWrapTRight>

                    <MuiTableCellBodyAndTRight
                      sx={{
                        "background-color":
                          driverPrice > 0 || driverPrice === 0 ? "yellow" : "",
                      }}
                    >
                      {log.notFoundAt ? <s>{driverPrice}</s> : driverPrice}
                    </MuiTableCellBodyAndTRight>
                    <MuiTableCellBody
                      sx={{
                        "background-color":
                          customLog.onKey === "default" ||
                          customLog.onKey === undefined
                            ? ""
                            : "yellow",
                      }}
                    >
                      <Select
                        dataSet={onPositionDataSet}
                        disabled={!isEditableData}
                        onChange={(e: any) => {
                          updateCustomerLog(log.id, { onKey: e.target.value });
                        }}
                        value={
                          customLog.onKey === undefined
                            ? "default"
                            : customLog.onKey
                        }
                        empty={true}
                        blankName={
                          "※" +
                          (log.onPosition || {}).name +
                          (log.getOnAt
                            ? moment(log.getOnAt.toDate()).format("(HH:mm)")
                            : "")
                        }
                      />
                    </MuiTableCellBody>
                    <MuiTableCellBody
                      sx={{
                        "background-color":
                          customLog.offKey === "default" ||
                          customLog.offKey === undefined
                            ? ""
                            : "yellow",
                      }}
                    >
                      <Select
                        dataSet={offPositionDataSet}
                        disabled={!isEditableData}
                        onChange={(e: any) => {
                          updateCustomerLog(log.id, { offKey: e.target.value });
                        }}
                        value={
                          customLog.offKey === undefined
                            ? "default"
                            : customLog.offKey
                        }
                        empty={true}
                        blankName={
                          "※" +
                          (log.offPosition || {}).name +
                          (log.getOffAt
                            ? moment(log.getOffAt.toDate()).format("(HH:mm)")
                            : "")
                        }
                      />
                    </MuiTableCellBody>
                    <MuiTableCellBody>
                      <Button
                        variant="outlined"
                        disabled={!isEditableData}
                        onClick={() => {
                          setUpdateLog(log);
                        }}
                      >
                        {log.notFoundAt
                          ? moment(log.notFoundAt.toDate()).format(
                              "現れなかった(HH:mm)",
                            )
                          : "乗車"}
                      </Button>
                    </MuiTableCellBody>
                    <MuiTableCellBody>
                      {log.setByWeb ? (
                        <input
                          type="button"
                          value="削除"
                          disabled={!isEditableData}
                          onClick={(e: any) => {
                            setDeleteLog(log);
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </MuiTableCellBody>
                  </TableRow>
                );
              })}
            <TableRow>
              <MuiTableCellBodyAndFooter>合計</MuiTableCellBodyAndFooter>
              <MuiTableCellBodyAndFooter>
                {finalResult.adult || 0}
              </MuiTableCellBodyAndFooter>
              <MuiTableCellBodyAndFooter>
                {finalResult.handicapped || 0}
              </MuiTableCellBodyAndFooter>
              <MuiTableCellBodyAndFooter>
                {finalResult.returnLicense || 0}
              </MuiTableCellBodyAndFooter>
              <MuiTableCellBodyAndFooter>
                {finalResult.hascommuterpass || 0}
              </MuiTableCellBodyAndFooter>
              <MuiTableCellBodyAndFooter>
                {finalResult.pointC || 0}
              </MuiTableCellBodyAndFooter>
              <MuiTableCellBodyAndFooter>
                {finalResult.child || 0}
              </MuiTableCellBodyAndFooter>
              <MuiTableCellBodyAndFooter>
                {finalResult.extraChild || 0}
              </MuiTableCellBodyAndFooter>
              <MuiTableCellBodyAndFooter>
                {finalResult.baby || 0}
              </MuiTableCellBodyAndFooter>
              <MuiTableCellBodyAndFooter />
              <MuiTableCellBodyAndFooter />
              <MuiTableCellBodyAndFooter>
                {totalPrice}
              </MuiTableCellBodyAndFooter>
              <MuiTableCellBodyAndFooter>
                {finalResult.cash}
              </MuiTableCellBodyAndFooter>
              <MuiTableCellBodyAndFooter>
                {finalResult.taxi_ticket}
              </MuiTableCellBodyAndFooter>
              <MuiTableCellBodyAndFooter>
                {finalResult.local_currency}
              </MuiTableCellBodyAndFooter>
              <MuiTableCellBodyAndFooter />
              <MuiTableCellBodyAndFooter />
              <MuiTableCellBodyAndFooter />
              <MuiTableCellBodyAndFooter />
              <MuiTableCellBodyAndFooter />
            </TableRow>
          </TableBody>
        </MuiTableRoot>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          disabled={!isEditableData}
          onClick={() => {
            submit();
          }}
          sx={{ float: "left" }}
        >
          運行後の確認完了
        </Button>
        {(customerLogs || []).length === 0 ? (
          ""
        ) : existValidLog ? (
          <WarnGreen>
            <BMiddle>
              ◀ ◀ 完了済みです。再修正した場合は必ず完了ボタンを押してください
            </BMiddle>
          </WarnGreen>
        ) : (
          <WarnRed>
            <BMiddle>
              ◀ ◀ 未完了です。修正がない場合も必ず完了ボタンを押してください
            </BMiddle>
          </WarnRed>
        )}
        {(customerLogs || []).length === 0 ? (
          ""
        ) : (
          <Button
            variant="contained"
            onClick={() => {
              setOpen(true);
            }}
            sx={{ float: "left" }}
          >
            乗客追加
          </Button>
        )}
      </Grid>
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">実績追加</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Select
              dataSet={userDataSet}
              onChange={(e: any) => Utils.onChange2(e, setExtraUser)}
              value={extraUser}
            />
            <br />
            乗車:
            <Select
              dataSet={onPositionDataSet}
              onChange={(e: any) => Utils.onChange2(e, setExtraOnPosition)}
              value={extraOnPosition}
            />
            <br />
            降車:
            <Select
              dataSet={offPositionDataSet}
              onChange={(e: any) => Utils.onChange2(e, setExtraOffPosition)}
              value={extraOffPosition}
            />
            <br />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div style={{ textAlign: "center" }}>
            <Button
              variant="contained"
              onClick={() => {
                addExtraUser();
              }}
              color="primary"
              autoFocus
            >
              追加
            </Button>
            <Button
              variant="outlined"
              onClick={handleCancel}
              color="primary"
              autoFocus
            >
              キャンセル
            </Button>
          </div>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteLog !== null}
        onClose={handleResetDeleteLog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">削除</DialogTitle>
        <DialogContent>{(deleteLog || ({} as any)).name}</DialogContent>
        <DialogActions>
          <div style={{ textAlign: "center" }}>
            <Button
              variant="contained"
              onClick={() => {
                const path = `companies/${
                  company.id
                }/lines/${currentLineId}/services/${currentServiceId}/customerlog/${current.format(
                  "YYYY-MM-DD",
                )}/lineResults/${((deleteLog || {}) as any).id}`;
                db.doc(path).delete();
                handleResetDeleteLog();
              }}
              color="primary"
              autoFocus
            >
              削除
            </Button>
            <Button
              variant="outlined"
              onClick={handleResetDeleteLog}
              color="primary"
              autoFocus
            >
              キャンセル
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      <Dialog
        open={updateLog !== null}
        onClose={handleResetUpdateLog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">更新</DialogTitle>
        <DialogContent>{(deleteLog || ({} as any)).name}</DialogContent>
        <DialogActions>
          <div style={{ textAlign: "center" }}>
            <Button
              variant={
                updateLog && updateLog.notFoundAt ? "outlined" : "contained"
              }
              onClick={() => {
                const path = `companies/${
                  company.id
                }/lines/${currentLineId}/services/${currentServiceId}/customerlog/${current.format(
                  "YYYY-MM-DD",
                )}/lineResults/${updateLog.id}`;
                db.doc(path).update({ notFoundAt: null });
                handleResetUpdateLog();
              }}
              color="primary"
              autoFocus
            >
              乗車
            </Button>
            <br />
            <br />

            <Button
              variant={
                updateLog && updateLog.notFoundAt ? "contained" : "outlined"
              }
              onClick={() => {
                const path = `companies/${
                  company.id
                }/lines/${currentLineId}/services/${currentServiceId}/customerlog/${current.format(
                  "YYYY-MM-DD",
                )}/lineResults/${updateLog.id}`;
                const time = updateLog.getOnAt || carLog.minTime.start;
                const newPriceData = { ...updateLog.priceData };
                newPriceData.basePrice = 0;
                newPriceData.finalPrice = 0;
                db.doc(path).update({
                  notFoundAt: time,
                  paymentData: null,
                  priceData: newPriceData,
                });
                handleResetUpdateLog();
              }}
              color="primary"
              autoFocus
            >
              現れなかった
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default DailyLogRaw;
