import { createRoot } from "react-dom/client";

import "./index.css";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import * as config from "./config";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: config.sentry.dsn,
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

const container = document.getElementById("root");

const root = createRoot(container!);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
