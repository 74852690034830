import React from "react";
import { Button } from "@mui/material";
import { styled } from "@mui/system";

import * as Utils from "../common/Utils";

import { Link } from "react-router-dom";

import {
  ReservationData,
  CourseDataService,
  CourseDataPin,
} from "../types/data_types";

import { butStopId2PinIndex } from "../common/DataUtils";

export const ReservationTableRoot = styled("table")(({ theme }) => ({
  border: "solid 0.5px #aaaaaa",
  borderCollapse: "collapse",
  textAlign: "center",
  "tbody > tr:nth-of-type(2n+1)": {
    background: "#ffff",
  },
  width: "fit-content",
  fontSize: "14px",
}));
const TableBorderTD = styled("td")(({ theme }) => ({
  border: "solid 0.5px #aaaaaa",
}));

const TableBorderTR = styled("tr")(({ theme }) => ({
  border: "solid 0.5px #aaaaaa",
}));
const ReservationTableHeadTd = styled("td")(({ theme }) => ({
  border: "solid 0.5px #aaaaaa",
  lineHeight: "1.2em",
  padding: "4px 2px 0px 2px",
  backgroundColor: "#e5e5e5",
}));

const ReservationTableHeadTdCustomerName = styled(ReservationTableHeadTd)(
  ({ theme }) => ({
    width: "150px",
  }),
);
const ReservationTableHeadTdGetOnOff = styled(ReservationTableHeadTd)(
  ({ theme }) => ({
    width: "270px",
  }),
);
const ReservationTableHeadTdPrice = styled(ReservationTableHeadTd)(
  ({ theme }) => ({
    width: "40px",
  }),
);

const tableElement = (
  reservation: ReservationData,
  pinkey: number,
  service: CourseDataService,
  pins: CourseDataPin[],
) => {
  // v2 case
  const onBusStopId = butStopId2PinIndex(
    reservation.onPosition.busStop1Id,
    pins,
  );
  const offBusStopId = butStopId2PinIndex(
    reservation.offPosition.busStop1Id,
    pins,
  );

  if (onBusStopId === pinkey) {
    return "乗";
  }
  if (offBusStopId === pinkey) {
    return "降";
  }
  if (service.inbound) {
    if (onBusStopId < pinkey && offBusStopId > pinkey) {
      return ">";
    }
  } else {
    if (onBusStopId > pinkey && offBusStopId < pinkey) {
      return ">";
    }
  }
  return "";
};

function ReservationTable(props: any) {
  const { reservations, lineId, service, serviceId, lineData } = props;

  const pins = lineData[lineId].pins;
  const pinTimes = lineData[lineId]["pinTimes"][serviceId];
  const rFilter = (arr: any, flag: boolean) => {
    if (flag) {
      return arr.reverse();
    }
    return arr;
  };

  return (
    <div>
      <ReservationTableRoot>
        <thead>
          <TableBorderTR style={{ verticalAlign: "bottom" }}>
            <ReservationTableHeadTdCustomerName colSpan={2}>
              乗客名
            </ReservationTableHeadTdCustomerName>
            <ReservationTableHeadTdGetOnOff>
              乗車
            </ReservationTableHeadTdGetOnOff>
            <ReservationTableHeadTdGetOnOff>
              降車
            </ReservationTableHeadTdGetOnOff>
            <ReservationTableHeadTdPrice>料金</ReservationTableHeadTdPrice>
            {rFilter(
              pins.map((pin: any, key: number) => {
                return (
                  <ReservationTableHeadTd key={key} rowSpan={2}>
                    {pin.name
                      .replace(/\(|（/g, "︵")
                      .replace(/\)|）/g, "︶")
                      .replace(/-|ー/g, "丨")
                      .split("")
                      .map((a: any, k: number) => {
                        return (
                          <React.Fragment key={k}>
                            {a}
                            <br />
                          </React.Fragment>
                        );
                      })}
                  </ReservationTableHeadTd>
                );
              }),
              !service.inbound,
            )}
          </TableBorderTR>
        </thead>
        <tbody>
          {(reservations || []).map((reservation: any, rkey: number) => {
            const hasBaby = (reservation.baby || 0) > 0;
            const hasChild = (reservation.child || 0) > 0;

            return (
              <TableBorderTR key={[String(rkey)].join("_")}>
                <TableBorderTD style={{ textAlign: "left" }} colSpan={2}>
                  <Link
                    to={`/admin/user/${reservation.customerId}/reservation/${reservation.id}`}
                  >
                    {reservation.customerName}
                  </Link>
                  {hasBaby || hasChild ? (
                    <span>
                      <br />
                      同乗{hasChild ? <span>小人{reservation.child}</span> : ""}
                      {hasBaby && hasChild ? "/" : ""}
                      {hasBaby ? <span>未就{reservation.baby}</span> : ""}
                    </span>
                  ) : (
                    ""
                  )}
                </TableBorderTD>
                <TableBorderTD style={{ textAlign: "left" }}>
                  {reservation.onPosition.name} (
                  {pinTimes[reservation.onPosition.busStop1Id].time})
                  {Utils.isNull(reservation.memo) || reservation.memo === "" ? (
                    ""
                  ) : (
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={(e) => {
                        props.setOpenMemo(true);
                        props.setMemo(reservation.memo);
                      }}
                    >
                      メモ
                    </Button>
                  )}
                </TableBorderTD>
                <TableBorderTD style={{ textAlign: "left" }}>
                  {reservation.offPosition.name}
                </TableBorderTD>
                <TableBorderTD>
                  {reservation.price.actualPrice !== undefined &&
                  reservation.price.actualPrice !== null
                    ? reservation.price.actualPrice
                    : reservation.price.price}
                </TableBorderTD>
                {rFilter(
                  pins.map((pin: any, key: number) => {
                    return (
                      <TableBorderTD key={key}>
                        {tableElement(reservation, key, service, pins)}
                      </TableBorderTD>
                    );
                  }),
                  !service.inbound,
                )}
              </TableBorderTR>
            );
          })}
        </tbody>
      </ReservationTableRoot>
    </div>
  );
}

export default ReservationTable;
