import firebase from "firebase/compat/app";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import * as iconv from "iconv-lite";
import moment from "moment";
import "moment/locale/ja";

import { Grid } from "@mui/material";
import { TableBody, TableHead, TableRow } from "@mui/material";

import Select from "../Select";

import * as DataUtils from "../common/DataUtils";
import * as Utils from "../common/Utils";
import useOnCollectionGroup from "../common/useOnCollectionGroup";
import User from "../models/user";
import * as SummaryUtils from "./utils/SummaryUtils";

import { LineData, Company } from "../types/data_types";
import {
  MuiTableCellRootAndHead,
  MuiTableCellRootAndHeadA2H,
  MuiTableCellRootAndHeadBorderBottomNone,
  MuiTableCellRootAndBody,
  MuiTableRoot,
  MuiTableRowBottom,
} from "./styles/MonthlyLogStyle";

type Props = {
  db: firebase.firestore.Firestore;
  company: Company;
  lineData: LineData;
  setHeaderTitle: any;
  user: User;
};

const MonthyLog: React.FC<Props> = ({
  db,
  company,
  lineData,
  setHeaderTitle,
  user,
}) => {
  const { year, month, lineId, serviceId } = useParams();
  const now = moment(`${year}-${month}-01`, "YYYY-MM-DD");
  now.endOf("month");

  const [current, setCurrent] = useState(now);
  const [dispatchData, setDispatchData] = useState<any>([]);

  const [currentLineId, setCurrentLineId] = useState(
    lineId || Object.keys(lineData)[0],
  );
  const [currentServiceId, setCurrentServiceId] = useState(
    serviceId === "sum" ? "sum" : Number(serviceId || 0),
  );

  const [currentService, setCurrentService] = useState<any>({});
  const [currentLine, setCurrentLine] = useState<any>({});

  const [validLogQuery, setValidLogQuery] = useState<any>({});
  const [validLogs] = useOnCollectionGroup(db, "validLog", validLogQuery);
  const [validLogObject, setValidLogObject] = useState<any>({});

  const [operationRecordQuery, setOperationRecordQuery] = useState<any>({});
  const [operationRecords] = useOnCollectionGroup(
    db,
    "operationRecord",
    operationRecordQuery,
  );
  const [operationRecordObject, setOperationRecordObject] = useState<any>({});

  const [displayData, setDisplayData] = useState([]);

  const [sumMode, setSumMode] = useState(false);

  const [summary, setSummary] = useState<any>({});
  const navigate = useNavigate();

  useEffect(() => {
    const query = {
      queryFilter: (query: any) => {
        query = query
          .where("companyId", "==", company.id)
          .where("month", "==", current.format("YYYY-MM"));
        if (currentLineId !== "sum") {
          query = query.where("lineId", "==", currentLineId);
        }
        if (currentServiceId !== "sum" && currentLineId !== "sum") {
          query = query.where("serviceId", "==", Number(currentServiceId));
        }
        return query;
      },
    };
    setValidLogQuery(query);

    const orQuery = {
      queryFilter: (query: any) => {
        query = query
          .where("companyId", "==", company.id)
          .where("month", "==", current.format("YYYY-MM"));
        if (currentLineId !== "sum") {
          query = query.where("lineId", "==", currentLineId);
        }
        return query;
      },
    };
    setOperationRecordQuery(orQuery);
  }, [currentLineId, currentServiceId, current]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setHeaderTitle("月報");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let summaryFixExpense = 0;
    let summaryDistance = 0;
    let summaryDistancePrice = 0;
    let summarySubTotalExpense = 0;
    let summaryTotalPayment = 0;
    let summaryTotalPrice = 0;
    let summaryTotalNumber = 0;
    const res = dispatchData.map((current: any) => {
      // depen on car log
      // const dataArray = Object.values(validLogObject[current.day.format("YYYY-MM-DD")] || {})[0] || [];
      const dataObj = validLogObject[current.day.format("YYYY-MM-DD")] || {};
      const opRecord = operationRecordObject[current.day.format("YYYY-MM-DD")];

      const lineIds = Array.from(
        new Set(Object.keys(dataObj || {}).concat(Object.keys(opRecord || {}))),
      );
      const theDayData = SummaryUtils.getTheDayData(
        lineIds,
        dataObj,
        current,
        currentLineId,
        currentServiceId,
        company.id,
        opRecord,
      );

      // todo cleanup here. merge to reduce.
      const newData = SummaryUtils.getNewDayLog(
        theDayData,
        lineData,
        dataObj,
        currentLineId,
        current,
        sumMode,
      );

      if (newData.existCarResult) {
        summaryFixExpense += newData.fixExpense;
        summaryDistance += newData.distance;
        summaryDistancePrice += newData.distancePrice;
        summarySubTotalExpense += newData.subTotalExpense; // F
        summaryTotalPrice += newData.totalPrice;
        summaryTotalNumber += newData.totalNumber || newData.totalNubmer || 0; // TODO: REVEMO
        summaryTotalPayment += newData.totalPayment || 0; // G
      } else if (newData.existOperation) {
        // if there is operationRecord, add fixExpense
        summaryFixExpense += newData.fixExpense;
        summarySubTotalExpense += newData.fixExpense;
        summaryTotalPayment += newData.totalPayment || 0;
      }
      return newData;
    });

    setSummary({
      summaryFixExpense,
      summaryDistance,
      summaryDistancePrice,
      summarySubTotalExpense,
      summaryTotalPayment,
      summaryTotalPrice,
      summaryTotalNumber,
    });
    setDisplayData(res);
  }, [dispatchData, validLogObject, operationRecordObject]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const obj = (validLogs || []).reduce((tmp: any, current: any) => {
      if (!tmp[current["date"]]) {
        tmp[current["date"]] = {};
      }
      if (!tmp[current["date"]][current.lineId]) {
        tmp[current["date"]][current.lineId] = [];
      }
      tmp[current["date"]][current.lineId].push(current);
      return tmp;
    }, {});
    setValidLogObject(obj);
  }, [validLogs]);

  useEffect(() => {
    const obj = (operationRecords || []).reduce((tmp: any, current: any) => {
      if (!tmp[current["id"]]) {
        tmp[current["id"]] = {};
      }
      tmp[current["id"]][current.lineId] = current;
      return tmp;
    }, {});
    setOperationRecordObject(obj);
  }, [operationRecords]);

  const decreaseDate = () => {
    const newCurrent = current.clone();
    newCurrent.add(-1, "month");
    navigate(
      `/admin/monthlylog/${newCurrent.format(
        "YYYY/MM",
      )}/${currentLineId}/${currentServiceId}`,
    );
    setCurrent(newCurrent);
  };
  const increaseDate = () => {
    const newCurrent = current.clone();
    newCurrent.add(1, "month");
    navigate(
      `/admin/monthlylog/${newCurrent.format(
        "YYYY/MM",
      )}/${currentLineId}/${currentServiceId}`,
    );
    setCurrent(newCurrent);
  };
  const location = useLocation();
  useEffect(() => {
    setSumMode(currentLineId === "sum" || currentServiceId === "sum");
    const url = location.pathname;
    const newPath = `/admin/monthlylog/${current.format(
      "YYYY/MM",
    )}/${currentLineId}/${currentServiceId}`;
    if (newPath !== url) {
      navigate(newPath);
    }
  }, [currentLineId, currentServiceId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const [_a, _b, _c, year, month, _lineId, serviceId] =
      location.pathname.split("/"); // eslint-disable-line
    const now = moment(`${year}-${month}-01`, "YYYY-MM-DD");
    now.endOf("month");
    setCurrent(now);
    setCurrentServiceId(serviceId === "sum" ? "sum" : Number(serviceId));
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    (async () => {
      const endDay = current.endOf("month").date();
      moment.locale("ja");
      const _dispatchData = [];
      const options: any = { year: "numeric", month: "long", day: "numeric" };
      for (let i = 0; i < endDay; i++) {
        const key = current.startOf("month").add(i, "day");
        _dispatchData.push({
          day: moment(key),
          dayString: key.format("YYYY年MM月DD日(ddd)"),
          dayStringJP: new Intl.DateTimeFormat(
            "ja-JP-u-ca-japanese",
            options,
          ).format(current.toDate()),
        });
      }
      setDispatchData(_dispatchData);
    })();
  }, [current]);

  const lineDataset = DataUtils.getLineDatasetWithSum(lineData);
  const serviceDataset = DataUtils.getServiceDatasetWithSum(
    lineData[currentLineId],
    true,
  );

  useEffect(() => {
    const line = lineData[currentLineId] || {};
    const service =
      currentLineId === "sum" || currentServiceId === "sum"
        ? {}
        : line.services.find((ele: any) => ele.id === currentServiceId);
    setCurrentLine(line);
    setCurrentService(service);
  }, [currentLineId, currentServiceId]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDownload = () => {
    const blob = new Blob([downloadData()], {
      type: `application/csv`,
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    const day = current.format("YYYY-MM");
    link.download = `${day}-${
      currentLineId === "sum" ? "総括" : lineData[currentLineId].name
    }-${
      currentLineId === "sum" || currentServiceId === "sum"
        ? "総括"
        : currentService.sname
    }.csv`;
    link.click();
  };

  const downloadData = () => {
    console.log(currentService, currentLine);
    const csv_data = [
      "ーーーーーーーーーー",
      "ヘッダ",
      "ーーーーーーーーーー",
      ["便No.", currentService.sname].join(","),
      [
        "区域",
        currentLineId === "sum"
          ? "三崎・下川口"
          : currentLine.name.replace("〜", "～"),
      ].join(","),
      ["区分", sumMode ? "" : currentService.inbound ? "往路" : "復路"].join(
        ",",
      ),
      ["運行エリア", sumMode ? "" : currentService.lname].join(","),
      ["交通事業者名", company.name].join(","),
      ["補助金請求額", summary.summaryTotalPayment].join(","),
      "",
      "ーーーーーーーーーー",
      "運行実績",
      "ーーーーーーーーーー",
    ];
    displayData.forEach((data: any, key: number) => {
      if (data.existCarResult) {
        csv_data.push(
          [
            data.dayStringJP,
            data.carName,
            data.fixExpense,
            data.drivedTerm ? data.drivedTerm.onPosition.name : "",
            data.drivedTerm ? "~" : "",
            data.drivedTerm ? data.drivedTerm.offPosition.name : "",
            data.distance,
            data.distancePrice,
            data.subTotalExpense,
            data.totalNumber,
            data.totalPrice,
            data.totalPayment,
          ].join(","),
        );
      } else {
        csv_data.push(
          [data.dayStringJP, data.carName, data.fixExpense].join(","),
        );
      }
    });
    csv_data.push(
      [
        "合計",
        "",
        summary.summaryFixExpense,
        "",
        "",
        "",
        summary.summaryDistance,
        summary.summaryDistancePrice,
        summary.summarySubTotalExpense,
        summary.summaryTotalNumber,
        summary.summaryTotalPrice,
        summary.summaryTotalPayment,
      ].join(","),
    );
    const buf = iconv.encode(csv_data.join("\n"), "Shift_JIS");
    return buf;
  };

  const isAdmin = !!user.user.admin;
  return (
    <Grid container alignItems="stretch" direction="row">
      <Grid item xs={12}>
        <h2>
          <button
            onClick={(e: any) => {
              decreaseDate();
            }}
          >
            &#9664;
          </button>
          {current.format("YYYY-MM")}
          <button
            onClick={(e: any) => {
              increaseDate();
            }}
          >
            &#9654;
          </button>
        </h2>
        <br />
      </Grid>
      <Grid item xs={12}>
        <h2>デマンド交通おでかけ号　月報</h2>
        <Select
          dataSet={lineDataset}
          onChange={(e: any) => Utils.onChange2(e, setCurrentLineId)}
          value={currentLineId}
        />
        <MuiTableRoot>
          <TableHead>
            <TableRow>
              <MuiTableCellRootAndHead>便</MuiTableCellRootAndHead>
              <MuiTableCellRootAndHead>区域</MuiTableCellRootAndHead>
              <MuiTableCellRootAndHead>区分</MuiTableCellRootAndHead>
              <MuiTableCellRootAndHead>運行エリア</MuiTableCellRootAndHead>
              <MuiTableCellRootAndHead>交通事業者</MuiTableCellRootAndHead>
              <MuiTableCellRootAndHead>補助金請求額</MuiTableCellRootAndHead>
              <MuiTableCellRootAndHead>報告日</MuiTableCellRootAndHead>
              <MuiTableCellRootAndHead>報告者</MuiTableCellRootAndHead>
              <MuiTableCellRootAndHead>受け取り者</MuiTableCellRootAndHead>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow style={{ height: "80px" }}>
              <MuiTableCellRootAndBody>
                {currentLineId === "sum" ? (
                  "総括"
                ) : (
                  <Select
                    dataSet={serviceDataset}
                    onChange={(e: any) =>
                      Utils.onChange2(e, setCurrentServiceId)
                    }
                    value={currentServiceId}
                  />
                )}
              </MuiTableCellRootAndBody>
              <MuiTableCellRootAndBody>
                {currentLineId === "sum" ? "三崎・下川口" : currentLine.name}
              </MuiTableCellRootAndBody>
              <MuiTableCellRootAndBody>
                {sumMode ? "" : currentService.inbound ? "往路" : "復路"}
              </MuiTableCellRootAndBody>
              <MuiTableCellRootAndBody>
                {sumMode ? "" : currentService.lname}
              </MuiTableCellRootAndBody>
              <MuiTableCellRootAndBody>{company.name}</MuiTableCellRootAndBody>
              <MuiTableCellRootAndBody>
                {isAdmin ? summary.summaryTotalPayment : "-"}
              </MuiTableCellRootAndBody>
              <MuiTableCellRootAndBody></MuiTableCellRootAndBody>
              <MuiTableCellRootAndBody>
                {currentLine.reporter}
              </MuiTableCellRootAndBody>
              <MuiTableCellRootAndBody></MuiTableCellRootAndBody>
            </TableRow>
          </TableBody>
        </MuiTableRoot>
        <h2>運行実績</h2>
        <MuiTableRoot>
          <TableHead>
            <TableRow>
              <MuiTableCellRootAndHead rowSpan={4}>
                運行日
              </MuiTableCellRootAndHead>
              <MuiTableCellRootAndHead rowSpan={4}>
                車両
              </MuiTableCellRootAndHead>
              <MuiTableCellRootAndHead colSpan={8}>
                人数/料金
              </MuiTableCellRootAndHead>
              <MuiTableCellRootAndHead colSpan={2}>
                運行収入
              </MuiTableCellRootAndHead>
              <MuiTableCellRootAndHeadBorderBottomNone rowSpan={3}>
                補助金額
              </MuiTableCellRootAndHeadBorderBottomNone>
            </TableRow>
            <TableRow>
              <MuiTableCellRootAndHead>固定経費&#9312;</MuiTableCellRootAndHead>
              <MuiTableCellRootAndHead colSpan={4}>
                基本経費&#9313;
              </MuiTableCellRootAndHead>
              <MuiTableCellRootAndHead colSpan={2}>
                その他経費&#9314;
              </MuiTableCellRootAndHead>
              <MuiTableCellRootAndHeadBorderBottomNone rowSpan={2}>
                合計
              </MuiTableCellRootAndHeadBorderBottomNone>
              <MuiTableCellRootAndHead rowSpan={3}>
                利用
                <br />
                人数
              </MuiTableCellRootAndHead>
              <MuiTableCellRootAndHeadBorderBottomNone rowSpan={2}>
                金額
              </MuiTableCellRootAndHeadBorderBottomNone>
            </TableRow>
            <TableRow>
              <MuiTableCellRootAndHeadBorderBottomNone>
                金額
              </MuiTableCellRootAndHeadBorderBottomNone>
              <MuiTableCellRootAndHead rowSpan={2}>
                区間
                <br />
                発ポイント　〜　着ポイント
              </MuiTableCellRootAndHead>
              <MuiTableCellRootAndHeadBorderBottomNone>
                実車
                <br />
                距離
              </MuiTableCellRootAndHeadBorderBottomNone>
              <MuiTableCellRootAndHeadBorderBottomNone>
                距離
                <br />
                単価
              </MuiTableCellRootAndHeadBorderBottomNone>
              <MuiTableCellRootAndHeadBorderBottomNone>
                金額
              </MuiTableCellRootAndHeadBorderBottomNone>
              <MuiTableCellRootAndHead rowSpan={2}>
                &nbsp;
              </MuiTableCellRootAndHead>
              <MuiTableCellRootAndHeadBorderBottomNone>
                金額
              </MuiTableCellRootAndHeadBorderBottomNone>
            </TableRow>
            <TableRow>
              <MuiTableCellRootAndHeadA2H>A</MuiTableCellRootAndHeadA2H>
              <MuiTableCellRootAndHeadA2H>B</MuiTableCellRootAndHeadA2H>
              <MuiTableCellRootAndHeadA2H>C</MuiTableCellRootAndHeadA2H>
              <MuiTableCellRootAndHeadA2H>B ✕ C = D</MuiTableCellRootAndHeadA2H>
              <MuiTableCellRootAndHeadA2H>E</MuiTableCellRootAndHeadA2H>
              <MuiTableCellRootAndHeadA2H>
                A + D + E = F
              </MuiTableCellRootAndHeadA2H>
              <MuiTableCellRootAndHeadA2H>G</MuiTableCellRootAndHeadA2H>
              <MuiTableCellRootAndHeadA2H>F - G = H</MuiTableCellRootAndHeadA2H>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayData.map((data: any, key: number) => {
              if (data.existCarResult || data.existChargedOperation) {
                return (
                  <TableRow
                    style={key % 2 === 0 ? {} : { backgroundColor: "#eeefef" }}
                    key={key}
                  >
                    <MuiTableCellRootAndBody>
                      {data.dayString}
                    </MuiTableCellRootAndBody>
                    <MuiTableCellRootAndBody>
                      {data.carName}
                    </MuiTableCellRootAndBody>
                    <MuiTableCellRootAndBody>
                      {isAdmin ? data.fixExpense : "-"}
                    </MuiTableCellRootAndBody>
                    <MuiTableCellRootAndBody>
                      {data.drivedTerm ? (
                        <span>
                          {data.drivedTerm.onPosition.name} ~{" "}
                          {data.drivedTerm.offPosition.name}
                        </span>
                      ) : (
                        ""
                      )}
                    </MuiTableCellRootAndBody>
                    <MuiTableCellRootAndBody>
                      {data.distance}
                    </MuiTableCellRootAndBody>
                    <MuiTableCellRootAndBody>
                      {isAdmin ? data.distanceUnitPrice : "-"}
                    </MuiTableCellRootAndBody>
                    <MuiTableCellRootAndBody>
                      {isAdmin ? data.distancePrice : "-"}
                    </MuiTableCellRootAndBody>
                    <MuiTableCellRootAndBody></MuiTableCellRootAndBody>
                    <MuiTableCellRootAndBody>
                      {isAdmin ? data.otherExpense : "-"}
                    </MuiTableCellRootAndBody>
                    <MuiTableCellRootAndBody>
                      {isAdmin ? data.subTotalExpense : "-"}
                    </MuiTableCellRootAndBody>
                    <MuiTableCellRootAndBody>
                      {data.totalNumber}
                    </MuiTableCellRootAndBody>
                    <MuiTableCellRootAndBody>
                      {data.totalPrice}
                    </MuiTableCellRootAndBody>
                    <MuiTableCellRootAndBody>
                      {isAdmin ? data.totalPayment : "-"}
                    </MuiTableCellRootAndBody>
                  </TableRow>
                );
              } else {
                return (
                  <TableRow
                    style={key % 2 === 0 ? {} : { backgroundColor: "#eeefef" }}
                    key={key}
                  >
                    <MuiTableCellRootAndBody>
                      {data.dayString}
                    </MuiTableCellRootAndBody>
                    <MuiTableCellRootAndBody>
                      {data.carName}
                    </MuiTableCellRootAndBody>
                    <MuiTableCellRootAndBody>
                      {data.fixExpense}
                    </MuiTableCellRootAndBody>
                    <MuiTableCellRootAndBody></MuiTableCellRootAndBody>
                    <MuiTableCellRootAndBody></MuiTableCellRootAndBody>
                    <MuiTableCellRootAndBody></MuiTableCellRootAndBody>
                    <MuiTableCellRootAndBody></MuiTableCellRootAndBody>
                    <MuiTableCellRootAndBody></MuiTableCellRootAndBody>
                    <MuiTableCellRootAndBody></MuiTableCellRootAndBody>
                    <MuiTableCellRootAndBody></MuiTableCellRootAndBody>
                    <MuiTableCellRootAndBody></MuiTableCellRootAndBody>
                    <MuiTableCellRootAndBody></MuiTableCellRootAndBody>
                    <MuiTableCellRootAndBody></MuiTableCellRootAndBody>
                  </TableRow>
                );
              }
            })}
            <MuiTableRowBottom>
              <MuiTableCellRootAndBody>合計</MuiTableCellRootAndBody>
              <MuiTableCellRootAndBody></MuiTableCellRootAndBody>
              <MuiTableCellRootAndBody>
                {isAdmin ? summary.summaryFixExpense : "-"}
              </MuiTableCellRootAndBody>
              <MuiTableCellRootAndBody></MuiTableCellRootAndBody>
              <MuiTableCellRootAndBody>
                {summary.summaryDistance}
              </MuiTableCellRootAndBody>
              <MuiTableCellRootAndBody></MuiTableCellRootAndBody>
              <MuiTableCellRootAndBody>
                {isAdmin ? summary.summaryDistancePrice : "-"}
              </MuiTableCellRootAndBody>
              <MuiTableCellRootAndBody></MuiTableCellRootAndBody>
              <MuiTableCellRootAndBody></MuiTableCellRootAndBody>
              <MuiTableCellRootAndBody>
                {isAdmin ? summary.summarySubTotalExpense : "-"}
              </MuiTableCellRootAndBody>
              <MuiTableCellRootAndBody>
                {summary.summaryTotalNumber}
              </MuiTableCellRootAndBody>
              <MuiTableCellRootAndBody>
                {summary.summaryTotalPrice}
              </MuiTableCellRootAndBody>
              <MuiTableCellRootAndBody>
                {isAdmin ? summary.summaryTotalPayment : "-"}
              </MuiTableCellRootAndBody>
            </MuiTableRowBottom>
          </TableBody>
        </MuiTableRoot>
        <button
          onClick={(e: any) => {
            handleDownload();
          }}
        >
          DOWNLOAD
        </button>
      </Grid>
    </Grid>
  );
};

export default MonthyLog;
