export const ways = [
  [
    { lng: 133.00051, lat: 32.89536 }, // 立石
    { lng: 133.00051, lat: 32.89534 },
    { lng: 133.0005, lat: 32.89532 },
    { lng: 133.00049, lat: 32.89528 },
    { lng: 133.00047, lat: 32.89512 },
    { lng: 133.00047, lat: 32.89501 },
    { lng: 133.00045, lat: 32.89481 },
    { lng: 133.00045, lat: 32.89469 },
    { lng: 133.00051, lat: 32.8946 },
    { lng: 133.00056, lat: 32.89457 },
    { lng: 133.00065, lat: 32.89453 },
    { lng: 133.0011, lat: 32.89441 },
    { lng: 133.00112, lat: 32.89441 },
    { lng: 133.00125, lat: 32.89437 },
    { lng: 133.00142, lat: 32.89434 },
    { lng: 133.00148, lat: 32.89434 },
    { lng: 133.00155, lat: 32.89435 },
    { lng: 133.00167, lat: 32.89438 },
    { lng: 133.00192, lat: 32.89451 },
    { lng: 133.00195, lat: 32.89452 },
    { lng: 133.0021, lat: 32.8946 },
    { lng: 133.00228, lat: 32.89468 },
    { lng: 133.00229, lat: 32.89468 },
    { lng: 133.00242, lat: 32.89472 },
    { lng: 133.00247, lat: 32.89472 },
    { lng: 133.0025, lat: 32.89473 },
    { lng: 133.00264, lat: 32.89473 },
    { lng: 133.00268, lat: 32.89474 },
    { lng: 133.00271, lat: 32.89474 },
    { lng: 133.00272, lat: 32.89475 },
    { lng: 133.00277, lat: 32.89476 },
    { lng: 133.00289, lat: 32.89482 },
    { lng: 133.0029, lat: 32.89483 },
    { lng: 133.00296, lat: 32.89486 },
    { lng: 133.00322, lat: 32.89506 },
    { lng: 133.00334, lat: 32.89512 },
    { lng: 133.0035, lat: 32.89516 },
    { lng: 133.00362, lat: 32.89516 },
    { lng: 133.00375, lat: 32.89514 },
    { lng: 133.00385, lat: 32.8951 },
    { lng: 133.0039, lat: 32.89506 },
    { lng: 133.00402, lat: 32.89492 },
    { lng: 133.00454, lat: 32.8944 },
    { lng: 133.00466, lat: 32.89431 },
    { lng: 133.00477, lat: 32.89419 },
    { lng: 133.00483, lat: 32.89409 },
    { lng: 133.00497, lat: 32.89379 },
    { lng: 133.00497, lat: 32.89375 },
    { lng: 133.00498, lat: 32.89372 },
    { lng: 133.00498, lat: 32.89367 },
    { lng: 133.00495, lat: 32.8936 },
    { lng: 133.00491, lat: 32.89354 },
    { lng: 133.00485, lat: 32.8935 },
    { lng: 133.00483, lat: 32.89347 },
    { lng: 133.00472, lat: 32.89338 },
    { lng: 133.00467, lat: 32.89333 },
    { lng: 133.0046, lat: 32.89329 },
    { lng: 133.00456, lat: 32.89328 },
    { lng: 133.00451, lat: 32.89328 },
    { lng: 133.00422, lat: 32.89331 },
    { lng: 133.00389, lat: 32.89338 },
    { lng: 133.00371, lat: 32.89346 },
    { lng: 133.0037, lat: 32.89346 },
    { lng: 133.00363, lat: 32.89349 },
    { lng: 133.00358, lat: 32.8935 },
    { lng: 133.0035, lat: 32.8935 },
    { lng: 133.00306, lat: 32.89342 },
    { lng: 133.0029, lat: 32.89341 },
    { lng: 133.00287, lat: 32.89342 },
    { lng: 133.0024, lat: 32.89351 },
    { lng: 133.00235, lat: 32.89351 },
    { lng: 133.00225, lat: 32.89348 },
    { lng: 133.00151, lat: 32.89313 },
    { lng: 133.00126, lat: 32.89305 },
    { lng: 133.00109, lat: 32.89301 },
    { lng: 133.00108, lat: 32.893 },
    { lng: 133.00095, lat: 32.89298 },
    { lng: 133.0009, lat: 32.89296 },
    { lng: 133.00089, lat: 32.89296 },
    { lng: 133.00084, lat: 32.89293 },
    { lng: 133.00083, lat: 32.89292 },
    { lng: 133.0008, lat: 32.89284 },
    { lng: 133.0008, lat: 32.89282 },
    { lng: 133.00081, lat: 32.89277 },
    { lng: 133.00086, lat: 32.89263 },
    { lng: 133.00086, lat: 32.89262 },
    { lng: 133.0008, lat: 32.8924 },
    { lng: 133.00057, lat: 32.89193 },
    { lng: 133.00057, lat: 32.89192 },
    { lng: 133.00052, lat: 32.89184 },
    { lng: 133.00041, lat: 32.89171 },
    { lng: 132.99978, lat: 32.89117 },
    { lng: 132.99975, lat: 32.89113 },
    { lng: 132.99974, lat: 32.8911 },
    { lng: 132.9997, lat: 32.89103 },
    { lng: 132.99968, lat: 32.89095 },
    { lng: 132.99967, lat: 32.89093 },
    { lng: 132.99963, lat: 32.89075 },
    { lng: 132.99963, lat: 32.89061 },
    { lng: 132.99971, lat: 32.89041 },
    { lng: 132.9999, lat: 32.89004 },
    { lng: 132.99992, lat: 32.88995 },
    { lng: 132.99992, lat: 32.88984 },
    { lng: 132.99991, lat: 32.8898 },
    { lng: 132.99988, lat: 32.88973 },
    { lng: 132.99985, lat: 32.8897 },
    { lng: 132.99979, lat: 32.88966 },
    { lng: 132.9996, lat: 32.8896 },
    { lng: 132.99952, lat: 32.88955 },
    { lng: 132.99948, lat: 32.8895 },
    { lng: 132.99945, lat: 32.88943 },
    { lng: 132.99944, lat: 32.88932 },
    { lng: 132.99942, lat: 32.88923 },
    { lng: 132.99942, lat: 32.8892 },
    { lng: 132.99943, lat: 32.88918 },
    { lng: 132.99943, lat: 32.88913 },
    { lng: 132.99948, lat: 32.88904 },
    { lng: 132.99951, lat: 32.88901 },
    { lng: 132.99962, lat: 32.88894 },
    { lng: 132.99976, lat: 32.88894 },
    { lng: 132.99988, lat: 32.88896 },
    { lng: 133, lat: 32.88896 },
    { lng: 133.00004, lat: 32.88894 },
    { lng: 133.00005, lat: 32.88894 },
    { lng: 133.00007, lat: 32.88892 },
    { lng: 133.00007, lat: 32.88891 },
    { lng: 133.00008, lat: 32.8889 },
    { lng: 133.00009, lat: 32.88888 },
    { lng: 133.0001, lat: 32.88887 },
    { lng: 133.0001, lat: 32.88879 },
    { lng: 133.00009, lat: 32.88878 },
    { lng: 133.00009, lat: 32.88876 },
    { lng: 133.00007, lat: 32.88875 },
    { lng: 133.00006, lat: 32.88873 },
    { lng: 132.99992, lat: 32.88864 },
    { lng: 132.99986, lat: 32.88861 },
    { lng: 132.99974, lat: 32.88852 },
    { lng: 132.9997, lat: 32.88848 },
    { lng: 132.99966, lat: 32.88839 },
    { lng: 132.99965, lat: 32.88834 },
    { lng: 132.99965, lat: 32.88825 },
    { lng: 132.99969, lat: 32.88809 },
    { lng: 132.9997, lat: 32.88799 },
    { lng: 132.99966, lat: 32.88792 },
    { lng: 132.99958, lat: 32.88786 },
    { lng: 132.99955, lat: 32.88785 },
    { lng: 132.99949, lat: 32.88784 },
    { lng: 132.99944, lat: 32.88784 },
    { lng: 132.99937, lat: 32.88785 },
    { lng: 132.99936, lat: 32.88786 },
    { lng: 132.99933, lat: 32.88787 },
    { lng: 132.99911, lat: 32.88814 },
    { lng: 132.99908, lat: 32.88816 },
    { lng: 132.999, lat: 32.88819 },
    { lng: 132.99897, lat: 32.88819 },
    { lng: 132.99894, lat: 32.88818 },
    { lng: 132.99891, lat: 32.88818 },
    { lng: 132.99889, lat: 32.88817 },
    { lng: 132.99887, lat: 32.88815 },
    { lng: 132.99884, lat: 32.88813 },
    { lng: 132.99884, lat: 32.88811 },
    { lng: 132.99883, lat: 32.88808 },
    { lng: 132.99886, lat: 32.88796 },
    { lng: 132.99896, lat: 32.88776 },
    { lng: 132.99899, lat: 32.88766 },
    { lng: 132.99904, lat: 32.88759 },
    { lng: 132.9991, lat: 32.88755 },
    { lng: 132.99912, lat: 32.88753 },
    { lng: 132.99928, lat: 32.88746 },
    { lng: 132.9994, lat: 32.88745 },
    { lng: 132.99953, lat: 32.88746 },
    { lng: 132.99992, lat: 32.88753 },
    { lng: 133.00005, lat: 32.88754 },
    { lng: 133.00013, lat: 32.88752 },
    { lng: 133.00016, lat: 32.88752 },
    { lng: 133.00021, lat: 32.8875 },
    { lng: 133.00029, lat: 32.88744 },
    { lng: 133.0003, lat: 32.88742 },
    { lng: 133.00032, lat: 32.8874 },
    { lng: 133.00039, lat: 32.88726 },
    { lng: 133.0004, lat: 32.88723 },
    { lng: 133.00053, lat: 32.88698 },
    { lng: 133.00058, lat: 32.88694 },
    { lng: 133.00067, lat: 32.8869 },
    { lng: 133.00081, lat: 32.88686 },
    { lng: 133.00119, lat: 32.88679 },
    { lng: 133.00121, lat: 32.88678 },
    { lng: 133.00123, lat: 32.88676 },
    { lng: 133.00126, lat: 32.88675 },
    { lng: 133.0013, lat: 32.88669 },
    { lng: 133.00131, lat: 32.88664 },
    { lng: 133.00127, lat: 32.88624 },
    { lng: 133.00128, lat: 32.88596 },
    { lng: 133.00124, lat: 32.88589 },
    { lng: 133.00118, lat: 32.88581 },
    { lng: 133.00115, lat: 32.88578 },
    { lng: 133.00104, lat: 32.88562 },
    { lng: 133.00101, lat: 32.88559 },
    { lng: 133.0009, lat: 32.88539 },
    { lng: 133.0009, lat: 32.88535 },
    { lng: 133.00092, lat: 32.88529 },
    { lng: 133.00094, lat: 32.88526 },
    { lng: 133.00097, lat: 32.88525 },
    { lng: 133.00102, lat: 32.88522 },
    { lng: 133.00126, lat: 32.88513 },
    { lng: 133.00147, lat: 32.88503 },
    { lng: 133.0017, lat: 32.88494 },
    { lng: 133.00219, lat: 32.8847 },
    { lng: 133.00226, lat: 32.88465 },
    { lng: 133.00231, lat: 32.8846 },
    { lng: 133.00235, lat: 32.88454 },
    { lng: 133.00237, lat: 32.88448 },
    { lng: 133.00238, lat: 32.88442 },
    { lng: 133.00238, lat: 32.88435 },
    { lng: 133.00237, lat: 32.88429 },
    { lng: 133.00233, lat: 32.88418 },
    { lng: 133.00225, lat: 32.88409 },
    { lng: 133.00217, lat: 32.88403 },
    { lng: 133.00214, lat: 32.88402 },
    { lng: 133.00208, lat: 32.88399 },
    { lng: 133.00189, lat: 32.88393 },
    { lng: 133.00182, lat: 32.88389 },
    { lng: 133.00161, lat: 32.88374 },
    { lng: 133.00156, lat: 32.88368 },
    { lng: 133.00151, lat: 32.88364 },
    { lng: 133.00151, lat: 32.88363 },
    { lng: 133.00147, lat: 32.88358 },
    { lng: 133.00143, lat: 32.88351 },
    { lng: 133.00138, lat: 32.88337 },
    { lng: 133.00137, lat: 32.88332 },
    { lng: 133.00137, lat: 32.88326 },
    { lng: 133.00138, lat: 32.88321 },
    { lng: 133.00138, lat: 32.88305 },
    { lng: 133.00136, lat: 32.88303 },
    { lng: 133.00135, lat: 32.88301 },
    { lng: 133.00133, lat: 32.88299 },
    { lng: 133.00113, lat: 32.88288 },
    { lng: 133.00112, lat: 32.88288 },
    { lng: 133.00108, lat: 32.88286 },
    { lng: 133.00098, lat: 32.88279 },
    { lng: 133.00097, lat: 32.88277 },
    { lng: 133.00094, lat: 32.88266 },
    { lng: 133.0009, lat: 32.88214 },
    { lng: 133.00087, lat: 32.88208 },
    { lng: 133.00079, lat: 32.88196 },
    { lng: 133.00074, lat: 32.8819 },
    { lng: 133.0007, lat: 32.88182 },
    { lng: 133.00068, lat: 32.88176 },
    { lng: 133.00066, lat: 32.88172 },
    { lng: 133.00065, lat: 32.88146 },
    { lng: 133.00066, lat: 32.88138 },
    { lng: 133.00067, lat: 32.88137 },
    { lng: 133.00067, lat: 32.88133 },
    { lng: 133.00069, lat: 32.88128 },
    { lng: 133.00076, lat: 32.88121 },
    { lng: 133.00086, lat: 32.88115 },
    { lng: 133.00129, lat: 32.88101 },
    { lng: 133.00141, lat: 32.88094 },
    { lng: 133.00145, lat: 32.88091 },
    { lng: 133.00163, lat: 32.88071 },
    { lng: 133.00169, lat: 32.88062 },
    { lng: 133.00176, lat: 32.88054 },
    { lng: 133.00186, lat: 32.88039 },
    { lng: 133.0019, lat: 32.8803 },
    { lng: 133.00193, lat: 32.88025 },
    { lng: 133.00193, lat: 32.88024 },
    { lng: 133.00195, lat: 32.8802 },
    { lng: 133.00196, lat: 32.88016 },
    { lng: 133.00196, lat: 32.88006 },
    { lng: 133.00193, lat: 32.87998 },
    { lng: 133.00188, lat: 32.87993 },
    { lng: 133.00136, lat: 32.87963 },
    { lng: 133.00132, lat: 32.87962 },
    { lng: 133.00124, lat: 32.87962 },
    { lng: 133.0012, lat: 32.87963 },
    { lng: 133.00112, lat: 32.87968 },
    { lng: 133.00106, lat: 32.87973 },
    { lng: 133.00101, lat: 32.87979 },
    { lng: 133.00097, lat: 32.87982 },
    { lng: 133.00094, lat: 32.87985 },
    { lng: 133.00092, lat: 32.87985 },
    { lng: 133.0009, lat: 32.87987 },
    { lng: 133.00075, lat: 32.8799 },
    { lng: 133.00028, lat: 32.87996 },
    { lng: 133.00021, lat: 32.87996 },
    { lng: 133.00014, lat: 32.87995 },
    { lng: 132.9999, lat: 32.87987 },
    { lng: 132.99988, lat: 32.87985 },
    { lng: 132.99982, lat: 32.87976 },
    { lng: 132.99981, lat: 32.87972 },
    { lng: 132.99984, lat: 32.87965 },
    { lng: 132.99986, lat: 32.87963 },
    { lng: 132.9999, lat: 32.87961 },
    { lng: 132.99991, lat: 32.87961 },
    { lng: 132.99997, lat: 32.87959 },
    { lng: 133.00011, lat: 32.87959 },
    { lng: 133.00016, lat: 32.87958 },
    { lng: 133.00021, lat: 32.87958 },
    { lng: 133.00026, lat: 32.87957 },
    { lng: 133.0003, lat: 32.87955 },
    { lng: 133.00031, lat: 32.87954 },
    { lng: 133.00033, lat: 32.87953 },
    { lng: 133.00047, lat: 32.8794 },
    { lng: 133.00054, lat: 32.87931 },
    { lng: 133.00074, lat: 32.8791 },
    { lng: 133.00081, lat: 32.87901 },
    { lng: 133.00083, lat: 32.87897 },
    { lng: 133.00083, lat: 32.87896 },
    { lng: 133.00085, lat: 32.87891 },
    { lng: 133.00085, lat: 32.87878 },
    { lng: 133.00081, lat: 32.87864 },
    { lng: 133.0008, lat: 32.87862 },
    { lng: 133.00079, lat: 32.87857 },
    { lng: 133.00073, lat: 32.87849 },
    { lng: 133.00061, lat: 32.87836 },
    { lng: 133.00058, lat: 32.87831 },
    { lng: 132.99995, lat: 32.87761 },
    { lng: 132.99994, lat: 32.87759 },
    { lng: 132.99993, lat: 32.87759 },
    { lng: 132.99991, lat: 32.87757 },
    { lng: 132.99986, lat: 32.87755 },
    { lng: 132.99969, lat: 32.87752 },
    { lng: 132.99953, lat: 32.87751 },
    { lng: 132.99947, lat: 32.8775 },
    { lng: 132.99943, lat: 32.8775 },
    { lng: 132.9994, lat: 32.87751 },
    { lng: 132.99937, lat: 32.87751 },
    { lng: 132.99933, lat: 32.87753 },
    { lng: 132.99932, lat: 32.87753 },
    { lng: 132.99916, lat: 32.87765 },
    { lng: 132.99909, lat: 32.87769 },
    { lng: 132.999, lat: 32.87772 },
    { lng: 132.99895, lat: 32.87772 },
    { lng: 132.99889, lat: 32.87771 },
    { lng: 132.99886, lat: 32.8777 },
    { lng: 132.99882, lat: 32.87767 },
    { lng: 132.99878, lat: 32.87765 },
    { lng: 132.99877, lat: 32.87764 },
    { lng: 132.99836, lat: 32.87736 },
    { lng: 132.99833, lat: 32.87735 },
    { lng: 132.9983, lat: 32.87733 },
    { lng: 132.99827, lat: 32.87729 },
    { lng: 132.99824, lat: 32.87726 },
    { lng: 132.99824, lat: 32.87723 },
    { lng: 132.99823, lat: 32.87721 },
    { lng: 132.99824, lat: 32.8772 },
    { lng: 132.99824, lat: 32.87716 },
    { lng: 132.99825, lat: 32.87714 },
    { lng: 132.99829, lat: 32.87711 },
    { lng: 132.99835, lat: 32.87709 },
    { lng: 132.99838, lat: 32.87707 },
    { lng: 132.99851, lat: 32.87701 },
    { lng: 132.99853, lat: 32.87699 },
    { lng: 132.99856, lat: 32.87697 },
    { lng: 132.99858, lat: 32.87695 },
    { lng: 132.9986, lat: 32.87692 },
    { lng: 132.99862, lat: 32.87685 },
    { lng: 132.99862, lat: 32.87676 },
    { lng: 132.99859, lat: 32.87666 },
    { lng: 132.99856, lat: 32.8766 },
    { lng: 132.99852, lat: 32.87649 },
    { lng: 132.99846, lat: 32.87636 },
    { lng: 132.99822, lat: 32.87602 },
    { lng: 132.99815, lat: 32.87594 },
    { lng: 132.99813, lat: 32.87593 },
    { lng: 132.99811, lat: 32.87591 },
    { lng: 132.99808, lat: 32.8759 },
    { lng: 132.99807, lat: 32.87589 },
    { lng: 132.99786, lat: 32.87577 },
    { lng: 132.99772, lat: 32.87571 },
    { lng: 132.99765, lat: 32.87569 },
    { lng: 132.99756, lat: 32.87563 },
    { lng: 132.99752, lat: 32.87561 },
    { lng: 132.99751, lat: 32.8756 },
    { lng: 132.99742, lat: 32.87556 },
    { lng: 132.99722, lat: 32.8755 },
    { lng: 132.99717, lat: 32.87546 },
    { lng: 132.99716, lat: 32.87544 },
    { lng: 132.99715, lat: 32.87543 },
    { lng: 132.99712, lat: 32.87536 },
    { lng: 132.99712, lat: 32.87527 },
    { lng: 132.99713, lat: 32.87527 },
    { lng: 132.99715, lat: 32.87522 },
    { lng: 132.99718, lat: 32.8752 },
    { lng: 132.99721, lat: 32.87517 },
    { lng: 132.99737, lat: 32.87513 },
    { lng: 132.99772, lat: 32.87508 },
    { lng: 132.99777, lat: 32.87506 },
    { lng: 132.9978, lat: 32.87504 },
    { lng: 132.99782, lat: 32.87502 },
    { lng: 132.99785, lat: 32.87497 },
    { lng: 132.99785, lat: 32.87495 },
    { lng: 132.99786, lat: 32.87493 },
    { lng: 132.99784, lat: 32.87486 },
    { lng: 132.99779, lat: 32.87478 },
    { lng: 132.99754, lat: 32.87446 },
    { lng: 132.99746, lat: 32.87434 },
    { lng: 132.99719, lat: 32.87399 },
    { lng: 132.99711, lat: 32.87387 },
    { lng: 132.99684, lat: 32.87352 },
    { lng: 132.99675, lat: 32.87338 },
    { lng: 132.99652, lat: 32.87307 },
    { lng: 132.99621, lat: 32.87275 },
    { lng: 132.9953, lat: 32.87188 },
    { lng: 132.99518, lat: 32.87183 },
    { lng: 132.99511, lat: 32.87182 },
    { lng: 132.99481, lat: 32.87181 },
    { lng: 132.99456, lat: 32.87185 },
    { lng: 132.99454, lat: 32.87186 },
    { lng: 132.99452, lat: 32.87186 },
    { lng: 132.99446, lat: 32.87188 },
    { lng: 132.99421, lat: 32.87199 },
    { lng: 132.99415, lat: 32.872 },
    { lng: 132.99409, lat: 32.87202 },
    { lng: 132.99404, lat: 32.87203 },
    { lng: 132.99395, lat: 32.87203 },
    { lng: 132.99383, lat: 32.87198 },
    { lng: 132.99372, lat: 32.87187 },
    { lng: 132.9937, lat: 32.87183 },
    { lng: 132.99369, lat: 32.87179 },
    { lng: 132.99369, lat: 32.87158 },
    { lng: 132.9937, lat: 32.87147 },
    { lng: 132.99367, lat: 32.87116 },
    { lng: 132.99363, lat: 32.8711 },
    { lng: 132.99358, lat: 32.87107 },
    { lng: 132.99358, lat: 32.87106 },
    { lng: 132.99305, lat: 32.8708 },
    { lng: 132.99282, lat: 32.87065 },
    { lng: 132.99254, lat: 32.87044 },
    { lng: 132.99252, lat: 32.87043 },
    { lng: 132.99242, lat: 32.87035 },
    { lng: 132.99232, lat: 32.87029 },
    { lng: 132.99218, lat: 32.87025 },
    { lng: 132.99184, lat: 32.8702 },
    { lng: 132.99178, lat: 32.87018 },
    { lng: 132.99172, lat: 32.87015 },
    { lng: 132.99074, lat: 32.86946 },
    { lng: 132.99071, lat: 32.86942 },
    { lng: 132.9906, lat: 32.86916 },
    { lng: 132.99057, lat: 32.86906 },
    { lng: 132.99057, lat: 32.86898 },
    { lng: 132.99058, lat: 32.86892 },
    { lng: 132.99062, lat: 32.86882 },
    { lng: 132.99071, lat: 32.8687 },
    { lng: 132.99075, lat: 32.86867 },
    { lng: 132.99081, lat: 32.86856 },
    { lng: 132.99083, lat: 32.8685 },
    { lng: 132.99077, lat: 32.86675 },
    { lng: 132.99075, lat: 32.86664 },
    { lng: 132.99069, lat: 32.86658 },
    { lng: 132.99061, lat: 32.86653 },
    { lng: 132.99045, lat: 32.86646 },
    { lng: 132.99037, lat: 32.86644 },
    { lng: 132.99033, lat: 32.86644 },
    { lng: 132.99029, lat: 32.86645 },
    { lng: 132.99021, lat: 32.8665 },
    { lng: 132.9902, lat: 32.86651 },
    { lng: 132.99004, lat: 32.86683 },
    { lng: 132.98999, lat: 32.86688 },
    { lng: 132.98995, lat: 32.8669 },
    { lng: 132.98966, lat: 32.86697 },
    { lng: 132.9895, lat: 32.86703 },
    { lng: 132.98921, lat: 32.8673 },
    { lng: 132.98919, lat: 32.86733 },
    { lng: 132.98915, lat: 32.86737 },
    { lng: 132.98912, lat: 32.86743 },
    { lng: 132.98911, lat: 32.86749 },
    { lng: 132.98912, lat: 32.86759 },
    { lng: 132.98915, lat: 32.8677 },
    { lng: 132.98919, lat: 32.86777 },
    { lng: 132.98927, lat: 32.86795 },
    { lng: 132.98927, lat: 32.86797 },
    { lng: 132.98928, lat: 32.868 },
    { lng: 132.98927, lat: 32.86801 },
    { lng: 132.98927, lat: 32.86802 },
    { lng: 132.98926, lat: 32.86804 },
    { lng: 132.98922, lat: 32.86808 },
    { lng: 132.98917, lat: 32.8681 },
    { lng: 132.9891, lat: 32.8681 },
    { lng: 132.98908, lat: 32.86809 },
    { lng: 132.98901, lat: 32.86804 },
    { lng: 132.98896, lat: 32.86797 },
    { lng: 132.98896, lat: 32.86796 },
    { lng: 132.98869, lat: 32.86753 },
    { lng: 132.98867, lat: 32.86748 },
    { lng: 132.98863, lat: 32.86727 },
    { lng: 132.98859, lat: 32.86717 },
    { lng: 132.98855, lat: 32.86713 },
    { lng: 132.98849, lat: 32.86711 },
    { lng: 132.98844, lat: 32.86711 },
    { lng: 132.98825, lat: 32.86714 },
    { lng: 132.98804, lat: 32.86719 },
    { lng: 132.98792, lat: 32.86723 },
    { lng: 132.98788, lat: 32.86725 },
    { lng: 132.98766, lat: 32.86743 },
    { lng: 132.98764, lat: 32.86746 },
    { lng: 132.98736, lat: 32.86773 },
    { lng: 132.9873, lat: 32.8678 },
    { lng: 132.98698, lat: 32.86831 },
    { lng: 132.98677, lat: 32.86855 },
    { lng: 132.98625, lat: 32.86821 },
    { lng: 132.9861, lat: 32.86801 },
    { lng: 132.98608, lat: 32.86799 },
    { lng: 132.98605, lat: 32.86798 },
    { lng: 132.98602, lat: 32.86796 },
    { lng: 132.98593, lat: 32.86796 },
    { lng: 132.98576, lat: 32.86798 },
    { lng: 132.98573, lat: 32.86799 },
  ],
  [
    { lng: 132.98555, lat: 32.86802 }, // 布
    { lng: 132.98544, lat: 32.86802 },
    { lng: 132.98535, lat: 32.86799 },
    { lng: 132.98496, lat: 32.86797 },
    { lng: 132.98483, lat: 32.86795 },
    { lng: 132.98441, lat: 32.86784 },
    { lng: 132.98419, lat: 32.86775 },
    { lng: 132.98316, lat: 32.86714 },
    { lng: 132.98309, lat: 32.86711 },
    { lng: 132.98291, lat: 32.86706 },
    { lng: 132.98243, lat: 32.86701 },
    { lng: 132.98234, lat: 32.86696 },
    { lng: 132.9823, lat: 32.86692 },
    { lng: 132.98228, lat: 32.86689 },
    { lng: 132.98227, lat: 32.86684 },
    { lng: 132.98228, lat: 32.8668 },
    { lng: 132.98228, lat: 32.86677 },
    { lng: 132.98229, lat: 32.86673 },
    { lng: 132.98234, lat: 32.86666 },
    { lng: 132.98239, lat: 32.86662 },
    { lng: 132.98249, lat: 32.86658 },
    { lng: 132.98283, lat: 32.86651 },
    { lng: 132.98293, lat: 32.86647 },
    { lng: 132.98298, lat: 32.86644 },
    { lng: 132.98304, lat: 32.86638 },
    { lng: 132.98306, lat: 32.86635 },
    { lng: 132.98312, lat: 32.86618 },
    { lng: 132.98316, lat: 32.86598 },
    { lng: 132.98317, lat: 32.86589 },
    { lng: 132.98316, lat: 32.86584 },
    { lng: 132.98313, lat: 32.8658 },
    { lng: 132.98311, lat: 32.86576 },
    { lng: 132.98296, lat: 32.86565 },
    { lng: 132.98265, lat: 32.86547 },
    { lng: 132.98251, lat: 32.86542 },
    { lng: 132.98236, lat: 32.86538 },
    { lng: 132.98203, lat: 32.86535 },
    { lng: 132.98053, lat: 32.8653 },
    { lng: 132.9804, lat: 32.86532 },
    { lng: 132.9803, lat: 32.86536 },
    { lng: 132.97999, lat: 32.86555 },
    { lng: 132.97992, lat: 32.86558 },
    { lng: 132.97986, lat: 32.86559 },
    { lng: 132.97977, lat: 32.86559 },
    { lng: 132.97967, lat: 32.86557 },
    { lng: 132.97907, lat: 32.86536 },
    { lng: 132.97902, lat: 32.86536 },
    { lng: 132.97896, lat: 32.86535 },
    { lng: 132.97675, lat: 32.86535 },
    { lng: 132.97663, lat: 32.86536 },
    { lng: 132.97645, lat: 32.86539 },
    { lng: 132.97488, lat: 32.86583 },
    { lng: 132.97332, lat: 32.86612 },
    { lng: 132.97323, lat: 32.86613 },
    { lng: 132.9732, lat: 32.86614 },
    { lng: 132.97301, lat: 32.86614 },
    { lng: 132.97273, lat: 32.86616 },
    { lng: 132.97257, lat: 32.86616 },
    { lng: 132.97213, lat: 32.86609 },
    { lng: 132.97195, lat: 32.86605 },
    { lng: 132.97169, lat: 32.86603 },
    { lng: 132.97132, lat: 32.86607 },
    { lng: 132.97127, lat: 32.86607 },
    { lng: 132.97111, lat: 32.86609 },
    { lng: 132.97107, lat: 32.86609 },
    { lng: 132.97042, lat: 32.86616 },
    { lng: 132.97015, lat: 32.86614 },
    { lng: 132.96931, lat: 32.86602 },
    { lng: 132.96921, lat: 32.86602 },
    { lng: 132.96913, lat: 32.86601 },
    { lng: 132.96905, lat: 32.86601 },
    { lng: 132.96871, lat: 32.86604 },
    { lng: 132.9686, lat: 32.86606 },
    { lng: 132.9664, lat: 32.86609 },
    { lng: 132.96623, lat: 32.86607 },
    { lng: 132.96607, lat: 32.86603 },
    { lng: 132.96584, lat: 32.86599 },
    { lng: 132.96545, lat: 32.8659 },
    { lng: 132.96537, lat: 32.86589 },
    { lng: 132.96523, lat: 32.86585 },
    { lng: 132.9647, lat: 32.86574 },
    { lng: 132.96385, lat: 32.86565 },
    { lng: 132.96373, lat: 32.86562 },
    { lng: 132.96348, lat: 32.86551 },
    { lng: 132.96346, lat: 32.86549 },
    { lng: 132.9633, lat: 32.8654 },
    { lng: 132.96328, lat: 32.86538 },
    { lng: 132.96283, lat: 32.86509 },
    { lng: 132.96267, lat: 32.86503 },
    { lng: 132.96243, lat: 32.86498 },
    { lng: 132.96206, lat: 32.86484 },
    { lng: 132.96181, lat: 32.86477 },
    { lng: 132.96176, lat: 32.86477 },
    { lng: 132.96167, lat: 32.86475 },
    { lng: 132.96135, lat: 32.86473 },
    { lng: 132.96128, lat: 32.86474 },
    { lng: 132.96116, lat: 32.86478 },
    { lng: 132.96092, lat: 32.86481 },
    { lng: 132.96073, lat: 32.86478 },
    { lng: 132.96025, lat: 32.86463 },
    { lng: 132.96014, lat: 32.86458 },
    { lng: 132.96007, lat: 32.86452 },
    { lng: 132.95998, lat: 32.86439 },
    { lng: 132.95965, lat: 32.86402 },
    { lng: 132.95915, lat: 32.86337 },
    { lng: 132.95868, lat: 32.86268 },
    { lng: 132.95863, lat: 32.86262 },
    { lng: 132.95853, lat: 32.86253 },
    { lng: 132.95845, lat: 32.86248 },
    { lng: 132.95835, lat: 32.86243 },
    { lng: 132.95824, lat: 32.86241 },
    { lng: 132.95813, lat: 32.8624 },
    { lng: 132.95795, lat: 32.86244 },
    { lng: 132.95787, lat: 32.86249 },
    { lng: 132.95781, lat: 32.86254 },
    { lng: 132.95748, lat: 32.86303 },
    { lng: 132.95745, lat: 32.86306 },
    { lng: 132.95745, lat: 32.86307 },
    { lng: 132.95744, lat: 32.86307 },
    { lng: 132.95742, lat: 32.86308 },
    { lng: 132.95741, lat: 32.86308 },
    { lng: 132.95737, lat: 32.8631 },
    { lng: 132.9574, lat: 32.86316 },
    { lng: 132.95736, lat: 32.86321 },
    { lng: 132.95733, lat: 32.86326 },
    { lng: 132.95729, lat: 32.86331 },
    { lng: 132.95729, lat: 32.86332 },
    { lng: 132.95719, lat: 32.86346 },
    { lng: 132.95715, lat: 32.86354 },
    { lng: 132.95708, lat: 32.86379 },
    { lng: 132.9569, lat: 32.86426 },
    { lng: 132.95681, lat: 32.86442 },
    { lng: 132.95673, lat: 32.86452 },
    { lng: 132.95665, lat: 32.86464 },
    { lng: 132.95645, lat: 32.86486 },
    { lng: 132.95612, lat: 32.86516 },
    { lng: 132.95562, lat: 32.86554 },
    { lng: 132.95548, lat: 32.86563 },
    { lng: 132.95539, lat: 32.8657 },
    { lng: 132.95448, lat: 32.8666 },
  ],
  [
    { lng: 132.95414, lat: 32.86686 }, // 下ノ加江市民センター
    { lng: 132.95316, lat: 32.86741 },
    { lng: 132.95274, lat: 32.86703 },
    { lng: 132.95254, lat: 32.86721 },
    { lng: 132.95248, lat: 32.86724 },
    { lng: 132.95245, lat: 32.86724 },
    { lng: 132.95242, lat: 32.8673 },
    { lng: 132.95239, lat: 32.86734 },
    { lng: 132.95236, lat: 32.86737 },
    { lng: 132.95233, lat: 32.86741 },
    { lng: 132.95227, lat: 32.86745 },
  ],
  [
    { lng: 132.95213, lat: 32.86752 }, // 下浦バス停
    { lng: 132.95227, lat: 32.86745 },
    { lng: 132.95233, lat: 32.86741 },
    { lng: 132.95236, lat: 32.86737 },
    { lng: 132.95239, lat: 32.86734 },
    { lng: 132.95242, lat: 32.8673 },
    { lng: 132.95247, lat: 32.86721 },
    { lng: 132.95251, lat: 32.86703 },
    { lng: 132.9525, lat: 32.86695 },
    { lng: 132.95247, lat: 32.86682 },
    { lng: 132.95236, lat: 32.86667 },
    { lng: 132.95076, lat: 32.8652 },
    { lng: 132.95044, lat: 32.86488 },
    { lng: 132.95035, lat: 32.86477 },
    { lng: 132.95029, lat: 32.86464 },
    { lng: 132.95029, lat: 32.8645 },
    { lng: 132.95038, lat: 32.86407 },
    { lng: 132.95058, lat: 32.86328 },
    { lng: 132.9506, lat: 32.86312 },
    { lng: 132.9506, lat: 32.86299 },
    { lng: 132.95059, lat: 32.86288 },
  ],
  [
    { lng: 132.94998, lat: 32.85948 }, // 鍵掛
    { lng: 132.94993, lat: 32.85926 },
    { lng: 132.94991, lat: 32.8592 },
    { lng: 132.9498, lat: 32.85897 },
    { lng: 132.94972, lat: 32.85884 },
    { lng: 132.94946, lat: 32.85853 },
    { lng: 132.94912, lat: 32.85818 },
    { lng: 132.9491, lat: 32.85817 },
    { lng: 132.94908, lat: 32.85814 },
    { lng: 132.94805, lat: 32.85708 },
    { lng: 132.94801, lat: 32.85703 },
    { lng: 132.94794, lat: 32.85691 },
    { lng: 132.94791, lat: 32.85683 },
    { lng: 132.94789, lat: 32.85669 },
    { lng: 132.94789, lat: 32.85664 },
    { lng: 132.94791, lat: 32.85654 },
    { lng: 132.94791, lat: 32.85651 },
    { lng: 132.94799, lat: 32.85633 },
    { lng: 132.94845, lat: 32.85564 },
    { lng: 132.94853, lat: 32.85555 },
    { lng: 132.94863, lat: 32.85546 },
    { lng: 132.94873, lat: 32.85539 },
    { lng: 132.94951, lat: 32.85507 },
    { lng: 132.9498, lat: 32.85489 },
    { lng: 132.94998, lat: 32.85472 },
    { lng: 132.95079, lat: 32.85374 },
    { lng: 132.95091, lat: 32.85362 },
    { lng: 132.95094, lat: 32.85358 },
    { lng: 132.95104, lat: 32.8535 },
    { lng: 132.95128, lat: 32.85337 },
    { lng: 132.95247, lat: 32.85297 },
    { lng: 132.95256, lat: 32.85293 },
    { lng: 132.95265, lat: 32.8529 },
    { lng: 132.95274, lat: 32.85286 },
    { lng: 132.95289, lat: 32.85277 },
    { lng: 132.95314, lat: 32.85254 },
    { lng: 132.95316, lat: 32.85253 },
    { lng: 132.95337, lat: 32.85217 },
    { lng: 132.9536, lat: 32.85166 },
    { lng: 132.95364, lat: 32.8516 },
    { lng: 132.95367, lat: 32.85154 },
    { lng: 132.95381, lat: 32.85137 },
    { lng: 132.95391, lat: 32.85129 },
    { lng: 132.95402, lat: 32.85122 },
    { lng: 132.95461, lat: 32.85092 },
    { lng: 132.9547, lat: 32.85085 },
    { lng: 132.95473, lat: 32.85081 },
    { lng: 132.95478, lat: 32.85076 },
    { lng: 132.95483, lat: 32.85068 },
    { lng: 132.95488, lat: 32.85058 },
    { lng: 132.9549, lat: 32.85051 },
    { lng: 132.9549, lat: 32.85044 },
    { lng: 132.95491, lat: 32.8504 },
    { lng: 132.95489, lat: 32.85024 },
    { lng: 132.95452, lat: 32.84829 },
    { lng: 132.9545, lat: 32.84812 },
    { lng: 132.9545, lat: 32.84794 },
    { lng: 132.95451, lat: 32.84782 },
    { lng: 132.95455, lat: 32.8476 },
    { lng: 132.95479, lat: 32.8468 },
    { lng: 132.95483, lat: 32.84661 },
    { lng: 132.95483, lat: 32.8465 },
    { lng: 132.95481, lat: 32.84638 },
    { lng: 132.95475, lat: 32.84624 },
    { lng: 132.95472, lat: 32.84619 },
    { lng: 132.95459, lat: 32.84605 },
    { lng: 132.95454, lat: 32.84601 },
    { lng: 132.95442, lat: 32.84594 },
    { lng: 132.95435, lat: 32.84591 },
    { lng: 132.95433, lat: 32.84591 },
    { lng: 132.9543, lat: 32.84589 },
    { lng: 132.95423, lat: 32.84587 },
    { lng: 132.95406, lat: 32.84585 },
    { lng: 132.95386, lat: 32.84585 },
    { lng: 132.95312, lat: 32.84595 },
    { lng: 132.95308, lat: 32.84596 },
    { lng: 132.95294, lat: 32.84596 },
    { lng: 132.95269, lat: 32.84589 },
    { lng: 132.95254, lat: 32.84578 },
    { lng: 132.95252, lat: 32.84575 },
    { lng: 132.9525, lat: 32.84573 },
    { lng: 132.95244, lat: 32.84564 },
    { lng: 132.95239, lat: 32.8455 },
    { lng: 132.95226, lat: 32.84462 },
    { lng: 132.95227, lat: 32.84456 },
    { lng: 132.95227, lat: 32.84445 },
    { lng: 132.95233, lat: 32.84423 },
  ],
  [
    { lng: 132.9524, lat: 32.84407 }, // 久百々
    { lng: 132.95241, lat: 32.84406 },
    { lng: 132.95245, lat: 32.84399 },
    { lng: 132.95267, lat: 32.84372 },
    { lng: 132.95282, lat: 32.84357 },
    { lng: 132.95283, lat: 32.84355 },
    { lng: 132.95289, lat: 32.84349 },
    { lng: 132.95299, lat: 32.84341 },
    { lng: 132.95319, lat: 32.84332 },
    { lng: 132.95355, lat: 32.84324 },
    { lng: 132.95356, lat: 32.84323 },
    { lng: 132.95364, lat: 32.8432 },
    { lng: 132.95378, lat: 32.84313 },
    { lng: 132.95394, lat: 32.84301 },
    { lng: 132.95402, lat: 32.84293 },
    { lng: 132.95424, lat: 32.84276 },
    { lng: 132.95453, lat: 32.8425 },
    { lng: 132.95454, lat: 32.8425 },
    { lng: 132.9546, lat: 32.84239 },
    { lng: 132.95463, lat: 32.84231 },
    { lng: 132.95483, lat: 32.84135 },
    { lng: 132.95486, lat: 32.84125 },
    { lng: 132.95487, lat: 32.84119 },
    { lng: 132.95491, lat: 32.84107 },
    { lng: 132.95496, lat: 32.84097 },
    { lng: 132.955, lat: 32.84091 },
    { lng: 132.95539, lat: 32.84045 },
    { lng: 132.95549, lat: 32.8403 },
    { lng: 132.95554, lat: 32.84015 },
    { lng: 132.95555, lat: 32.84007 },
    { lng: 132.95555, lat: 32.83998 },
    { lng: 132.95553, lat: 32.83988 },
    { lng: 132.95545, lat: 32.83964 },
    { lng: 132.95535, lat: 32.83941 },
    { lng: 132.95524, lat: 32.83924 },
    { lng: 132.95517, lat: 32.83917 },
    { lng: 132.95502, lat: 32.83905 },
    { lng: 132.9549, lat: 32.83898 },
    { lng: 132.95466, lat: 32.8388 },
    { lng: 132.95463, lat: 32.83877 },
    { lng: 132.95459, lat: 32.83871 },
    { lng: 132.95457, lat: 32.83869 },
    { lng: 132.95452, lat: 32.83857 },
    { lng: 132.95451, lat: 32.83849 },
    { lng: 132.95449, lat: 32.83779 },
    { lng: 132.95447, lat: 32.83767 },
    { lng: 132.95445, lat: 32.83762 },
    { lng: 132.95444, lat: 32.83757 },
    { lng: 132.95442, lat: 32.83753 },
    { lng: 132.95432, lat: 32.83739 },
    { lng: 132.95416, lat: 32.83724 },
    { lng: 132.95356, lat: 32.83692 },
    { lng: 132.95337, lat: 32.8368 },
    { lng: 132.95326, lat: 32.8367 },
    { lng: 132.95319, lat: 32.83661 },
    { lng: 132.95312, lat: 32.83649 },
    { lng: 132.9531, lat: 32.83642 },
    { lng: 132.95309, lat: 32.8364 },
    { lng: 132.95309, lat: 32.83637 },
    { lng: 132.95307, lat: 32.83627 },
    { lng: 132.95308, lat: 32.83614 },
    { lng: 132.9531, lat: 32.83609 },
    { lng: 132.95311, lat: 32.83605 },
    { lng: 132.95311, lat: 32.83604 },
    { lng: 132.95326, lat: 32.83569 },
    { lng: 132.95339, lat: 32.83545 },
    { lng: 132.95357, lat: 32.83506 },
    { lng: 132.95362, lat: 32.83484 },
    { lng: 132.95366, lat: 32.83459 },
    { lng: 132.95368, lat: 32.83455 },
    { lng: 132.95369, lat: 32.83451 },
    { lng: 132.95371, lat: 32.83448 },
    { lng: 132.95374, lat: 32.83441 },
    { lng: 132.95378, lat: 32.83437 },
    { lng: 132.95379, lat: 32.83434 },
    { lng: 132.95385, lat: 32.83428 },
    { lng: 132.95389, lat: 32.83426 },
    { lng: 132.95391, lat: 32.83424 },
    { lng: 132.954, lat: 32.83419 },
    { lng: 132.95406, lat: 32.83417 },
    { lng: 132.95412, lat: 32.83414 },
    { lng: 132.95423, lat: 32.83412 },
    { lng: 132.95442, lat: 32.83412 },
    { lng: 132.95469, lat: 32.83415 },
    { lng: 132.95502, lat: 32.83421 },
    { lng: 132.95522, lat: 32.83421 },
    { lng: 132.95542, lat: 32.83418 },
    { lng: 132.95549, lat: 32.83416 },
    { lng: 132.95566, lat: 32.83408 },
    { lng: 132.9557, lat: 32.83405 },
    { lng: 132.95577, lat: 32.83401 },
    { lng: 132.95744, lat: 32.83268 },
    { lng: 132.95747, lat: 32.83265 },
    { lng: 132.95753, lat: 32.83257 },
    { lng: 132.95754, lat: 32.83254 },
    { lng: 132.95756, lat: 32.83251 },
    { lng: 132.95761, lat: 32.83239 },
    { lng: 132.95761, lat: 32.83235 },
    { lng: 132.95762, lat: 32.83232 },
    { lng: 132.95762, lat: 32.83228 },
    { lng: 132.9576, lat: 32.83213 },
    { lng: 132.95722, lat: 32.83094 },
    { lng: 132.95721, lat: 32.83085 },
    { lng: 132.95722, lat: 32.83082 },
    { lng: 132.95722, lat: 32.83079 },
    { lng: 132.95726, lat: 32.83065 },
    { lng: 132.95729, lat: 32.83059 },
    { lng: 132.95735, lat: 32.83051 },
    { lng: 132.95757, lat: 32.83031 },
    { lng: 132.95802, lat: 32.83 },
    { lng: 132.9581, lat: 32.82993 },
    { lng: 132.95828, lat: 32.82974 },
    { lng: 132.95838, lat: 32.82951 },
    { lng: 132.9584, lat: 32.82939 },
    { lng: 132.95842, lat: 32.82825 },
    { lng: 132.95838, lat: 32.82809 },
    { lng: 132.95831, lat: 32.82794 },
    { lng: 132.95825, lat: 32.82787 },
    { lng: 132.95817, lat: 32.82781 },
    { lng: 132.9581, lat: 32.82774 },
    { lng: 132.95616, lat: 32.82677 },
    { lng: 132.95588, lat: 32.82666 },
    { lng: 132.95568, lat: 32.82661 },
    { lng: 132.95551, lat: 32.82658 },
    { lng: 132.95548, lat: 32.82658 },
    { lng: 132.95317, lat: 32.82616 },
    { lng: 132.95296, lat: 32.82616 },
    { lng: 132.95218, lat: 32.82626 },
    { lng: 132.95208, lat: 32.82626 },
    { lng: 132.95074, lat: 32.82612 },
    { lng: 132.9504, lat: 32.82605 },
    { lng: 132.94962, lat: 32.82585 },
    { lng: 132.94949, lat: 32.82579 },
    { lng: 132.94947, lat: 32.82577 },
    { lng: 132.94941, lat: 32.82574 },
    { lng: 132.94895, lat: 32.82534 },
    { lng: 132.94883, lat: 32.82525 },
    { lng: 132.94862, lat: 32.82513 },
    { lng: 132.94852, lat: 32.8251 },
    { lng: 132.94846, lat: 32.82509 },
    { lng: 132.94845, lat: 32.82508 },
    { lng: 132.9479, lat: 32.82496 },
    { lng: 132.9479, lat: 32.82495 },
    { lng: 132.94777, lat: 32.82493 },
    { lng: 132.94627, lat: 32.82459 },
    { lng: 132.946, lat: 32.82448 },
    { lng: 132.94553, lat: 32.82426 },
  ],
  [
    { lng: 132.94546, lat: 32.82424 }, // 久百々
    { lng: 132.94524, lat: 32.82413 },
    { lng: 132.94511, lat: 32.82403 },
    { lng: 132.94497, lat: 32.82388 },
    { lng: 132.94492, lat: 32.8238 },
    // yokomichi

    { lng: 132.94487, lat: 32.82368 },
    { lng: 132.94403, lat: 32.82376 },
    { lng: 132.94399, lat: 32.82377 },
    { lng: 132.94329, lat: 32.82382 },
    { lng: 132.94306, lat: 32.82387 },
    { lng: 132.94272, lat: 32.82402 },
    { lng: 132.94223, lat: 32.82421 },
    { lng: 132.94212, lat: 32.82424 },
    { lng: 132.94085, lat: 32.82449 },
    { lng: 132.94067, lat: 32.82456 },
    { lng: 132.94063, lat: 32.82457 },
    { lng: 132.9406, lat: 32.82459 },
    { lng: 132.94057, lat: 32.8246 },
    { lng: 132.94011, lat: 32.82483 },
    { lng: 132.94, lat: 32.82487 },
    { lng: 132.93984, lat: 32.82491 },
    { lng: 132.93981, lat: 32.82491 },
    { lng: 132.93972, lat: 32.82493 },
    { lng: 132.93963, lat: 32.82494 },
    { lng: 132.9396, lat: 32.82495 },
    { lng: 132.93925, lat: 32.82499 },
    { lng: 132.939, lat: 32.82499 },
    { lng: 132.93756, lat: 32.82479 },
    { lng: 132.93748, lat: 32.82479 },
    { lng: 132.9374, lat: 32.82478 },
    { lng: 132.93734, lat: 32.82479 },
    { lng: 132.93724, lat: 32.82479 },
    { lng: 132.9371, lat: 32.82482 },
    { lng: 132.93705, lat: 32.82484 },
    { lng: 132.937, lat: 32.82485 },
    { lng: 132.93694, lat: 32.82487 },
    { lng: 132.93679, lat: 32.82494 },
    { lng: 132.93665, lat: 32.82499 },
    { lng: 132.93608, lat: 32.82525 },
    { lng: 132.93598, lat: 32.82528 },
    { lng: 132.93591, lat: 32.82531 },
    { lng: 132.9358, lat: 32.82533 },
    { lng: 132.93573, lat: 32.82535 },
    { lng: 132.93498, lat: 32.82547 },
    { lng: 132.93466, lat: 32.82555 },
    { lng: 132.93443, lat: 32.82566 },
    { lng: 132.93437, lat: 32.8257 },
    { lng: 132.93417, lat: 32.82586 },
    { lng: 132.93382, lat: 32.82624 },
    { lng: 132.93368, lat: 32.82635 },
    { lng: 132.93357, lat: 32.82641 },
    { lng: 132.93356, lat: 32.82641 },
    { lng: 132.93351, lat: 32.82643 },
    { lng: 132.93348, lat: 32.82645 },
    { lng: 132.93329, lat: 32.8265 },
    { lng: 132.93307, lat: 32.82653 },
    { lng: 132.9325, lat: 32.82657 },
    { lng: 132.93231, lat: 32.82661 },
    { lng: 132.93212, lat: 32.82669 },
    { lng: 132.93208, lat: 32.82672 },
    { lng: 132.93207, lat: 32.82672 },
    { lng: 132.93199, lat: 32.82678 },
    { lng: 132.93149, lat: 32.8271 },
    { lng: 132.93126, lat: 32.82718 },
    { lng: 132.93074, lat: 32.82728 },
    { lng: 132.93054, lat: 32.82733 },
    { lng: 132.93053, lat: 32.82733 },
    { lng: 132.93038, lat: 32.82737 },
    { lng: 132.93017, lat: 32.82748 },
    { lng: 132.93015, lat: 32.8275 },
    { lng: 132.93009, lat: 32.82754 },
    { lng: 132.92962, lat: 32.82792 },
    { lng: 132.92945, lat: 32.82804 },
    { lng: 132.9294, lat: 32.82806 },
    { lng: 132.92935, lat: 32.82809 },
    { lng: 132.92912, lat: 32.82816 },
    { lng: 132.92907, lat: 32.82816 },
    { lng: 132.92901, lat: 32.82817 },
    { lng: 132.9289, lat: 32.82817 },
    { lng: 132.92871, lat: 32.82813 },
    { lng: 132.9286, lat: 32.82809 },
    { lng: 132.92851, lat: 32.82804 },
    { lng: 132.92846, lat: 32.82802 },
    { lng: 132.92844, lat: 32.828 },
    { lng: 132.92837, lat: 32.82795 },
    { lng: 132.92832, lat: 32.82781 },
    { lng: 132.92804, lat: 32.82744 },
    { lng: 132.92787, lat: 32.82727 },
    { lng: 132.92765, lat: 32.82714 },
    { lng: 132.92756, lat: 32.8271 },
    { lng: 132.92747, lat: 32.82707 },
    { lng: 132.92746, lat: 32.82706 },
    { lng: 132.92607, lat: 32.82663 },
    { lng: 132.92597, lat: 32.82659 },
    { lng: 132.92588, lat: 32.82654 },
    { lng: 132.92576, lat: 32.82645 },
    { lng: 132.92562, lat: 32.8263 },
    { lng: 132.92529, lat: 32.8258 },
    { lng: 132.92483, lat: 32.82501 },
    { lng: 132.92474, lat: 32.8249 },
    { lng: 132.92463, lat: 32.8248 },
    { lng: 132.92449, lat: 32.82471 },
    { lng: 132.92419, lat: 32.82456 },
    { lng: 132.92416, lat: 32.82453 },
    { lng: 132.92395, lat: 32.82442 },
    { lng: 132.92378, lat: 32.82431 },
    { lng: 132.92375, lat: 32.8243 },
    { lng: 132.92367, lat: 32.82424 },
    { lng: 132.92362, lat: 32.82419 },
    { lng: 132.92359, lat: 32.82417 },
    { lng: 132.92353, lat: 32.82411 },
    { lng: 132.92333, lat: 32.82378 },
    { lng: 132.9232, lat: 32.82343 },
    { lng: 132.92319, lat: 32.82339 },
    { lng: 132.92307, lat: 32.82308 },
    { lng: 132.92251, lat: 32.8221 },
    { lng: 132.9225, lat: 32.82209 },
    { lng: 132.92199, lat: 32.82131 },
    { lng: 132.92198, lat: 32.8213 },
    { lng: 132.92195, lat: 32.82123 },
    { lng: 132.92193, lat: 32.82115 },
    { lng: 132.92192, lat: 32.82103 },
    { lng: 132.92194, lat: 32.82089 },
    { lng: 132.92194, lat: 32.82086 },
    { lng: 132.92198, lat: 32.82071 },
    { lng: 132.92216, lat: 32.82034 },
    { lng: 132.92224, lat: 32.8202 },
    { lng: 132.92225, lat: 32.82019 },
    { lng: 132.92228, lat: 32.8201 },
    { lng: 132.9223, lat: 32.82006 },
    { lng: 132.92232, lat: 32.81999 },
    { lng: 132.92232, lat: 32.81985 },
    { lng: 132.92231, lat: 32.8198 },
    { lng: 132.92231, lat: 32.81976 },
    { lng: 132.92229, lat: 32.81971 },
    { lng: 132.92221, lat: 32.81958 },
    { lng: 132.92202, lat: 32.81933 },
    { lng: 132.92188, lat: 32.81918 },
    { lng: 132.92181, lat: 32.81909 },
    { lng: 132.92157, lat: 32.81886 },
    { lng: 132.92139, lat: 32.81875 },
    { lng: 132.92119, lat: 32.81866 },
    { lng: 132.92113, lat: 32.81864 },
    { lng: 132.92112, lat: 32.81864 },
    { lng: 132.92099, lat: 32.81859 },
    { lng: 132.92088, lat: 32.81856 },
    { lng: 132.92067, lat: 32.81847 },
    { lng: 132.91999, lat: 32.81824 },
    { lng: 132.91991, lat: 32.81822 },
    { lng: 132.91987, lat: 32.81822 },
    { lng: 132.91985, lat: 32.81821 },
    { lng: 132.9198, lat: 32.81821 },
  ],
  [
    { lng: 132.91962, lat: 32.81816 }, // yokomichi
    { lng: 132.9198, lat: 32.81821 },
    { lng: 132.91985, lat: 32.81821 },
    { lng: 132.91987, lat: 32.81822 },
    { lng: 132.91991, lat: 32.81822 },

    { lng: 132.91999, lat: 32.81824 },
    { lng: 132.92067, lat: 32.81847 },
    { lng: 132.92088, lat: 32.81856 },
    { lng: 132.92099, lat: 32.81859 },
    { lng: 132.92112, lat: 32.81864 },
    { lng: 132.92113, lat: 32.81864 },
    { lng: 132.92119, lat: 32.81866 },
    { lng: 132.92139, lat: 32.81875 },
    { lng: 132.92157, lat: 32.81886 },
    { lng: 132.92181, lat: 32.81909 },
    { lng: 132.92188, lat: 32.81918 },
    { lng: 132.92202, lat: 32.81933 },
    { lng: 132.92221, lat: 32.81958 },
    { lng: 132.92229, lat: 32.81971 },
    { lng: 132.92231, lat: 32.81976 },
    { lng: 132.92231, lat: 32.8198 },
    { lng: 132.92232, lat: 32.81985 },
    { lng: 132.92232, lat: 32.81999 },
    { lng: 132.9223, lat: 32.82006 },
    { lng: 132.92228, lat: 32.8201 },
    { lng: 132.92225, lat: 32.82019 },
    { lng: 132.92224, lat: 32.8202 },
    { lng: 132.92216, lat: 32.82034 },
    { lng: 132.92198, lat: 32.82071 },
    { lng: 132.92194, lat: 32.82086 },
    { lng: 132.92194, lat: 32.82089 },
    { lng: 132.92192, lat: 32.82103 },
    { lng: 132.92193, lat: 32.82115 },
    { lng: 132.92195, lat: 32.82123 },
    { lng: 132.92198, lat: 32.8213 },
    { lng: 132.92199, lat: 32.82131 },
    { lng: 132.9225, lat: 32.82209 },
    { lng: 132.92251, lat: 32.8221 },
    { lng: 132.92307, lat: 32.82308 },
    { lng: 132.92319, lat: 32.82339 },
    { lng: 132.9232, lat: 32.82343 },
    { lng: 132.92333, lat: 32.82378 },
    { lng: 132.92353, lat: 32.82411 },
    { lng: 132.92359, lat: 32.82417 },
    { lng: 132.92362, lat: 32.82419 },
    { lng: 132.92367, lat: 32.82424 },
    { lng: 132.92375, lat: 32.8243 },
    { lng: 132.92378, lat: 32.82431 },
    { lng: 132.92395, lat: 32.82442 },
    { lng: 132.92416, lat: 32.82453 },
    { lng: 132.92419, lat: 32.82456 },
    { lng: 132.92449, lat: 32.82471 },
    { lng: 132.92463, lat: 32.8248 },
    { lng: 132.92474, lat: 32.8249 },
    { lng: 132.92483, lat: 32.82501 },
    { lng: 132.92529, lat: 32.8258 },
    { lng: 132.92562, lat: 32.8263 },
    { lng: 132.92576, lat: 32.82645 },
    { lng: 132.92588, lat: 32.82654 },
    { lng: 132.92597, lat: 32.82659 },
    { lng: 132.92607, lat: 32.82663 },
    { lng: 132.92746, lat: 32.82706 },
    { lng: 132.92747, lat: 32.82707 },
    { lng: 132.92756, lat: 32.8271 },
    { lng: 132.92765, lat: 32.82714 },
    { lng: 132.92787, lat: 32.82727 },
    { lng: 132.92804, lat: 32.82744 },
    { lng: 132.92832, lat: 32.82781 },
    { lng: 132.92837, lat: 32.82795 },
    { lng: 132.92844, lat: 32.828 },
    { lng: 132.92846, lat: 32.82802 },
    { lng: 132.92851, lat: 32.82804 },
    { lng: 132.9286, lat: 32.82809 },
    { lng: 132.92871, lat: 32.82813 },
    { lng: 132.9289, lat: 32.82817 },
    { lng: 132.92901, lat: 32.82817 },
    { lng: 132.92907, lat: 32.82816 },
    { lng: 132.92912, lat: 32.82816 },
    { lng: 132.92935, lat: 32.82809 },
    { lng: 132.9294, lat: 32.82806 },
    { lng: 132.92945, lat: 32.82804 },
    { lng: 132.92962, lat: 32.82792 },
    { lng: 132.93009, lat: 32.82754 },
    { lng: 132.93015, lat: 32.8275 },
    { lng: 132.93017, lat: 32.82748 },
    { lng: 132.93038, lat: 32.82737 },
    { lng: 132.93053, lat: 32.82733 },
    { lng: 132.93054, lat: 32.82733 },
    { lng: 132.93074, lat: 32.82728 },
    { lng: 132.93126, lat: 32.82718 },
    { lng: 132.93149, lat: 32.8271 },
    { lng: 132.93199, lat: 32.82678 },
    { lng: 132.93207, lat: 32.82672 },
    { lng: 132.93208, lat: 32.82672 },
    { lng: 132.93212, lat: 32.82669 },
    { lng: 132.93231, lat: 32.82661 },
    { lng: 132.9325, lat: 32.82657 },
    { lng: 132.93307, lat: 32.82653 },
    { lng: 132.93329, lat: 32.8265 },
    { lng: 132.93348, lat: 32.82645 },
    { lng: 132.93351, lat: 32.82643 },
    { lng: 132.93356, lat: 32.82641 },
    { lng: 132.93357, lat: 32.82641 },
    { lng: 132.93368, lat: 32.82635 },
    { lng: 132.93382, lat: 32.82624 },
    { lng: 132.93417, lat: 32.82586 },
    { lng: 132.93437, lat: 32.8257 },
    { lng: 132.93443, lat: 32.82566 },
    { lng: 132.93466, lat: 32.82555 },
    { lng: 132.93498, lat: 32.82547 },
    { lng: 132.93573, lat: 32.82535 },
    { lng: 132.9358, lat: 32.82533 },
    { lng: 132.93591, lat: 32.82531 },
    { lng: 132.93598, lat: 32.82528 },
    { lng: 132.93608, lat: 32.82525 },
    { lng: 132.93665, lat: 32.82499 },
    { lng: 132.93679, lat: 32.82494 },
    { lng: 132.93694, lat: 32.82487 },
    { lng: 132.937, lat: 32.82485 },
    { lng: 132.93705, lat: 32.82484 },
    { lng: 132.9371, lat: 32.82482 },
    { lng: 132.93724, lat: 32.82479 },
    { lng: 132.93734, lat: 32.82479 },
    { lng: 132.9374, lat: 32.82478 },
    { lng: 132.93748, lat: 32.82479 },
    { lng: 132.93756, lat: 32.82479 },
    { lng: 132.939, lat: 32.82499 },
    { lng: 132.93925, lat: 32.82499 },
    { lng: 132.9396, lat: 32.82495 },
    { lng: 132.93963, lat: 32.82494 },
    { lng: 132.93972, lat: 32.82493 },
    { lng: 132.93981, lat: 32.82491 },
    { lng: 132.93984, lat: 32.82491 },
    { lng: 132.94, lat: 32.82487 },
    { lng: 132.94011, lat: 32.82483 },
    { lng: 132.94057, lat: 32.8246 },
    { lng: 132.9406, lat: 32.82459 },
    { lng: 132.94063, lat: 32.82457 },
    { lng: 132.94067, lat: 32.82456 },
    { lng: 132.94085, lat: 32.82449 },
    { lng: 132.94212, lat: 32.82424 },
    { lng: 132.94223, lat: 32.82421 },
    { lng: 132.94272, lat: 32.82402 },
    { lng: 132.94306, lat: 32.82387 },
    { lng: 132.94329, lat: 32.82382 },
    { lng: 132.94399, lat: 32.82377 },
    { lng: 132.94403, lat: 32.82376 },

    // end of yokomichi
    { lng: 132.94487, lat: 32.82368 },
    { lng: 132.94487, lat: 32.82344 },
    { lng: 132.9449, lat: 32.8233 },
    { lng: 132.94506, lat: 32.8228 },
    { lng: 132.94507, lat: 32.82274 },
    { lng: 132.94534, lat: 32.82185 }, // 大岐
    { lng: 132.94535, lat: 32.82183 },
    { lng: 132.94715, lat: 32.81534 },
    { lng: 132.94715, lat: 32.81532 },
    { lng: 132.94723, lat: 32.81511 },
    { lng: 132.94732, lat: 32.81495 },
    { lng: 132.94875, lat: 32.81272 },
    { lng: 132.94875, lat: 32.81271 },
    { lng: 132.9492, lat: 32.81202 },
    { lng: 132.94923, lat: 32.81199 },
    { lng: 132.94926, lat: 32.81195 },
    { lng: 132.94929, lat: 32.81192 },
    { lng: 132.94936, lat: 32.81187 },
    { lng: 132.94939, lat: 32.81186 },
    { lng: 132.94942, lat: 32.81184 },
    { lng: 132.94946, lat: 32.81183 },
    { lng: 132.94951, lat: 32.81181 },
    { lng: 132.94955, lat: 32.81181 },
    { lng: 132.9496, lat: 32.8118 },
    { lng: 132.94968, lat: 32.8118 },
    { lng: 132.94983, lat: 32.81182 },
    { lng: 132.95126, lat: 32.81215 },
    { lng: 132.95145, lat: 32.81217 },
    { lng: 132.95147, lat: 32.81218 },
    { lng: 132.95188, lat: 32.81221 },
    { lng: 132.95199, lat: 32.81221 },
    { lng: 132.9521, lat: 32.8122 },
    { lng: 132.9522, lat: 32.81217 },
    { lng: 132.95236, lat: 32.81209 },
    { lng: 132.9525, lat: 32.81198 },
    { lng: 132.95251, lat: 32.81198 },
    { lng: 132.95326, lat: 32.81134 },
    { lng: 132.95341, lat: 32.81119 },
    { lng: 132.95346, lat: 32.81115 },
    { lng: 132.95346, lat: 32.81114 },
    { lng: 132.95348, lat: 32.81111 },
    { lng: 132.95355, lat: 32.81103 },
    { lng: 132.95413, lat: 32.81021 },
    { lng: 132.95428, lat: 32.80997 },
    { lng: 132.95433, lat: 32.80987 },
    { lng: 132.95488, lat: 32.80842 },
    { lng: 132.95496, lat: 32.80817 },
    { lng: 132.95507, lat: 32.80789 },
    { lng: 132.95521, lat: 32.80746 },
    { lng: 132.95578, lat: 32.80547 },
    { lng: 132.95588, lat: 32.80547 },
    { lng: 132.95596, lat: 32.80546 },
    { lng: 132.95602, lat: 32.80544 },
    { lng: 132.95604, lat: 32.80544 },
    { lng: 132.95608, lat: 32.80541 },
    { lng: 132.95614, lat: 32.80535 },
    { lng: 132.95633, lat: 32.8052 },
    { lng: 132.95731, lat: 32.80452 },
    { lng: 132.95732, lat: 32.80451 },
    { lng: 132.95757, lat: 32.80437 },
    { lng: 132.95777, lat: 32.80429 },
    { lng: 132.95789, lat: 32.80426 },
    { lng: 132.95794, lat: 32.80424 },
    { lng: 132.959, lat: 32.80395 },
    { lng: 132.95901, lat: 32.80395 },
    { lng: 132.95917, lat: 32.80389 },
    { lng: 132.9592, lat: 32.80386 },
    { lng: 132.95923, lat: 32.80384 },
    { lng: 132.95928, lat: 32.80378 },
    { lng: 132.95932, lat: 32.8037 },
    { lng: 132.95933, lat: 32.80366 },
    { lng: 132.95933, lat: 32.80357 },
    { lng: 132.95932, lat: 32.80354 },
    { lng: 132.95932, lat: 32.80351 },
    { lng: 132.95917, lat: 32.80307 },
    { lng: 132.95908, lat: 32.80273 },
    { lng: 132.95901, lat: 32.80253 },
    { lng: 132.95896, lat: 32.80232 },
    { lng: 132.95896, lat: 32.80221 },
    { lng: 132.95897, lat: 32.80216 },
    { lng: 132.95901, lat: 32.80206 },
    { lng: 132.95902, lat: 32.80202 },
    { lng: 132.95922, lat: 32.80156 },
    { lng: 132.95923, lat: 32.80155 },
    { lng: 132.95928, lat: 32.80145 },
    { lng: 132.9594, lat: 32.80127 },
    { lng: 132.95946, lat: 32.8012 },
    { lng: 132.95954, lat: 32.80113 },
    { lng: 132.9598, lat: 32.80096 },
    { lng: 132.96111, lat: 32.80024 },
    { lng: 132.96122, lat: 32.80019 },
    { lng: 132.96128, lat: 32.80017 },
    { lng: 132.96134, lat: 32.80014 },
    { lng: 132.9616, lat: 32.80005 },
    { lng: 132.96319, lat: 32.79969 },
    { lng: 132.96332, lat: 32.79967 },
    { lng: 132.96332, lat: 32.79966 },
    { lng: 132.96347, lat: 32.79961 },
    { lng: 132.96351, lat: 32.79959 },
    { lng: 132.9636, lat: 32.79952 },
    { lng: 132.96363, lat: 32.79948 },
    { lng: 132.96372, lat: 32.79939 },
  ],
  [
    { lng: 132.96382, lat: 32.79922 }, //  以布利
    { lng: 132.96403, lat: 32.79853 },
    { lng: 132.9641, lat: 32.7984 },
    { lng: 132.96427, lat: 32.7982 },
    { lng: 132.96431, lat: 32.79814 },
    { lng: 132.96433, lat: 32.79812 },
    { lng: 132.96435, lat: 32.79808 },
    { lng: 132.96437, lat: 32.798 },
    { lng: 132.96437, lat: 32.79788 },
    { lng: 132.96392, lat: 32.79631 },
    { lng: 132.9639, lat: 32.79616 },
    { lng: 132.96396, lat: 32.79599 },
    { lng: 132.96414, lat: 32.79575 },
    { lng: 132.96465, lat: 32.79519 },
    { lng: 132.96468, lat: 32.79518 },
    { lng: 132.96471, lat: 32.79518 },
    { lng: 132.96474, lat: 32.79517 },
    { lng: 132.96476, lat: 32.79517 },
    { lng: 132.96479, lat: 32.79518 },
    { lng: 132.96481, lat: 32.79518 },
    { lng: 132.96485, lat: 32.79522 },
    { lng: 132.96486, lat: 32.79524 },
    { lng: 132.96486, lat: 32.79534 },
    { lng: 132.96482, lat: 32.79544 },
    { lng: 132.96482, lat: 32.79546 },
    { lng: 132.96461, lat: 32.79591 },
    { lng: 132.9646, lat: 32.79595 },
    { lng: 132.96459, lat: 32.79597 },
    { lng: 132.96457, lat: 32.79608 },
    { lng: 132.9646, lat: 32.79618 },
    { lng: 132.96466, lat: 32.79628 },
    { lng: 132.96467, lat: 32.79628 },
    { lng: 132.96471, lat: 32.79632 },
    { lng: 132.96474, lat: 32.79633 },
    { lng: 132.96476, lat: 32.79635 },
    { lng: 132.96477, lat: 32.79635 },
    { lng: 132.9648, lat: 32.79637 },
    { lng: 132.96493, lat: 32.7964 },
    { lng: 132.96506, lat: 32.7964 },
    { lng: 132.96512, lat: 32.79639 },
    { lng: 132.96523, lat: 32.79634 },
    { lng: 132.96528, lat: 32.7963 },
    { lng: 132.96532, lat: 32.79625 },
    { lng: 132.96538, lat: 32.79611 },
    { lng: 132.96538, lat: 32.79607 },
    { lng: 132.96542, lat: 32.79588 },
    { lng: 132.96555, lat: 32.79495 },
    { lng: 132.96555, lat: 32.79491 },
    { lng: 132.96538, lat: 32.79402 },
    { lng: 132.96537, lat: 32.79393 },
    { lng: 132.96508, lat: 32.79261 },
    { lng: 132.96506, lat: 32.79256 },
    { lng: 132.96505, lat: 32.79245 },
    { lng: 132.965, lat: 32.79215 },
    { lng: 132.96492, lat: 32.79201 },
    { lng: 132.96484, lat: 32.79192 },
    { lng: 132.96413, lat: 32.79156 },
    { lng: 132.96412, lat: 32.79156 },
    { lng: 132.96355, lat: 32.79125 },
    { lng: 132.96348, lat: 32.79122 },
    { lng: 132.96346, lat: 32.7912 },
    { lng: 132.96342, lat: 32.79118 },
    { lng: 132.96333, lat: 32.7911 },
    { lng: 132.96332, lat: 32.7911 },
    { lng: 132.96319, lat: 32.79095 },
    { lng: 132.96308, lat: 32.79077 },
    { lng: 132.96305, lat: 32.7907 },
    { lng: 132.96296, lat: 32.79028 },
    { lng: 132.96296, lat: 32.79006 },
    { lng: 132.96291, lat: 32.78994 },
    { lng: 132.96286, lat: 32.78987 },
    { lng: 132.96276, lat: 32.78977 },
    { lng: 132.96247, lat: 32.78955 },
    { lng: 132.96238, lat: 32.7895 },
    { lng: 132.96237, lat: 32.78949 },
    { lng: 132.96232, lat: 32.78946 },
    { lng: 132.96227, lat: 32.78945 },
    { lng: 132.96223, lat: 32.78943 },
    { lng: 132.96184, lat: 32.78935 },
    { lng: 132.96175, lat: 32.78931 },
    { lng: 132.96161, lat: 32.78921 },
    { lng: 132.96158, lat: 32.78918 },
    { lng: 132.96144, lat: 32.7891 },
    { lng: 132.96139, lat: 32.7891 },
    { lng: 132.96133, lat: 32.78911 },
    { lng: 132.96117, lat: 32.78911 },
    { lng: 132.96115, lat: 32.78863 },
    { lng: 132.96113, lat: 32.78844 },
    { lng: 132.96109, lat: 32.78824 },
    { lng: 132.96109, lat: 32.78821 },
    { lng: 132.96096, lat: 32.78744 },
    { lng: 132.96094, lat: 32.78712 },
    { lng: 132.96096, lat: 32.78696 },
    { lng: 132.96104, lat: 32.78677 },
    { lng: 132.96082, lat: 32.78665 },
    { lng: 132.96076, lat: 32.78659 },
    { lng: 132.96074, lat: 32.78656 },
    { lng: 132.96068, lat: 32.78633 },
    { lng: 132.96056, lat: 32.78597 },
    { lng: 132.96055, lat: 32.78571 },
    { lng: 132.96048, lat: 32.78535 },
    { lng: 132.96047, lat: 32.78533 },
    { lng: 132.96026, lat: 32.78459 },
    { lng: 132.96004, lat: 32.78417 },
    { lng: 132.95978, lat: 32.78384 },
    { lng: 132.95935, lat: 32.78346 },
    { lng: 132.95914, lat: 32.78332 },
    { lng: 132.9591, lat: 32.78328 },
    { lng: 132.95887, lat: 32.78315 },
    { lng: 132.95868, lat: 32.78306 },
    { lng: 132.95855, lat: 32.78301 },
    { lng: 132.95785, lat: 32.7828 },
    { lng: 132.95729, lat: 32.78255 },
    { lng: 132.9565, lat: 32.78234 },
    { lng: 132.95625, lat: 32.7823 },
    { lng: 132.9561, lat: 32.78229 },
    { lng: 132.95592, lat: 32.78234 },
    { lng: 132.95573, lat: 32.78233 },
    { lng: 132.9556, lat: 32.78231 },
    { lng: 132.95523, lat: 32.78229 },
    { lng: 132.95458, lat: 32.7823 },
    { lng: 132.95434, lat: 32.78233 },
    { lng: 132.9543, lat: 32.78233 },
    { lng: 132.95427, lat: 32.78227 },
    { lng: 132.95424, lat: 32.78218 },
    { lng: 132.95425, lat: 32.78215 },
    { lng: 132.95425, lat: 32.78211 },
    { lng: 132.95426, lat: 32.78206 },
    { lng: 132.95425, lat: 32.78205 },
    { lng: 132.95424, lat: 32.78203 },
    { lng: 132.95423, lat: 32.78202 },
    { lng: 132.95422, lat: 32.782 },
    { lng: 132.95408, lat: 32.7815 },
    { lng: 132.95408, lat: 32.78148 },
    { lng: 132.95407, lat: 32.78146 },
    { lng: 132.95407, lat: 32.78145 },
    { lng: 132.95406, lat: 32.78143 },
    { lng: 132.95405, lat: 32.78142 },
    { lng: 132.95405, lat: 32.78141 },
    { lng: 132.95401, lat: 32.78139 },
    { lng: 132.95364, lat: 32.7801 },
  ], //  市街地（清水）
];
