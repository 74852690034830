import { CourseData } from "../types/data_types";
import { ways } from "./way3";

export const data: CourseData = {
  id: "xgncQ6JLTWJ4dvFSpJLk",
  companyId: "Qqh1S4vBjZ88Dv2uKPks",
  name: "有永〜市街地（清水）",
  carName: "ジャンボ",
  shouldSchedule: true,
  reporter: "西村",
  defaultPosition: {
    lng: 132.82124588739418,
    lat: 32.794262649596,
  },
  services: [
    {
      id: 0,
      sname: "401便",
      lname: "有永 → 市街地",
      name: "401便 有永 6:50 → 市街地 8:03",
      inbound: true,
      capacity: 9,
      start: 650,
    },
    {
      id: 1,
      sname: "402便",
      lname: "宗呂上 → 市街地",
      name: "402便 宗呂上 9:30 → 市街地 10:13",
      inbound: true,
      capacity: 9,
      start: 930,
    },
    {
      id: 2,
      sname: "404便",
      lname: "市街地 → 宗呂上",
      name: "404便 市街地 10:13 → 宗呂上 10:56",
      inbound: false,
      capacity: 9,
      start: 1013,
    },
    {
      id: 3,
      sname: "403便",
      lname: "有永 → 市街地",
      name: "403便 有永 12:00 → 市街地 13:13",
      inbound: true,
      capacity: 9,
      start: 1200,
    },
    {
      id: 4,
      sname: "405便",
      lname: "市街地 → 有永",
      name: "405便 市街地 13:13 → 有永 14:26",
      inbound: false,
      capacity: 9,
      start: 1313,
    },
    {
      id: 5,
      sname: "406便",
      lname: "市街地 → 有永",
      name: "406便 市街地 16:35 → 有永 17:48",
      inbound: false,
      capacity: 9,
      start: 1635,
    },
  ],
  ways,
  pins: [
    {
      id: 3010,
      name: "有永",
      location: { lng: 132.7925859, lat: 32.86109 },
      busStopId: 0,
    },
    {
      id: 3020,
      name: "珠々玉",
      location: { lng: 132.7899082, lat: 32.8433984 },
      busStopId: 1,
    },
    {
      id: 3030,
      name: "坂井",
      location: { lng: 132.780842, lat: 32.830058 },
      busStopId: 2,
    },
    {
      id: 3040,
      name: "木ノ川",
      location: { lng: 132.801383, lat: 32.83125 },
      busStopId: 3,
    },
    {
      id: 3050,
      name: "宗呂上",
      location: { lng: 132.819418, lat: 32.80542 },
      busStopId: 4,
    },
    {
      id: 3060,
      name: "宗呂下",
      location: { lng: 132.821162, lat: 32.7981838 },
      busStopId: 5,
    },
    {
      id: 3070,
      name: "下川口市民センター",
      location: { lng: 132.8408134, lat: 32.7848854 },
      busStopId: 6,
    },
    {
      id: 3080,
      name: "下川口漁協バス停",
      location: { lng: 132.8434372, lat: 32.7817265 },
      busStopId: 7,
    },
    {
      id: 3090,
      name: "爪白",
      location: { lng: 132.8566206, lat: 32.7876326 },
      busStopId: 8,
    },
    {
      id: 3100,
      name: "竜串",
      location: { lng: 132.8671067, lat: 32.7892587 },
      busStopId: 9,
    },
    {
      id: 3110,
      name: "三崎市民センター",
      location: { lng: 132.8771661, lat: 32.7916579 },
      busStopId: 10,
    },
    {
      id: 3120,
      name: "下ノ段",
      location: { lng: 132.874408, lat: 32.794788 },
      busStopId: 11,
    },
    {
      id: 3130,
      name: "平ノ段",
      location: { lng: 132.867016, lat: 32.796104 },
      busStopId: 12,
    },
    {
      id: 3140,
      name: "斧積",
      location: { lng: 132.880253, lat: 32.814185 },
      busStopId: 13,
    },
    {
      id: 3150,
      name: "上野",
      location: { lng: 132.8966406, lat: 32.8172989 },
      busStopId: 14,
    },
    {
      id: 3160,
      name: "益野橋バス停",
      location: { lng: 132.8968625, lat: 32.8011875 },
      busStopId: 15,
    },
    {
      id: 3163,
      name: "松崎",
      location: { lng: 132.91873587057768, lat: 32.78631106011095 },
      busStopId: 17,
    },
    {
      id: 3166,
      name: "養老",
      location: { lng: 132.93123055705905, lat: 32.78364226571635 },
      busStopId: 18,
    },
    {
      id: 3170,
      name: "市街地（清水）",
      location: { lng: 132.953638, lat: 32.7800888 },
      busStopId: 16,
    },
  ],
  // key is busStopId
  pinTimes: [
    [
      // 401
      { time: "6:50" }, // 有永
      { time: "7:00" },
      { time: "7:10" },
      { time: "7:15" },
      { time: "7:20" },
      { time: "7:23" },
      { time: "7:26" },
      { time: "7:29" },
      { time: "7:32" },
      { time: "7:35" },
      { time: "7:38" },
      { time: "7:41" },
      { time: "7:44" },
      { time: "7:47" },
      { time: "7:50" },
      { time: "7:53" },
      { time: "8:03" }, // 市街地
      { time: "" },
      { time: "" },
    ],
    [
      // 402
      { time: "" }, //有永
      { time: "" },
      { time: "" },
      { time: "" }, // 木ノ川 (幻)
      { time: "9:30" },
      { time: "9:33" },
      { time: "9:36" },
      { time: "9:39" },
      { time: "9:42" },
      { time: "9:45" },
      { time: "9:48" },
      { time: "9:51" },
      { time: "9:54" },
      { time: "9:57" },
      { time: "10:00" },
      { time: "10:03" },
      { time: "10:13" }, // 市街地
      { time: "" },
      { time: "" },
    ],
    [
      // 404
      { time: "" }, // 有永
      { time: "" },
      { time: "" },
      { time: "" },
      { time: "10:56" },
      { time: "10:53" },
      { time: "10:50" },
      { time: "10:47" },
      { time: "10:44" },
      { time: "10:41" },
      { time: "10:38" },
      { time: "10:35" },
      { time: "10:32" },
      { time: "10:29" },
      { time: "10:26" },
      { time: "10:23" },
      { time: "10:13" }, // 市街地
      { time: "" },
      { time: "" },
    ],
    [
      // 403
      { time: "12:00" }, // 有永
      { time: "12:10" },
      { time: "12:20" },
      { time: "12:25" },
      { time: "12:30" },
      { time: "12:33" },
      { time: "12:36" },
      { time: "12:39" },
      { time: "12:42" },
      { time: "12:45" },
      { time: "12:48" },
      { time: "12:51" },
      { time: "12:54" },
      { time: "12:57" },
      { time: "13:00" },
      { time: "13:03" },
      { time: "13:13" }, // 市街地
      { time: "" },
      { time: "" },
    ],
    [
      // 405
      { time: "14:26" },
      { time: "14:16" },
      { time: "14:06" },
      { time: "14:01" },
      { time: "13:56" },
      { time: "13:53" },
      { time: "13:50" },
      { time: "13:47" },
      { time: "13:44" },
      { time: "13:41" },
      { time: "13:38" },
      { time: "13:35" },
      { time: "13:32" },
      { time: "13:29" },
      { time: "13:26" },
      { time: "13:23" },
      { time: "13:13" }, // 市街地
      { time: "" },
      { time: "" },
    ],
    [
      // 406
      { time: "17:48" },
      { time: "17:38" },
      { time: "17:28" },
      { time: "17:23" },
      { time: "17:18" },
      { time: "17:15" },
      { time: "17:12" },
      { time: "17:09" },
      { time: "17:06" },
      { time: "17:03" },
      { time: "17:00" },
      { time: "16:57" },
      { time: "16:54" },
      { time: "16:51" },
      { time: "16:48" },
      { time: "16:45" },
      { time: "16:35" }, // 市街地
      { time: "14:42" },
      { time: "16:41" },
    ],
  ],
  areas: [
    {
      id: 13010,
      name: "下川口エリア",
      pins: [3010, 3020, 3030, 3040, 3050, 3060, 3070, 3080],
    },
    {
      id: 13020,
      name: "三崎エリア",
      pins: [3090, 3100, 3110, 3120, 3130, 3140, 3150, 3160],
    },
    {
      id: 13030,
      name: "市街地",
      pins: [3170],
    },
    {
      id: 13040,
      name: "養老エリア",
      pins: [3163, 3166],
    },
  ],
  prices: {
    13010: {
      to_area: {
        13010: { price: 100 },
        13020: { price: 200 },
        13030: { price: 800 },
        13040: { price: 2000 }, // dummy
      },
    },
    13020: {
      to_area: {
        13020: { price: 100 },
        13030: { price: 600 },
        13040: { price: 2000 }, // dummy
      },
    },
    13030: {
      to_area: {
        13030: { price: 200 },
        13040: { price: 200 },
      },
    },
    13040: {
      to_area: {
        13040: { price: 2000 }, // dummy
      },
    },
  },
};
