export const phoneValidator = (value: string) => {
  return value.match(/^[0-9]+$/) && value.length === 11;
};
export const landlineValidator = (value: string) => {
  return value.match(/^[0-9]+$/) && value.length === 10;
};
export const postalCodeValidator = (value: string) => {
  return value.match(/^[0-9]+$/) && value.length === 7;
};

export const zen2hanNumber = (value: string) => {
  return value
    .replace(/[０-９]/g, (s) => {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
    })
    .replace(/[^0-9]/g, "");
};
