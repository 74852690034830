import { styled } from "@mui/system";
import { Table, TableCell, TableRow } from "@mui/material";

export const DailyLogTable = styled(Table)(({ theme }) => ({
  padding: "1px 1px 1px 1px",
  display: "block",
  "overflow-x": "scroll",
  "white-space": "nowrap",
  "-webkit-overflow-scrolling": "touch",
}));

export const DailyLogCell = styled(TableCell)(({ theme }) => ({
  textAlign: "center",
  color: "black",
  borderBottom: "none",
  borderRight: "solid 1px #888888",
  lineHeight: 1.3,
  padding: "1px 1px 1px 1px",
}));

export const DailyLogHeadCell = styled(DailyLogCell)(({ theme }) => ({
  backgroundColor: "#eeefef",
  fontWeight: "bold",
}));

export const DailyLogHeadCellTop = styled(DailyLogHeadCell)(({ theme }) => ({
  borderTop: "solid 1px #888888",
}));
export const DailyLogHeadCellTopNinzuRyokin = styled(DailyLogHeadCellTop)(
  ({ theme }) => ({
    borderBottom: "solid 1px #888888",
    height: "35px",
  }),
);
export const DailyLogHeadCellTopLeft = styled(DailyLogHeadCellTop)(
  ({ theme }) => ({
    borderLeft: "solid 1px #888888",
  }),
);
export const DailyLogBodyLeftCell = styled(DailyLogHeadCell)(({ theme }) => ({
  borderRight: "none",
  borderLeft: "solid 1px",
  borderTop: "solid 1px",
  borderBottom: "solid 1px",
  textAlign: "center",
  backgroundColor: "#dedede",
  fontWeight: "bold",
}));

export const DailyLogBodyRightCell = styled(DailyLogHeadCell)(({ theme }) => ({
  borderRight: "solid 1px",
  borderLeft: "none",
  borderTop: "solid 1px",
  borderBottom: "solid 1px",
  textAlign: "center",
  backgroundColor: "#dedede",
  fontWeight: "bold",
}));

export const DailyLogHeadCellTategakiLight = styled(DailyLogHeadCell)(
  ({ theme }) => ({
    width: "20px",
    verticalAlign: "top",
    lineHeight: 1.2,
    backgroundColor: "#dedede",
  }),
);

export const DailyLogHeadCellTategaki = styled(DailyLogHeadCell)(
  ({ theme }) => ({
    width: "20px",
    height: "80px",
    verticalAlign: "top",
    lineHeight: 1.2,
  }),
);

export const DailyLogBodyMiddleCell = styled(DailyLogHeadCell)(({ theme }) => ({
  borderRight: "none",
  borderLeft: "none",
  borderTop: "solid 1px",
  borderBottom: "solid 1px",
  textAlign: "center",
  backgroundColor: "#dedede",
  fontWeight: "bold",
}));

export const DailyLogCellNoWrap = styled(DailyLogCell)(({ theme }) => ({
  whiteSpace: "nowrap",
}));
export const DailyLogHeadCellNoWrap = styled(DailyLogHeadCell)(({ theme }) => ({
  whiteSpace: "nowrap",
}));
export const DailyLogBodyRowOdd = styled(TableRow)(({ theme }) => ({
  backgroundColor: "#eeefef",
}));
export const DailyLogBodyRowEven = styled(TableRow)(({ theme }) => ({
  backgroundColor: "transparent",
}));
export const DailyLogBodyCell = styled(TableCell)(({ theme }) => ({
  borderBottom: "none",
  borderRight: "solid 1px #888888",
  borderLeft: "solid 1px #888888",
  padding: "1px 1px 1px 1px",
}));

export const DailyLogBodyCellOdd = styled(DailyLogBodyCell)(({ theme }) => ({
  backgroundColor: "#dedede",
}));

export const DailyLogBodyCellEven = styled(DailyLogBodyCell)(({ theme }) => ({
  backgroundColor: "#eeefef",
}));
