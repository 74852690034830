export const ways = [
  [
    { lng: 132.87617, lat: 32.79076 },
    { lng: 132.87658, lat: 32.7908 },
    //      {lng: 132.87716, lat: 32.79165}, // 三崎市民センター"
    //     {lng: 132.87708, lat: 32.79169},
    //     {lng: 132.87685, lat: 32.79178},
    //     {lng: 132.87643, lat: 32.79195},
    //     {lng: 132.87628, lat: 32.79202},
    //     {lng: 132.87623, lat: 32.79203},
    //     {lng: 132.87618, lat: 32.79202},
    //     {lng: 132.87612, lat: 32.79201},
    //     {lng: 132.87604, lat: 32.792},
    { lng: 132.87595, lat: 32.79197 },
    { lng: 132.87594, lat: 32.79198 },
    { lng: 132.87526, lat: 32.79327 },
    { lng: 132.87525, lat: 32.79328 },
    { lng: 132.87497, lat: 32.79381 },
    { lng: 132.87495, lat: 32.79384 },
    { lng: 132.87487, lat: 32.79402 },
    { lng: 132.8747, lat: 32.79432 },
  ],
  [
    { lng: 132.8744, lat: 32.79486 }, // 下ノ段
    { lng: 132.87426, lat: 32.79512 },
    { lng: 132.87403, lat: 32.79552 },
    { lng: 132.874, lat: 32.79557 },
    { lng: 132.87397, lat: 32.79562 },
    { lng: 132.87391, lat: 32.79568 },
    { lng: 132.87386, lat: 32.79573 },
    { lng: 132.87381, lat: 32.79575 },
    { lng: 132.87378, lat: 32.79577 },
    { lng: 132.87369, lat: 32.79583 },
    { lng: 132.87357, lat: 32.79592 },
    { lng: 132.87354, lat: 32.79594 },
    { lng: 132.87351, lat: 32.79597 },
    { lng: 132.87348, lat: 32.796 },
    { lng: 132.87339, lat: 32.79611 },
    { lng: 132.8733, lat: 32.79622 },
    { lng: 132.87312, lat: 32.79613 },
    { lng: 132.87257, lat: 32.79586 },
    { lng: 132.87237, lat: 32.79576 },
    { lng: 132.8722, lat: 32.79567 },
    { lng: 132.87203, lat: 32.79558 },
    { lng: 132.872, lat: 32.79557 },
    { lng: 132.87145, lat: 32.79529 },
    { lng: 132.87138, lat: 32.79526 },
    { lng: 132.87134, lat: 32.79524 },
    { lng: 132.87131, lat: 32.79523 },
    { lng: 132.87127, lat: 32.79523 },
    { lng: 132.87123, lat: 32.79522 },
    { lng: 132.87117, lat: 32.79522 },
    { lng: 132.87113, lat: 32.79522 },
    { lng: 132.87108, lat: 32.79522 },
    { lng: 132.87096, lat: 32.79524 },
    { lng: 132.87095, lat: 32.79524 },
    { lng: 132.8708, lat: 32.79527 },
    { lng: 132.87025, lat: 32.79541 },
    { lng: 132.87013, lat: 32.79544 },
    { lng: 132.86957, lat: 32.79558 },
    { lng: 132.86955, lat: 32.79558 },
    { lng: 132.86923, lat: 32.79565 },
    { lng: 132.86916, lat: 32.79566 },
    { lng: 132.86904, lat: 32.79569 },
    { lng: 132.86896, lat: 32.79569 },
    { lng: 132.86889, lat: 32.79569 },
    { lng: 132.86874, lat: 32.79569 },
    { lng: 132.86869, lat: 32.79569 },
    { lng: 132.86864, lat: 32.7957 },
    { lng: 132.86856, lat: 32.79573 },
    { lng: 132.86837, lat: 32.7958 },
    { lng: 132.8682, lat: 32.79587 },
    { lng: 132.86814, lat: 32.7959 },
    { lng: 132.86796, lat: 32.79598 },
    { lng: 132.8679, lat: 32.79601 },
    { lng: 132.86784, lat: 32.79603 },
    { lng: 132.86778, lat: 32.79604 },
    { lng: 132.86763, lat: 32.79606 },
    { lng: 132.86749, lat: 32.79609 },
    { lng: 132.8674, lat: 32.7961 },
    { lng: 132.8673, lat: 32.79611 },
    { lng: 132.86727, lat: 32.79611 },
    { lng: 132.86721, lat: 32.7961 },
    { lng: 132.86713, lat: 32.79608 },
    { lng: 132.86706, lat: 32.79607 },
  ],
  [
    { lng: 132.86703, lat: 32.79607 }, // 平ノ段
    { lng: 132.86706, lat: 32.79607 },
    { lng: 132.86713, lat: 32.79608 },
    { lng: 132.86721, lat: 32.7961 },
    { lng: 132.86727, lat: 32.79611 },
    { lng: 132.8673, lat: 32.79611 },
    { lng: 132.8674, lat: 32.7961 },
    { lng: 132.86749, lat: 32.79609 },
    { lng: 132.86763, lat: 32.79606 },
    { lng: 132.86778, lat: 32.79604 },
    { lng: 132.86784, lat: 32.79603 },
    { lng: 132.8679, lat: 32.79601 },
    { lng: 132.86796, lat: 32.79598 },
    { lng: 132.86814, lat: 32.7959 },
    { lng: 132.8682, lat: 32.79587 },
    { lng: 132.86837, lat: 32.7958 },
    { lng: 132.86856, lat: 32.79573 },
    { lng: 132.86864, lat: 32.7957 },
    { lng: 132.86869, lat: 32.79569 },
    { lng: 132.86874, lat: 32.79569 },
    { lng: 132.86889, lat: 32.79569 },
    { lng: 132.86896, lat: 32.79569 },
    { lng: 132.86904, lat: 32.79569 },
    { lng: 132.86916, lat: 32.79566 },
    { lng: 132.86923, lat: 32.79565 },
    { lng: 132.86955, lat: 32.79558 },
    { lng: 132.86957, lat: 32.79558 },
    { lng: 132.87013, lat: 32.79544 },
    { lng: 132.87025, lat: 32.79541 },
    { lng: 132.8708, lat: 32.79527 },
    { lng: 132.87095, lat: 32.79524 },
    { lng: 132.87096, lat: 32.79524 },
    { lng: 132.87108, lat: 32.79522 },
    { lng: 132.87113, lat: 32.79522 },
    { lng: 132.87117, lat: 32.79522 },
    { lng: 132.87123, lat: 32.79522 },
    { lng: 132.87127, lat: 32.79523 },
    { lng: 132.87131, lat: 32.79523 },
    { lng: 132.87134, lat: 32.79524 },
    { lng: 132.87138, lat: 32.79526 },
    { lng: 132.87145, lat: 32.79529 },
    { lng: 132.872, lat: 32.79557 },
    { lng: 132.87203, lat: 32.79558 },
    { lng: 132.87197, lat: 32.79567 },
    { lng: 132.87195, lat: 32.79571 },
    { lng: 132.87193, lat: 32.79573 },
    { lng: 132.87192, lat: 32.79575 },
    { lng: 132.87187, lat: 32.79586 },
    { lng: 132.87179, lat: 32.79605 },
    { lng: 132.87178, lat: 32.79607 },
    { lng: 132.87178, lat: 32.79609 },
    { lng: 132.87177, lat: 32.79611 },
    { lng: 132.87177, lat: 32.79612 },
    { lng: 132.87176, lat: 32.79617 },
    { lng: 132.87175, lat: 32.79621 },
    { lng: 132.87174, lat: 32.79626 },
    { lng: 132.87173, lat: 32.7963 },
    { lng: 132.87171, lat: 32.79645 },
    { lng: 132.87169, lat: 32.79658 },
    { lng: 132.87169, lat: 32.79678 },
    { lng: 132.8717, lat: 32.7969 },
    { lng: 132.87172, lat: 32.79701 },
    { lng: 132.87177, lat: 32.79732 },
    { lng: 132.87182, lat: 32.79761 },
    { lng: 132.87183, lat: 32.79777 },
    { lng: 132.87184, lat: 32.79785 },
    { lng: 132.87185, lat: 32.7979 },
    { lng: 132.87185, lat: 32.79802 },
    { lng: 132.87186, lat: 32.79824 },
    { lng: 132.87186, lat: 32.79856 },
    { lng: 132.87186, lat: 32.79857 },
    { lng: 132.87186, lat: 32.7988 },
    { lng: 132.87187, lat: 32.7989 },
    { lng: 132.87188, lat: 32.799 },
    { lng: 132.8719, lat: 32.79908 },
    { lng: 132.87194, lat: 32.79921 },
    { lng: 132.87198, lat: 32.79932 },
    { lng: 132.87205, lat: 32.79944 },
    { lng: 132.87205, lat: 32.79945 },
    { lng: 132.87211, lat: 32.79951 },
    { lng: 132.87213, lat: 32.79953 },
    { lng: 132.87215, lat: 32.79955 },
    { lng: 132.87217, lat: 32.79956 },
    { lng: 132.87221, lat: 32.79956 },
    { lng: 132.8722, lat: 32.79961 },
    { lng: 132.87218, lat: 32.79972 },
    { lng: 132.87218, lat: 32.79996 },
    { lng: 132.87219, lat: 32.80024 },
    { lng: 132.8722, lat: 32.80035 },
    { lng: 132.8722, lat: 32.80042 },
    { lng: 132.87221, lat: 32.8006 },
    { lng: 132.87221, lat: 32.80062 },
    { lng: 132.87221, lat: 32.8008 },
    { lng: 132.87222, lat: 32.80096 },
    { lng: 132.87223, lat: 32.80113 },
    { lng: 132.87224, lat: 32.80118 },
    { lng: 132.87227, lat: 32.80123 },
    { lng: 132.87229, lat: 32.80127 },
    { lng: 132.87236, lat: 32.80133 },
    { lng: 132.87241, lat: 32.80137 },
    { lng: 132.87249, lat: 32.80142 },
    { lng: 132.87277, lat: 32.80158 },
    { lng: 132.87319, lat: 32.80181 },
    { lng: 132.87326, lat: 32.80186 },
    { lng: 132.87329, lat: 32.80188 },
    { lng: 132.87331, lat: 32.8019 },
    { lng: 132.87333, lat: 32.80194 },
    { lng: 132.87333, lat: 32.80195 },
    { lng: 132.87334, lat: 32.80199 },
    { lng: 132.87333, lat: 32.80201 },
    { lng: 132.87332, lat: 32.80207 },
    { lng: 132.87331, lat: 32.80213 },
    { lng: 132.87329, lat: 32.80218 },
    { lng: 132.87326, lat: 32.80226 },
    { lng: 132.87326, lat: 32.8023 },
    { lng: 132.87326, lat: 32.80233 },
    { lng: 132.87328, lat: 32.80241 },
    { lng: 132.87329, lat: 32.80246 },
    { lng: 132.8733, lat: 32.80252 },
    { lng: 132.87334, lat: 32.8026 },
    { lng: 132.87338, lat: 32.80266 },
    { lng: 132.87345, lat: 32.80278 },
    { lng: 132.87351, lat: 32.80289 },
    { lng: 132.87354, lat: 32.80298 },
    { lng: 132.87356, lat: 32.80302 },
    { lng: 132.8736, lat: 32.80307 },
    { lng: 132.87361, lat: 32.80309 },
    { lng: 132.87363, lat: 32.8031 },
    { lng: 132.87367, lat: 32.80312 },
    { lng: 132.87372, lat: 32.80313 },
    { lng: 132.87378, lat: 32.80313 },
    { lng: 132.87383, lat: 32.80312 },
    { lng: 132.87388, lat: 32.80311 },
    { lng: 132.87398, lat: 32.80308 },
    { lng: 132.87411, lat: 32.80304 },
    { lng: 132.87417, lat: 32.80302 },
    { lng: 132.87424, lat: 32.80301 },
    { lng: 132.87431, lat: 32.803 },
    { lng: 132.87439, lat: 32.80299 },
    { lng: 132.87446, lat: 32.80299 },
    { lng: 132.87454, lat: 32.803 },
    { lng: 132.87467, lat: 32.80303 },
    { lng: 132.87475, lat: 32.80306 },
    { lng: 132.87484, lat: 32.8031 },
    { lng: 132.87492, lat: 32.80314 },
    { lng: 132.87502, lat: 32.80317 },
    { lng: 132.87507, lat: 32.80318 },
    { lng: 132.8752, lat: 32.8032 },
    { lng: 132.87558, lat: 32.8032 },
    { lng: 132.87571, lat: 32.80318 },
    { lng: 132.87573, lat: 32.80317 },
    { lng: 132.87581, lat: 32.80315 },
    { lng: 132.87602, lat: 32.80307 },
    { lng: 132.87623, lat: 32.80298 },
    { lng: 132.87639, lat: 32.80292 },
    { lng: 132.87646, lat: 32.80289 },
    { lng: 132.87652, lat: 32.80287 },
    { lng: 132.87659, lat: 32.80286 },
    { lng: 132.87664, lat: 32.80286 },
    { lng: 132.87671, lat: 32.80288 },
    { lng: 132.87677, lat: 32.80289 },
    { lng: 132.87682, lat: 32.80292 },
    { lng: 132.87687, lat: 32.80296 },
    { lng: 132.87694, lat: 32.80304 },
    { lng: 132.87698, lat: 32.80311 },
    { lng: 132.87701, lat: 32.80318 },
    { lng: 132.87719, lat: 32.80365 },
    { lng: 132.87726, lat: 32.80382 },
    { lng: 132.8773, lat: 32.8039 },
    { lng: 132.87739, lat: 32.80404 },
    { lng: 132.87751, lat: 32.80419 },
    { lng: 132.87767, lat: 32.8044 },
    { lng: 132.87773, lat: 32.80446 },
    { lng: 132.8779, lat: 32.80462 },
    { lng: 132.87799, lat: 32.80469 },
    { lng: 132.87806, lat: 32.80475 },
    { lng: 132.87813, lat: 32.80479 },
    { lng: 132.87826, lat: 32.80487 },
    { lng: 132.87834, lat: 32.80491 },
    { lng: 132.87841, lat: 32.80494 },
    { lng: 132.8785, lat: 32.80497 },
    { lng: 132.87876, lat: 32.80504 },
    { lng: 132.87891, lat: 32.80508 },
    { lng: 132.87894, lat: 32.80509 },
    { lng: 132.87909, lat: 32.80513 },
    { lng: 132.87925, lat: 32.80519 },
    { lng: 132.87934, lat: 32.80523 },
    { lng: 132.87944, lat: 32.80529 },
    { lng: 132.8795, lat: 32.80533 },
    { lng: 132.87951, lat: 32.80534 },
    { lng: 132.87958, lat: 32.80539 },
    { lng: 132.87967, lat: 32.80546 },
    { lng: 132.87975, lat: 32.80553 },
    { lng: 132.8798, lat: 32.8056 },
    { lng: 132.87984, lat: 32.80567 },
    { lng: 132.87987, lat: 32.80576 },
    { lng: 132.87989, lat: 32.80595 },
    { lng: 132.87993, lat: 32.80623 },
    { lng: 132.87994, lat: 32.8063 },
    { lng: 132.87997, lat: 32.80655 },
    { lng: 132.8801, lat: 32.8076 },
    { lng: 132.88012, lat: 32.80778 },
    { lng: 132.88012, lat: 32.80782 },
    { lng: 132.88011, lat: 32.80785 },
    { lng: 132.88009, lat: 32.80789 },
    { lng: 132.88007, lat: 32.80791 },
    { lng: 132.88001, lat: 32.80798 },
    { lng: 132.87993, lat: 32.80804 },
    { lng: 132.87983, lat: 32.80813 },
    { lng: 132.87973, lat: 32.80823 },
    { lng: 132.87963, lat: 32.80837 },
    { lng: 132.87955, lat: 32.80855 },
    { lng: 132.87952, lat: 32.80868 },
    { lng: 132.87949, lat: 32.80887 },
    { lng: 132.8795, lat: 32.80933 },
    { lng: 132.8795, lat: 32.80938 },
    { lng: 132.87949, lat: 32.80951 },
    { lng: 132.87949, lat: 32.80956 },
    { lng: 132.87949, lat: 32.80968 },
    { lng: 132.87948, lat: 32.80988 },
    { lng: 132.8795, lat: 32.80998 },
    { lng: 132.87952, lat: 32.81004 },
    { lng: 132.87954, lat: 32.81009 },
    { lng: 132.87958, lat: 32.81014 },
    { lng: 132.87961, lat: 32.81019 },
    { lng: 132.87966, lat: 32.81024 },
    { lng: 132.87988, lat: 32.81047 },
    { lng: 132.87991, lat: 32.8105 },
    { lng: 132.87995, lat: 32.81055 },
    { lng: 132.87998, lat: 32.81059 },
    { lng: 132.88001, lat: 32.81064 },
    { lng: 132.88005, lat: 32.8107 },
    { lng: 132.88008, lat: 32.81076 },
    { lng: 132.88011, lat: 32.81083 },
    { lng: 132.88018, lat: 32.81102 },
    { lng: 132.88021, lat: 32.8111 },
    { lng: 132.88023, lat: 32.81116 },
    { lng: 132.88022, lat: 32.81119 },
    { lng: 132.88021, lat: 32.81125 },
    { lng: 132.88017, lat: 32.81136 },
    { lng: 132.88001, lat: 32.8117 },
    { lng: 132.87994, lat: 32.81185 },
    { lng: 132.87992, lat: 32.81192 },
    { lng: 132.87994, lat: 32.81205 },
    { lng: 132.87994, lat: 32.8122 },
    { lng: 132.87993, lat: 32.81231 },
    { lng: 132.87991, lat: 32.81239 },
    { lng: 132.8799, lat: 32.81246 },
    { lng: 132.8799, lat: 32.81254 },
    { lng: 132.87992, lat: 32.81258 },
    { lng: 132.87995, lat: 32.81269 },
    { lng: 132.87999, lat: 32.8128 },
    { lng: 132.88003, lat: 32.81296 },
    { lng: 132.88009, lat: 32.81311 },
    { lng: 132.88012, lat: 32.81324 },
    { lng: 132.88015, lat: 32.81337 },
    { lng: 132.88016, lat: 32.81348 },
    { lng: 132.88017, lat: 32.81368 },
    { lng: 132.88016, lat: 32.81379 },
    { lng: 132.88017, lat: 32.81389 },
    { lng: 132.88019, lat: 32.81401 },
    { lng: 132.88021, lat: 32.81409 },
    { lng: 132.88024, lat: 32.81414 },
    { lng: 132.88026, lat: 32.81416 },
  ],
  [
    { lng: 132.88028, lat: 32.81419 }, // 斧積
    { lng: 132.88032, lat: 32.81421 },
    { lng: 132.88038, lat: 32.81423 },
    { lng: 132.88052, lat: 32.81424 },
    { lng: 132.88089, lat: 32.81427 },
    { lng: 132.8815, lat: 32.8143 },
    { lng: 132.88177, lat: 32.81431 },
    { lng: 132.882, lat: 32.81433 },
    { lng: 132.88213, lat: 32.81434 },
    { lng: 132.88215, lat: 32.81434 },
    { lng: 132.88221, lat: 32.81435 },
    { lng: 132.88226, lat: 32.81434 },
    { lng: 132.88228, lat: 32.81433 },
    { lng: 132.8823, lat: 32.81431 },
    { lng: 132.88232, lat: 32.81429 },
    { lng: 132.88234, lat: 32.81426 },
    { lng: 132.88244, lat: 32.81399 },
    { lng: 132.88247, lat: 32.81377 },
    { lng: 132.88248, lat: 32.81371 },
    { lng: 132.8825, lat: 32.81359 },
    { lng: 132.88251, lat: 32.81347 },
    { lng: 132.88251, lat: 32.81289 },
    { lng: 132.88252, lat: 32.81283 },
    { lng: 132.88254, lat: 32.8128 },
    { lng: 132.88256, lat: 32.81278 },
    { lng: 132.88258, lat: 32.81275 },
    { lng: 132.88261, lat: 32.81274 },
    { lng: 132.88264, lat: 32.81274 },
    { lng: 132.88265, lat: 32.81273 },
    { lng: 132.88272, lat: 32.81272 },
    { lng: 132.88284, lat: 32.81269 },
    { lng: 132.88317, lat: 32.81262 },
    { lng: 132.88325, lat: 32.81261 },
    { lng: 132.88326, lat: 32.81261 },
    { lng: 132.88337, lat: 32.81262 },
    { lng: 132.88347, lat: 32.81264 },
    { lng: 132.88357, lat: 32.81267 },
    { lng: 132.88366, lat: 32.81271 },
    { lng: 132.88385, lat: 32.8128 },
    { lng: 132.88388, lat: 32.81281 },
    { lng: 132.88404, lat: 32.81288 },
    { lng: 132.88419, lat: 32.81295 },
    { lng: 132.88458, lat: 32.81314 },
    { lng: 132.88467, lat: 32.81318 },
    { lng: 132.88475, lat: 32.81321 },
    { lng: 132.88479, lat: 32.81322 },
    { lng: 132.88485, lat: 32.81323 },
    { lng: 132.88495, lat: 32.81324 },
    { lng: 132.88547, lat: 32.81326 },
    { lng: 132.88559, lat: 32.81328 },
    { lng: 132.88583, lat: 32.81329 },
    { lng: 132.88594, lat: 32.81329 },
    { lng: 132.88601, lat: 32.8133 },
    { lng: 132.88605, lat: 32.81332 },
    { lng: 132.88607, lat: 32.81332 },
    { lng: 132.88609, lat: 32.81334 },
    { lng: 132.88613, lat: 32.81336 },
    { lng: 132.88618, lat: 32.8134 },
    { lng: 132.88633, lat: 32.81348 },
    { lng: 132.88643, lat: 32.81352 },
    { lng: 132.88645, lat: 32.81353 },
    { lng: 132.88653, lat: 32.81355 },
    { lng: 132.88663, lat: 32.81356 },
    { lng: 132.88686, lat: 32.81356 },
    { lng: 132.88707, lat: 32.81355 },
    { lng: 132.88714, lat: 32.81356 },
    { lng: 132.88721, lat: 32.81358 },
    { lng: 132.88727, lat: 32.81361 },
    { lng: 132.88735, lat: 32.81366 },
    { lng: 132.88737, lat: 32.81369 },
    { lng: 132.88739, lat: 32.81372 },
    { lng: 132.88745, lat: 32.81385 },
    { lng: 132.88752, lat: 32.81403 },
    { lng: 132.88753, lat: 32.81408 },
    { lng: 132.88755, lat: 32.81422 },
    { lng: 132.88759, lat: 32.81432 },
    { lng: 132.88763, lat: 32.81439 },
    { lng: 132.88767, lat: 32.81444 },
    { lng: 132.88774, lat: 32.81452 },
    { lng: 132.88779, lat: 32.81456 },
    { lng: 132.88795, lat: 32.81468 },
    { lng: 132.88854, lat: 32.81512 },
    { lng: 132.88881, lat: 32.81533 },
    { lng: 132.88889, lat: 32.81539 },
    { lng: 132.88896, lat: 32.81545 },
    { lng: 132.88903, lat: 32.81549 },
    { lng: 132.88911, lat: 32.81553 },
    { lng: 132.88921, lat: 32.81557 },
    { lng: 132.8893, lat: 32.81559 },
    { lng: 132.88941, lat: 32.81561 },
    { lng: 132.88953, lat: 32.81562 },
    { lng: 132.88964, lat: 32.81561 },
    { lng: 132.88979, lat: 32.8156 },
    { lng: 132.89016, lat: 32.81554 },
    { lng: 132.89042, lat: 32.81551 },
    { lng: 132.89049, lat: 32.81549 },
    { lng: 132.89052, lat: 32.81549 },
    { lng: 132.89057, lat: 32.81548 },
    { lng: 132.89064, lat: 32.81545 },
    { lng: 132.89071, lat: 32.81542 },
    { lng: 132.89111, lat: 32.81523 },
    { lng: 132.89132, lat: 32.8151 },
    { lng: 132.89151, lat: 32.81499 },
    { lng: 132.89167, lat: 32.81491 },
    { lng: 132.89214, lat: 32.81466 },
    { lng: 132.89227, lat: 32.81459 },
    { lng: 132.8924, lat: 32.81452 },
    { lng: 132.89244, lat: 32.81449 },
    { lng: 132.89248, lat: 32.81447 },
    { lng: 132.89254, lat: 32.81445 },
    { lng: 132.89259, lat: 32.81444 },
    { lng: 132.89264, lat: 32.81443 },
    { lng: 132.89287, lat: 32.81438 },
    { lng: 132.89307, lat: 32.81433 },
    { lng: 132.89314, lat: 32.81431 },
    { lng: 132.89324, lat: 32.81428 },
    { lng: 132.89335, lat: 32.81423 },
    { lng: 132.89346, lat: 32.81418 },
    { lng: 132.89372, lat: 32.81405 },
    { lng: 132.89376, lat: 32.81402 },
    { lng: 132.8939, lat: 32.81394 },
    { lng: 132.89407, lat: 32.81415 },
    { lng: 132.89425, lat: 32.81438 },
    { lng: 132.89436, lat: 32.81453 },
    { lng: 132.89453, lat: 32.81476 },
    { lng: 132.8947, lat: 32.815 },
    { lng: 132.8948, lat: 32.81515 },
    { lng: 132.89524, lat: 32.81576 },
    { lng: 132.89535, lat: 32.81592 },
    { lng: 132.89546, lat: 32.81607 },
    { lng: 132.89562, lat: 32.81626 },
    { lng: 132.89576, lat: 32.8164 },
    { lng: 132.89585, lat: 32.8165 },
    { lng: 132.89613, lat: 32.81678 },
    { lng: 132.89621, lat: 32.81687 },
    { lng: 132.89647, lat: 32.81713 },
    { lng: 132.89654, lat: 32.81719 },
    { lng: 132.89661, lat: 32.81726 },
  ],
  [
    { lng: 132.89665, lat: 32.81729 }, // 上野
    { lng: 132.89661, lat: 32.81726 },
    { lng: 132.89654, lat: 32.81719 },
    { lng: 132.89647, lat: 32.81713 },
    { lng: 132.89621, lat: 32.81687 },
    { lng: 132.89613, lat: 32.81678 },
    { lng: 132.89585, lat: 32.8165 },
    { lng: 132.89576, lat: 32.8164 },
    { lng: 132.89562, lat: 32.81626 },
    { lng: 132.89546, lat: 32.81607 },
    { lng: 132.89535, lat: 32.81592 },
    { lng: 132.89524, lat: 32.81576 },
    { lng: 132.8948, lat: 32.81515 },
    { lng: 132.8947, lat: 32.815 },
    { lng: 132.89453, lat: 32.81476 },
    { lng: 132.89436, lat: 32.81453 },
    { lng: 132.89425, lat: 32.81438 },
    { lng: 132.89407, lat: 32.81415 },
    { lng: 132.8939, lat: 32.81394 },
    { lng: 132.89365, lat: 32.81362 },
    { lng: 132.89356, lat: 32.81351 },
    { lng: 132.89329, lat: 32.81318 },
    { lng: 132.89318, lat: 32.81303 },
    { lng: 132.89313, lat: 32.81295 },
    { lng: 132.8931, lat: 32.81287 },
    { lng: 132.89308, lat: 32.81277 },
    { lng: 132.89307, lat: 32.81268 },
    { lng: 132.89308, lat: 32.81259 },
    { lng: 132.8931, lat: 32.8125 },
    { lng: 132.89312, lat: 32.81248 },
    { lng: 132.89314, lat: 32.81242 },
    { lng: 132.89326, lat: 32.81217 },
    { lng: 132.89331, lat: 32.81209 },
    { lng: 132.89338, lat: 32.81193 },
    { lng: 132.89341, lat: 32.81184 },
    { lng: 132.89344, lat: 32.81175 },
    { lng: 132.89346, lat: 32.81166 },
    { lng: 132.89347, lat: 32.81163 },
    { lng: 132.89348, lat: 32.81158 },
    { lng: 132.89354, lat: 32.81128 },
    { lng: 132.89358, lat: 32.8111 },
    { lng: 132.89361, lat: 32.81096 },
    { lng: 132.89365, lat: 32.81074 },
    { lng: 132.89367, lat: 32.8106 },
    { lng: 132.8937, lat: 32.81038 },
    { lng: 132.89371, lat: 32.81021 },
    { lng: 132.89384, lat: 32.80871 },
    { lng: 132.89386, lat: 32.80841 },
    { lng: 132.89392, lat: 32.80769 },
    { lng: 132.89394, lat: 32.80752 },
    { lng: 132.89397, lat: 32.80737 },
    { lng: 132.89401, lat: 32.80722 },
    { lng: 132.89414, lat: 32.80685 },
    { lng: 132.8944, lat: 32.80616 },
    { lng: 132.89485, lat: 32.80498 },
    { lng: 132.89505, lat: 32.80445 },
    { lng: 132.89515, lat: 32.80422 },
    { lng: 132.89523, lat: 32.80406 },
    { lng: 132.89533, lat: 32.80385 },
    { lng: 132.89543, lat: 32.80369 },
    { lng: 132.89548, lat: 32.8036 },
    { lng: 132.89617, lat: 32.80238 },
    { lng: 132.8962, lat: 32.80233 },
    { lng: 132.89623, lat: 32.80228 },
    { lng: 132.89629, lat: 32.80218 },
    { lng: 132.89646, lat: 32.80185 },
    { lng: 132.89653, lat: 32.80172 },
    { lng: 132.89664, lat: 32.80152 },
    { lng: 132.89666, lat: 32.80148 },
    { lng: 132.89669, lat: 32.80143 },
    { lng: 132.89671, lat: 32.80141 },
    { lng: 132.89672, lat: 32.80139 },
    { lng: 132.89675, lat: 32.80137 },
    { lng: 132.89677, lat: 32.80136 },
    { lng: 132.89692, lat: 32.80128 },
  ],
  [
    { lng: 132.89684, lat: 32.80118 }, // 益野橋バス停
    { lng: 132.89677, lat: 32.80108 },
    { lng: 132.89668, lat: 32.80099 },
    { lng: 132.89663, lat: 32.80093 },
    { lng: 132.89661, lat: 32.80092 },
    { lng: 132.89659, lat: 32.8009 },
    { lng: 132.89653, lat: 32.80086 },
    { lng: 132.89638, lat: 32.80075 },
    { lng: 132.89633, lat: 32.80071 },
    { lng: 132.89611, lat: 32.80056 },
    { lng: 132.89594, lat: 32.80047 },
    { lng: 132.8955, lat: 32.80019 },
    { lng: 132.89532, lat: 32.80009 },
    { lng: 132.89515, lat: 32.79998 },
    { lng: 132.89508, lat: 32.79994 },
    { lng: 132.89498, lat: 32.79987 },
    { lng: 132.8949, lat: 32.79982 },
    { lng: 132.89474, lat: 32.7997 },
    { lng: 132.89467, lat: 32.79965 },
    { lng: 132.89452, lat: 32.79952 },
    { lng: 132.89427, lat: 32.79931 },
    { lng: 132.89412, lat: 32.79918 },
    { lng: 132.89398, lat: 32.79905 },
    { lng: 132.89343, lat: 32.7985 },
    { lng: 132.89336, lat: 32.79843 },
    { lng: 132.89296, lat: 32.79803 },
    { lng: 132.89249, lat: 32.79757 },
    { lng: 132.89205, lat: 32.79713 },
    { lng: 132.89123, lat: 32.79631 },
    { lng: 132.89111, lat: 32.7962 },
    { lng: 132.89098, lat: 32.79607 },
    { lng: 132.8909, lat: 32.796 },
    { lng: 132.8908, lat: 32.79592 },
    { lng: 132.89068, lat: 32.79583 },
    { lng: 132.89046, lat: 32.79569 },
    { lng: 132.89027, lat: 32.79557 },
    { lng: 132.89017, lat: 32.79551 },
    { lng: 132.88999, lat: 32.79542 },
    { lng: 132.88981, lat: 32.79533 },
    { lng: 132.88968, lat: 32.79527 },
    { lng: 132.88958, lat: 32.79522 },
    { lng: 132.88949, lat: 32.79518 },
  ],
  [
    { lng: 132.88936, lat: 32.79512 }, // 浜益野
    { lng: 132.8893, lat: 32.7951 },
    { lng: 132.88919, lat: 32.79507 },
    { lng: 132.88915, lat: 32.79506 },
    { lng: 132.88904, lat: 32.79504 },
    { lng: 132.88891, lat: 32.79503 },
    { lng: 132.8887, lat: 32.79502 },
    { lng: 132.88785, lat: 32.79495 },
    { lng: 132.88753, lat: 32.79493 },
    { lng: 132.88731, lat: 32.79491 },
    { lng: 132.88689, lat: 32.79488 },
    { lng: 132.8866, lat: 32.79486 },
    { lng: 132.88646, lat: 32.79485 },
    { lng: 132.88604, lat: 32.79481 },
    { lng: 132.88593, lat: 32.7948 },
    { lng: 132.88583, lat: 32.79478 },
    { lng: 132.88573, lat: 32.79475 },
    { lng: 132.88563, lat: 32.79473 },
    { lng: 132.88552, lat: 32.79469 },
    { lng: 132.88543, lat: 32.79466 },
    { lng: 132.88533, lat: 32.79463 },
    { lng: 132.88523, lat: 32.79459 },
    { lng: 132.88509, lat: 32.79454 },
    { lng: 132.88504, lat: 32.79452 },
    { lng: 132.88496, lat: 32.79449 },
    { lng: 132.88491, lat: 32.79446 },
    { lng: 132.88485, lat: 32.79443 },
    { lng: 132.88477, lat: 32.79438 },
    { lng: 132.88472, lat: 32.79435 },
    { lng: 132.88468, lat: 32.79433 },
    { lng: 132.88464, lat: 32.7943 },
    { lng: 132.88461, lat: 32.79427 },
    { lng: 132.88457, lat: 32.79424 },
    { lng: 132.88453, lat: 32.79421 },
    { lng: 132.88449, lat: 32.79418 },
    { lng: 132.88446, lat: 32.79415 },
    { lng: 132.88403, lat: 32.79376 },
    { lng: 132.88402, lat: 32.79376 },
    { lng: 132.88381, lat: 32.79355 },
    { lng: 132.88365, lat: 32.79336 },
    { lng: 132.88362, lat: 32.79331 },
    { lng: 132.88358, lat: 32.79327 },
    { lng: 132.88353, lat: 32.7932 },
    { lng: 132.88349, lat: 32.79314 },
    { lng: 132.88334, lat: 32.79288 },
    { lng: 132.88328, lat: 32.7928 },
    { lng: 132.88311, lat: 32.79253 },
    { lng: 132.88305, lat: 32.79242 },
    { lng: 132.88292, lat: 32.79221 },
    { lng: 132.88281, lat: 32.79202 },
    { lng: 132.88268, lat: 32.79181 },
    { lng: 132.88265, lat: 32.79177 },
    { lng: 132.88263, lat: 32.79174 },
    { lng: 132.88261, lat: 32.79173 },
    { lng: 132.8826, lat: 32.79171 },
    { lng: 132.88256, lat: 32.79167 },
    { lng: 132.88238, lat: 32.7915 },
    { lng: 132.88224, lat: 32.79138 },
    { lng: 132.88205, lat: 32.79121 },
    { lng: 132.882, lat: 32.79117 },
    { lng: 132.88199, lat: 32.79115 },
    { lng: 132.88196, lat: 32.79113 },
    { lng: 132.88192, lat: 32.7911 },
    { lng: 132.8819, lat: 32.79109 },
    { lng: 132.88188, lat: 32.79108 },
    { lng: 132.88184, lat: 32.79106 },
    { lng: 132.88182, lat: 32.79105 },
    { lng: 132.8818, lat: 32.79104 },
    { lng: 132.88177, lat: 32.79103 },
    { lng: 132.88175, lat: 32.79102 },
    { lng: 132.88173, lat: 32.79102 },
    { lng: 132.8817, lat: 32.79101 },
    { lng: 132.88168, lat: 32.79101 },
    { lng: 132.88165, lat: 32.791 },
    { lng: 132.88161, lat: 32.791 },
    { lng: 132.88151, lat: 32.79098 },
    { lng: 132.88145, lat: 32.79098 },
    { lng: 132.88126, lat: 32.79096 },
    { lng: 132.88118, lat: 32.79096 },
    { lng: 132.88087, lat: 32.79093 },
    { lng: 132.88078, lat: 32.79093 },
    { lng: 132.88067, lat: 32.79094 },
    { lng: 132.88057, lat: 32.79095 },
    { lng: 132.88046, lat: 32.79097 },
    { lng: 132.88036, lat: 32.791 },
    { lng: 132.88026, lat: 32.79103 },
    { lng: 132.88016, lat: 32.79105 },
    { lng: 132.88004, lat: 32.79108 },
    { lng: 132.87996, lat: 32.79111 },
    { lng: 132.87985, lat: 32.79112 },
    { lng: 132.8798, lat: 32.79113 },
    { lng: 132.87974, lat: 32.79113 },
    { lng: 132.87963, lat: 32.79113 },
    { lng: 132.87953, lat: 32.79112 },
    { lng: 132.87942, lat: 32.79111 },
    { lng: 132.87932, lat: 32.7911 },
    { lng: 132.87905, lat: 32.79107 },
    { lng: 132.87868, lat: 32.79103 },
    { lng: 132.87782, lat: 32.79093 },
    { lng: 132.87711, lat: 32.79086 },
    { lng: 132.87679, lat: 32.79083 },
    { lng: 132.87658, lat: 32.7908 },
    { lng: 132.87617, lat: 32.79076 },
  ],
  /*
    [{lng: 132.87617, lat: 32.79076}, // 三崎バス停
    {lng: 132.87658, lat: 32.7908},
    {lng: 132.87679, lat: 32.79083},
    {lng: 132.87678, lat: 32.79094},
    {lng: 132.87678, lat: 32.79098},
    {lng: 132.87678, lat: 32.791},
    {lng: 132.8768, lat: 32.79103},
    {lng: 132.87682, lat: 32.79106},
    {lng: 132.87688, lat: 32.79116},
    {lng: 132.87697, lat: 32.79133},
    {lng: 132.877, lat: 32.79138},
    {lng: 132.87716, lat: 32.79165}], // 三崎市民センター
  */
];
