import React, { useState, useEffect } from "react";
import GoogleMapReact from "google-map-react";

import { CourseDataService, Position, CourseData } from "../types/data_types";
import { mapKey } from "../config";

import * as Utils from "../common/Utils";
import { kochiPosition, defaultCenter } from "./utils/position";

function UserGoogleMap(props: any) {
  const { currentReservation, lineData } = props;

  const [center, setCenter] = useState(kochiPosition);
  const [zoom, setZoom] = useState(11);
  // Google js instances
  const [myGoogleMap, setMyGoogleMap] = useState<{
    map: any;
    maps: any;
  } | null>(null);

  const [onPositionPin, setOnPositionPin] = useState<google.maps.Marker | null>(
    null,
  );
  const [offPositionPin, setOffPositionPin] =
    useState<google.maps.Marker | null>(null);
  const [currentPolyline, setCurrentPolyline] =
    useState<google.maps.Polyline | null>(null);

  const getPath = (maps: any, courseData: CourseData): google.maps.Polyline => {
    const inbound = (
      courseData.services.find(
        (ele: CourseDataService) => ele.id === currentReservation.serviceId,
      ) || ({} as any)
    ).inbound;

    const path: Position[] = [];
    courseData.ways.flat().forEach((p: Position, key: number) => {
      const ret = { lat: p.lat, lng: p.lng };
      const onIndex = currentReservation.onPosition.positionIndex;
      const offIndex = currentReservation.offPosition.positionIndex;
      if (inbound) {
        if (onIndex <= key && key <= offIndex) {
          path.push(ret);
        }
      } else {
        if (offIndex <= key && key <= onIndex) {
          path.push(ret);
        }
      }
    });
    return new maps.Polyline({
      path: path,
      geodesic: true,
      strokeColor: "#356859",
      strokeOpacity: 1,
      strokeWeight: 3,
    });
  };

  const moveToLine = (key: number) => {
    setZoom(13);
    const center =
      lineData[key].ways.flat()[
        Math.round(lineData[key].ways.flat().length / 2)
      ];
    setCenter(center);
  };

  const clearAllLines = () => {
    if (currentPolyline) {
      currentPolyline.setMap(null);
    }
  };
  useEffect(() => {
    const showLine = (key: number) => {
      if (myGoogleMap !== null) {
        const map = myGoogleMap.map;
        const line = lineData[key];
        const polyline = getPath(myGoogleMap.maps, line);
        polyline.setMap(map);
        setCurrentPolyline(polyline);

        if (onPositionPin) {
          onPositionPin.setMap(null);
        }
        if (offPositionPin) {
          offPositionPin.setMap(null);
        }
        const onMarker = setMarker(
          myGoogleMap,
          Utils.getPinPosition(currentReservation.onPosition),
          "/images/start.png",
          currentReservation.onPosition.name,
          "#00F",
        );
        const offMarker = setMarker(
          myGoogleMap,
          Utils.getPinPosition(currentReservation.offPosition),
          "/images/goal.png",
          currentReservation.offPosition.name,
          "#F00",
        );
        setOnPositionPin(onMarker);
        setOffPositionPin(offMarker);
      }
    };

    (async () => {
      if (myGoogleMap) {
        clearAllLines();
        showLine(currentReservation.lineId);
        moveToLine(currentReservation.lineId);
        myGoogleMap.map.setZoom(zoom);
      }
    })();
  }, [myGoogleMap, currentReservation, zoom]); // eslint-disable-line react-hooks/exhaustive-deps

  const setMarker = (
    myMap: any,
    position: Position,
    icon: string,
    name: string,
    color: string,
  ): google.maps.Marker => {
    const marker = new myMap.maps.Marker({
      position,
      map: myMap.map,
      icon: {
        url: icon,
        labelOrigin: new myMap.maps.Point(0, 70),
      },
      label: {
        text: name,
        color: color,
        fontFamily: "Arial",
        fontSize: "20",
        fontWeight: "bold",
      },
    });
    return marker;
  };

  const renderMarkers = (map: any, maps: google.maps.Map) => {
    setMyGoogleMap({ maps, map });
  };

  return (
    <React.Fragment>
      <GoogleMapReact
        bootstrapURLKeys={{ key: mapKey }}
        defaultCenter={defaultCenter}
        yesIWantToUseGoogleMapApiInternals={true}
        center={center}
        defaultZoom={11}
        zoom={zoom}
        onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
      ></GoogleMapReact>
    </React.Fragment>
  );
}

export default UserGoogleMap;
