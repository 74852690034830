import { useState, useEffect } from "react";
import firebase from "firebase/compat/app";

// This function asynchronously fetches a Firestore document specified by the path and returns it.
// It returns null the document does not exist.
// It returns undefined if the path is null or undefined.
// WARNING: queryFilter must be a static function, not a callback function.
// Otherwise, it will be called infinitly.
function useOnCollection<T = any>(
  db: firebase.firestore.Firestore,
  path: string,
  option = null as any,
) {
  const [collection, setCollection] = useState<T[]>([]);
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    let detacher = () => {};
    if (path) {
      try {
        let ref = db.collection(path);
        if (option && option.queryFilter) {
          ref = option.queryFilter(ref); // optional query filters
        }
        detacher = ref.onSnapshot(
          (snapshot: firebase.firestore.QuerySnapshot) => {
            const list: T[] = [];
            snapshot.forEach((doc) => {
              const data = doc.data();
              data.id = doc.id;
              if (option && option.responseFilter) {
                list.push(option.responseFilter(data));
              } else {
                list.push(data as T);
              }
            });
            setCollection(list);
          },
          (err: any) => {
            console.log("err 1", err);
            setCollection([]);
            setError(err);
          },
        );
      } catch (err) {
        console.log("err 2", err);
        setError(err);
      }
    } else {
      setCollection([]);
    }
    return detacher;
  }, [db, path, option]);

  return [collection, error];
}

export default useOnCollection;
