import React, { useState, useEffect } from "react";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import Home from "./Home";
import About from "./About";
import Login from "./Login";
import Login2 from "./Login2";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import * as config from "./config";

import AdminRouter from "./AdminRouter";

import LoginRequire from "./LoginRequire";
import MessageModal from "./common/MessageModal";

import User from "./models/user";

if (firebase.apps.length === 0) {
  firebase.initializeApp(config.firebaseConfig);
}
const db = firebase.firestore();

function App() {
  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [messageType, setMessageType] = useState("");

  const [user, setUser] = useState<User | false | null>(null);

  useEffect(() => {
    const unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged(async (_user) => {
        if (_user) {
          const refUser = db.doc(`users/${_user.uid}`);
          const userData = (await refUser.get()).data();
          console.log(userData);
          if (userData && (userData.phoneNumber || userData.email)) {
            const userInstance = new User(userData);
            setUser(userInstance);
          } else {
            const data = {
              phoneNumber: _user.phoneNumber,
              uid: _user.uid,
              email: _user.email ?? undefined,
            };
            await refUser.set(data);
            const userInstance = new User(data);
            setUser(userInstance);
          }
        } else {
          setUser(false);
        }
      });
    return unregisterAuthObserver;
  }, []);

  const params = { user, db, setMessageModalOpen, setMessageType };
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path="/" element={<Home {...params} />} />
          <Route path="/about" element={<About {...params} />} />
          <Route
            path="/admin/*"
            element={
              user ? (
                <AdminRouter user={user} db={db} />
              ) : (
                <LoginRequire {...params} />
              )
            }
          />
          <Route path="/login" element={<Login {...params} />} />
          <Route path="/login2" element={<Login2 {...params} />} />
          <Route path="/login/target/*" element={<Login {...params} />} />
        </Routes>
      </Router>
      <MessageModal
        modalOpen={messageModalOpen}
        toggle={() => {
          setMessageModalOpen(!messageModalOpen);
        }}
        messageType={messageType}
      />
    </MuiThemeProvider>
  );
}

export default App;
