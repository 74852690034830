// import * as courses1 from './courses1';
// import * as courses2 from './courses2';
import * as courses3 from "./courses3";
import * as courses4 from "./courses4";
// import * as courses5 from './courses5';
// import * as courses6 from './courses6';
import * as courses7 from "./courses7";
// import * as courses8 from './courses8';
// import * as courses9 from './courses9';

import { CourseData, AllLineData } from "../types/data_types";

const lineDataArray = [
  //  courses1.data,
  //  courses2.data,
  courses3.data,
  courses4.data,
  //  courses5.data,
  //courses6.data,
  courses7.data,
  //  courses8.data,
  //  courses9.data,
];

export const allLineData: AllLineData = lineDataArray.reduce(
  (tmp: AllLineData, current: CourseData) => {
    if (!tmp[current.companyId]) {
      tmp[current.companyId] = {};
    }
    tmp[current.companyId][current.id] = current;
    return tmp;
  },
  {},
);
