const holidays: any = {
  "2020-01-01": "元日",
  "2020-01-13": "成人の日",
  "2020-02-11": "建国記念の日",
  "2020-02-23": "天皇誕生日",
  "2020-02-24": "天皇誕生日 振替休日",
  "2020-03-20": "春分の日",
  "2020-04-29": "昭和の日",
  "2020-05-03": "憲法記念日",
  "2020-05-04": "みどりの日",
  "2020-05-05": "こどもの日",
  "2020-05-06": "憲法記念日 振替休日",
  "2020-07-23": "海の日",
  "2020-07-24": "スポーツの日",
  "2020-08-10": "山の日",
  "2020-09-21": "敬老の日",
  "2020-09-22": "秋分の日",
  "2020-11-03": "文化の日",
  "2020-11-23": "勤労感謝の日",
  "2021-01-01": "元日",
  "2021-01-11": "成人の日",
  "2021-02-11": "建国記念の日",
  "2021-02-23": "天皇誕生日",
  "2021-03-20": "春分の日",
  "2021-04-29": "昭和の日",
  "2021-05-03": "憲法記念日",
  "2021-05-04": "みどりの日",
  "2021-05-05": "こどもの日",
  "2021-07-22": "海の日",
  "2021-07-23": "スポーツの日",
  "2021-08-08": "山の日",
  "2021-08-09": "休日 山の日",
  "2021-09-20": "敬老の日",
  "2021-09-23": "秋分の日",
  "2021-11-03": "文化の日",
  "2021-11-23": "勤労感謝の日",
  "2022-01-01": "元日",
  "2022-01-10": "成人の日",
  "2022-02-11": "建国記念の日",
  "2022-02-23": "天皇誕生日",
  "2022-03-21": "春分の日",
  "2022-04-29": "昭和の日",
  "2022-05-03": "憲法記念日",
  "2022-05-04": "みどりの日",
  "2022-05-05": "こどもの日",
  "2022-07-18": "海の日",
  "2022-08-11": "山の日",
  "2022-09-19": "敬老の日",
  "2022-09-23": "秋分の日",
  "2022-10-10": "スポーツの日",
  "2022-11-03": "文化の日",
  "2022-11-23": "勤労感謝の日",
  "2023-01-01": "元日",
  "2023-01-02": "休日 元日",
  "2023-01-09": "成人の日",
  "2023-02-11": "建国記念の日",
  "2023-02-23": "天皇誕生日",
  "2023-03-21": "春分の日",
  "2023-04-29": "昭和の日",
  "2023-05-03": "憲法記念日",
  "2023-05-04": "みどりの日",
  "2023-05-05": "こどもの日",
  "2023-07-17": "海の日",
  "2023-08-11": "山の日",
  "2023-09-18": "敬老の日",
  "2023-09-23": "秋分の日",
  "2023-10-09": "スポーツの日",
  "2023-11-03": "文化の日",
  "2023-11-23": "勤労感謝の日",
  "2024-01-01": "元日",
  "2024-01-08": "成人の日",
  "2024-02-11": "建国記念の日",
  "2024-02-12": "建国記念の日 振替休日",
  "2024-02-23": "天皇誕生日",
  "2024-03-20": "春分の日",
  "2024-04-29": "昭和の日",
  "2024-05-03": "憲法記念日",
  "2024-05-04": "みどりの日",
  "2024-05-05": "こどもの日",
  "2024-05-06": "こどもの日 振替休日",
  "2024-07-15": "海の日",
  "2024-08-11": "山の日",
  "2024-08-12": "休日 山の日",
  "2024-09-16": "敬老の日",
  "2024-09-22": "秋分の日",
  "2024-09-23": "秋分の日 振替休日",
  "2024-10-14": "スポーツの日",
  "2024-11-03": "文化の日",
  "2024-11-04": "文化の日 振替休日",
  "2024-11-23": "勤労感謝の日",
};
export default holidays;
