import firebase from "firebase/compat/app";
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";

import * as Utils from "../common/Utils";

import DailyLogView from "./DailyLogView";

import useOnCollectionGroup from "../common/useOnCollectionGroup";

import moment from "moment";
import "moment/locale/ja";

import {
  moment2min,
  getActualDrivingTimeMinutes,
  getAllDrivingTimeMinutes,
  getForwardDrivingTimes,
} from "./utils/DailyLogUtil";

import {
  LineData,
  CourseDataService,
  CourseData,
  DailyLogServiceData,
  DailyLogSummary,
  ValidLog,
  Company,
} from "../types/data_types";

import { useNavigate, useLocation, useParams } from "react-router-dom";

type Props = {
  db: firebase.firestore.Firestore;
  lineData: LineData;
  setHeaderTitle: any;
  company: Company;
};

const DailyLog: React.FC<Props> = ({
  db,
  lineData,
  setHeaderTitle,
  company,
}) => {
  const { year, month, day } = useParams();

  const now = moment(`${year}-${month}-${day}`);

  const [validLogQuery, setValidLogQuery] = useState({});
  const [validLogs] = useOnCollectionGroup(db, "validLog", validLogQuery);
  const [validLogObject, setValidLogObject] = useState<{
    [key: string]: { [key: number]: ValidLog };
  }>({});

  const [carLogs] = useOnCollectionGroup(db, "mileageMeter", validLogQuery);
  const [carLogObject, setCarLogObject] = useState<any>({});

  const [current, setCurrent] = useState(now);
  const lineDataArray = Object.values(lineData);
  const navigate = useNavigate();

  useEffect(() => {
    setHeaderTitle("日報");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const query = {
      queryFilter: (query: any) => {
        return query
          .where("date", "==", current.format("YYYY-MM-DD"))
          .where("companyId", "==", company.id);
      },
    };
    setValidLogQuery(query);
  }, [current]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const data = Utils.convData(validLogs, "");
    setValidLogObject(data);
  }, [validLogs]);

  useEffect(() => {
    if (carLogs) {
      const tmpLog: any = carLogs.reduce((tmp: any, log: any) => {
        if (!tmp[log.lineId]) {
          tmp[log.lineId] = {};
        }
        tmp[log.lineId][log.serviceId] = log;
        return tmp;
      }, {});
      setCarLogObject(tmpLog);
    }
  }, [carLogs]);

  const decreaseDate = () => {
    const newCurrent = current.clone();
    newCurrent.add(-1, "day");
    navigate(`/admin/dailylog/${newCurrent.format("YYYY/MM/DD")}`);
    setCurrent(newCurrent);
  };
  const increaseDate = () => {
    const newCurrent = current.clone();
    newCurrent.add(1, "day");
    navigate(`/admin/dailylog/${newCurrent.format("YYYY/MM/DD")}`);
    setCurrent(newCurrent);
  };
  const location = useLocation();
  useEffect(() => {
    const [, , , year, month, day] = location.pathname.split("/");
    const now = moment(`${year}-${month}-${day}`);
    setCurrent(now);
  }, [location]);

  const totalDefault = {
    number: 0,
    price: 0,
    cach: 0,
    taxiTicket: 0,
    localCurrency: 0,
    serviceDistance: 0,
    distance: 0,
    operationTime: 0,
    driveTime: 0,
    actualDrivingTimes: 0,
    forwardDrivingTimes: 0,
  };
  const total = { ...totalDefault };

  const dailyLogData: DailyLogSummary[] = lineDataArray.map(
    (courseData: CourseData, lineKey: number) => {
      const subTotal = { ...totalDefault };
      const serviceLogs: DailyLogServiceData[] = courseData.services.map(
        (service: CourseDataService, serviceKey: number) => {
          const serviceId = service.id;
          const cyclick = serviceKey % 2 === 0;

          const start = courseData.pins[0];
          const end = courseData.pins[courseData.pins.length - 1];

          const existValidLog = !!(validLogObject[courseData.id] || {})[
            serviceId
          ];
          const existDriveLog = !!(carLogObject[courseData.id] || {})[
            serviceId
          ];
          const materLog = existDriveLog
            ? carLogObject[courseData.id][serviceId]
            : {};
          const alert = existDriveLog && !existValidLog;

          const log = (validLogObject[courseData.id] || {})[serviceId] || {};
          const carLog = log.carLog || {};

          const distance =
            (carLog.endDistance || 0) - (carLog.startDistance || 0);
          const operationTime =
            carLog.endAt && carLog.startAt
              ? moment2min(carLog.endAt) - moment2min(carLog.startAt)
              : 0;
          const driveTime =
            carLog.minTimeEndAt && carLog.minTimeStartAt
              ? moment2min(carLog.minTimeEndAt) -
                moment2min(carLog.minTimeStartAt)
              : 0;

          const meterDistance =
            (carLog.startDistance || materLog.startDistance) &&
            (carLog.endDistance || materLog.endDistance)
              ? (carLog.endDistance || materLog.endDistance) -
                (carLog.startDistance || materLog.startDistance)
              : "-";

          const actualDrivingTime = getActualDrivingTimeMinutes(carLog);
          const allDrivingTime = getAllDrivingTimeMinutes(carLog);
          const forwardDrivingTimes = getForwardDrivingTimes(
            actualDrivingTime,
            allDrivingTime,
          );

          Utils.objectAddition(subTotal, {
            number: log.totalNumber || log.totalNubmer || 0,
            price: log.totalPrice || 0,
            cach: log.cash || 0,
            taxiTicket: log.taxi_ticket || 0,
            localCurrency: log.local_currency || 0,
            serviceDistance: carLog.distance || 0,
            distance,
            driveTime: driveTime,
            operationTime: operationTime,
            actualDrivingTimes: actualDrivingTime || 0,
            forwardDrivingTimes: forwardDrivingTimes || 0,
          });

          return {
            service,
            serviceId,
            cyclick,
            start,
            end,
            alert,
            log,
            carLog,
            materLog,
            meterDistance,
            actualDrivingTime,
            forwardDrivingTimes,
          };
        },
      );
      Utils.objectAddition(total, subTotal);
      return {
        courseData,
        serviceLogs,
        subTotal,
      };
    },
  );

  const params = {
    dailyLogData,
    total,
    current,
  };
  return (
    <Grid container alignItems="stretch" direction="row">
      <Grid item xs={12}>
        <h2>
          <button
            onClick={(e) => {
              decreaseDate();
            }}
          >
            &#9664;
          </button>
          {current.format("YYYY-MM-DD")}
          <button
            onClick={(e) => {
              increaseDate();
            }}
          >
            &#9654;
          </button>
        </h2>
        <br />
      </Grid>

      <DailyLogView {...params} />
    </Grid>
  );
};

export default DailyLog;
