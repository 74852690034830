import React, { useEffect, useState } from "react";
import { Grid, Button, Select, MenuItem } from "@mui/material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { styled } from "@mui/system";

import Calendar from "react-calendar";

import { useParams } from "react-router-dom";

import AnchorLink from "react-anchor-link-smooth-scroll";

import useOnCollectionGroup from "../common/useOnCollectionGroup";
import useOnCollection from "../common/useOnCollection";

import ReservationTable from "./ReservationTable";
import ReservationCustomerTable from "./ReservationCustomerTable";

import { ArrowForward, ArrowBack } from "@mui/icons-material";

import * as Utils from "../common/Utils";

import moment from "moment";

import {
  CustomerData,
  ReservationData,
  CarData,
  DriverData,
} from "../types/data_types";

const ReservationButtonB = styled("b")(({ theme }) => ({
  fontSize: "14px",
}));
const ReservationButton = styled("button")(({ theme }) => ({
  fontSize: "14px",
}));

function Reservations(props: any) {
  const { db, lineData, setHeaderTitle, company, ...other } = props;
  const { year, month, day, _line, _service } = useParams();
  const defaltDate = year && month && day ? `${year}-${month}-${day}` : null;

  const [lineQuery, setLineQuery] = useState({});

  const [lineReservations, error] = useOnCollectionGroup(
    db,
    "lineReservations",
    lineQuery,
  );
  const [calanderDate, setCalenderDate] = useState(
    defaltDate ? new Date(defaltDate) : new Date(),
  );

  const [currentLine, setCurrentLine] = useState(
    _line || Object.keys(lineData)[0],
  );
  const [currentService, setCurrentService] = useState(Number(_service || 0));
  const [driverSchedule, setDriverSchedule] = useState<any>({});

  const driverPath = `companies/${company.id}/drivers`;
  const [driverData, error2] = useOnCollection<DriverData>(db, driverPath);
  const [filteredDriverData, setFilteredDriverData] = useState<DriverData[]>(
    [],
  );

  const carPath = `companies/${company.id}/cars`;
  const [carData, error3] = useOnCollection<CarData>(db, carPath);
  const [filteredCarData, setFilteredCarData] = useState<CarData[]>([]);

  const [open, setOpen] = useState<boolean>(false);
  const [currentValue, setCurrentValue] = useState<any>({});

  const [customers, error4] = useOnCollection<CustomerData>(
    db,
    `companies/${company.id}/customers`,
  );
  const [customerObj, setCustomerObj] = useState<{
    [key: string]: CustomerData;
  }>({});

  useEffect(() => {
    const tmp = (driverData || []).filter((data: any) => {
      return !(data || ({} as any)).left;
    });
    setFilteredDriverData(tmp);
  }, [driverData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const tmp = (carData || []).filter((data: any) => {
      return !(data || ({} as any)).left;
    });
    setFilteredCarData(tmp);
  }, [carData]); // eslint-disable-line react-hooks/exhaustive-deps

  const [openMemo, setOpenMemo] = useState(false);
  const [memo, setMemo] = useState("");

  useEffect(() => {
    setHeaderTitle("日付別 予約一覧／運行計画");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const ret: { [key: string]: CustomerData } = {};
    (customers || []).forEach((customer: CustomerData) => {
      ret[customer.id] = customer;
    });
    setCustomerObj(ret);
  }, [customers]);

  useEffect(() => {
    const from = moment(calanderDate);
    const query = {
      queryFilter: (query: any) => {
        return query
          .where("date", "==", from.format("YYYY-MM-DD"))
          .where("companyId", "==", company.id);
      },
    };
    setLineQuery(query);

    (async () => {
      const current = moment(calanderDate);

      const mykey = current.startOf("month").format("YYYY-MM");
      const doc = await db
        .doc(`companies/${company.id}/driverScedule/${mykey}`)
        .get();
      if (doc.exists) {
        setDriverSchedule(doc.data());
      }
    })();
  }, [calanderDate]); // eslint-disable-line react-hooks/exhaustive-deps

  const convLinesReservation = (linesReservations: ReservationData[]) => {
    return linesReservations
      .sort((a: ReservationData, b: ReservationData) => {
        if (a.serviceId === b.serviceId) {
          return a.onPosition.positionIndex > b.onPosition.positionIndex
            ? 1
            : -1;
        }
        return a.serviceId > b.serviceId ? 1 : -1;
      })
      .reduce(
        (
          ret: { [key: string]: { [key: number]: ReservationData[] } },
          data: ReservationData,
        ) => {
          console.log(data);
          if (!ret[data.lineId]) {
            ret[data.lineId] = {};
          }
          if (!ret[data.lineId][data.serviceId]) {
            ret[data.lineId][data.serviceId] = [];
          }
          ret[data.lineId][data.serviceId].push(data);
          return ret;
        },
        {},
      );
  };

  const linesReservationObj = convLinesReservation(lineReservations || []);

  if (error || error2 || error3 || error4) {
    return <div>error</div>;
  }

  const lineDataArray = Object.values(lineData);

  const defaultSchedule = (lineId: string, serviceId: string) => {
    const current = moment(calanderDate);
    const _driverData = driverSchedule[current.format("YYYY-MM-DD")];
    if (_driverData) {
      if (
        _driverData[lineId]["custom"] &&
        _driverData[lineId]["custom"][serviceId]
      ) {
        return _driverData[lineId]["custom"][serviceId];
      }
      return _driverData[lineId];
    }
    return {};
  };
  const car = (data: any) => {
    if (filteredCarData && data) {
      const carObj: any = Utils.array2obj(filteredCarData, "id");
      if (carObj[data.car]) {
        return carObj[data.car];
      }
    }
    return { name: "--" };
  };

  const driver = (data: any) => {
    if (filteredDriverData && data) {
      const driverObj: any = Utils.array2obj(filteredDriverData, "id");
      if (driverObj[data.driver]) {
        return driverObj[data.driver];
      }
    }
    return { name: "--" };
  };
  const handleCancel = () => {
    setCurrentValue({});
    setOpen(false);
  };
  const handleUpdate = async () => {
    const current = moment(calanderDate);

    const updateData = { ...driverSchedule };
    if (!updateData[current.format("YYYY-MM-DD")][currentLine]["custom"]) {
      updateData[current.format("YYYY-MM-DD")][currentLine]["custom"] = {};
    }
    updateData[current.format("YYYY-MM-DD")][currentLine]["custom"][
      currentService
    ] = currentValue;

    const mykey = current.startOf("month").format("YYYY-MM");
    await db
      .doc(`companies/${company.id}/driverScedule/${mykey}`)
      .set(updateData);
    setDriverSchedule(updateData);

    setOpen(false);
  };
  const handleOpen = (line: any, service: any, c: any, d: any) => {
    setCurrentLine(line);
    setCurrentService(service);
    const value = {
      car: c.id,
      driver: d.id,
    };
    setCurrentValue(value);
    setOpen(true);
  };
  const updateValue = (key: string, value: any) => {
    const newValue = { ...currentValue };
    newValue[key] = value;
    setCurrentValue(newValue);
  };
  const onChange = (key: string) => {
    return (value: any) => {
      updateValue(key, value);
    };
  };

  return (
    <React.Fragment>
      <Grid container alignItems="stretch" direction="row">
        <Grid item xs={6}>
          <Calendar
            value={calanderDate}
            onChange={(date: any) => setCalenderDate(date)}
            formatDay={(locale, date) => {
              return String(date.getDate());
            }}
          />
        </Grid>
        <Grid item xs={6}>
          {lineDataArray.map((line: any, lkey: number) => {
            return (
              <Grid item xs={12} key={"l-" + lkey}>
                {line.services.map((service: any, key: number) => {
                  return (
                    <AnchorLink
                      href={"#" + line.id + "_" + service.id}
                      key={key}
                      style={{ lineHeight: 1.8, fontSize: "1.1rem" }}
                    >
                      {service.name}
                      <br />
                    </AnchorLink>
                  );
                })}
              </Grid>
            );
          })}
        </Grid>

        {lineDataArray.map((line: any, lkey) => {
          return (
            <Grid item xs={12} key={"l-" + lkey}>
              {line.services.map((service: any) => {
                const serviceId = service.id;
                const reservations = ((linesReservationObj || {})[line.id] ||
                  {})[service.id];
                const params = {
                  reservations,
                  lineId: line.id,
                  service,
                  serviceId: service.id,
                  customerObj,
                  setOpenMemo,
                  setMemo,
                  lineData,
                };
                const schedule = defaultSchedule(line.id, serviceId);
                return (
                  <Grid item xs={12} key={"s-" + serviceId}>
                    <h3 id={line.id + "_" + serviceId}>
                      {serviceId === 0 ? line.name : ""}
                    </h3>
                    <div>
                      <ReservationButtonB>
                        {moment(calanderDate).format("YYYY年MM月DD日(ddd)")}
                        {service.inbound ? (
                          <ArrowForward style={{ verticalAlign: "middle" }} />
                        ) : (
                          <ArrowBack style={{ verticalAlign: "middle" }} />
                        )}{" "}
                        &nbsp; {service.name} &nbsp;
                      </ReservationButtonB>
                      <ReservationButton
                        onClick={(e) => {
                          handleOpen(
                            line.id,
                            serviceId,
                            car(schedule),
                            driver(schedule),
                          );
                        }}
                      >
                        {car(schedule).name}
                      </ReservationButton>{" "}
                      &nbsp;
                      <ReservationButton
                        onClick={(e) => {
                          handleOpen(
                            line.id,
                            serviceId,
                            car(schedule),
                            driver(schedule),
                          );
                        }}
                      >
                        {driver(schedule).name}
                      </ReservationButton>
                    </div>
                    {reservations ? (
                      <ReservationTable {...other} {...params} />
                    ) : (
                      ""
                    )}
                    {reservations ? (
                      <ReservationCustomerTable {...other} {...params} />
                    ) : (
                      ""
                    )}
                  </Grid>
                );
              })}
            </Grid>
          );
        })}
      </Grid>
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {
            lineData[currentLine].services.find(
              (ele: any) => ele.id === currentService,
            ).name
          }
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Select
              label={"号数"}
              value={currentValue["car"]}
              onChange={(e) => {
                onChange("car")(e.target.value);
              }}
            >
              {(filteredCarData || []).map((car: any) => {
                return (
                  <MenuItem key={car.id} value={car.id}>
                    {car.name}
                  </MenuItem>
                );
              })}
            </Select>
            <Select
              label={"運転手"}
              value={currentValue["driver"]}
              onChange={(e) => {
                onChange("driver")(e.target.value);
              }}
            >
              {(filteredDriverData || []).map((driver: any) => {
                return (
                  <MenuItem key={driver.id} value={driver.id}>
                    {driver.name}
                  </MenuItem>
                );
              })}
            </Select>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div style={{ textAlign: "center" }}>
            <Button
              variant="contained"
              onClick={handleUpdate}
              color="primary"
              autoFocus
            >
              更新
            </Button>
            <Button
              variant="outlined"
              onClick={handleCancel}
              color="primary"
              autoFocus
            >
              キャンセル
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openMemo}
        onClose={() => {
          setOpenMemo(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title2" style={{ minWidth: "300px" }}>
          メモ
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description2">
            {memo}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={(e) => {
              setOpenMemo(false);
            }}
            color="primary"
            autoFocus
          >
            閉じる
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default Reservations;
