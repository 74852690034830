import React from "react";
import { Select, MenuItem } from "@mui/material";

function SelectTemplate(props: any) {
  const { dataSet, onChange, value, empty, blankName, disabled } = props;

  const currentValue =
    value === null || value === undefined
      ? dataSet.data[0]
        ? dataSet.data[0].value
        : ""
      : value;

  const onSelectChange = (e: any) => {
    if (onChange) {
      onChange(e);
    }
  };
  const ret = (dataSet.data || []).map((data: any, key: number) => {
    return (
      <MenuItem value={data.value} key={key}>
        {data.name}
      </MenuItem>
    );
  });
  if (empty) {
    ret.unshift(
      <MenuItem aria-label="None" value="default" key="blank">
        {blankName || ""}
      </MenuItem>,
    );
  }

  return (
    <Select
      onChange={onSelectChange}
      value={currentValue}
      name={dataSet.name}
      disabled={disabled || false}
    >
      {ret}
    </Select>
  );
}

export default SelectTemplate;
