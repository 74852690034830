import React from "react";
import { Typography } from "@mui/material";
import Header from "./Header";

import { CaptionGrip, RootGrid } from "./styles";

interface Props {
  user: any;
}

const About: React.FC<Props> = ({ user }) => {
  return (
    <React.Fragment>
      <Header user={user} login="/Login/target/about" />
      <RootGrid container alignItems="center" direction="row">
        <CaptionGrip>
          <Typography component="h2" variant="h5" gutterBottom>
            バスバスバス！！！
          </Typography>
        </CaptionGrip>
      </RootGrid>
    </React.Fragment>
  );
};

export default About;
