import React, { useState } from "react";
import { styled } from "@mui/system";
import { Grid, InputLabel, Select, MenuItem, Button } from "@mui/material";
import { Link, useParams, useNavigate } from "react-router-dom";

import EditableField from "../common/EditableField";

import GoogleMapHome from "./GoogleMapHome";
import { mapKey } from "../config";

import customerClass from "../models/customer";

import useOnDocument from "../common/useOnDocument";
import * as inputValidator from "../common/inputValidator";

const StyledSelect = styled(Select)(({ theme }) => ({
  margin: theme.spacing(1),
  minWidth: 120,
}));
function UserEdit(props: any) {
  const navigate = useNavigate();
  const { customerId } = useParams();
  const { db, setHeaderTitle, company } = props;

  const [customer, error] = useOnDocument(
    db,
    `companies/${company.id}/customers/${customerId}/`,
  );
  const [currentValue, setCurrentValue] = useState<any>(null);

  // for map
  const [clickPosition, setClickPosition] = useState(null);

  React.useEffect(() => {
    setHeaderTitle("個人情報変更");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!customer) {
    return <div>loading</div>;
  }
  if (currentValue === null) {
    setCurrentValue(customer);
    return <div />;
  }
  const updateValue = (key: string, value: any) => {
    const newValue = { ...currentValue };
    newValue[key] = value;
    setCurrentValue(newValue);
  };
  const onChange = (key: string) => {
    return (value: any) => {
      updateValue(key, value);
    };
  };
  const saveData = () => {
    const newValue = Object.assign(
      {},
      customerClass.defaultValue(),
      currentValue,
    );
    db.doc(`companies/${company.id}/customers/${customerId}`).update(newValue);
    navigate(`/admin/user`);
  };
  const geoCode = async (word: string) => {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${word}&key=${mapKey}`,
    );
    if (response.status === 200) {
      const res = await response.json();
      if (res && res.results) {
        return res.results;
      }
    }
    return null;
  };
  const postal2Address = async () => {
    const res = await geoCode(currentValue.postalCode);
    if (res && res[0] && res[0].address_components) {
      const address = res[0].address_components
        .reverse()
        .filter((data: any) => {
          return (
            data.types.includes("political") && !data.types.includes("country")
          );
        })
        .map((data: any) => {
          return data.long_name;
        })
        .join("");
      updateValue("addressFull", address);
    }
  };
  const address2Map = async () => {
    const res = await geoCode(currentValue.addressFull);
    if (res && res[0] && res[0].geometry) {
      const location = res[0].geometry.location;
      setClickPosition(location);
    }
  };

  if (error) {
    return <div>error</div>;
  }

  return (
    <Grid container alignItems="stretch" direction="row">
      <Grid item xs={2}>
        <Button
          variant="outlined"
          color="primary"
          to={`/admin/reservation/map/${customer.customerId}`}
          component={Link}
        >
          予約へ
        </Button>
      </Grid>
      <Grid item xs={8}>
        <EditableField
          label={"姓名"}
          onChange={onChange("name")}
          value={currentValue.name}
        />
        <EditableField
          label={"せいめい"}
          onChange={onChange("namekana")}
          value={currentValue.namekana}
        />
        <InputLabel>性別</InputLabel>
        <StyledSelect
          label={"性別"}
          value={currentValue.sex || 1}
          onChange={(e) => {
            onChange("sex")(e.target.value);
          }}
        >
          <MenuItem key={1} value={1}>
            男性
          </MenuItem>
          <MenuItem key={2} value={2}>
            女性
          </MenuItem>
        </StyledSelect>
        <EditableField
          label={"固定電話番号"}
          onChange={onChange("landlineNumber")}
          validate={inputValidator.landlineValidator}
          convData={inputValidator.zen2hanNumber}
          helperText={"半角の数字のみ１０桁。カッコとハイフンは不要です"}
          value={currentValue.landlineNumber}
        />
        <EditableField
          label={"携帯電話番号"}
          onChange={onChange("phoneNumber")}
          validate={inputValidator.phoneValidator}
          convData={inputValidator.zen2hanNumber}
          helperText={"半角の数字のみ１１桁。カッコとハイフンは不要です"}
          value={currentValue.phoneNumber}
        />
        電話番号はお客様の識別子になるので正確に入力をお願いします。基本的には、この画面で変更しないようにしてください。
        <hr />
        <EditableField
          label={"郵便番号"}
          onChange={onChange("postalCode")}
          convData={inputValidator.zen2hanNumber}
          validate={inputValidator.postalCodeValidator}
          helperText={"半角の数字のみ。ハイフンは不要です"}
          value={currentValue.postalCode}
        />
        <Button
          variant="outlined"
          color="primary"
          onClick={(e) => {
            postal2Address();
          }}
        >
          郵便番号を住所に反映
        </Button>
        <br />
        <EditableField
          label={"住所詳細"}
          onChange={onChange("addressFull")}
          value={currentValue.addressFull}
        />
        <Button
          variant="outlined"
          color="primary"
          onClick={(e) => {
            address2Map();
          }}
        >
          住所を地図に反映
        </Button>
        <br />
        利用者行政区
        <br />
        住所区域名
        <br />
        住所エリア名
        <br />
        住所ゾーン名
        <br />
        住所地区名
        <br />
        <StyledSelect
          label={"isAdult"}
          value={currentValue.isAdult === 0 ? 0 : 1}
          onChange={(e) => {
            onChange("isAdult")(e.target.value);
          }}
        >
          <MenuItem key={1} value={1}>
            大人
          </MenuItem>
          <MenuItem key={0} value={0}>
            小人
          </MenuItem>
        </StyledSelect>
        <InputLabel>定期券あり</InputLabel>
        <StyledSelect
          label={"定期券"}
          value={currentValue.hascommuterpass || 0}
          onChange={(e) => {
            onChange("hascommuterpass")(e.target.value);
          }}
        >
          <MenuItem value={1}>はい</MenuItem>
          <MenuItem value={0}>いいえ</MenuItem>
        </StyledSelect>
        <InputLabel>障害者</InputLabel>
        <StyledSelect
          label={"障害者"}
          value={currentValue.handicapped || 0}
          onChange={(e) => {
            onChange("handicapped")(e.target.value);
          }}
        >
          <MenuItem value={1}>はい</MenuItem>
          <MenuItem value={0}>いいえ</MenuItem>
        </StyledSelect>
        <InputLabel>免許返納者</InputLabel>
        <StyledSelect
          label={"免許返納者"}
          value={currentValue.returnLicense || 0}
          onChange={(e) => {
            onChange("returnLicense")(e.target.value);
          }}
        >
          <MenuItem value={1}>はい</MenuItem>
          <MenuItem value={0}>いいえ</MenuItem>
        </StyledSelect>
        <br />
        {/*定期券区間　どこ〜どこ<br/>*/}
        <EditableField
          label={"ポイント数"}
          disabled={true}
          value={currentValue.point || 123}
        />
        <Button
          variant="outlined"
          color="primary"
          onClick={(e) => {
            saveData();
          }}
        >
          保存
        </Button>
        <hr />
        {/* - 生年月日 */}
        {/* 顔写真  <hr /> */}
        自宅
        <br />
        <div style={{ height: "80vh", width: "100%" }}>
          <GoogleMapHome
            clickPosition={clickPosition}
            setClickPosition={setClickPosition}
            setHomePosition={onChange("homePosition")}
            homePosition={currentValue["homePosition"] || {}}
          />
        </div>
        <hr />
      </Grid>
      <Grid item xs={2} />
    </Grid>
  );
}

export default UserEdit;
