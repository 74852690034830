import React from "react";
import { styled } from "@mui/system";
import { Button } from "@mui/material";

import { Link } from "react-router-dom";

import {
  CourseDataService,
  CourseDataWays,
  ReservationData,
} from "../types/data_types";

const TableBorderTD = styled("td")(({ theme }) => ({
  border: "solid 0.5px #aaaaaa",
}));
const TableBorderTR = styled("tr")(({ theme }) => ({
  border: "solid 0.5px #aaaaaa",
}));

const ReservationTableHeadTd = styled(TableBorderTD)(({ theme }) => ({
  lineHeight: "1.2em",
  padding: "4px 2px 0px 2px",
  backgroundColor: "#e5e5e5",
}));

const ReservationTable = styled("table")(({ theme }) => ({
  border: "solid 0.5px #aaaaaa",
  borderCollapse: "collapse",
  textAlign: "center",
  "tbody > tr:nth-of-type(2n+1)": {
    background: "#ffff",
  },
  width: "fit-content",
  fontSize: "14px",
}));

const tableElement = (
  reservation: ReservationData,
  header: any,
  service: CourseDataService,
) => {
  if (reservation.onPosition.positionIndex === header.index) {
    return "乗車";
  }
  if (reservation.offPosition.positionIndex === header.index) {
    return "下車";
  }
  if (service.inbound) {
    if (
      reservation.onPosition.positionIndex < header.index &&
      reservation.offPosition.positionIndex > header.index
    ) {
      return "--->";
    }
  } else {
    if (
      reservation.onPosition.positionIndex > header.index &&
      reservation.offPosition.positionIndex < header.index
    ) {
      return "--->";
    }
  }
  return "";
};

function ReservationMapTable(props: any) {
  const { reservations, currentLineId, lineData, copyReservasion, serviceId } =
    props;
  if (!reservations) {
    return <div />;
  }
  const pointObj: any = {
    0: { name: lineData[currentLineId].pins[0].name, index: 0 },
  };
  let index = -1;
  lineData[currentLineId].ways.forEach((way: CourseDataWays, key: number) => {
    index = index + way.length;
    pointObj[index] = {
      name: lineData[currentLineId].pins[key + 1].name,
      index,
    };
  });

  reservations.forEach((reservation: ReservationData, key: number) => {
    [
      reservation.onPosition.positionIndex,
      reservation.offPosition.positionIndex,
    ].forEach((index) => {
      if (!pointObj[index]) {
        pointObj[index] = {
          name: "--",
          index,
        };
      }
    });
  });
  const service = lineData[currentLineId].services.find(
    (ele: CourseDataService) => ele.id === serviceId,
  );
  const headers = Object.keys(pointObj)
    .sort((a, b) => {
      if (service.inbound) {
        return pointObj[a].index < pointObj[b].index ? -1 : 1;
      }
      return pointObj[a].index > pointObj[b].index ? -1 : 1;
    })
    .map((key) => {
      return pointObj[key];
    });
  index = 0;

  return (
    <div>
      <ReservationTable>
        <thead>
          <TableBorderTR style={{ verticalAlign: "bottom" }}>
            <ReservationTableHeadTd colSpan={2}>乗客名</ReservationTableHeadTd>
            {headers.map((header, key) => {
              return (
                <ReservationTableHeadTd key={key}>
                  {header.name
                    .replace(/\(|（/g, "︵")
                    .replace(/\)|）/g, "︶")
                    .replace(/-|ー/g, "丨")
                    .split("")
                    .map((a: string, k: number) => {
                      return (
                        <React.Fragment key={k}>
                          {a}
                          <br />
                        </React.Fragment>
                      );
                    })}
                </ReservationTableHeadTd>
              );
            })}
            {copyReservasion ? <ReservationTableHeadTd /> : <React.Fragment />}
          </TableBorderTR>
        </thead>
        <tbody>
          {reservations.map((reservation: ReservationData, rkey: number) => {
            index++;

            const baby = reservation.baby || 0;
            const child = reservation.child || 0;

            const element = (__index: number, option = "") => {
              return (
                <TableBorderTR key={[String(rkey), String(__index)].join("_")}>
                  <TableBorderTD>{__index}</TableBorderTD>
                  <TableBorderTD>
                    <Link
                      to={`/admin/user/${reservation.customerId}/reservation/${reservation.id}`}
                    >
                      {reservation.customerName}
                    </Link>
                    {option}
                  </TableBorderTD>
                  {headers.map((header, key) => {
                    return (
                      <TableBorderTD key={key}>
                        {tableElement(reservation, header, service)}
                      </TableBorderTD>
                    );
                  })}
                  {copyReservasion ? (
                    <TableBorderTD>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={(e) => {
                          copyReservasion(reservation);
                        }}
                      >
                        区間コピー
                      </Button>
                    </TableBorderTD>
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
                </TableBorderTR>
              );
            };
            const ret = [];
            ret.push(element(index)); // self

            for (let n = 0; n < child; n++) {
              index++;
              ret.push(element(index, "同乗者小人"));
            }
            for (let n = 0; n < baby; n++) {
              index++;
              ret.push(element(index, "同乗者未就学児"));
            }

            return ret;
          })}
        </tbody>
      </ReservationTable>
    </div>
  );
}

export default ReservationMapTable;
