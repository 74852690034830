import { styled } from "@mui/system";
import { Table, TableCell } from "@mui/material";

export const MuiTableRoot = styled(Table)(({ theme }) => ({
  border: "solid 1px #888888",
  width: "1050px",
  margin: "5px 0",
}));

export const MuiTableCellRootAndHead = styled(TableCell)(({ theme }) => ({
  "&:last-child": {
    padding: "5px 1px",
  },
  textAlign: "center",
  fontWeight: "bold",
  color: "black",
  border: "solid 1px #888888",
  padding: "5px 1px",
  backgroundColor: "#eeefef",
  fontSize: "0.875rem",
  lineHeight: "1.3",
}));

export const MuiTableCellRootAndHeadVTOP = styled(MuiTableCellRootAndHead)(
  ({ theme }) => ({
    verticalAlign: "top",
  }),
);

export const MuiTableCellRootAndHeadWidth150 = styled(MuiTableCellRootAndHead)(
  ({ theme }) => ({
    width: "150px",
  }),
);

export const MuiTableCellRootAndHeadWidth270 = styled(MuiTableCellRootAndHead)(
  ({ theme }) => ({
    width: "270px",
  }),
);
export const MuiTableCellRootAndHeadNoBottom = styled(MuiTableCellRootAndHead)(
  ({ theme }) => ({
    borderBottom: "none",
  }),
);

export const MuiTableCellBody = styled(TableCell)(({ theme }) => ({
  textAlign: "center",
  borderBottom: "none",
  // borderRight: "solid 1px #888888",
  fontSize: "0.8rem",
  padding: "1px 3px 1px 3px",
  border: "solid 0.5px #BBB",
}));

export const MuiTableCellBodyAndFooter = styled(MuiTableCellBody)(
  ({ theme }) => ({
    /*合計の行の設定*/ height: "40px",
    fontWeight: "bold",
    textAlign: "center",
    backgroundColor: "#eeefef",
  }),
);

export const MuiTableCellBodyAndTCenter = styled(MuiTableCellBody)(
  ({ theme }) => ({
    /*健常者〜未就学児の人数の文字をセンタリング。ただしここはラジオボタンになる*/
    textAlign: "center",
  }),
);

export const MuiTableCellBodyAndTRight = styled(MuiTableCellBody)(
  ({ theme }) => ({
    textAlign: "right",
  }),
);

export const MuiTableCellBodyAndNoWrap = styled(MuiTableCellBody)(
  ({ theme }) => ({
    whiteSpace: "nowrap",
  }),
);

export const MuiTableCellBodyAndNoWrapTRight = styled(MuiTableCellBody)(
  ({ theme }) => ({
    textAlign: "right",
    whiteSpace: "nowrap",
  }),
);

export const MuiTableCellBodyAndNoWrapTRightAdjustPrice = styled(
  MuiTableCellBodyAndNoWrapTRight,
)(({ theme }) => ({
  width: "100px",
  textAlign: "center",
}));

export const WarnRed = styled("div")(({ theme }) => ({
  float: "left",
  color: "white",
  marginLeft: "20px",
  borderRadius: "5px",
  backgroundColor: "red",
}));

export const WarnGreen = styled("div")(({ theme }) => ({
  float: "left",
  marginLeft: "20px",
  borderRadius: "5px",
  backgroundColor: "#33FF00",
}));

export const BMiddle = styled("b")(({ theme }) => ({
  verticalAlign: "middle",
  marginLeft: "20px",
  marginRight: "20px",
}));
