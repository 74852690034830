import firebase from "firebase/compat/app";

import { createHash } from "crypto";

import moment from "moment";

import {
  Position,
  CourseData,
  ReservationPrice,
  CourseDataArea,
  ReservationFreeRidePositionData,
  CourseDataService,
} from "../types/data_types";

import { butStopId2PinIndex } from "./DataUtils";

export const timeout = async (s: number) => {
  return new Promise((resolve) => setTimeout(resolve, s * 1000));
};

export const array2obj = <T>(
  array: { [key: string]: T }[],
  key: string | string[],
) => {
  const obj: any = {};
  if (Array.isArray(key)) {
    array.forEach((elem: { [key: string]: T }) => {
      if (!obj[elem[key[0]]]) {
        obj[elem[key[0]]] = {};
      }
      if (!obj[elem[key[0]]][elem[key[1]]]) {
        obj[elem[key[0]]][elem[key[1]]] = {};
      }
      obj[elem[key[0]]][elem[key[1]]] = elem;
    });
    return obj;
  } else {
    (array || []).forEach((elem: { [key: string]: T }) => {
      obj[elem[key]] = elem;
    });
    return obj;
  }
};

// TODO
/*
export function setReserveData(lineData: LineData, data: any) {
  const line = lineData[data.lineId];
  const service = line.services.find((ele: any) => ele.id === data.serviceId);
  const busStop1 = line.pins[data.busStop1Id];
  const busStop2 = line.pins[data.busStop2Id];
  data.line = line;
  data.busStop1 = busStop1;
  data.busStop2 = busStop2;
  data.service = service;
  return data;
}
*/
export const onChange = (e: any, setFunc: any) => {
  setFunc(e.currentTarget.value);
};
export const onChange2 = (e: any, setFunc: any) => {
  setFunc(e.target.value);
};

export const sha256base64String = (key: string) => {
  return createHash("sha256")
    .update(key)
    .digest("base64")
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=/g, "");
};

export const num2time = (num: number) => {
  return Math.floor(num / 100) + ":" + String(num % 100);
};

// TODO investigate
export const getPinPosition = (
  position: ReservationFreeRidePositionData,
): Position => {
  if (position.type === "click") {
    return position.clickPosition;
  } else if (position.type === "homeNearBusStop") {
    return position.clickPosition;
  } else if (position.type === "pickup") {
    return position.clickPosition;
  } else if (position.type === "busStop") {
    return position.position as Position;
  }
  return position.position as Position;
};

export const getTypeName = (data: ReservationFreeRidePositionData) => {
  if (data.onOffPositionData?.type === "busStop") {
    return "バス停";
  } else if (data.onOffPositionData?.type === "homeNearBusStop") {
    return "自宅近く";
  } else if (data.onOffPositionData?.type === "pickup") {
    return "よくある乗降場所";
  }
  return "--";
};

export const generateElementKey = (arrayEle: (number | string)[]) => {
  return arrayEle.map((r: number | string) => String(r)).join("_");
};

export const convData = (dataSet: any[], init: any) => {
  return dataSet.reduce((tmp: any, data: any) => {
    if (!tmp[data.lineId]) {
      tmp[data.lineId] = {};
    }
    if (!tmp[data.lineId][data.serviceId]) {
      tmp[data.lineId][data.serviceId] = init;
    }
    if (Array.isArray(init)) {
      tmp[data.lineId][data.serviceId].push(data);
    } else if (typeof init === "string" || init instanceof String) {
      tmp[data.lineId][data.serviceId] = data;
    } else {
      tmp[data.lineId][data.serviceId][data.id] = data;
    }
    return tmp;
  }, {});
};

export const timeStamp2Format = (
  time: firebase.firestore.Timestamp,
  format: string,
) => {
  return time ? moment(time.toDate()).format(format) : "";
};

export const area2price = (
  currentLineData: CourseData,
  fromArea: number,
  toArea: number,
): ReservationPrice => {
  if (fromArea > toArea) {
    return currentLineData.prices[toArea].to_area[fromArea];
  }
  return currentLineData.prices[fromArea].to_area[toArea];
};

export const pin2area = (
  courseData: CourseData,
  pin: number,
): CourseDataArea | null => {
  return (
    courseData.areas.find((area: CourseDataArea) => {
      return area.pins.indexOf(pin) > -1;
    }) || null
  );
};

export const busStopId2price = (
  currentLineData: CourseData,
  fromBusStopId: number,
  toBusStopId: number,
) => {
  const fromPinIndex = butStopId2PinIndex(fromBusStopId, currentLineData.pins);
  const ToPinIndex = butStopId2PinIndex(toBusStopId, currentLineData.pins);

  const onBus = currentLineData.pins[fromPinIndex];
  const offBus = currentLineData.pins[ToPinIndex];

  const onArea = pin2area(currentLineData, onBus.id);
  const offArea = pin2area(currentLineData, offBus.id);

  if (onArea && offArea) {
    const priceData = Object.assign(
      {},
      area2price(currentLineData, onArea.id, offArea.id),
    );
    return priceData;
  }
  return {
    price: 0,
  };
};

export const isNull = <T>(value: T) => {
  return value === null || value === undefined;
};

export const getCompanyExpense = (companyId: string) => {
  const fixExpenseList: { [key: string]: number } = {
    Qqh1S4vBjZ88Dv2uKPks: 18000, // T
    UEqSulzUjeysV9nfXvQj: 10000, // A
  };
  return fixExpenseList[companyId];
};
export const getExpense = (lineId: string, serviceId: number | string) => {
  const fixExpenseList: { [key: string]: number } = {
    xgncQ6JLTWJ4dvFSpJLk: 10000, //40x
    QbitiXYi3v6KxJS98Qva: 8000, // 30x
    OtKXIZnkxxcBXdrmSANU: 10000, // 10x
  };
  // console.log(lineId, serviceId);
  if (!lineId) {
    return 0;
  }
  if (serviceId !== 0 && serviceId !== "sum") {
    return 0;
  }
  if (fixExpenseList[lineId]) {
    return fixExpenseList[lineId];
  }
  return 0;
};

export const getServiceLongName = (service: CourseDataService) => {
  return service.name + ":" + (service.inbound ? "上り" : "下り");
};

//40x便　219円／km
//30x便　210円／km
//10x便　247円／km

export const getDistanceUnutPrice = (lineId: string, current: any) => {
  const changePriceDay = moment(`2021-04-01`, "YYYY-MM-DD");

  if (current.day.unix() >= changePriceDay.unix()) {
    /* after 2021/04/01 */
    const distanceUnitPriceList: { [key: string]: number } = {
      xgncQ6JLTWJ4dvFSpJLk: 219, // 40x
      QbitiXYi3v6KxJS98Qva: 210, // 30x
      OtKXIZnkxxcBXdrmSANU: 247, // 10x
    };
    return distanceUnitPriceList[lineId] || 0;
  } else {
    /* before 2021/03/31 */
    const distanceUnitPriceList: { [key: string]: number } = {
      xgncQ6JLTWJ4dvFSpJLk: 211, // 40x
      QbitiXYi3v6KxJS98Qva: 207, // 30x
      OtKXIZnkxxcBXdrmSANU: 237, // 10x
    };
    return distanceUnitPriceList[lineId] || 0;
  }
};

export const kanaChars =
  "あいうえおかきくけこさしすせそたちつてとなにぬねのはひふへほまみむめもやゆよらりるれろわをん".split(
    "",
  );

export const convChar = (c: string) => {
  const from = "がぎぐげござじずぜぞだじづでどばびぶべぼはぴぷぺぽ".split("");
  const to = "かきくけこさしすせそたちつてとはひふへほはひふへほ".split("");
  const match = from.findIndex((el: string) => c === el);
  if (match !== -1) {
    return to[match];
  }
  return c;
};

export const yomiKashira = (name: string) => {
  return convChar((name || "").split("")[0]);
};

export const objectAddition = (obj1: any, obj2: any) => {
  Object.keys(obj1).forEach((key: string) => {
    obj1[key] = obj1[key] + obj2[key];
  });
};
