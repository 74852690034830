import React, { useState, useRef } from "react";
import { styled } from "@mui/system";
import { TextField } from "@mui/material";

export const Form = styled("form")(({ theme }) => ({
  width: "100%",
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

function EditableField(props: any) {
  const refTextField = useRef(null);
  const [value, setValue] = useState(props.value);
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState(null);
  const { label, multiline, disabled } = props;

  const onChange = (e: any) => {
    const value = props.convData
      ? props.convData(e.target.value)
      : e.target.value;
    setValue(value);
    if (props.onChange) {
      props.onChange(value);
    }
    if (props.validate) {
      const isValid = props.validate(value);
      setError(!isValid);
      if (props.helperText) {
        setHelperText(isValid ? "" : props.helperText);
      }
    }
  };

  const formValue = props.onChange ? props.value : value;

  return (
    <Form>
      <TextField
        label={label}
        value={formValue || ""}
        variant="outlined"
        sx={{ width: "90%" }}
        multiline={multiline}
        rows={2}
        InputLabelProps={{ shrink: true }}
        disabled={disabled || false}
        error={error}
        helperText={helperText}
        inputRef={refTextField}
        onChange={onChange}
      />
    </Form>
  );
}

EditableField.propTypes = {};

export default EditableField as any;
