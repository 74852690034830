import firebase from "firebase/compat/app";
import React, { useReducer, useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import GoogleMapReact from "google-map-react";
import { mapKey } from "../config";

import { styled } from "@mui/system";

import useOnCollectionGroup from "../common/useOnCollectionGroup";
import { kochiPosition, defaultCenter } from "./utils/position";

import moment from "moment";

const MapDiv = styled("div")(({ theme }) => ({
  height: "90vh",
  width: "100%",
}));

function infowindowReducer(state: any, action: any) {
  if (state) {
    state.close();
  }
  return action;
}

type Props = {
  db: firebase.firestore.Firestore;
  company: any;
  setHeaderTitle: any;
};
const Map: React.FC<Props> = ({ db, company, setHeaderTitle }) => {
  // Google js instances
  const [myGoogleMap, setMyGoogleMap] = useState<any>(null);

  const [positionQuery, setPositionQuery] = useState({});
  useEffect(() => {
    setPositionQuery({
      queryFilter: (query: any) => {
        return query.where("companyId", "==", company.id);
      },
    });
  }, []);

  const [currentPosition] = useOnCollectionGroup(
    db,
    "currentPosition",
    positionQuery,
  );
  const [listeners, setListeners] = useState<any>({});
  const [markers, setMarkers] = useState<any>({});

  const [infoWindowInstance, setInfoWindowInstance] = useReducer(
    infowindowReducer,
    null,
  );

  useEffect(() => {
    setHeaderTitle("バスの現在位置");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const renderMarkers = (map: any, maps: any) => {
    setMyGoogleMap({ maps, map });
  };

  function openReservationWindow(data: any, map: any, maps: any) {
    const { id, position } = data;
    return () => {
      const offset = new maps.Size(0, -30);
      const infoWindow = new maps.InfoWindow({
        content: '<div id="' + id + '" />',
        position,
        pixelOffset: offset,
      });
      const carName = (position["car"] || {})["name"] || "--";
      const driverName = (position["driver"] || {})["name"] || "--";
      const time = moment(position.created.toDate()).format("MM/DD HH:mm");

      infoWindow.addListener("domready", (e: any) => {
        const root = ReactDOM.createRoot(document.getElementById(id)!);
        root.render(
          <div>
            {time}
            <br />
            {carName}
            <br />
            {driverName}
            <br />
          </div>,
        );
      });
      infoWindow.open(map);
      setInfoWindowInstance(infoWindow);
    };
  }

  useEffect(() => {
    (async () => {
      if (myGoogleMap && (currentPosition || []).length > 0) {
        const map = myGoogleMap.map;
        const maps = myGoogleMap.maps;

        const newMarkers: any = { ...markers };

        (currentPosition || []).map((position: any, key: number) => {
          const markerKey = position["key"];

          if (markers[markerKey] && markers[markerKey].setPosition) {
            markers[markerKey].setPosition(position);
            const marker = markers[markerKey];
            newMarkers[markerKey] = marker;

            maps.event.removeListener(listeners[markerKey]);
            const data = { id: key, markerKey, position };
            const listener = marker.addListener(
              "click",
              openReservationWindow(data, map, maps),
            );
            listeners[markerKey] = listener;
          } else {
            const marker = new myGoogleMap.maps.Marker({
              position,
              map: myGoogleMap.map,
              icon: "http://maps.google.co.jp/mapfiles/ms/icons/cabs.png",
            });
            const data = { id: key, markerKey, position };
            const listener = marker.addListener(
              "click",
              openReservationWindow(data, map, maps),
            );
            listeners[markerKey] = listener;
            newMarkers[markerKey] = marker;
          }
          return null;
        });
        setMarkers(newMarkers);
      }
    })();
  }, [myGoogleMap, currentPosition]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <MapDiv>
      <GoogleMapReact
        bootstrapURLKeys={{ key: mapKey }}
        defaultCenter={defaultCenter}
        yesIWantToUseGoogleMapApiInternals={true}
        center={kochiPosition}
        defaultZoom={11}
        zoom={11}
        onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
      ></GoogleMapReact>
    </MapDiv>
  );
};

export default Map;
