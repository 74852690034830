import React, { useReducer, useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import GoogleMapReact from "google-map-react";
import { mapKey } from "../config";
import { BusStopMarkerData } from "../types/data_types";

function infowindowReducer(state: any, action: any) {
  if (state) {
    state.close();
  }
  return action;
}

type Props = {
  callback: any;
  busStops: BusStopMarkerData;
  defaultPosition: any;
};

const GoogleMap: React.FC<Props> = ({
  callback,
  busStops,
  defaultPosition,
}) => {
  const [clickPosition, setClickPosition] = useState<any>(null);
  const [clickPositionMarker, setClickPositionMarker] = useState<any>(null);

  const [clickMarkers, setClickMarkers] = useState<any>([]);

  const [center, setCenter] = useState<any>(defaultPosition);
  const [zoom, setZoom] = useState(11);
  // Google js instances
  const [myGoogleMap, setMyGoogleMap] = useState<any>(null);

  const [infoWindowInstance, setInfoWindowInstance] = useReducer(
    infowindowReducer,
    null,
  );

  useEffect(() => {
    const moveToLine = () => {
      setZoom(13);
      setCenter(center);
    };

    (async () => {
      if (myGoogleMap) {
        moveToLine();
        const map = myGoogleMap.map;
        map.setZoom(zoom);
      }
    })();
  }, [myGoogleMap, zoom]); // eslint-disable-line react-hooks/exhaustive-deps

  function openReservationWindow(type: string, data: any, map: any, maps: any) {
    const { id, clickPosition, name } = data;
    return () => {
      const offset = new maps.Size(0, type === "onoff" ? -45 : -30);
      const infoWindow = new maps.InfoWindow({
        content: '<div id="' + id + '" />',
        position: clickPosition,
        pixelOffset: offset,
      });
      infoWindow.addListener("domready", (e: any) => {
        const root = ReactDOM.createRoot(document.getElementById(id)!);
        root.render(<div>{name}</div>);
      });
      infoWindow.open(map);
      setInfoWindowInstance(infoWindow);
    };
  }
  const setClickMarker = (
    id: number,
    name: string,
    position: any,
    icon: string,
  ) => {
    const marker = setMarker(position, icon);
    const data = { id, clickPosition: position, name };
    marker.addListener(
      "click",
      openReservationWindow("ride", data, myGoogleMap.map, myGoogleMap.maps),
    );
    return marker;
  };
  const setMarker = (position: any, icon: string) => {
    const marker = new myGoogleMap.maps.Marker({
      position,
      map: myGoogleMap.map,
      icon: "http://maps.google.com/mapfiles/" + icon,
    });
    return marker;
  };

  useEffect(() => {
    // update user click position
    if (clickPositionMarker) {
      clickPositionMarker.setMap(null);
    }
    if (myGoogleMap) {
      const marker = setMarker(clickPosition, "ms/icons/blue-dot.png");
      setClickPositionMarker(marker);
    }
  }, [clickPosition]); // eslint-disable-line react-hooks/exhaustive-deps

  const mapClickEvent = (e: any) => {
    console.log("mapClickEvent");
    setInfoWindowInstance(null);
    const position = {
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    };
    setClickPosition(position);
    if (callback) {
      callback(position);
    }
  };

  useEffect(() => {
    if (myGoogleMap) {
      if (clickMarkers && clickMarkers.length > 0) {
        clickMarkers.map((marker: any) => {
          marker.setMap(null);
        });
      }

      const markers = busStops.data.map((pin: any, k: number) => {
        const marker = setClickMarker(
          k,
          pin.name,
          pin.location,
          "ms/icons/pink-dot.png",
        );
        return marker;
      });
      setClickMarkers(markers);
    }
  }, [myGoogleMap, busStops.data]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderMarkers = (map: any, maps: any) => {
    setMyGoogleMap({ maps, map });
    console.log(busStops);
    map.addListener("click", mapClickEvent);
  };

  const mapStyles = [
    {
      featureType: "all",
      elementType: "labels.text",
    },
  ];

  return (
    <React.Fragment>
      <GoogleMapReact
        bootstrapURLKeys={{ key: mapKey }}
        defaultCenter={defaultPosition}
        yesIWantToUseGoogleMapApiInternals={true}
        center={center}
        defaultZoom={11}
        zoom={zoom}
        options={{
          styles: mapStyles as any,
        }}
        onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
      ></GoogleMapReact>
    </React.Fragment>
  );
};

export default GoogleMap;
