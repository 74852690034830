export const ways = [
  [
    { lng: 132.79257, lat: 32.86109 }, // 有永
    { lng: 132.79258, lat: 32.86104 },
    { lng: 132.79263, lat: 32.86093 },
    { lng: 132.79273, lat: 32.86078 },
    { lng: 132.79278, lat: 32.86069 },
    { lng: 132.79278, lat: 32.86068 },
    { lng: 132.79281, lat: 32.86062 },
    { lng: 132.79284, lat: 32.86051 },
    { lng: 132.79284, lat: 32.86034 },
    { lng: 132.79283, lat: 32.86029 },
    { lng: 132.79276, lat: 32.86019 },
    { lng: 132.79266, lat: 32.86013 },
    { lng: 132.79234, lat: 32.86005 },
    { lng: 132.7923, lat: 32.86003 },
    { lng: 132.7923, lat: 32.86002 },
    { lng: 132.79229, lat: 32.86001 },
    { lng: 132.79229, lat: 32.85999 },
    { lng: 132.79234, lat: 32.8599 },
    { lng: 132.79237, lat: 32.85987 },
    { lng: 132.79243, lat: 32.85978 },
    { lng: 132.79247, lat: 32.85974 },
    { lng: 132.79258, lat: 32.85966 },
    { lng: 132.79261, lat: 32.85963 },
    { lng: 132.79263, lat: 32.85959 },
    { lng: 132.79264, lat: 32.85955 },
    { lng: 132.79264, lat: 32.85948 },
    { lng: 132.79262, lat: 32.85942 },
    { lng: 132.7926, lat: 32.85939 },
    { lng: 132.79258, lat: 32.85933 },
    { lng: 132.79258, lat: 32.85917 },
    { lng: 132.7926, lat: 32.85914 },
    { lng: 132.79263, lat: 32.85911 },
    { lng: 132.79263, lat: 32.8591 },
    { lng: 132.79267, lat: 32.85907 },
    { lng: 132.79271, lat: 32.85905 },
    { lng: 132.79285, lat: 32.85891 },
    { lng: 132.79287, lat: 32.85888 },
    { lng: 132.79288, lat: 32.85887 },
    { lng: 132.79289, lat: 32.85884 },
    { lng: 132.79289, lat: 32.85877 },
    { lng: 132.79288, lat: 32.85874 },
    { lng: 132.79285, lat: 32.85869 },
    { lng: 132.79278, lat: 32.85862 },
    { lng: 132.79276, lat: 32.85859 },
    { lng: 132.79275, lat: 32.85858 },
    { lng: 132.79275, lat: 32.85854 },
    { lng: 132.79276, lat: 32.85851 },
    { lng: 132.79278, lat: 32.85847 },
    { lng: 132.79283, lat: 32.85842 },
    { lng: 132.79284, lat: 32.85842 },
    { lng: 132.79289, lat: 32.85836 },
    { lng: 132.79297, lat: 32.85822 },
    { lng: 132.79301, lat: 32.85818 },
    { lng: 132.79305, lat: 32.85815 },
    { lng: 132.79308, lat: 32.85814 },
    { lng: 132.79309, lat: 32.85813 },
    { lng: 132.7933, lat: 32.85813 },
    { lng: 132.7936, lat: 32.85816 },
    { lng: 132.79368, lat: 32.85815 },
    { lng: 132.79387, lat: 32.85809 },
    { lng: 132.79409, lat: 32.858 },
    { lng: 132.79418, lat: 32.85795 },
    { lng: 132.79423, lat: 32.85793 },
    { lng: 132.79432, lat: 32.85784 },
    { lng: 132.79436, lat: 32.85778 },
    { lng: 132.79441, lat: 32.85765 },
    { lng: 132.79453, lat: 32.85741 },
    { lng: 132.79489, lat: 32.85679 },
    { lng: 132.79493, lat: 32.85662 },
    { lng: 132.79494, lat: 32.85654 },
    { lng: 132.79494, lat: 32.85632 },
    { lng: 132.79493, lat: 32.85616 },
    { lng: 132.79492, lat: 32.85613 },
    { lng: 132.79491, lat: 32.85604 },
    { lng: 132.7949, lat: 32.856 },
    { lng: 132.79488, lat: 32.85596 },
    { lng: 132.79488, lat: 32.85595 },
    { lng: 132.79468, lat: 32.85563 },
    { lng: 132.79467, lat: 32.8556 },
    { lng: 132.79461, lat: 32.85548 },
    { lng: 132.7946, lat: 32.8554 },
    { lng: 132.79458, lat: 32.85535 },
    { lng: 132.79457, lat: 32.85528 },
    { lng: 132.79452, lat: 32.85507 },
    { lng: 132.79449, lat: 32.85499 },
    { lng: 132.79446, lat: 32.85494 },
    { lng: 132.79446, lat: 32.85493 },
    { lng: 132.79428, lat: 32.85467 },
    { lng: 132.79416, lat: 32.85457 },
    { lng: 132.79409, lat: 32.85453 },
    { lng: 132.79404, lat: 32.85451 },
    { lng: 132.79376, lat: 32.85455 },
    { lng: 132.79372, lat: 32.85456 },
    { lng: 132.79367, lat: 32.85456 },
    { lng: 132.79365, lat: 32.85455 },
    { lng: 132.79362, lat: 32.85452 },
    { lng: 132.79361, lat: 32.8545 },
    { lng: 132.79361, lat: 32.85449 },
    { lng: 132.7936, lat: 32.85448 },
    { lng: 132.7936, lat: 32.85444 },
    { lng: 132.79361, lat: 32.85441 },
    { lng: 132.79393, lat: 32.85388 },
    { lng: 132.79396, lat: 32.85385 },
    { lng: 132.79402, lat: 32.85382 },
    { lng: 132.79405, lat: 32.85379 },
    { lng: 132.7941, lat: 32.8537 },
    { lng: 132.79412, lat: 32.85364 },
    { lng: 132.79412, lat: 32.85358 },
    { lng: 132.79411, lat: 32.85356 },
    { lng: 132.79411, lat: 32.85355 },
    { lng: 132.79409, lat: 32.8535 },
    { lng: 132.79409, lat: 32.85346 },
    { lng: 132.79411, lat: 32.85339 },
    { lng: 132.79413, lat: 32.85335 },
    { lng: 132.79414, lat: 32.8533 },
    { lng: 132.79414, lat: 32.85312 },
    { lng: 132.79415, lat: 32.85303 },
    { lng: 132.79415, lat: 32.85261 },
    { lng: 132.79414, lat: 32.85256 },
    { lng: 132.79409, lat: 32.85245 },
    { lng: 132.79404, lat: 32.85236 },
    { lng: 132.79398, lat: 32.85229 },
    { lng: 132.79394, lat: 32.85223 },
    { lng: 132.79389, lat: 32.85218 },
    { lng: 132.79381, lat: 32.85213 },
    { lng: 132.79372, lat: 32.85209 },
    { lng: 132.79371, lat: 32.85208 },
    { lng: 132.79356, lat: 32.85206 },
    { lng: 132.79352, lat: 32.85206 },
    { lng: 132.79336, lat: 32.85212 },
    { lng: 132.79323, lat: 32.85219 },
    { lng: 132.79312, lat: 32.85226 },
    { lng: 132.79303, lat: 32.85235 },
    { lng: 132.79296, lat: 32.85244 },
    { lng: 132.79281, lat: 32.8527 },
    { lng: 132.79276, lat: 32.85282 },
    { lng: 132.79274, lat: 32.85285 },
    { lng: 132.7927, lat: 32.85289 },
    { lng: 132.79265, lat: 32.85293 },
    { lng: 132.79259, lat: 32.85295 },
    { lng: 132.79248, lat: 32.85297 },
    { lng: 132.79242, lat: 32.85297 },
    { lng: 132.79235, lat: 32.85295 },
    { lng: 132.79234, lat: 32.85295 },
    { lng: 132.79226, lat: 32.85292 },
    { lng: 132.79224, lat: 32.8529 },
    { lng: 132.79222, lat: 32.85287 },
    { lng: 132.79221, lat: 32.85281 },
    { lng: 132.79219, lat: 32.85276 },
    { lng: 132.79218, lat: 32.85269 },
    { lng: 132.79218, lat: 32.85255 },
    { lng: 132.7922, lat: 32.85242 },
    { lng: 132.79223, lat: 32.85231 },
    { lng: 132.79227, lat: 32.85222 },
    { lng: 132.79263, lat: 32.85175 },
    { lng: 132.79281, lat: 32.85141 },
    { lng: 132.79283, lat: 32.85139 },
    { lng: 132.79289, lat: 32.85131 },
    { lng: 132.79298, lat: 32.85122 },
    { lng: 132.79308, lat: 32.85114 },
    { lng: 132.79308, lat: 32.85113 },
    { lng: 132.79329, lat: 32.851 },
    { lng: 132.79337, lat: 32.85094 },
    { lng: 132.79341, lat: 32.8509 },
    { lng: 132.79345, lat: 32.85087 },
    { lng: 132.79375, lat: 32.85057 },
    { lng: 132.79382, lat: 32.85046 },
    { lng: 132.79384, lat: 32.85041 },
    { lng: 132.79386, lat: 32.85033 },
    { lng: 132.79387, lat: 32.85031 },
    { lng: 132.79387, lat: 32.85009 },
    { lng: 132.79386, lat: 32.85003 },
    { lng: 132.79386, lat: 32.85 },
    { lng: 132.79385, lat: 32.84996 },
    { lng: 132.79382, lat: 32.8499 },
    { lng: 132.7938, lat: 32.84987 },
    { lng: 132.79378, lat: 32.84985 },
    { lng: 132.79375, lat: 32.84983 },
    { lng: 132.79369, lat: 32.84981 },
    { lng: 132.79354, lat: 32.84979 },
    { lng: 132.79336, lat: 32.84975 },
    { lng: 132.79266, lat: 32.84954 },
    { lng: 132.79207, lat: 32.84928 },
    { lng: 132.79185, lat: 32.84917 },
    { lng: 132.79175, lat: 32.84913 },
    { lng: 132.79168, lat: 32.84909 },
    { lng: 132.79156, lat: 32.84905 },
    { lng: 132.79154, lat: 32.84904 },
    { lng: 132.79148, lat: 32.84903 },
    { lng: 132.79135, lat: 32.84903 },
    { lng: 132.79126, lat: 32.84902 },
    { lng: 132.79111, lat: 32.84897 },
    { lng: 132.79096, lat: 32.84888 },
    { lng: 132.79094, lat: 32.84886 },
    { lng: 132.79086, lat: 32.84882 },
    { lng: 132.79063, lat: 32.84864 },
    { lng: 132.78995, lat: 32.84796 },
    { lng: 132.78991, lat: 32.84791 },
    { lng: 132.78956, lat: 32.84756 },
    { lng: 132.78954, lat: 32.84753 },
    { lng: 132.78951, lat: 32.84747 },
    { lng: 132.7895, lat: 32.84744 },
    { lng: 132.7895, lat: 32.84737 },
    { lng: 132.78955, lat: 32.84726 },
    { lng: 132.78957, lat: 32.84723 },
    { lng: 132.78959, lat: 32.84721 },
    { lng: 132.78969, lat: 32.84707 },
    { lng: 132.78971, lat: 32.84705 },
    { lng: 132.78999, lat: 32.84667 },
    { lng: 132.79002, lat: 32.84662 },
    { lng: 132.79003, lat: 32.84659 },
    { lng: 132.79005, lat: 32.84656 },
    { lng: 132.79009, lat: 32.84647 },
    { lng: 132.79018, lat: 32.84619 },
    { lng: 132.79028, lat: 32.84578 },
    { lng: 132.79058, lat: 32.84506 },
    { lng: 132.7906, lat: 32.84499 },
    { lng: 132.79062, lat: 32.84486 },
    { lng: 132.79065, lat: 32.84473 },
    { lng: 132.79065, lat: 32.84464 },
    { lng: 132.79063, lat: 32.84458 },
    { lng: 132.79061, lat: 32.84454 },
    { lng: 132.79054, lat: 32.84446 },
    { lng: 132.79032, lat: 32.8443 },
    { lng: 132.79027, lat: 32.84427 },
    { lng: 132.79016, lat: 32.84422 },
    { lng: 132.79013, lat: 32.8442 },
    { lng: 132.79007, lat: 32.84413 },
    { lng: 132.79005, lat: 32.84412 },
    { lng: 132.79003, lat: 32.84407 },
    { lng: 132.78998, lat: 32.84398 },
    { lng: 132.78997, lat: 32.84397 },
    { lng: 132.78989, lat: 32.84378 },
    { lng: 132.78988, lat: 32.84374 },
    { lng: 132.78988, lat: 32.8437 },
    { lng: 132.78987, lat: 32.84365 },
    { lng: 132.78988, lat: 32.84362 },
    { lng: 132.78988, lat: 32.84359 },
  ],
  [
    { lng: 132.78994, lat: 32.8432 }, // 珠々玉
    { lng: 132.78996, lat: 32.8429 },
    { lng: 132.78997, lat: 32.84284 },
    { lng: 132.79001, lat: 32.84272 },
    { lng: 132.79002, lat: 32.84267 },
    { lng: 132.79008, lat: 32.84258 },
    { lng: 132.79012, lat: 32.84254 },
    { lng: 132.79016, lat: 32.84251 },
    { lng: 132.79028, lat: 32.84239 },
    { lng: 132.79054, lat: 32.84218 },
    { lng: 132.79068, lat: 32.84205 },
    { lng: 132.79085, lat: 32.84191 },
    { lng: 132.79087, lat: 32.84188 },
    { lng: 132.79089, lat: 32.84186 },
    { lng: 132.7909, lat: 32.84183 },
    { lng: 132.79127, lat: 32.84149 },
    { lng: 132.79131, lat: 32.84146 },
    { lng: 132.79134, lat: 32.84143 },
    { lng: 132.79146, lat: 32.84128 },
    { lng: 132.79157, lat: 32.84116 },
    { lng: 132.79177, lat: 32.84098 },
    { lng: 132.7918, lat: 32.84096 },
    { lng: 132.79187, lat: 32.8409 },
    { lng: 132.79194, lat: 32.8408 },
    { lng: 132.79206, lat: 32.84056 },
    { lng: 132.79227, lat: 32.8402 },
    { lng: 132.79233, lat: 32.84 },
    { lng: 132.79239, lat: 32.83954 },
    { lng: 132.79239, lat: 32.8395 },
    { lng: 132.79238, lat: 32.83946 },
    { lng: 132.79235, lat: 32.83941 },
    { lng: 132.79231, lat: 32.83936 },
    { lng: 132.79221, lat: 32.83931 },
    { lng: 132.79218, lat: 32.8393 },
    { lng: 132.79209, lat: 32.8393 },
    { lng: 132.79203, lat: 32.83932 },
    { lng: 132.79182, lat: 32.83937 },
    { lng: 132.79168, lat: 32.83939 },
    { lng: 132.79152, lat: 32.83936 },
    { lng: 132.79141, lat: 32.83932 },
    { lng: 132.79141, lat: 32.83931 },
    { lng: 132.79137, lat: 32.83929 },
    { lng: 132.79134, lat: 32.83928 },
    { lng: 132.79131, lat: 32.83925 },
    { lng: 132.79128, lat: 32.83923 },
    { lng: 132.79126, lat: 32.8392 },
    { lng: 132.79117, lat: 32.83892 },
    { lng: 132.79114, lat: 32.83874 },
    { lng: 132.79105, lat: 32.83838 },
    { lng: 132.79105, lat: 32.83828 },
    { lng: 132.79128, lat: 32.83768 },
    { lng: 132.79131, lat: 32.83763 },
    { lng: 132.79132, lat: 32.8376 },
    { lng: 132.79138, lat: 32.83751 },
    { lng: 132.79147, lat: 32.83742 },
    { lng: 132.79156, lat: 32.83735 },
    { lng: 132.79198, lat: 32.83716 },
    { lng: 132.79207, lat: 32.83711 },
    { lng: 132.79213, lat: 32.83709 },
    { lng: 132.79216, lat: 32.83707 },
    { lng: 132.79219, lat: 32.83704 },
    { lng: 132.79222, lat: 32.83696 },
    { lng: 132.79223, lat: 32.8369 },
    { lng: 132.79225, lat: 32.83683 },
    { lng: 132.79229, lat: 32.83634 },
    { lng: 132.7923, lat: 32.83632 },
    { lng: 132.7923, lat: 32.8363 },
    { lng: 132.79235, lat: 32.83625 },
    { lng: 132.79236, lat: 32.83625 },
    { lng: 132.79239, lat: 32.83623 },
    { lng: 132.79247, lat: 32.8362 },
    { lng: 132.79306, lat: 32.83613 },
    { lng: 132.79309, lat: 32.83612 },
    { lng: 132.79313, lat: 32.8361 },
    { lng: 132.79321, lat: 32.83603 },
    { lng: 132.79323, lat: 32.836 },
    { lng: 132.79328, lat: 32.83587 },
    { lng: 132.79345, lat: 32.83522 },
    { lng: 132.79347, lat: 32.83518 },
    { lng: 132.79348, lat: 32.83514 },
    { lng: 132.7935, lat: 32.8351 },
    { lng: 132.7936, lat: 32.83495 },
    { lng: 132.79363, lat: 32.83492 },
    { lng: 132.79368, lat: 32.83489 },
    { lng: 132.79385, lat: 32.83485 },
    { lng: 132.79428, lat: 32.83479 },
    { lng: 132.79435, lat: 32.83477 },
    { lng: 132.79438, lat: 32.83475 },
    { lng: 132.79441, lat: 32.83474 },
    { lng: 132.79458, lat: 32.8346 },
    { lng: 132.79468, lat: 32.83453 },
    { lng: 132.79475, lat: 32.83445 },
    { lng: 132.79476, lat: 32.83443 },
    { lng: 132.7948, lat: 32.83419 },
    { lng: 132.79478, lat: 32.83409 },
    { lng: 132.79476, lat: 32.83406 },
    { lng: 132.79472, lat: 32.83402 },
    { lng: 132.79468, lat: 32.834 },
    { lng: 132.79457, lat: 32.83381 },
    { lng: 132.79449, lat: 32.83361 },
    { lng: 132.79443, lat: 32.8335 },
    { lng: 132.7944, lat: 32.83346 },
    { lng: 132.79438, lat: 32.83344 },
    { lng: 132.79435, lat: 32.83342 },
    { lng: 132.79433, lat: 32.8334 },
    { lng: 132.79423, lat: 32.83334 },
    { lng: 132.79401, lat: 32.83324 },
    { lng: 132.79397, lat: 32.83321 },
    { lng: 132.79395, lat: 32.8332 },
    { lng: 132.79393, lat: 32.83316 },
    { lng: 132.79392, lat: 32.83315 },
    { lng: 132.79391, lat: 32.83313 },
    { lng: 132.79391, lat: 32.8331 },
    { lng: 132.7939, lat: 32.83308 },
    { lng: 132.7939, lat: 32.83303 },
    { lng: 132.79391, lat: 32.83302 },
    { lng: 132.79391, lat: 32.83301 },
    { lng: 132.79393, lat: 32.83297 },
    { lng: 132.79406, lat: 32.83285 },
    { lng: 132.79442, lat: 32.83264 },
    { lng: 132.79445, lat: 32.83263 },
    { lng: 132.7948, lat: 32.83244 },
    { lng: 132.79506, lat: 32.83227 },
    { lng: 132.79556, lat: 32.83186 },
    { lng: 132.7956, lat: 32.83182 },
    { lng: 132.79566, lat: 32.83171 },
    { lng: 132.79567, lat: 32.83167 },
    { lng: 132.79568, lat: 32.83165 },
    { lng: 132.79568, lat: 32.83151 },
    { lng: 132.79569, lat: 32.83149 },
    { lng: 132.79569, lat: 32.83148 },
    { lng: 132.7957, lat: 32.83144 },
    { lng: 132.79572, lat: 32.83142 },
    { lng: 132.79573, lat: 32.8314 },
    { lng: 132.79576, lat: 32.83136 },
    { lng: 132.79579, lat: 32.83133 },
    { lng: 132.79579, lat: 32.83132 },
    { lng: 132.79585, lat: 32.83127 },
    { lng: 132.7959, lat: 32.83124 },
    { lng: 132.79593, lat: 32.83123 },
    { lng: 132.79594, lat: 32.83122 },
    { lng: 132.79597, lat: 32.83121 },
    { lng: 132.79601, lat: 32.83121 },
    { lng: 132.79609, lat: 32.83124 },
    { lng: 132.79613, lat: 32.83128 },
    { lng: 132.79613, lat: 32.83129 },
    { lng: 132.7962, lat: 32.83148 },
    { lng: 132.79623, lat: 32.83163 },
    { lng: 132.79627, lat: 32.83176 },
    { lng: 132.79628, lat: 32.83178 },
    { lng: 132.7963, lat: 32.8318 },
    { lng: 132.79639, lat: 32.8318 },
    { lng: 132.79651, lat: 32.83178 },
    { lng: 132.79658, lat: 32.83176 },
    { lng: 132.79672, lat: 32.83174 },
    { lng: 132.79678, lat: 32.83174 },
    { lng: 132.79691, lat: 32.83172 },
    { lng: 132.79696, lat: 32.83172 },
    { lng: 132.79708, lat: 32.8317 },
    { lng: 132.79714, lat: 32.83168 },
    { lng: 132.7972, lat: 32.83165 },
    { lng: 132.79721, lat: 32.83163 },
    { lng: 132.79722, lat: 32.83162 },
    { lng: 132.79722, lat: 32.8316 },
    { lng: 132.79723, lat: 32.83158 },
    { lng: 132.79723, lat: 32.83153 },
    { lng: 132.79721, lat: 32.83144 },
    { lng: 132.79712, lat: 32.83122 },
    { lng: 132.79706, lat: 32.83102 },
    { lng: 132.79705, lat: 32.83092 },
    { lng: 132.79703, lat: 32.82988 },
    { lng: 132.79701, lat: 32.82963 },
    { lng: 132.797, lat: 32.82959 },
    { lng: 132.797, lat: 32.82955 },
    { lng: 132.79698, lat: 32.82941 },
    { lng: 132.79699, lat: 32.82935 },
    { lng: 132.79703, lat: 32.82922 },
    { lng: 132.79706, lat: 32.82916 },
    { lng: 132.79707, lat: 32.82908 },
    { lng: 132.79708, lat: 32.82906 },
    { lng: 132.79708, lat: 32.82896 },
    { lng: 132.79707, lat: 32.82888 },
    { lng: 132.79706, lat: 32.82885 },
    { lng: 132.79703, lat: 32.82868 },
    { lng: 132.79701, lat: 32.82864 },
    { lng: 132.79691, lat: 32.82852 },
    { lng: 132.79641, lat: 32.82802 },
    { lng: 132.79637, lat: 32.82797 },
    { lng: 132.79633, lat: 32.82793 },
    { lng: 132.79625, lat: 32.82775 },
    { lng: 132.79613, lat: 32.82713 },
    { lng: 132.79609, lat: 32.82702 },
    { lng: 132.79598, lat: 32.82678 },
    { lng: 132.79567, lat: 32.82624 },
    { lng: 132.79561, lat: 32.82609 },
    { lng: 132.79559, lat: 32.82596 },
    { lng: 132.79554, lat: 32.82588 },
    { lng: 132.79549, lat: 32.82575 },
    { lng: 132.79544, lat: 32.82568 },
    { lng: 132.7954, lat: 32.82564 },
    { lng: 132.79533, lat: 32.82559 },
    { lng: 132.79525, lat: 32.82555 },
    { lng: 132.79523, lat: 32.82553 },
    { lng: 132.79489, lat: 32.82541 },
    { lng: 132.79445, lat: 32.82533 },
    { lng: 132.79441, lat: 32.82533 },
    { lng: 132.79435, lat: 32.82532 },
    { lng: 132.7942, lat: 32.82531 },
    { lng: 132.79398, lat: 32.82532 },
    { lng: 132.7938, lat: 32.82535 },
    { lng: 132.79377, lat: 32.82535 },
    { lng: 132.79369, lat: 32.82538 },
    { lng: 132.79346, lat: 32.82552 },
    { lng: 132.7934, lat: 32.82557 },
    { lng: 132.79325, lat: 32.82572 },
    { lng: 132.79319, lat: 32.82583 },
    { lng: 132.79306, lat: 32.826 },
    { lng: 132.793, lat: 32.82604 },
    { lng: 132.79292, lat: 32.82612 },
    { lng: 132.79291, lat: 32.82612 },
    { lng: 132.79272, lat: 32.82628 },
    { lng: 132.79261, lat: 32.8264 },
    { lng: 132.79252, lat: 32.82655 },
    { lng: 132.79251, lat: 32.82656 },
    { lng: 132.79243, lat: 32.82672 },
    { lng: 132.7924, lat: 32.8268 },
    { lng: 132.79231, lat: 32.82697 },
    { lng: 132.79227, lat: 32.82701 },
    { lng: 132.79221, lat: 32.82713 },
    { lng: 132.7922, lat: 32.82714 },
    { lng: 132.79212, lat: 32.82726 },
    { lng: 132.7921, lat: 32.8273 },
    { lng: 132.79209, lat: 32.82733 },
    { lng: 132.79209, lat: 32.82738 },
    { lng: 132.79212, lat: 32.82754 },
    { lng: 132.79215, lat: 32.82782 },
    { lng: 132.79215, lat: 32.82786 },
    { lng: 132.79213, lat: 32.82791 },
    { lng: 132.79209, lat: 32.82795 },
    { lng: 132.79205, lat: 32.82797 },
    { lng: 132.79202, lat: 32.82798 },
    { lng: 132.792, lat: 32.82798 },
    { lng: 132.79197, lat: 32.82799 },
    { lng: 132.79193, lat: 32.82799 },
    { lng: 132.79186, lat: 32.82797 },
    { lng: 132.79184, lat: 32.82797 },
    { lng: 132.79176, lat: 32.82794 },
    { lng: 132.79171, lat: 32.82791 },
    { lng: 132.79165, lat: 32.82785 },
    { lng: 132.79157, lat: 32.82775 },
    { lng: 132.7914, lat: 32.82748 },
    { lng: 132.79128, lat: 32.82723 },
    { lng: 132.79127, lat: 32.8272 },
    { lng: 132.79096, lat: 32.82662 },
    { lng: 132.79087, lat: 32.8265 },
    { lng: 132.79083, lat: 32.82646 },
    { lng: 132.79079, lat: 32.82643 },
    { lng: 132.79077, lat: 32.82641 },
    { lng: 132.79063, lat: 32.82638 },
    { lng: 132.79056, lat: 32.82638 },
    { lng: 132.79051, lat: 32.82639 },
    { lng: 132.79042, lat: 32.82643 },
    { lng: 132.79036, lat: 32.82647 },
    { lng: 132.79022, lat: 32.82662 },
    { lng: 132.79019, lat: 32.82664 },
    { lng: 132.79018, lat: 32.82665 },
    { lng: 132.79015, lat: 32.82667 },
    { lng: 132.79013, lat: 32.82667 },
    { lng: 132.7901, lat: 32.82668 },
    { lng: 132.79005, lat: 32.82668 },
    { lng: 132.79002, lat: 32.82669 },
    { lng: 132.79, lat: 32.82668 },
    { lng: 132.78995, lat: 32.82667 },
    { lng: 132.7899, lat: 32.82665 },
    { lng: 132.78986, lat: 32.82661 },
    { lng: 132.78982, lat: 32.82654 },
    { lng: 132.78977, lat: 32.8263 },
    { lng: 132.78974, lat: 32.82623 },
    { lng: 132.78968, lat: 32.82617 },
    { lng: 132.78951, lat: 32.82604 },
    { lng: 132.78941, lat: 32.826 },
    { lng: 132.78928, lat: 32.826 },
    { lng: 132.78923, lat: 32.82601 },
    { lng: 132.78919, lat: 32.82603 },
    { lng: 132.78912, lat: 32.8261 },
    { lng: 132.78898, lat: 32.82637 },
    { lng: 132.78895, lat: 32.82641 },
    { lng: 132.78894, lat: 32.82643 },
    { lng: 132.78891, lat: 32.82646 },
    { lng: 132.78889, lat: 32.82647 },
    { lng: 132.7886, lat: 32.82669 },
    { lng: 132.78839, lat: 32.82689 },
    { lng: 132.78784, lat: 32.82746 },
    { lng: 132.78762, lat: 32.82763 },
    { lng: 132.78746, lat: 32.82774 },
    { lng: 132.78737, lat: 32.82778 },
    { lng: 132.78725, lat: 32.82785 },
    { lng: 132.78665, lat: 32.82808 },
    { lng: 132.7865, lat: 32.8281 },
    { lng: 132.78612, lat: 32.82809 },
    { lng: 132.78602, lat: 32.82808 },
    { lng: 132.78588, lat: 32.82808 },
    { lng: 132.78585, lat: 32.82809 },
    { lng: 132.78582, lat: 32.82809 },
    { lng: 132.7854, lat: 32.82819 },
    { lng: 132.78536, lat: 32.82819 },
    { lng: 132.78531, lat: 32.8282 },
    { lng: 132.78523, lat: 32.8282 },
    { lng: 132.78502, lat: 32.82815 },
    { lng: 132.78501, lat: 32.82815 },
    { lng: 132.78496, lat: 32.82813 },
    { lng: 132.7849, lat: 32.82812 },
    { lng: 132.78472, lat: 32.82812 },
    { lng: 132.78458, lat: 32.82814 },
    { lng: 132.78451, lat: 32.82816 },
    { lng: 132.78404, lat: 32.8282 },
    { lng: 132.784, lat: 32.82821 },
    { lng: 132.78396, lat: 32.82821 },
    { lng: 132.78393, lat: 32.82822 },
    { lng: 132.7839, lat: 32.82824 },
    { lng: 132.78385, lat: 32.82826 },
    { lng: 132.78383, lat: 32.82828 },
    { lng: 132.78349, lat: 32.82854 },
    { lng: 132.78343, lat: 32.82857 },
    { lng: 132.78329, lat: 32.82857 },
    { lng: 132.78316, lat: 32.82855 },
    { lng: 132.78314, lat: 32.82854 },
    { lng: 132.78304, lat: 32.82852 },
    { lng: 132.783, lat: 32.82852 },
    { lng: 132.78299, lat: 32.82851 },
    { lng: 132.78285, lat: 32.82849 },
    { lng: 132.78281, lat: 32.8285 },
    { lng: 132.78276, lat: 32.8285 },
    { lng: 132.78271, lat: 32.82851 },
    { lng: 132.78269, lat: 32.82851 },
    { lng: 132.78265, lat: 32.82852 },
    { lng: 132.78264, lat: 32.82853 },
    { lng: 132.78256, lat: 32.82856 },
    { lng: 132.78252, lat: 32.82859 },
    { lng: 132.78226, lat: 32.82895 },
    { lng: 132.78203, lat: 32.82932 },
    { lng: 132.78195, lat: 32.82947 },
    { lng: 132.78186, lat: 32.82958 },
    { lng: 132.78185, lat: 32.8296 },
    { lng: 132.7817, lat: 32.82968 },
    { lng: 132.78161, lat: 32.8297 },
    { lng: 132.78142, lat: 32.82972 },
    { lng: 132.78138, lat: 32.82973 },
    { lng: 132.78136, lat: 32.82974 },
    { lng: 132.78132, lat: 32.82975 },
    { lng: 132.78122, lat: 32.8298 },
    { lng: 132.78116, lat: 32.82984 },
    { lng: 132.78086, lat: 32.83009 },
  ],
  [
    { lng: 132.78083, lat: 32.83006 }, //酒井
    { lng: 132.78086, lat: 32.83009 },
    { lng: 132.78116, lat: 32.82984 },
    { lng: 132.78122, lat: 32.8298 },
    { lng: 132.78132, lat: 32.82975 },
    { lng: 132.78136, lat: 32.82974 },
    { lng: 132.78138, lat: 32.82973 },
    { lng: 132.78142, lat: 32.82972 },
    { lng: 132.78161, lat: 32.8297 },
    { lng: 132.7817, lat: 32.82968 },
    { lng: 132.78185, lat: 32.8296 },
    { lng: 132.78186, lat: 32.82958 },
    { lng: 132.78195, lat: 32.82947 },
    { lng: 132.78203, lat: 32.82932 },
    { lng: 132.78226, lat: 32.82895 },
    { lng: 132.78252, lat: 32.82859 },
    { lng: 132.78256, lat: 32.82856 },
    { lng: 132.78264, lat: 32.82853 },
    { lng: 132.78265, lat: 32.82852 },
    { lng: 132.78269, lat: 32.82851 },
    { lng: 132.78271, lat: 32.82851 },
    { lng: 132.78276, lat: 32.8285 },
    { lng: 132.78281, lat: 32.8285 },
    { lng: 132.78285, lat: 32.82849 },
    { lng: 132.78299, lat: 32.82851 },
    { lng: 132.783, lat: 32.82852 },
    { lng: 132.78304, lat: 32.82852 },
    { lng: 132.78314, lat: 32.82854 },
    { lng: 132.78316, lat: 32.82855 },
    { lng: 132.78329, lat: 32.82857 },
    { lng: 132.78343, lat: 32.82857 },
    { lng: 132.78349, lat: 32.82854 },
    { lng: 132.78383, lat: 32.82828 },
    { lng: 132.78385, lat: 32.82826 },
    { lng: 132.7839, lat: 32.82824 },
    { lng: 132.78393, lat: 32.82822 },
    { lng: 132.78396, lat: 32.82821 },
    { lng: 132.784, lat: 32.82821 },
    { lng: 132.78404, lat: 32.8282 },
    { lng: 132.78451, lat: 32.82816 },
    { lng: 132.78458, lat: 32.82814 },
    { lng: 132.78472, lat: 32.82812 },
    { lng: 132.7849, lat: 32.82812 },
    { lng: 132.78496, lat: 32.82813 },
    { lng: 132.78501, lat: 32.82815 },
    { lng: 132.78502, lat: 32.82815 },
    { lng: 132.78523, lat: 32.8282 },
    { lng: 132.78531, lat: 32.8282 },
    { lng: 132.78536, lat: 32.82819 },
    { lng: 132.7854, lat: 32.82819 },
    { lng: 132.78582, lat: 32.82809 },
    { lng: 132.78585, lat: 32.82809 },
    { lng: 132.78588, lat: 32.82808 },
    { lng: 132.78602, lat: 32.82808 },
    { lng: 132.78612, lat: 32.82809 },
    { lng: 132.7865, lat: 32.8281 },
    { lng: 132.78665, lat: 32.82808 },
    { lng: 132.78725, lat: 32.82785 },
    { lng: 132.78737, lat: 32.82778 },
    { lng: 132.78746, lat: 32.82774 },
    { lng: 132.78762, lat: 32.82763 },
    { lng: 132.78784, lat: 32.82746 },
    { lng: 132.78839, lat: 32.82689 },
    { lng: 132.7886, lat: 32.82669 },
    { lng: 132.78889, lat: 32.82647 },
    { lng: 132.78891, lat: 32.82646 },
    { lng: 132.78894, lat: 32.82643 },
    { lng: 132.78895, lat: 32.82641 },
    { lng: 132.78898, lat: 32.82637 },
    { lng: 132.78912, lat: 32.8261 },
    { lng: 132.78919, lat: 32.82603 },
    { lng: 132.78923, lat: 32.82601 },
    { lng: 132.78928, lat: 32.826 },
    { lng: 132.78941, lat: 32.826 },
    { lng: 132.78951, lat: 32.82604 },
    { lng: 132.78968, lat: 32.82617 },
    { lng: 132.78974, lat: 32.82623 },
    { lng: 132.78977, lat: 32.8263 },
    { lng: 132.78982, lat: 32.82654 },
    { lng: 132.78986, lat: 32.82661 },
    { lng: 132.7899, lat: 32.82665 },
    { lng: 132.78995, lat: 32.82667 },
    { lng: 132.79, lat: 32.82668 },
    { lng: 132.79002, lat: 32.82669 },
    { lng: 132.79005, lat: 32.82668 },
    { lng: 132.7901, lat: 32.82668 },
    { lng: 132.79013, lat: 32.82667 },
    { lng: 132.79015, lat: 32.82667 },
    { lng: 132.79018, lat: 32.82665 },
    { lng: 132.79019, lat: 32.82664 },
    { lng: 132.79022, lat: 32.82662 },
    { lng: 132.79036, lat: 32.82647 },
    { lng: 132.79042, lat: 32.82643 },
    { lng: 132.79051, lat: 32.82639 },
    { lng: 132.79056, lat: 32.82638 },
    { lng: 132.79063, lat: 32.82638 },
    { lng: 132.79077, lat: 32.82641 },
    { lng: 132.79079, lat: 32.82643 },
    { lng: 132.79083, lat: 32.82646 },
    { lng: 132.79087, lat: 32.8265 },
    { lng: 132.79096, lat: 32.82662 },
    { lng: 132.79127, lat: 32.8272 },
    { lng: 132.79128, lat: 32.82723 },
    { lng: 132.7914, lat: 32.82748 },
    { lng: 132.79157, lat: 32.82775 },
    { lng: 132.79165, lat: 32.82785 },
    { lng: 132.79171, lat: 32.82791 },
    { lng: 132.79176, lat: 32.82794 },
    { lng: 132.79184, lat: 32.82797 },
    { lng: 132.79186, lat: 32.82797 },
    { lng: 132.79193, lat: 32.82799 },
    { lng: 132.79197, lat: 32.82799 },
    { lng: 132.792, lat: 32.82798 },
    { lng: 132.79202, lat: 32.82798 },
    { lng: 132.79205, lat: 32.82797 },
    { lng: 132.79209, lat: 32.82795 },
    { lng: 132.79213, lat: 32.82791 },
    { lng: 132.79215, lat: 32.82786 },
    { lng: 132.79215, lat: 32.82782 },
    { lng: 132.79212, lat: 32.82754 },
    { lng: 132.79209, lat: 32.82738 },
    { lng: 132.79209, lat: 32.82733 },
    { lng: 132.7921, lat: 32.8273 },
    { lng: 132.79212, lat: 32.82726 },
    { lng: 132.7922, lat: 32.82714 },
    { lng: 132.79221, lat: 32.82713 },
    { lng: 132.79227, lat: 32.82701 },
    { lng: 132.79231, lat: 32.82697 },
    { lng: 132.7924, lat: 32.8268 },
    { lng: 132.79243, lat: 32.82672 },
    { lng: 132.79251, lat: 32.82656 },
    { lng: 132.79252, lat: 32.82655 },
    { lng: 132.79261, lat: 32.8264 },
    { lng: 132.79272, lat: 32.82628 },
    { lng: 132.79291, lat: 32.82612 },
    { lng: 132.79292, lat: 32.82612 },
    { lng: 132.793, lat: 32.82604 },
    { lng: 132.79306, lat: 32.826 },
    { lng: 132.79319, lat: 32.82583 },
    { lng: 132.79325, lat: 32.82572 },
    { lng: 132.7934, lat: 32.82557 },
    { lng: 132.79346, lat: 32.82552 },
    { lng: 132.79369, lat: 32.82538 },
    { lng: 132.79377, lat: 32.82535 },
    { lng: 132.7938, lat: 32.82535 },
    { lng: 132.79398, lat: 32.82532 },
    { lng: 132.7942, lat: 32.82531 },
    { lng: 132.79435, lat: 32.82532 },
    { lng: 132.79441, lat: 32.82533 },
    { lng: 132.79445, lat: 32.82533 },
    { lng: 132.79489, lat: 32.82541 },
    { lng: 132.79523, lat: 32.82553 },
    { lng: 132.79525, lat: 32.82555 },
    { lng: 132.79533, lat: 32.82559 },
    { lng: 132.7954, lat: 32.82564 },
    { lng: 132.79544, lat: 32.82568 },
    { lng: 132.79549, lat: 32.82575 },
    { lng: 132.79554, lat: 32.82588 },
    { lng: 132.79559, lat: 32.82596 },
    { lng: 132.79605, lat: 32.82584 },
    { lng: 132.79613, lat: 32.82581 },
    { lng: 132.79622, lat: 32.82597 },
    { lng: 132.79628, lat: 32.82612 },
    { lng: 132.79644, lat: 32.8264 },
    { lng: 132.79649, lat: 32.82646 },
    { lng: 132.79709, lat: 32.82731 },
    { lng: 132.79716, lat: 32.82738 },
    { lng: 132.79718, lat: 32.82742 },
    { lng: 132.79718, lat: 32.82744 },
    { lng: 132.7972, lat: 32.82752 },
    { lng: 132.79723, lat: 32.82757 },
    { lng: 132.79744, lat: 32.82779 },
    { lng: 132.79798, lat: 32.82817 },
    { lng: 132.79799, lat: 32.82817 },
    { lng: 132.79811, lat: 32.82825 },
    { lng: 132.7982, lat: 32.82833 },
    { lng: 132.79829, lat: 32.82847 },
    { lng: 132.79831, lat: 32.82874 },
    { lng: 132.79833, lat: 32.82879 },
    { lng: 132.79837, lat: 32.82885 },
    { lng: 132.79869, lat: 32.82919 },
    { lng: 132.79876, lat: 32.82923 },
    { lng: 132.79889, lat: 32.82926 },
    { lng: 132.79893, lat: 32.82926 },
    { lng: 132.79903, lat: 32.82929 },
    { lng: 132.79912, lat: 32.82933 },
    { lng: 132.79917, lat: 32.8294 },
    { lng: 132.79926, lat: 32.82958 },
    { lng: 132.79931, lat: 32.82964 },
    { lng: 132.79942, lat: 32.82973 },
    { lng: 132.79949, lat: 32.82976 },
    { lng: 132.79993, lat: 32.82982 },
    { lng: 132.80003, lat: 32.82985 },
    { lng: 132.80018, lat: 32.82995 },
    { lng: 132.80034, lat: 32.8301 },
    { lng: 132.80039, lat: 32.83016 },
    { lng: 132.80077, lat: 32.8307 },
    { lng: 132.80088, lat: 32.83089 },
    { lng: 132.80097, lat: 32.83102 },
    { lng: 132.80108, lat: 32.8311 },
  ],
  [
    { lng: 132.80138, lat: 32.83125 }, // 木ノ川
    { lng: 132.80108, lat: 32.8311 },
    { lng: 132.80097, lat: 32.83102 },
    { lng: 132.80088, lat: 32.83089 },
    { lng: 132.80077, lat: 32.8307 },
    { lng: 132.80039, lat: 32.83016 },
    { lng: 132.80034, lat: 32.8301 },
    { lng: 132.80018, lat: 32.82995 },
    { lng: 132.80003, lat: 32.82985 },
    { lng: 132.79993, lat: 32.82982 },
    { lng: 132.79949, lat: 32.82976 },
    { lng: 132.79942, lat: 32.82973 },
    { lng: 132.79931, lat: 32.82964 },
    { lng: 132.79926, lat: 32.82958 },
    { lng: 132.79917, lat: 32.8294 },
    { lng: 132.79912, lat: 32.82933 },
    { lng: 132.79903, lat: 32.82929 },
    { lng: 132.79893, lat: 32.82926 },
    { lng: 132.79889, lat: 32.82926 },
    { lng: 132.79876, lat: 32.82923 },
    { lng: 132.79869, lat: 32.82919 },
    { lng: 132.79837, lat: 32.82885 },
    { lng: 132.79833, lat: 32.82879 },
    { lng: 132.79831, lat: 32.82874 },
    { lng: 132.79829, lat: 32.82847 },
    { lng: 132.7982, lat: 32.82833 },
    { lng: 132.79811, lat: 32.82825 },
    { lng: 132.79799, lat: 32.82817 },
    { lng: 132.79798, lat: 32.82817 },
    { lng: 132.79744, lat: 32.82779 },
    { lng: 132.79723, lat: 32.82757 },
    { lng: 132.7972, lat: 32.82752 },
    { lng: 132.79718, lat: 32.82744 },
    { lng: 132.79718, lat: 32.82742 },
    { lng: 132.79716, lat: 32.82738 },
    { lng: 132.79709, lat: 32.82731 },
    { lng: 132.79649, lat: 32.82646 },
    { lng: 132.79644, lat: 32.8264 },
    { lng: 132.79628, lat: 32.82612 },
    { lng: 132.79622, lat: 32.82597 },
    { lng: 132.79613, lat: 32.82581 },
    { lng: 132.79635, lat: 32.8257 },
    { lng: 132.79669, lat: 32.8255 },
    { lng: 132.7967, lat: 32.82549 },
    { lng: 132.79681, lat: 32.82543 },
    { lng: 132.79696, lat: 32.82532 },
    { lng: 132.79706, lat: 32.82526 },
    { lng: 132.79718, lat: 32.82514 },
    { lng: 132.79719, lat: 32.82512 },
    { lng: 132.79725, lat: 32.82505 },
    { lng: 132.79729, lat: 32.82498 },
    { lng: 132.79733, lat: 32.82488 },
    { lng: 132.79734, lat: 32.82482 },
    { lng: 132.79735, lat: 32.8248 },
    { lng: 132.79737, lat: 32.82464 },
    { lng: 132.79739, lat: 32.82362 },
    { lng: 132.79741, lat: 32.82346 },
    { lng: 132.79746, lat: 32.8232 },
    { lng: 132.79769, lat: 32.82223 },
    { lng: 132.79771, lat: 32.82218 },
    { lng: 132.79774, lat: 32.82213 },
    { lng: 132.79783, lat: 32.82203 },
    { lng: 132.79788, lat: 32.82199 },
    { lng: 132.79796, lat: 32.82194 },
    { lng: 132.79913, lat: 32.82137 },
    { lng: 132.79916, lat: 32.82136 },
    { lng: 132.79969, lat: 32.82111 },
    { lng: 132.79985, lat: 32.82108 },
    { lng: 132.80049, lat: 32.82106 },
    { lng: 132.801, lat: 32.82115 },
    { lng: 132.80111, lat: 32.82118 },
    { lng: 132.80172, lat: 32.8213 },
    { lng: 132.80179, lat: 32.8213 },
    { lng: 132.80182, lat: 32.82131 },
    { lng: 132.80193, lat: 32.82132 },
    { lng: 132.80203, lat: 32.82132 },
    { lng: 132.80311, lat: 32.82117 },
    { lng: 132.8035, lat: 32.82114 },
    { lng: 132.80392, lat: 32.82113 },
    { lng: 132.80401, lat: 32.82112 },
    { lng: 132.80468, lat: 32.82113 },
    { lng: 132.8048, lat: 32.82114 },
    { lng: 132.80534, lat: 32.8211 },
  ],
  [
    { lng: 132.80553, lat: 32.82107 }, // 宗呂上 あやしい
    { lng: 132.80556, lat: 32.82106 },
    { lng: 132.80573, lat: 32.82103 },
    { lng: 132.80636, lat: 32.82088 },
    { lng: 132.80644, lat: 32.82087 },
    { lng: 132.80663, lat: 32.82081 },
    { lng: 132.80673, lat: 32.82077 },
    { lng: 132.80676, lat: 32.82075 },
    { lng: 132.80689, lat: 32.82069 },
    { lng: 132.80727, lat: 32.82043 },
    { lng: 132.8074, lat: 32.82032 },
    { lng: 132.80753, lat: 32.82018 },
    { lng: 132.80754, lat: 32.82015 },
    { lng: 132.80759, lat: 32.82008 },
    { lng: 132.80762, lat: 32.82001 },
    { lng: 132.80767, lat: 32.81985 },
    { lng: 132.80768, lat: 32.81983 },
    { lng: 132.80776, lat: 32.81948 },
    { lng: 132.80779, lat: 32.81939 },
    { lng: 132.80783, lat: 32.81932 },
    { lng: 132.80785, lat: 32.8193 },
    { lng: 132.80789, lat: 32.81924 },
    { lng: 132.80804, lat: 32.81912 },
    { lng: 132.80813, lat: 32.81907 },
    { lng: 132.80822, lat: 32.81904 },
    { lng: 132.80843, lat: 32.819 },
    { lng: 132.80929, lat: 32.81896 },
    { lng: 132.80941, lat: 32.81897 },
    { lng: 132.80962, lat: 32.81901 },
    { lng: 132.80983, lat: 32.8191 },
    { lng: 132.80996, lat: 32.81921 },
    { lng: 132.80998, lat: 32.81922 },
    { lng: 132.8101, lat: 32.81935 },
    { lng: 132.81011, lat: 32.81937 },
    { lng: 132.81017, lat: 32.81943 },
    { lng: 132.8104, lat: 32.81971 },
    { lng: 132.81044, lat: 32.81974 },
    { lng: 132.8105, lat: 32.8198 },
    { lng: 132.81068, lat: 32.81989 },
    { lng: 132.81069, lat: 32.81989 },
    { lng: 132.81075, lat: 32.81992 },
    { lng: 132.81082, lat: 32.81994 },
    { lng: 132.81106, lat: 32.81997 },
    { lng: 132.81117, lat: 32.81997 },
    { lng: 132.81131, lat: 32.81995 },
    { lng: 132.81147, lat: 32.81989 },
    { lng: 132.81165, lat: 32.81978 },
    { lng: 132.81172, lat: 32.81972 },
    { lng: 132.81178, lat: 32.81965 },
    { lng: 132.81188, lat: 32.81948 },
    { lng: 132.81191, lat: 32.81939 },
    { lng: 132.81203, lat: 32.81888 },
    { lng: 132.81206, lat: 32.81868 },
    { lng: 132.81219, lat: 32.81807 },
    { lng: 132.8122, lat: 32.81798 },
    { lng: 132.81229, lat: 32.8177 },
    { lng: 132.81232, lat: 32.81765 },
    { lng: 132.81239, lat: 32.81756 },
    { lng: 132.81243, lat: 32.81752 },
    { lng: 132.81246, lat: 32.81748 },
    { lng: 132.81251, lat: 32.81744 },
    { lng: 132.81255, lat: 32.81742 },
    { lng: 132.81261, lat: 32.81738 },
    { lng: 132.81282, lat: 32.8173 },
    { lng: 132.81356, lat: 32.81716 },
    { lng: 132.81378, lat: 32.81713 },
    { lng: 132.81492, lat: 32.81692 },
    { lng: 132.81627, lat: 32.81659 },
    { lng: 132.81649, lat: 32.81652 },
    { lng: 132.81659, lat: 32.81648 },
    { lng: 132.81676, lat: 32.81636 },
    { lng: 132.81685, lat: 32.81627 },
    { lng: 132.81693, lat: 32.81616 },
    { lng: 132.817, lat: 32.81599 },
    { lng: 132.81703, lat: 32.81588 },
    { lng: 132.81703, lat: 32.81572 },
    { lng: 132.8168, lat: 32.81409 },
    { lng: 132.8168, lat: 32.81387 },
    { lng: 132.81683, lat: 32.81361 },
    { lng: 132.81685, lat: 32.81355 },
    { lng: 132.81685, lat: 32.81352 },
    { lng: 132.81691, lat: 32.81331 },
    { lng: 132.81696, lat: 32.81317 },
    { lng: 132.817, lat: 32.81309 },
    { lng: 132.81703, lat: 32.81301 },
    { lng: 132.81713, lat: 32.81284 },
    { lng: 132.81713, lat: 32.81283 },
    { lng: 132.81722, lat: 32.81269 },
    { lng: 132.81824, lat: 32.81159 },
    { lng: 132.81828, lat: 32.81156 },
    { lng: 132.81861, lat: 32.81122 },
    { lng: 132.81865, lat: 32.81117 },
    { lng: 132.81892, lat: 32.81089 },
    { lng: 132.81896, lat: 32.81086 },
    { lng: 132.81908, lat: 32.8107 },
    { lng: 132.81922, lat: 32.81042 },
    { lng: 132.81928, lat: 32.81025 },
    { lng: 132.81936, lat: 32.81008 },
    { lng: 132.81938, lat: 32.81002 },

    { lng: 132.81942, lat: 32.80994 },
    { lng: 132.81945, lat: 32.80984 },
    { lng: 132.81949, lat: 32.80977 },
    { lng: 132.8195, lat: 32.80974 },
    { lng: 132.81959, lat: 32.80961 },
    { lng: 132.81966, lat: 32.80954 },
    { lng: 132.81971, lat: 32.80948 },
    { lng: 132.81993, lat: 32.80928 },
    { lng: 132.8202, lat: 32.80901 },
    { lng: 132.82028, lat: 32.80891 },
    { lng: 132.82029, lat: 32.80889 },
    { lng: 132.82048, lat: 32.80861 },
    { lng: 132.82067, lat: 32.80829 },
    { lng: 132.82074, lat: 32.80815 },
    { lng: 132.82076, lat: 32.80809 },
    { lng: 132.82079, lat: 32.80804 },
    { lng: 132.82085, lat: 32.80786 },
    { lng: 132.82089, lat: 32.80769 },
    { lng: 132.82095, lat: 32.80725 },
    { lng: 132.82097, lat: 32.80717 },
    { lng: 132.82105, lat: 32.80699 },
    { lng: 132.82113, lat: 32.80687 },
    { lng: 132.8214, lat: 32.80654 },
    { lng: 132.82148, lat: 32.80642 },
    { lng: 132.82193, lat: 32.80587 },
    { lng: 132.82202, lat: 32.8057 },
    { lng: 132.82205, lat: 32.80562 },
    { lng: 132.82209, lat: 32.80554 },
    { lng: 132.82216, lat: 32.80527 },
    { lng: 132.82219, lat: 32.805 },
    { lng: 132.82219, lat: 32.80491 },
    { lng: 132.8222, lat: 32.80482 },
    { lng: 132.82221, lat: 32.80446 },
    { lng: 132.82222, lat: 32.80441 },
    { lng: 132.82223, lat: 32.80318 },
    { lng: 132.82222, lat: 32.8029 },
    { lng: 132.82221, lat: 32.80283 },
    { lng: 132.8222, lat: 32.80281 },
    { lng: 132.82219, lat: 32.80273 },
    { lng: 132.82216, lat: 32.80261 },
    { lng: 132.8221, lat: 32.80244 },
    { lng: 132.82202, lat: 32.80228 },
    { lng: 132.82182, lat: 32.80196 },
    { lng: 132.82168, lat: 32.80171 },
    { lng: 132.82144, lat: 32.80136 },
    { lng: 132.82142, lat: 32.80132 },
    { lng: 132.8212, lat: 32.80106 },
    { lng: 132.82119, lat: 32.80104 },
    { lng: 132.82082, lat: 32.80059 },
    { lng: 132.82078, lat: 32.80053 },
    { lng: 132.82063, lat: 32.80035 },
    { lng: 132.82051, lat: 32.80016 },
    { lng: 132.82045, lat: 32.80003 },
    { lng: 132.82041, lat: 32.7999 },
    { lng: 132.82025, lat: 32.7992 },
    { lng: 132.82025, lat: 32.79911 },
    { lng: 132.82028, lat: 32.79898 },
    { lng: 132.82034, lat: 32.79884 },
    { lng: 132.82044, lat: 32.79873 },
  ], // 宗呂下
  [
    { lng: 132.82151, lat: 32.79791 },
    { lng: 132.82164, lat: 32.79775 },
    { lng: 132.82167, lat: 32.7977 },
    { lng: 132.82167, lat: 32.79769 },
    { lng: 132.8217, lat: 32.79762 },
    { lng: 132.82171, lat: 32.79753 },
    { lng: 132.82171, lat: 32.7974 },
    { lng: 132.8217, lat: 32.79738 },
    { lng: 132.82166, lat: 32.79718 },
    { lng: 132.82138, lat: 32.79613 },
    { lng: 132.82138, lat: 32.79611 },
    { lng: 132.82129, lat: 32.79579 },
    { lng: 132.82127, lat: 32.79574 },
    { lng: 132.82124, lat: 32.79559 },
    { lng: 132.82121, lat: 32.79533 },
    { lng: 132.82122, lat: 32.79528 },
    { lng: 132.82122, lat: 32.79522 },
    { lng: 132.82147, lat: 32.79362 },
    { lng: 132.8215, lat: 32.79326 },
    { lng: 132.82149, lat: 32.7929 },
    { lng: 132.82145, lat: 32.79254 },
    { lng: 132.82141, lat: 32.79235 },
    { lng: 132.8213, lat: 32.79201 },
    { lng: 132.82129, lat: 32.79201 },
    { lng: 132.82126, lat: 32.79192 },
    { lng: 132.82119, lat: 32.79177 },
    { lng: 132.82107, lat: 32.79155 },
    { lng: 132.82107, lat: 32.79154 },
    { lng: 132.82099, lat: 32.79135 },
    { lng: 132.82097, lat: 32.79128 },
    { lng: 132.82094, lat: 32.79109 },
    { lng: 132.82094, lat: 32.79102 },
    { lng: 132.82093, lat: 32.79098 },
    { lng: 132.82096, lat: 32.79009 },
    { lng: 132.82097, lat: 32.79 },
    { lng: 132.82099, lat: 32.7899 },
    { lng: 132.82104, lat: 32.78976 },
    { lng: 132.8211, lat: 32.78965 },
    { lng: 132.82116, lat: 32.78957 },
    { lng: 132.82128, lat: 32.78944 },
    { lng: 132.82153, lat: 32.78927 },
    { lng: 132.82172, lat: 32.7892 },
    { lng: 132.82328, lat: 32.78891 },
    { lng: 132.82372, lat: 32.78886 },
    { lng: 132.82514, lat: 32.78886 },
    { lng: 132.82555, lat: 32.78882 },
    { lng: 132.8264, lat: 32.78868 },
    { lng: 132.8265, lat: 32.78867 },
    { lng: 132.82679, lat: 32.78862 },
    { lng: 132.82735, lat: 32.78859 },
    { lng: 132.82769, lat: 32.78859 },
    { lng: 132.82791, lat: 32.78857 },
    { lng: 132.82813, lat: 32.78852 },
    { lng: 132.82832, lat: 32.78844 },
    { lng: 132.82879, lat: 32.78816 },
    { lng: 132.82887, lat: 32.7881 },
    { lng: 132.82889, lat: 32.78808 },
    { lng: 132.82895, lat: 32.78804 },
    { lng: 132.82902, lat: 32.78798 },
    { lng: 132.82905, lat: 32.78796 },
    { lng: 132.82944, lat: 32.78753 },
    { lng: 132.82949, lat: 32.78746 },
    { lng: 132.82955, lat: 32.7874 },
    { lng: 132.82956, lat: 32.78738 },
    { lng: 132.83019, lat: 32.7866 },
    { lng: 132.83046, lat: 32.78617 },
    { lng: 132.83047, lat: 32.78616 },
    { lng: 132.83061, lat: 32.78593 },
    { lng: 132.83067, lat: 32.78585 },
    { lng: 132.83067, lat: 32.78584 },
    { lng: 132.83077, lat: 32.78569 },
    { lng: 132.83089, lat: 32.78554 },
    { lng: 132.83096, lat: 32.78547 },
    { lng: 132.83142, lat: 32.78507 },
    { lng: 132.83172, lat: 32.78489 },
    { lng: 132.83193, lat: 32.78481 },
    { lng: 132.8321, lat: 32.78477 },
    { lng: 132.83232, lat: 32.78475 },
    { lng: 132.83243, lat: 32.78476 },
    { lng: 132.83253, lat: 32.78476 },
    { lng: 132.83264, lat: 32.78477 },
    { lng: 132.83582, lat: 32.78525 },
    { lng: 132.83668, lat: 32.78533 },
    { lng: 132.8374, lat: 32.78533 },
    { lng: 132.83755, lat: 32.78532 },
    { lng: 132.83762, lat: 32.78533 },
    { lng: 132.8382, lat: 32.78533 },
    { lng: 132.83825, lat: 32.78535 },
    { lng: 132.83833, lat: 32.78537 },
    { lng: 132.83843, lat: 32.78537 },
    { lng: 132.83852, lat: 32.78538 },
    { lng: 132.83876, lat: 32.78538 },
    { lng: 132.8389, lat: 32.78536 },
    { lng: 132.83895, lat: 32.78536 },
    { lng: 132.83952, lat: 32.78525 },
    { lng: 132.83961, lat: 32.78524 },
  ],
  [
    { lng: 132.84044, lat: 32.78498 }, // 下川口市民センター
    { lng: 132.84058, lat: 32.78495 },
    { lng: 132.8407, lat: 32.78491 },
    { lng: 132.84079, lat: 32.78489 },
    { lng: 132.84092, lat: 32.78483 },
    { lng: 132.84111, lat: 32.78477 },
    { lng: 132.8412, lat: 32.78476 },
    { lng: 132.84193, lat: 32.78457 },
    { lng: 132.84195, lat: 32.78456 },
    { lng: 132.84254, lat: 32.78441 },
    { lng: 132.84256, lat: 32.7844 },
    { lng: 132.84265, lat: 32.78438 },
    { lng: 132.84285, lat: 32.78431 },
    { lng: 132.84294, lat: 32.78427 },
    { lng: 132.84312, lat: 32.78414 },
    { lng: 132.84325, lat: 32.78401 },
    { lng: 132.84335, lat: 32.78386 },
    { lng: 132.84339, lat: 32.78377 },
    { lng: 132.84341, lat: 32.78368 },
    { lng: 132.84343, lat: 32.78364 },
  ],
  [
    { lng: 132.84343, lat: 32.78362 }, // 下川口漁協バス停
    { lng: 132.84346, lat: 32.78352 },
    { lng: 132.84347, lat: 32.78346 },
    { lng: 132.84348, lat: 32.78343 },
    { lng: 132.84349, lat: 32.78336 },
    { lng: 132.84354, lat: 32.78318 },
    { lng: 132.84354, lat: 32.78315 },
    { lng: 132.84358, lat: 32.78297 },
    { lng: 132.84363, lat: 32.7828 },
    { lng: 132.84384, lat: 32.78185 },
    { lng: 132.84384, lat: 32.78179 },
    { lng: 132.84383, lat: 32.78175 },
    { lng: 132.84382, lat: 32.78157 },
    { lng: 132.84412, lat: 32.78151 },
    { lng: 132.84435, lat: 32.78151 },
    { lng: 132.8444, lat: 32.78152 },
    { lng: 132.84447, lat: 32.78152 },
    { lng: 132.84453, lat: 32.78154 },
    { lng: 132.84458, lat: 32.78155 },
    { lng: 132.84467, lat: 32.78158 },
    { lng: 132.84677, lat: 32.78249 },
    { lng: 132.84749, lat: 32.78278 },
    { lng: 132.84772, lat: 32.78278 },
    { lng: 132.84775, lat: 32.7828 },
    { lng: 132.84779, lat: 32.78281 },
    { lng: 132.848, lat: 32.78291 },
    { lng: 132.84934, lat: 32.78346 },
    { lng: 132.84952, lat: 32.78356 },
    { lng: 132.84966, lat: 32.78368 },
    { lng: 132.84974, lat: 32.78377 },
    { lng: 132.8498, lat: 32.78386 },
    { lng: 132.84982, lat: 32.78388 },
    { lng: 132.84984, lat: 32.78393 },
    { lng: 132.84995, lat: 32.78412 },
    { lng: 132.85005, lat: 32.78435 },
    { lng: 132.8501, lat: 32.78444 },
    { lng: 132.85017, lat: 32.7846 },
    { lng: 132.85022, lat: 32.78468 },
    { lng: 132.85041, lat: 32.78505 },
    { lng: 132.85056, lat: 32.78528 },
    { lng: 132.85084, lat: 32.7856 },
    { lng: 132.85104, lat: 32.7858 },
    { lng: 132.85135, lat: 32.78614 },
    { lng: 132.85157, lat: 32.78644 },
    { lng: 132.85179, lat: 32.78677 },
    { lng: 132.85192, lat: 32.78692 },
    { lng: 132.85207, lat: 32.78705 },
    { lng: 132.85216, lat: 32.7871 },
    { lng: 132.85225, lat: 32.78714 },
    { lng: 132.85256, lat: 32.78723 },
    { lng: 132.85333, lat: 32.78741 },
    { lng: 132.85364, lat: 32.78745 },
    { lng: 132.85605, lat: 32.78751 },
    { lng: 132.85637, lat: 32.78756 },
    { lng: 132.85659, lat: 32.78761 },
  ],
  [
    { lng: 132.85693, lat: 32.78773 }, // 爪白
    { lng: 132.85804, lat: 32.78819 },
    { lng: 132.85826, lat: 32.78831 },
    { lng: 132.85832, lat: 32.78835 },
    { lng: 132.85843, lat: 32.78844 },
    { lng: 132.85854, lat: 32.78855 },
    { lng: 132.85861, lat: 32.78864 },
    { lng: 132.85864, lat: 32.78869 },
    { lng: 132.85865, lat: 32.7887 },
    { lng: 132.85912, lat: 32.78956 },
    { lng: 132.85922, lat: 32.78971 },
    { lng: 132.85943, lat: 32.78996 },
    { lng: 132.85956, lat: 32.79008 },
    { lng: 132.85957, lat: 32.79008 },
    { lng: 132.85961, lat: 32.79013 },
    { lng: 132.86081, lat: 32.79127 },
    { lng: 132.86093, lat: 32.79137 },
    { lng: 132.86103, lat: 32.79143 },
    { lng: 132.86117, lat: 32.7915 },
    { lng: 132.86123, lat: 32.79152 },
    { lng: 132.86127, lat: 32.79154 },
    { lng: 132.86143, lat: 32.79158 },
    { lng: 132.86146, lat: 32.79158 },
    { lng: 132.86148, lat: 32.79159 },
    { lng: 132.86151, lat: 32.79159 },
    { lng: 132.86176, lat: 32.79162 },
    { lng: 132.86185, lat: 32.79162 },
    { lng: 132.86398, lat: 32.7918 },
    { lng: 132.86435, lat: 32.7918 },
    { lng: 132.86443, lat: 32.79179 },
    { lng: 132.86449, lat: 32.79179 },
    { lng: 132.86457, lat: 32.79178 },
    { lng: 132.86493, lat: 32.7917 },
    { lng: 132.86523, lat: 32.79159 },
    { lng: 132.86546, lat: 32.79147 },
    { lng: 132.86556, lat: 32.7914 },
    { lng: 132.86567, lat: 32.79131 },
    { lng: 132.86572, lat: 32.79128 },
    { lng: 132.86584, lat: 32.79116 },
    { lng: 132.86588, lat: 32.79111 },
    { lng: 132.86593, lat: 32.79106 },
    { lng: 132.86605, lat: 32.79088 },
    { lng: 132.86607, lat: 32.79083 },
    { lng: 132.86611, lat: 32.79077 },
    { lng: 132.86616, lat: 32.79067 },
    { lng: 132.8662, lat: 32.79057 },
    { lng: 132.86657, lat: 32.78987 },
    { lng: 132.86666, lat: 32.78972 },
    { lng: 132.86687, lat: 32.78947 },
    { lng: 132.86695, lat: 32.78939 },
    { lng: 132.86708, lat: 32.78929 },
  ],
  [
    { lng: 132.86711, lat: 32.78926 }, // 竜串
    { lng: 132.86712, lat: 32.78926 },
    { lng: 132.86734, lat: 32.78909 },
    { lng: 132.86736, lat: 32.78907 },
    { lng: 132.86754, lat: 32.78895 },
    { lng: 132.8676, lat: 32.7889 },
    { lng: 132.86767, lat: 32.78886 },
    { lng: 132.8677, lat: 32.78885 },
    { lng: 132.86772, lat: 32.78883 },
    { lng: 132.8678, lat: 32.78881 },
    { lng: 132.86781, lat: 32.7888 },
    { lng: 132.86792, lat: 32.78877 },
    { lng: 132.868, lat: 32.78876 },
    { lng: 132.86803, lat: 32.78875 },
    { lng: 132.86827, lat: 32.78875 },
    { lng: 132.86835, lat: 32.78876 },
    { lng: 132.86857, lat: 32.78882 },
    { lng: 132.86862, lat: 32.78885 },
    { lng: 132.86868, lat: 32.78887 },
    { lng: 132.86965, lat: 32.78949 },
    { lng: 132.86966, lat: 32.7895 },
    { lng: 132.86979, lat: 32.78958 },
    { lng: 132.8699, lat: 32.78963 },
    { lng: 132.86994, lat: 32.78964 },
    { lng: 132.86999, lat: 32.78966 },
    { lng: 132.87003, lat: 32.78966 },
    { lng: 132.87009, lat: 32.78967 },
    { lng: 132.87027, lat: 32.78967 },
    { lng: 132.87028, lat: 32.78966 },
    { lng: 132.87043, lat: 32.78963 },
    { lng: 132.87125, lat: 32.78942 },
    { lng: 132.87131, lat: 32.78941 },
    { lng: 132.87146, lat: 32.78941 },
    { lng: 132.87157, lat: 32.78943 },
    { lng: 132.87159, lat: 32.78943 },
    { lng: 132.87178, lat: 32.78952 },
    { lng: 132.87248, lat: 32.79013 },
    { lng: 132.87254, lat: 32.79019 },
    { lng: 132.87291, lat: 32.79038 },
    { lng: 132.87311, lat: 32.79044 },
    { lng: 132.87331, lat: 32.79047 },
    { lng: 132.87679, lat: 32.79083 },
    { lng: 132.87678, lat: 32.791 },
  ],
  [
    { lng: 132.87716, lat: 32.79165 }, // 三崎市民センター
    { lng: 132.87708, lat: 32.79169 },
    { lng: 132.87643, lat: 32.79195 },
    { lng: 132.87628, lat: 32.79202 },
    { lng: 132.87623, lat: 32.79203 },
    { lng: 132.87604, lat: 32.792 },
    { lng: 132.87595, lat: 32.79197 },
    { lng: 132.87594, lat: 32.79198 },
    { lng: 132.87526, lat: 32.79327 },
    { lng: 132.87525, lat: 32.79328 },
    { lng: 132.87497, lat: 32.79381 },
    { lng: 132.87495, lat: 32.79384 },
    { lng: 132.87487, lat: 32.79402 },
  ],
  [
    { lng: 132.87397, lat: 32.79562 }, // 下ノ段
    { lng: 132.87386, lat: 32.79573 },
    { lng: 132.87381, lat: 32.79575 },
    { lng: 132.87354, lat: 32.79594 },
    { lng: 132.87348, lat: 32.796 },
    { lng: 132.8733, lat: 32.79622 },
    { lng: 132.87203, lat: 32.79558 },
    { lng: 132.872, lat: 32.79557 },
    { lng: 132.87134, lat: 32.79524 },
    { lng: 132.87131, lat: 32.79523 },
    { lng: 132.87127, lat: 32.79523 },
    { lng: 132.87123, lat: 32.79522 },
    { lng: 132.87108, lat: 32.79522 },
    { lng: 132.8708, lat: 32.79527 },
    { lng: 132.86957, lat: 32.79558 },
    { lng: 132.86955, lat: 32.79558 },
    { lng: 132.86904, lat: 32.79569 },
    { lng: 132.86869, lat: 32.79569 },
    { lng: 132.86864, lat: 32.7957 },
    { lng: 132.8682, lat: 32.79587 },
    { lng: 132.8679, lat: 32.79601 },
    { lng: 132.86784, lat: 32.79603 },
    { lng: 132.86763, lat: 32.79606 },
    { lng: 132.86749, lat: 32.79609 },
    { lng: 132.86727, lat: 32.79611 },
    { lng: 132.86706, lat: 32.79607 },
  ],
  [
    { lng: 132.86703, lat: 32.79607 }, // 平ノ段
    { lng: 132.86706, lat: 32.79607 },
    { lng: 132.86727, lat: 32.79611 },
    { lng: 132.86749, lat: 32.79609 },
    { lng: 132.86763, lat: 32.79606 },
    { lng: 132.86784, lat: 32.79603 },
    { lng: 132.8679, lat: 32.79601 },
    { lng: 132.8682, lat: 32.79587 },
    { lng: 132.86864, lat: 32.7957 },
    { lng: 132.86869, lat: 32.79569 },
    { lng: 132.86904, lat: 32.79569 },
    { lng: 132.86955, lat: 32.79558 },
    { lng: 132.86957, lat: 32.79558 },
    { lng: 132.8708, lat: 32.79527 },
    { lng: 132.87108, lat: 32.79522 },
    { lng: 132.87123, lat: 32.79522 },
    { lng: 132.87127, lat: 32.79523 },
    { lng: 132.87131, lat: 32.79523 },
    { lng: 132.87134, lat: 32.79524 },
    { lng: 132.872, lat: 32.79557 },
    { lng: 132.87203, lat: 32.79558 },
    { lng: 132.87197, lat: 32.79567 },
    { lng: 132.87195, lat: 32.79571 },
    { lng: 132.87193, lat: 32.79573 },
    { lng: 132.87178, lat: 32.79607 },
    { lng: 132.87178, lat: 32.79609 },
    { lng: 132.87177, lat: 32.79611 },
    { lng: 132.87177, lat: 32.79612 },
    { lng: 132.87173, lat: 32.7963 },
    { lng: 132.87169, lat: 32.79658 },
    { lng: 132.87169, lat: 32.79678 },
    { lng: 132.8717, lat: 32.7969 },
    { lng: 132.87182, lat: 32.79761 },
    { lng: 132.87183, lat: 32.79777 },
    { lng: 132.87185, lat: 32.7979 },
    { lng: 132.87186, lat: 32.7988 },
    { lng: 132.87188, lat: 32.799 },
    { lng: 132.87194, lat: 32.79921 },
    { lng: 132.87198, lat: 32.79932 },
    { lng: 132.87205, lat: 32.79944 },
    { lng: 132.87205, lat: 32.79945 },
    { lng: 132.87215, lat: 32.79955 },
    { lng: 132.87217, lat: 32.79956 },
    { lng: 132.87221, lat: 32.79956 },
    { lng: 132.87218, lat: 32.79972 },
    { lng: 132.87223, lat: 32.80113 },
    { lng: 132.87224, lat: 32.80118 },
    { lng: 132.87229, lat: 32.80127 },
    { lng: 132.87241, lat: 32.80137 },
    { lng: 132.87319, lat: 32.80181 },
    { lng: 132.87329, lat: 32.80188 },
    { lng: 132.87331, lat: 32.8019 },
    { lng: 132.87333, lat: 32.80194 },
    { lng: 132.87333, lat: 32.80195 },
    { lng: 132.87334, lat: 32.80199 },
    { lng: 132.87333, lat: 32.80201 },
    { lng: 132.87331, lat: 32.80213 },
    { lng: 132.87326, lat: 32.80226 },
    { lng: 132.87326, lat: 32.80233 },
    { lng: 132.8733, lat: 32.80252 },
    { lng: 132.87351, lat: 32.80289 },
    { lng: 132.87354, lat: 32.80298 },
    { lng: 132.87356, lat: 32.80302 },
    { lng: 132.8736, lat: 32.80307 },
    { lng: 132.87361, lat: 32.80309 },
    { lng: 132.87367, lat: 32.80312 },
    { lng: 132.87372, lat: 32.80313 },
    { lng: 132.87378, lat: 32.80313 },
    { lng: 132.87388, lat: 32.80311 },
    { lng: 132.87417, lat: 32.80302 },
    { lng: 132.87439, lat: 32.80299 },
    { lng: 132.87446, lat: 32.80299 },
    { lng: 132.87454, lat: 32.803 },
    { lng: 132.87467, lat: 32.80303 },
    { lng: 132.87475, lat: 32.80306 },
    { lng: 132.87492, lat: 32.80314 },
    { lng: 132.87502, lat: 32.80317 },
    { lng: 132.8752, lat: 32.8032 },
    { lng: 132.87558, lat: 32.8032 },
    { lng: 132.87571, lat: 32.80318 },
    { lng: 132.87573, lat: 32.80317 },
    { lng: 132.87581, lat: 32.80315 },
    { lng: 132.87652, lat: 32.80287 },
    { lng: 132.87659, lat: 32.80286 },
    { lng: 132.87664, lat: 32.80286 },
    { lng: 132.87671, lat: 32.80288 },
    { lng: 132.87677, lat: 32.80289 },
    { lng: 132.87682, lat: 32.80292 },
    { lng: 132.87687, lat: 32.80296 },
    { lng: 132.87694, lat: 32.80304 },
    { lng: 132.87698, lat: 32.80311 },
    { lng: 132.87726, lat: 32.80382 },
    { lng: 132.8773, lat: 32.8039 },
    { lng: 132.87739, lat: 32.80404 },
    { lng: 132.87767, lat: 32.8044 },
    { lng: 132.8779, lat: 32.80462 },
    { lng: 132.87806, lat: 32.80475 },
    { lng: 132.87826, lat: 32.80487 },
    { lng: 132.87841, lat: 32.80494 },
    { lng: 132.87909, lat: 32.80513 },
    { lng: 132.87934, lat: 32.80523 },
    { lng: 132.8795, lat: 32.80533 },
    { lng: 132.87975, lat: 32.80553 },
    { lng: 132.8798, lat: 32.8056 },
    { lng: 132.87984, lat: 32.80567 },
    { lng: 132.87987, lat: 32.80576 },
    { lng: 132.88012, lat: 32.80778 },
    { lng: 132.88012, lat: 32.80782 },
    { lng: 132.88011, lat: 32.80785 },
    { lng: 132.88009, lat: 32.80789 },
    { lng: 132.88001, lat: 32.80798 },
    { lng: 132.87993, lat: 32.80804 },
    { lng: 132.87973, lat: 32.80823 },
    { lng: 132.87963, lat: 32.80837 },
    { lng: 132.87955, lat: 32.80855 },
    { lng: 132.87952, lat: 32.80868 },
    { lng: 132.87949, lat: 32.80887 },
    { lng: 132.8795, lat: 32.80938 },
    { lng: 132.87948, lat: 32.80988 },
    { lng: 132.8795, lat: 32.80998 },
    { lng: 132.87954, lat: 32.81009 },
    { lng: 132.87958, lat: 32.81014 },
    { lng: 132.87961, lat: 32.81019 },
    { lng: 132.87991, lat: 32.8105 },
    { lng: 132.87998, lat: 32.81059 },
    { lng: 132.88005, lat: 32.8107 },
    { lng: 132.88011, lat: 32.81083 },
    { lng: 132.88023, lat: 32.81116 },
    { lng: 132.88022, lat: 32.81119 },
    { lng: 132.88021, lat: 32.81125 },
    { lng: 132.88017, lat: 32.81136 },
    { lng: 132.87994, lat: 32.81185 },
    { lng: 132.87992, lat: 32.81192 },
    { lng: 132.87994, lat: 32.81205 },
    { lng: 132.87994, lat: 32.8122 },
    { lng: 132.87993, lat: 32.81231 },
    { lng: 132.87991, lat: 32.81239 },
    { lng: 132.8799, lat: 32.81246 },
    { lng: 132.8799, lat: 32.81254 },
    { lng: 132.87992, lat: 32.81258 },
    { lng: 132.87995, lat: 32.81269 },
    { lng: 132.87999, lat: 32.8128 },
    { lng: 132.88003, lat: 32.81296 },
    { lng: 132.88009, lat: 32.81311 },
    { lng: 132.88015, lat: 32.81337 },
    { lng: 132.88017, lat: 32.81368 },
    { lng: 132.88016, lat: 32.81379 },
    { lng: 132.88019, lat: 32.81401 },
    { lng: 132.88021, lat: 32.81409 },
    { lng: 132.88024, lat: 32.81414 },
    { lng: 132.88026, lat: 32.81416 },
  ],
  [
    { lng: 132.88028, lat: 32.81419 }, // 斧積"
    { lng: 132.88032, lat: 32.81421 },
    { lng: 132.88038, lat: 32.81423 },
    { lng: 132.88215, lat: 32.81434 },
    { lng: 132.88221, lat: 32.81435 },
    { lng: 132.88226, lat: 32.81434 },
    { lng: 132.88228, lat: 32.81433 },
    { lng: 132.88232, lat: 32.81429 },
    { lng: 132.88234, lat: 32.81426 },
    { lng: 132.88244, lat: 32.81399 },
    { lng: 132.8825, lat: 32.81359 },
    { lng: 132.88251, lat: 32.81347 },
    { lng: 132.88251, lat: 32.81289 },
    { lng: 132.88252, lat: 32.81283 },
    { lng: 132.88254, lat: 32.8128 },
    { lng: 132.88256, lat: 32.81278 },
    { lng: 132.88258, lat: 32.81275 },
    { lng: 132.88261, lat: 32.81274 },
    { lng: 132.88264, lat: 32.81274 },
    { lng: 132.88265, lat: 32.81273 },
    { lng: 132.88272, lat: 32.81272 },
    { lng: 132.88317, lat: 32.81262 },
    { lng: 132.88325, lat: 32.81261 },
    { lng: 132.88337, lat: 32.81262 },
    { lng: 132.88347, lat: 32.81264 },
    { lng: 132.88357, lat: 32.81267 },
    { lng: 132.88385, lat: 32.8128 },
    { lng: 132.88388, lat: 32.81281 },
    { lng: 132.88467, lat: 32.81318 },
    { lng: 132.88475, lat: 32.81321 },
    { lng: 132.88485, lat: 32.81323 },
    { lng: 132.88547, lat: 32.81326 },
    { lng: 132.88559, lat: 32.81328 },
    { lng: 132.88594, lat: 32.81329 },
    { lng: 132.88601, lat: 32.8133 },
    { lng: 132.88605, lat: 32.81332 },
    { lng: 132.88607, lat: 32.81332 },
    { lng: 132.88609, lat: 32.81334 },
    { lng: 132.88613, lat: 32.81336 },
    { lng: 132.88618, lat: 32.8134 },
    { lng: 132.88633, lat: 32.81348 },
    { lng: 132.88645, lat: 32.81353 },
    { lng: 132.88653, lat: 32.81355 },
    { lng: 132.88663, lat: 32.81356 },
    { lng: 132.88707, lat: 32.81355 },
    { lng: 132.88714, lat: 32.81356 },
    { lng: 132.88721, lat: 32.81358 },
    { lng: 132.88727, lat: 32.81361 },
    { lng: 132.88735, lat: 32.81366 },
    { lng: 132.88739, lat: 32.81372 },
    { lng: 132.88752, lat: 32.81403 },
    { lng: 132.88755, lat: 32.81422 },
    { lng: 132.88759, lat: 32.81432 },
    { lng: 132.88763, lat: 32.81439 },
    { lng: 132.88774, lat: 32.81452 },
    { lng: 132.88896, lat: 32.81545 },
    { lng: 132.88911, lat: 32.81553 },
    { lng: 132.88921, lat: 32.81557 },
    { lng: 132.88941, lat: 32.81561 },
    { lng: 132.88953, lat: 32.81562 },
    { lng: 132.88979, lat: 32.8156 },
    { lng: 132.89042, lat: 32.81551 },
    { lng: 132.89049, lat: 32.81549 },
    { lng: 132.89052, lat: 32.81549 },
    { lng: 132.89057, lat: 32.81548 },
    { lng: 132.89111, lat: 32.81523 },
    { lng: 132.89151, lat: 32.81499 },
    { lng: 132.8924, lat: 32.81452 },
    { lng: 132.89244, lat: 32.81449 },
    { lng: 132.89248, lat: 32.81447 },
    { lng: 132.89254, lat: 32.81445 },
    { lng: 132.89287, lat: 32.81438 },
    { lng: 132.89324, lat: 32.81428 },
    { lng: 132.89372, lat: 32.81405 },
    { lng: 132.89376, lat: 32.81402 },
    { lng: 132.8939, lat: 32.81394 },
    { lng: 132.89425, lat: 32.81438 },
    { lng: 132.89546, lat: 32.81607 },
    { lng: 132.89562, lat: 32.81626 },
    { lng: 132.89647, lat: 32.81713 },
    { lng: 132.89661, lat: 32.81726 },
  ],
  [
    { lng: 132.89665, lat: 32.81729 }, // 上野
    { lng: 132.89661, lat: 32.81726 },
    { lng: 132.89647, lat: 32.81713 },
    { lng: 132.89562, lat: 32.81626 },
    { lng: 132.89546, lat: 32.81607 },
    { lng: 132.89425, lat: 32.81438 },
    { lng: 132.89318, lat: 32.81303 },
    { lng: 132.89313, lat: 32.81295 },
    { lng: 132.8931, lat: 32.81287 },
    { lng: 132.89308, lat: 32.81277 },
    { lng: 132.89307, lat: 32.81268 },
    { lng: 132.89308, lat: 32.81259 },
    { lng: 132.8931, lat: 32.8125 },
    { lng: 132.89312, lat: 32.81248 },
    { lng: 132.89314, lat: 32.81242 },
    { lng: 132.89326, lat: 32.81217 },
    { lng: 132.89331, lat: 32.81209 },
    { lng: 132.89338, lat: 32.81193 },
    { lng: 132.89344, lat: 32.81175 },
    { lng: 132.89365, lat: 32.81074 },
    { lng: 132.8937, lat: 32.81038 },
    { lng: 132.89394, lat: 32.80752 },
    { lng: 132.89401, lat: 32.80722 },
    { lng: 132.89505, lat: 32.80445 },
    { lng: 132.89533, lat: 32.80385 },
    { lng: 132.89629, lat: 32.80218 },
    { lng: 132.89669, lat: 32.80143 },
    { lng: 132.89671, lat: 32.80141 },
    { lng: 132.89672, lat: 32.80139 },
  ],
  [
    { lng: 132.89692, lat: 32.80128 }, // 益野橋バス停
    { lng: 132.89694, lat: 32.8013 },
    { lng: 132.89716, lat: 32.80161 },
    { lng: 132.89738, lat: 32.80189 },
    { lng: 132.8979, lat: 32.80262 },
    { lng: 132.89798, lat: 32.8027 },
    { lng: 132.898, lat: 32.80273 },
    { lng: 132.89813, lat: 32.80284 },
    { lng: 132.89824, lat: 32.80291 },
    { lng: 132.89828, lat: 32.80293 },
    { lng: 132.89844, lat: 32.80299 },
    { lng: 132.89845, lat: 32.80299 },
    { lng: 132.90007, lat: 32.80355 },
    { lng: 132.90009, lat: 32.80355 },
    { lng: 132.90019, lat: 32.80358 },
    { lng: 132.90035, lat: 32.80361 },
    { lng: 132.90046, lat: 32.80361 },
    { lng: 132.90063, lat: 32.80358 },
    { lng: 132.9007, lat: 32.80354 },
    { lng: 132.90073, lat: 32.80353 },
    { lng: 132.90084, lat: 32.80346 },
    { lng: 132.90243, lat: 32.80181 },
    { lng: 132.90247, lat: 32.80174 },
    { lng: 132.90254, lat: 32.80165 },
    { lng: 132.90257, lat: 32.80141 },
    { lng: 132.90255, lat: 32.80127 },
    { lng: 132.90249, lat: 32.80106 },
    { lng: 132.90231, lat: 32.80013 },
    { lng: 132.90231, lat: 32.8001 },
    { lng: 132.90225, lat: 32.79984 },
    { lng: 132.90224, lat: 32.79975 },
    { lng: 132.90221, lat: 32.79961 },
    { lng: 132.90221, lat: 32.79941 },
    { lng: 132.90222, lat: 32.79936 },
    { lng: 132.90242, lat: 32.79872 },
    { lng: 132.90245, lat: 32.79859 },
    { lng: 132.90262, lat: 32.79804 },
    { lng: 132.9027, lat: 32.79783 },
    { lng: 132.90276, lat: 32.7977 },
    { lng: 132.90277, lat: 32.79769 },
    { lng: 132.90362, lat: 32.79625 },
    { lng: 132.90368, lat: 32.79617 },
    { lng: 132.90373, lat: 32.79608 },
    { lng: 132.90424, lat: 32.7953 },
    { lng: 132.90424, lat: 32.79529 },
    { lng: 132.90431, lat: 32.79516 },
    { lng: 132.90435, lat: 32.79506 },
    { lng: 132.90439, lat: 32.79491 },
    { lng: 132.90439, lat: 32.79474 },
    { lng: 132.90437, lat: 32.7946 },
    { lng: 132.90433, lat: 32.79449 },
    { lng: 132.90423, lat: 32.79428 },
    { lng: 132.90422, lat: 32.79427 },
    { lng: 132.90415, lat: 32.79413 },
    { lng: 132.90414, lat: 32.79413 },
    { lng: 132.90411, lat: 32.79405 },
    { lng: 132.90409, lat: 32.79396 },
    { lng: 132.9041, lat: 32.79379 },
    { lng: 132.90411, lat: 32.79374 },
    { lng: 132.90413, lat: 32.79371 },
    { lng: 132.90413, lat: 32.7937 },
    { lng: 132.90416, lat: 32.79364 },
    { lng: 132.90455, lat: 32.79307 },
    { lng: 132.90459, lat: 32.79303 },
    { lng: 132.90463, lat: 32.79296 },
    { lng: 132.90517, lat: 32.79232 },
    { lng: 132.9053, lat: 32.79219 },
    { lng: 132.90578, lat: 32.79162 },
    { lng: 132.9058, lat: 32.79161 },
    { lng: 132.906, lat: 32.79136 },
    { lng: 132.90602, lat: 32.79132 },
    { lng: 132.90602, lat: 32.79131 },
    { lng: 132.90605, lat: 32.79126 },
    { lng: 132.9061, lat: 32.79111 },
    { lng: 132.90613, lat: 32.79095 },
    { lng: 132.90621, lat: 32.79028 },
    { lng: 132.90621, lat: 32.79024 },
    { lng: 132.90624, lat: 32.79013 },
    { lng: 132.90626, lat: 32.79009 },
    { lng: 132.90628, lat: 32.79003 },
    { lng: 132.90633, lat: 32.78994 },
    { lng: 132.90636, lat: 32.78991 },
    { lng: 132.90637, lat: 32.78989 },
    { lng: 132.90649, lat: 32.78975 },
    { lng: 132.9066, lat: 32.78967 },
    { lng: 132.90679, lat: 32.78955 },
    { lng: 132.90773, lat: 32.78909 },
    { lng: 132.90819, lat: 32.78883 },
    { lng: 132.90847, lat: 32.78871 },
    { lng: 132.90926, lat: 32.7886 },
    { lng: 132.90938, lat: 32.78857 },
    { lng: 132.9094, lat: 32.78856 },
    { lng: 132.9095, lat: 32.78853 },
    { lng: 132.90985, lat: 32.78837 },
    { lng: 132.91028, lat: 32.78805 },
    { lng: 132.91029, lat: 32.78805 },
    { lng: 132.91038, lat: 32.78798 },
    { lng: 132.91052, lat: 32.78791 },
    { lng: 132.91062, lat: 32.78787 },
    { lng: 132.91067, lat: 32.78786 },
    { lng: 132.91073, lat: 32.78784 },
    { lng: 132.9108, lat: 32.78783 },
    { lng: 132.91087, lat: 32.78783 },
    { lng: 132.91092, lat: 32.78782 },
    { lng: 132.91097, lat: 32.78783 },
    { lng: 132.91106, lat: 32.78783 },
    { lng: 132.91123, lat: 32.78787 },
    { lng: 132.91189, lat: 32.78814 },
    { lng: 132.91199, lat: 32.78819 },
    { lng: 132.91213, lat: 32.78824 },
    { lng: 132.91239, lat: 32.78829 },
    { lng: 132.91257, lat: 32.78829 },
    { lng: 132.91261, lat: 32.78828 },
    { lng: 132.91263, lat: 32.78828 },
    { lng: 132.91298, lat: 32.78818 },
    { lng: 132.91302, lat: 32.78816 },
    { lng: 132.91313, lat: 32.78812 },
    { lng: 132.91324, lat: 32.78807 },
    { lng: 132.91341, lat: 32.78801 },
    { lng: 132.91645, lat: 32.78721 },
    { lng: 132.91655, lat: 32.78717 },
    { lng: 132.91742, lat: 32.78693 },
    { lng: 132.91771, lat: 32.78682 },
    { lng: 132.91817, lat: 32.7866 },
  ],
  [
    { lng: 132.91924, lat: 32.786 }, // 松崎
    { lng: 132.91952, lat: 32.78587 },
    { lng: 132.91985, lat: 32.78576 },
    { lng: 132.92133, lat: 32.78551 },
    { lng: 132.92218, lat: 32.78528 },
    { lng: 132.92224, lat: 32.78527 },
    { lng: 132.92254, lat: 32.78518 },
    { lng: 132.92259, lat: 32.78517 },
    { lng: 132.92268, lat: 32.78517 },
    { lng: 132.92408, lat: 32.7848 },
    { lng: 132.92411, lat: 32.7848 },
    { lng: 132.9242, lat: 32.78477 },
    { lng: 132.92842, lat: 32.78367 },
    { lng: 132.9285, lat: 32.78366 },
    { lng: 132.92854, lat: 32.78366 },
    { lng: 132.92857, lat: 32.78365 },
    { lng: 132.92878, lat: 32.78363 },
    { lng: 132.93175, lat: 32.78363 },
  ],
  // 養老
  [
    { lng: 132.93197, lat: 32.78364 },
    { lng: 132.93212, lat: 32.78369 },
    { lng: 132.93223, lat: 32.78374 },
    { lng: 132.9323, lat: 32.78379 },
    { lng: 132.9323, lat: 32.7838 },
    { lng: 132.93235, lat: 32.78385 },
    { lng: 132.93239, lat: 32.78392 },
    { lng: 132.93239, lat: 32.78393 },
    { lng: 132.93255, lat: 32.7843 },
    { lng: 132.93265, lat: 32.78445 },
    { lng: 132.93315, lat: 32.78494 },
    { lng: 132.93319, lat: 32.78499 },
    { lng: 132.93326, lat: 32.78512 },
    { lng: 132.93345, lat: 32.78585 },
    { lng: 132.93348, lat: 32.78591 },
    { lng: 132.93349, lat: 32.78594 },
    { lng: 132.93353, lat: 32.78601 },
    { lng: 132.93357, lat: 32.78606 },
    { lng: 132.9336, lat: 32.78608 },
    { lng: 132.93362, lat: 32.78611 },
    { lng: 132.9338, lat: 32.78626 },
    { lng: 132.93417, lat: 32.78653 },
    { lng: 132.93428, lat: 32.78659 },
    { lng: 132.93498, lat: 32.78691 },
    { lng: 132.93516, lat: 32.78697 },
    { lng: 132.93526, lat: 32.78699 },
    { lng: 132.93611, lat: 32.78697 },
    { lng: 132.93613, lat: 32.78696 },
    { lng: 132.93618, lat: 32.78696 },
    { lng: 132.93622, lat: 32.78695 },
    { lng: 132.93625, lat: 32.78695 },
    { lng: 132.93629, lat: 32.78694 },
    { lng: 132.93651, lat: 32.78686 },
    { lng: 132.93671, lat: 32.78676 },
    { lng: 132.93679, lat: 32.78673 },
    { lng: 132.93683, lat: 32.7867 },
    { lng: 132.9369, lat: 32.78668 },
    { lng: 132.93694, lat: 32.78666 },
    { lng: 132.9371, lat: 32.78662 },
    { lng: 132.93729, lat: 32.78662 },
    { lng: 132.93819, lat: 32.78677 },
    { lng: 132.93835, lat: 32.78681 },
    { lng: 132.93842, lat: 32.78682 },
    { lng: 132.93855, lat: 32.78687 },
    { lng: 132.93867, lat: 32.78694 },
    { lng: 132.93877, lat: 32.78704 },
    { lng: 132.93922, lat: 32.7876 },
    { lng: 132.93927, lat: 32.78765 },
    { lng: 132.93927, lat: 32.78766 },
    { lng: 132.93931, lat: 32.78769 },
    { lng: 132.9394, lat: 32.78774 },
    { lng: 132.93953, lat: 32.78778 },
    { lng: 132.93958, lat: 32.78779 },
    { lng: 132.94398, lat: 32.78778 },
    { lng: 132.94417, lat: 32.78774 },
    { lng: 132.94419, lat: 32.78773 },
    { lng: 132.9442, lat: 32.78773 },
    { lng: 132.94438, lat: 32.78767 },
    { lng: 132.94453, lat: 32.78759 },
    { lng: 132.94472, lat: 32.78745 },
    { lng: 132.94474, lat: 32.78743 },
    { lng: 132.94476, lat: 32.78742 },
    { lng: 132.94502, lat: 32.78719 },
    { lng: 132.94506, lat: 32.78717 },
    { lng: 132.9451, lat: 32.78712 },
    { lng: 132.94656, lat: 32.78587 },
    { lng: 132.94657, lat: 32.78587 },
    { lng: 132.94713, lat: 32.78538 },
    { lng: 132.94721, lat: 32.7853 },
    { lng: 132.94726, lat: 32.78526 },
    { lng: 132.94729, lat: 32.78521 },
    { lng: 132.94733, lat: 32.78517 },
    { lng: 132.94734, lat: 32.78513 },
    { lng: 132.94737, lat: 32.78508 },
    { lng: 132.94738, lat: 32.78504 },
    { lng: 132.94738, lat: 32.78502 },
    { lng: 132.9474, lat: 32.78492 },
    { lng: 132.9474, lat: 32.78486 },
    { lng: 132.94739, lat: 32.78481 },
    { lng: 132.94737, lat: 32.78476 },
    { lng: 132.94737, lat: 32.78474 },
    { lng: 132.94735, lat: 32.78471 },
    { lng: 132.94732, lat: 32.78463 },
    { lng: 132.94711, lat: 32.78427 },
    { lng: 132.94704, lat: 32.78409 },
    { lng: 132.94702, lat: 32.78402 },
    { lng: 132.94699, lat: 32.7838 },
    { lng: 132.94699, lat: 32.78367 },
    { lng: 132.9469, lat: 32.78261 },
    { lng: 132.94685, lat: 32.78223 },
    { lng: 132.94679, lat: 32.78201 },
    { lng: 132.94666, lat: 32.78163 },
    { lng: 132.95252, lat: 32.78033 },
    { lng: 132.95265, lat: 32.78031 },
    { lng: 132.95364, lat: 32.7801 },
  ], // 市街地（清水）
];
