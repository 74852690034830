import { MinTime, CustomerLog, CarLog } from "../../types/data_types";
import moment from "moment";

export const getDistance = (
  lat1: number,
  lng1: number,
  lat2: number,
  lng2: number,
) => {
  lat1 *= Math.PI / 180;
  lng1 *= Math.PI / 180;
  lat2 *= Math.PI / 180;
  lng2 *= Math.PI / 180;
  return (
    6371 *
    Math.acos(
      Math.cos(lat1) * Math.cos(lat2) * Math.cos(lng2 - lng1) +
        Math.sin(lat1) * Math.sin(lat2),
    )
  );
};

export const updateMinTime = (customerLogs: CustomerLog[]) => {
  const minTime = customerLogs.reduce((tmp: MinTime, log: CustomerLog) => {
    if (tmp.start === undefined && log.getOnAt) {
      tmp.start = log.getOnAt;
    }
    if (tmp.start === undefined && log.notFoundAt) {
      tmp.start = log.notFoundAt;
    }
    if (tmp.end === undefined && log.getOffAt) {
      tmp.end = log.getOffAt;
    }
    if (tmp.end === undefined && log.notFoundAt) {
      tmp.end = log.notFoundAt;
    }

    if (log.notFoundAt) {
      if (tmp.start > log.notFoundAt) {
        tmp.start = log.notFoundAt;
      }
    } else {
      if (tmp.start > log.getOnAt) {
        tmp.start = log.getOnAt;
      }
      if (tmp.end < log.getOffAt) {
        tmp.end = log.getOffAt;
      }
    }
    return tmp;
  }, {} as MinTime);
  if (minTime.end === undefined) {
    minTime.end = minTime.start;
  }
  return minTime;
};

export const isEditableData = (carLog: CarLog) => {
  // TODO Date
  if (carLog.endAt) {
    return true;
  }

  if (
    Number(moment().format("YYYYMMDD")) >
    Number((carLog.date || "").replace(/-/g, ""))
  ) {
    return true;
  }
  return false;
};
export const getCurrentCarStatus = (carLog: CarLog) => {
  if (carLog.endAt) {
    return "入庫済み";
  }
  if (carLog.serviceEndAt) {
    return "到着済み";
  }
  if (carLog.serviceStartAt) {
    return "出発済み";
  }
  if (carLog.startAt) {
    return "出庫済み";
  }
  return "運行前";
};
