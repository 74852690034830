import React, { useReducer, useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import GoogleMapReact from "google-map-react";
import { mapKey } from "../config";

import InfoWindow from "./InfoWindow";
import InfoWindowHome from "./InfoWindowHome";

import { kochiPosition, defaultCenter } from "./utils/position";

function infowindowReducer(state: any, action: any) {
  if (state) {
    state.close();
  }
  return action;
}

type Props = {
  homePosition: any;
  setHomePosition: any;
  clickPosition: any;
  setClickPosition: any;
};

const GoogleMapHome: React.FC<Props> = ({
  homePosition,
  setHomePosition,
  clickPosition,
  setClickPosition,
}) => {
  const props = {
    homePosition,
    setHomePosition,
    clickPosition,
    setClickPosition,
  };

  const [clickPositionMarker, setClickPositionMarker] = useState<any>(null);
  const [infoWindowInstance, setInfoWindowInstance] = useReducer(
    infowindowReducer,
    null,
  );

  const [homePositionMarker, setHomePositionMarker] = useState<any>(null);

  const [center, setCenter] = useState<any>(kochiPosition);
  const [zoom, setZoom] = useState(11);
  // Google js instances
  const [myGoogleMap, setMyGoogleMap] = useState<any>(null);

  useEffect(() => {
    (async () => {
      if (myGoogleMap) {
        const map = myGoogleMap.map;
        map.setZoom(zoom);
      }
    })();
  }, [myGoogleMap, zoom]);

  useEffect(() => {
    setZoom(15);
  }, [homePosition]);

  const setClickMarker = (
    position: any,
    icon: string,
    id: string,
    name: string,
    type: string,
  ) => {
    const marker = setMarker(
      position,
      "http://maps.google.com/mapfiles/" + icon,
    );
    // open reservation window
    const data = { id, clickPosition: position, name, type };
    openReservationWindow("home", data, myGoogleMap.map, myGoogleMap.maps)();
    marker.addListener(
      "click",
      openReservationWindow("home", data, myGoogleMap.map, myGoogleMap.maps),
    );
    return marker;
  };
  const setMarker = (position: any, icon: string) => {
    const marker = new myGoogleMap.maps.Marker({
      position,
      map: myGoogleMap.map,
      icon: icon,
    });
    return marker;
  };

  useEffect(() => {
    // update user click position
    if (clickPositionMarker) {
      clickPositionMarker.setMap(null);
    }
    if (myGoogleMap) {
      const marker = setClickMarker(
        clickPosition,
        "ms/icons/blue-dot.png",
        "clickPoint",
        "ここ",
        "click",
      );
      setClickPositionMarker(marker);
    }
  }, [clickPosition]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (myGoogleMap && homePosition && homePosition.lat && homePosition.lng) {
      if (homePositionMarker) {
        homePositionMarker.setMap(null);
      }
      setCenter(homePosition);
      const marker = setMarker(
        homePosition,
        "http://maps.google.com/mapfiles/ms/icons/pink-dot.png",
      );
      setHomePositionMarker(marker);
    }
  }, [myGoogleMap, homePosition]); // eslint-disable-line react-hooks/exhaustive-deps

  function callbackPin(action: string, data: any) {
    if (action === "home") {
      setHomePosition(data.clickPosition);
    }
    setInfoWindowInstance(null);
  }
  function openReservationWindow(type: string, data: any, map: any, maps: any) {
    const { id, clickPosition, name } = data;
    return () => {
      const offset = new maps.Size(0, -30);
      const infoWindow = new maps.InfoWindow({
        content: '<div id="' + id + '" />',
        position: clickPosition,
        pixelOffset: offset,
      });
      const params = { id, callbackPin, name, data };
      infoWindow.addListener("domready", (e: any) => {
        const root = ReactDOM.createRoot(document.getElementById(id)!);
        root.render(
          <InfoWindow>
            {type === "home" ? <InfoWindowHome {...props} {...params} /> : ""}
          </InfoWindow>,
        );
      });
      infoWindow.open(map);
      setInfoWindowInstance(infoWindow);
    };
  }

  const mapClickEvent = (e: any) => {
    setInfoWindowInstance(null);
    setClickPosition({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
  };

  const renderMarkers = (map: any, maps: any) => {
    setMyGoogleMap({ maps, map });

    map.addListener("click", mapClickEvent);
  };

  return (
    <React.Fragment>
      <GoogleMapReact
        bootstrapURLKeys={{ key: mapKey }}
        defaultCenter={defaultCenter}
        yesIWantToUseGoogleMapApiInternals={true}
        center={center}
        defaultZoom={11}
        zoom={zoom}
        onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
      ></GoogleMapReact>
    </React.Fragment>
  );
};

export default GoogleMapHome;
