import React, { useState } from "react";
import { Link } from "react-router-dom";

import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Drawer,
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";

import { env } from "./config";

import { DefaultLoginProps } from "./props";

const Header: React.FC<DefaultLoginProps> = ({ user, login }) => {
  const [drawer, setDrawer] = useState(false);

  const handleMenu = () => {
    setDrawer(true);
  };
  const handleClose = () => {
    setDrawer(false);
  };
  const logout = () => {
    firebase.auth().signOut();
  };

  return (
    <div style={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        className={env === "dev" ? "!bg-orange-400" : ""}
      >
        <Toolbar>
          <IconButton
            sx={{ marginLeft: "-12px", marginRight: "20px" }}
            onClick={handleMenu}
            color="inherit"
            aria-label="Menu"
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" sx={{ flexGrow: 1 }}>
            バス予約
          </Typography>
          {user ? (
            <Button color="inherit" onClick={logout}>
              Logout
            </Button>
          ) : (
            <Button color="inherit" to={login || "/login"} component={Link}>
              Login
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <Drawer open={drawer} onClose={handleClose}>
        <List>
          <ListItem button to="/" component={Link}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>
          <Divider />
          <ListItem button to="/about" component={Link}>
            <ListItemIcon>
              <InfoIcon />
            </ListItemIcon>
            <ListItemText primary="About" />
          </ListItem>
        </List>
      </Drawer>
    </div>
  );
};

export default Header;
