import React, { useState } from "react";
import { Radio } from "@mui/material";

type Props = {
  carData: any;
  day: any;
  lineId: any;
  defaultValue: any;
  handleChangeCar: any;
};

const Car: React.FC<Props> = ({
  carData,
  day,
  lineId,
  defaultValue,
  handleChangeCar,
}) => {
  const [value, setValue] = useState(defaultValue);

  const handleData = (key: string, lineId: string, id: string) => {
    return (e: any) => {
      setValue(id);
      handleChangeCar(key, lineId, id);
    };
  };

  return (
    <React.Fragment>
      {carData.map((car: any, key3: number) => {
        return (
          <React.Fragment key={key3}>
            <Radio
              checked={value === car.id}
              onChange={handleData(day, lineId, car.id)}
              value={car.id}
              color="default"
              name="radio-button-demo"
              inputProps={{ "aria-label": "E" }}
              size="small"
            />
            {car.name}
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

export default Car;
